import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import { Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { Icon } from '@iconify/react';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import apiInstance from '../../../../apis/ApiService.jsx';
import MRTIconButton from '../../../../components/table/MRTIconButton.tsx';

const ListCashBankReceipt = (props) => {
  const {
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading } = useLoader();
  const { control, setValue, handleSubmit } = useForm();
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const location = useLocation();

  const handleEdit = (row) => {
    setEditingRowId(row?.original?.financialTransactionId);
    setEditingRowData({ ...row?.original });
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'financialtransactions',
      props?.original?.financialTransactionId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('deleted successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleUpdateEvent = async (values) => {
    try {
      // Start loading indicator
      startEditLoading();
      // Prepare the data to be updated
      const combinedData = {
        ...values,
        requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'), // Format resumption date
        chequeDate: dayjs(values?.chequeDate).format('YYYY-MM-DD'),
        Status: 2,
      };
      await crud.update(
        'financialtransactions',
        editingRowId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success(' Updated Successfully');
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
            toast.error('Failed to update');
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating');
    }
  };

  const handleView = (id) => {
    navigate(`${location.pathname}/view/${id}`);
  };
  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => {
        handleView(props?.original?.financialTransactionId);
      },
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
    },
  ];

  const columns = [
    {
      field: 'action',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,

      Cell: (params) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId === params?.row?.original?.financialTransactionId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateEvent)}>Save</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params.row),
                }))}
                selectedRow={params.row}
                setSelectedRow={() => {}}
              />
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'financialTransactionCode',
      header: 'Financial Transaction Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmTextField
            name='financialTransactionCode'
            control={control}
            label='Financial Transaction Code'
          />
        ) : (
          salesDetails?.financialTransactionCode || ''
        );
      },
    },
    {
      accessorKey: 'requestDate',
      header: 'Request Date',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmDatePicker
            name='requestDate'
            label='Request Date'
            control={control}
            required
          />
        ) : (
          FmViewDateField({ value: cell?.row?.original?.requestDate })
        );
      },
    },
    {
      accessorKey: 'bankName',
      header: 'Bank',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='bankId'
            control={control}
            apiUrl='banks'
            valueField='bankId'
            headerField={['Bank Code', 'Bank Name']}
            labelField={['bankCode', 'bankName']}
            showField={['bankName']}
            pageSize={20}
            label='Bank'
            defaultValue={{
              bankId: editingRowData?.bankId,
              bankName: editingRowData?.bankName,
            }}
          />
        ) : (
          salesItemDetails?.bankName || ''
        );
      },
    },
    {
      accessorKey: 'companyId',
      header: 'Company',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            headerField={['Company Code', 'Company Name']}
            labelField={['companyCode', 'companyName']}
            showField={['companyName']}
            pageSize={20}
            label='Company'
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyName: editingRowData?.companyName,
            }}
          />
        ) : (
          incidentReport?.companyName || ''
        );
      },
    },
    {
      accessorKey: 'branchId',
      header: 'Branch',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='branchId'
            control={control}
            apiUrl='emirates'
            valueField='emirateId'
            labelField={['emirateName']}
            showField={['emirateName']}
            pageSize={20}
            label={'Branch'}
            defaultValue={{
              emirateId: editingRowData?.branchId,
              emirateName: editingRowData?.branchName,
            }}
          />
        ) : (
          incidentReport?.branchName || ''
        );
      },
    },
    {
      accessorKey: 'payeeUserId',
      header: 'Payee User',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='payeeUserId'
            control={control}
            headerField={['Code', 'Name']}
            apiUrl='loginusers'
            valueField='loginUserId'
            labelField={['emailId', 'loginUserName']}
            showField={['emailId', 'loginUserName']}
            pageSize={50}
            label='Payee User'
            defaultValue={{
              loginUserId: editingRowData?.payeeUserId,
              loginUserName: editingRowData?.payeeusername,
            }}
          />
        ) : (
          incidentReport?.payeeusername || ''
        );
      },
    },
    {
      accessorKey: 'currencyId',
      header: 'Currency',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='currencyId'
            control={control}
            apiUrl='currencies'
            valueField='currencyId'
            headerField={['Currency Code', 'Currency Name']}
            labelField={['currencyCode', 'currencyName']}
            showField={['currencyName']}
            pageSize={20}
            label='Currency'
            defaultValue={{
              currencyId: editingRowData?.currencyId,
              currencyName: editingRowData?.currencyName,
            }}
          />
        ) : (
          incidentReport?.currencyName || ''
        );
      },
    },
    {
      accessorKey: 'fileId',
      header: 'Attached Document',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return   (<MRTIconButton
        icon={<Icon icon="akar-icons:download" />}
        tooltip={incidentReport?.fileName}
        onClick={async () => {
          if (incidentReport?.fileId) {
          try{  const imageUrl = await apiInstance.getFiles(`files/download/${incidentReport?.fileId}`);
            const link = document.createElement('a');
            link.href = imageUrl;
            link.setAttribute('download', `${incidentReport?.fileName}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);}
          catch (error) {
            toast.error("File not found");
          }
          }
        }}
      />);
      },
    },
    {
      accessorKey: 'narration',
      header: 'Narration',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmTextField name='narration' control={control} label='Narration' />
        ) : (
          incidentReport?.narration || ''
        );
      },
    },
    {
      accessorKey: 'chequeDate',
      header: 'Cheque Date',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmDatePicker
            name='chequeDate'
            label='Cheque Date'
            control={control}
            required
          />
        ) : (
          FmViewDateField({ value: cell?.row?.original?.chequeDate })
        );
      },
    },
    {
      accessorKey: 'jurisdictionId',
      header: 'Jurisdiction',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='jurisdictionId'
            control={control}
            apiUrl='emirates'
            valueField='emirateId'
            labelField={['emirateName']}
            showField={['emirateName']}
            pageSize={20}
            label={'Jurisdiction'}
            defaultValue={{
              emirateId: editingRowData?.jurisdictionId,
              emirateName: editingRowData?.jurisdictionName,
            }}
          />
        ) : (
          incidentReport?.jurisdictionName || ''
        );
      },
    },

    {
      accessorKey: 'currencyId',
      header: 'Currency',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmSearchableSelect
            name='currencyId'
            control={control}
            apiUrl='currencies'
            valueField='currencyId'
            headerField={['Currency Code', 'Currency Name']}
            labelField={['currencyCode', 'currencyName']}
            showField={['currencyName']}
            pageSize={20}
            label='Currency'
            defaultValue={{
              currencyId: editingRowData?.currencyId,
              currencyName: editingRowData?.currencyName,
            }}
          />
        ) : (
          incidentReport?.currencyName || ''
        );
      },
    },
    {
      accessorKey: 'pdcNo',
      header: 'PDCNo',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionId ===
            cell?.row?.original?.financialTransactionId
        );
        return editingRowId === cell?.row?.original?.financialTransactionId ? (
          <FmTextField name='pdcNo' control={control} label='PDCNo' />
        ) : (
          incidentReport?.pdcNo || ''
        );
      },
    },
  ];
  useEffect(() => {
    if (editingRowData) {
      Object.entries(editingRowData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);
  return (
    <>
      <GlassCard className='my-4'>
        <MRTExpandTableStyle>
          {/* <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnActions={false}
            enableDensityToggle={false}
            positionGlobalFilter='left'
            enableGlobalFilter={true}
            initialState={{
              showGlobalFilter: true,
              columnPinning: {
                left: ['mrt-row-actions'],
              },
            }}
            enableColumnPinning
            enableEditing
            editDisplayMode='row'
            createDisplayMode='row'
            onRowSave={(newRow) => handleCreateNewRow(newRow)}
            onEditingRowSave={(newRow) => handleCreateNewRow(newRow)}
            icons={{
              EditIcon: () => Icons.edit,
              SaveIcon: () => Icons.save,
              CancelIcon: () => Icons.clear,
            }}
            muiEditTextFieldProps={(column) => ({
              variant: 'filled',
              label: column.column.columnDef.header,
            })}
            enableStickyHeader
            enableRowActions
            renderRowActions={({ row, table }) => (
              <Box className='flex gap-1 me-2'>
                <MRTIconButton
                  icon={Icons.view}
                  title='View'
                  onClick={() => navigation(`${location.pathname}/view`)}
                />
                <MRTIconButton
                  icon={Icons.edit}
                  title='Edit'
                  onClick={() => {
                    table.setEditingRow(row);
                  }}
                />
                <MRTIconButton
                  error
                  icon={Icons.delete}
                  title='Delete'
                  onClick={() => {
                    data.splice(row.index, 1);
                    setData([...data]);
                  }}
                />
              </Box>
            )}
          /> */}
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ListCashBankReceipt;
