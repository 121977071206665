import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';

const SideBarIconButton = ({ name, icon, ...props }) => {
  return (
    <Box className='flex justify-center'>
      <Tooltip title={name} placement='right' arrow>
        <IconButton
          sx={{
            color: 'inherit',
            padding: '5px',
            borderRadius: '5px',
            '&:hover': {
              bgcolor: 'background.overlayTwo',
            },
            '&.active': {
              bgcolor: 'primary.main',
              color: 'text.white',
              boxShadow: '0 6px 12px rgb(140 152 164 / 8%)',
            },
          }}
          {...props}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default SideBarIconButton;
