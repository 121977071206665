import React from 'react';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import { Box, Grid } from '@mui/material';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { ReceiptKeyValidationSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';

const CreatereceiptKeys = (props) => {
  const { fetchData, CheckInFormId } = props;
  const crud = new Crud_Service();
  const MdItemValue = 2;

  const {
    createLoading,
    startCreateLoading,
    startEditLoading,
    stopCreateLoading,
    stopEditLoading,
  } = useLoader();
  const { handleSubmit, control, setValue, register, reset } = useForm({
    resolver: yupResolver(ReceiptKeyValidationSchema),
    mode: 'onChange',
  });

  const handleSubmitReceiptKey = async (data) => {
    startCreateLoading();
    const combinedData = {
      checkInFormId: CheckInFormId,
      createCheckInFormItems: [
        {
          propertyId: null,
          checkInLocationId: null,
          quantity: data?.quantity,
          codeNumber: data?.codeNumber,
          remarks: data?.remarks,
        },
      ],
    };
    await crud.create('checkinformitems', combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Receipt Key Created successfully');
        fetchData();
        reset();
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <>
      <h3 className='mb-3 text-sm font-bold'>
        Receipt of Keys, Access Cards & Remote Controls
      </h3>

      <GlassCard className='my-4'>
        <form onSubmit={handleSubmit(handleSubmitReceiptKey)}>
          <Box className='p-3'>
            <Grid container spacing={2}>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='quantity'
                  control={control}
                  label='Quantity'
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='codeNumber'
                  control={control}
                  label='Code Number'
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='remarks'
                  control={control}
                  label='Remarks'
                  multiline={true}
                  rows={2}
                  maxRows={3}
                />
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitReceiptKey)}
              onReset={reset}
              submitLoading={createLoading}
              cancelLoading={false}
            />
          </Box>
        </form>
      </GlassCard>
    </>
  );
};

export default CreatereceiptKeys;
