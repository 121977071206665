import React, { useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { MaterialReactTable } from 'material-react-table';
import MRTExpandTableStyle from '../../../../../components/table/MRTExpandTableStyle.tsx';
import Icons from '../../../../../utils/Icon.jsx';
import { Box } from '@mui/material';
import MRTIconButton from '../../../../../components/table/MRTIconButton.tsx';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const ListPendingBillsPayments = () => {
  const generateData = () => {
    const data = [];
    for (let i = 1; i <= 15; i++) {
      data.push({
        id: i,
        voucher: 'Voucher 1',
        accountName: 'AC1226558',
        amount: '126685',
        chequeNo: '9865857',
        branch: 'Branch 1',
        pdcNo: 'PDC1255681',
        createdBy: 'Created 1',
        modifiedBy: 'Modified 1',
        authStatus: 'Status 1',
        createDateandTime: '12/10/2024 10:00 AM',
        modifiedDateandTime: '15/10/2024 10:00 AM',
        suspend: 'Suspened 1',
        balance: '1265.00',
      });
    }
    return data;
  };

  const initialRows = generateData();

  const [data, setData] = useState(initialRows);
  const [, setCreatingRow] = useState(null);

  const handleCreateNewRow = (newRow) => {
    setData((prevData) => [...prevData, newRow]);
    setCreatingRow(null);
  };

  // Define the columns
  const columns = [
    {
      accessorKey: 'voucher',
      header: 'Voucher #',
    },
    {
      accessorKey: 'accountName',
      header: 'Account Name',
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
    },
    {
      accessorKey: 'chequeNo',
      header: 'Cheque No',
      Cell: ({ renderedCellValue }) => <BoldBox title={renderedCellValue} />,
    },
    {
      accessorKey: 'branch',
      header: 'Branch',
    },
    {
      accessorKey: 'pdcNo',
      header: 'PDC No',
      Cell: ({ renderedCellValue }) => <BoldBox title={renderedCellValue} />,
    },

    {
      accessorKey: 'createdBy',
      header: 'Created By',
    },

    {
      accessorKey: 'modifiedBy',
      header: 'Modified By',
    },

    {
      accessorKey: 'authStatus',
      header: 'Authorization Status',
    },

    {
      accessorKey: 'createDateandTime',
      header: 'Create Date and Time',
    },
    {
      accessorKey: 'modifiedDateandTime',
      header: 'Modified Date and Time',
    },
    {
      accessorKey: 'suspend',
      header: 'Suspended',
    },
    {
      accessorKey: 'balance',
      header: 'Balance',
    },
  ];

  return (
    <>
      <GlassCard className='my-4'>
        <MRTExpandTableStyle>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnActions={false}
            enableDensityToggle={false}
            positionGlobalFilter='left'
            enableGlobalFilter={true}
            initialState={{
              showGlobalFilter: true,
              columnPinning: {
                left: ['mrt-row-actions'],
              },
            }}
            enableColumnPinning
            enableEditing
            editDisplayMode='row'
            createDisplayMode='row'
            onRowSave={(newRow) => handleCreateNewRow(newRow)}
            onEditingRowSave={(newRow) => handleCreateNewRow(newRow)}
            icons={{
              EditIcon: () => Icons.edit,
              SaveIcon: () => Icons.save,
              CancelIcon: () => Icons.clear,
            }}
            muiEditTextFieldProps={(column) => ({
              variant: 'filled',
              label: column.column.columnDef.header,
            })}
            enableStickyHeader
            enableRowActions
            renderRowActions={({ row, table }) => (
              <Box className='flex gap-1 me-2'>
                <MRTIconButton
                  icon={Icons.edit}
                  title='Edit'
                  onClick={() => {
                    table.setEditingRow(row);
                  }}
                />
                <MRTIconButton
                  error
                  icon={Icons.delete}
                  title='Delete'
                  onClick={() => {
                    data.splice(row.index, 1);
                    setData([...data]);
                  }}
                />
              </Box>
            )}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ListPendingBillsPayments;
