import React from 'react';
import { Box } from '@mui/material';
import { FontSize } from './../../theme/FontSize.jsx';

const colorClasses = {
  green: 'bg-green-700',
  red: 'bg-red-700',
  blue: 'bg-blue-700',
  black: 'bg-black',
  purple: 'bg-purple-700',
  orange: 'bg-orange-700',
  teal: 'bg-teal-700',
  indigo: 'bg-indigo-700',
};

const ViewStatus = ({ title, type }) => {
  const badgeClass = colorClasses[type] || 'bg-gray-700';
  return (
    <Box
      className={`${badgeClass}  px-2 text-white inline-flex items-center font-semibold`}
      sx={{
        borderRadius: '5px',
        textTransform: 'uppercase',
        fontSize: FontSize.bodyMinusOne,
      }}
    >
      {title}
    </Box>
  );
};

export default ViewStatus;
