import React from 'react';
import { Box, alpha } from '@mui/material';

const MRTExpandTableStyle = ({
  enableFullHeight = false,
  children,
  ...props
}) => {
  return (
    <Box
      sx={{
        height: enableFullHeight && '100%',
        '& > .MuiBox-root': {
          height: enableFullHeight && '100%',
          '& > .MuiPaper-root': {
            height: enableFullHeight && '100%',
            display: enableFullHeight && 'flex',
            flexDirection: enableFullHeight && 'column',
            height: enableFullHeight && '100%',
          },
        },
        '& .MuiTableCell-root, & .MuiFormLabel-root, & .MuiInputBase-root, & .MuiTypography-root':
          {
            fontSize: '13px',
            color: 'inherit',
          },

        '& .MuiInputBase-root.MuiFilledInput-root': {
          background: (theme) => `${theme.palette.background.white} !important`,
          borderColor: (theme) => alpha(theme.palette.text.main, 0.3),
        },

        '& .MuiTablePagination-root': {
          padding: '6px',
        },
        '& .MuiTableCell-root': {
          padding: '3px 5px',
          border: '1px solid',
          borderRight: 'none',
          borderTop: 'none',
          borderColor: 'border.main',
        },

        '& .MuiTableCell-head': {
          bgcolor: 'background.light',
          padding: '5px 5px',
          color: 'text.dark',
        },

        '& .MuiCollapse-horizontal': {
          '& .MuiInputBase-root': {
            padding: '0px 10px',
            '& .MuiOutlinedInput-input': {
              padding: '8px 4px',
              minWidth: '200px',
            },
          },
          '& .MuiSvgIcon-root': {
            fontSize: '20px',
          },
        },
        '& .MuiTableCell-root.MuiTableCell-head': {
          fontWeight: '600',
          lineHeight: 1.5,
        },

        '& .MuiTableCell-root .MuiIconButton-root': {
          padding: '6px',
          fontSize: '1rem',
        },
        '& .MuiTableContainer-root + .MuiBox-root': {
          minHeight: '3rem',
        },
        '& .MuiPaper-elevation': {
          boxShadow: 'inherit',
          border: 'none',
          borderRadius: '0px',
          color: 'inherit',
        },
        '& .MuiPaper-root>.MuiBox-root:first-child': {
          minHeight: '49px',
          borderBottom: '1px solid',
          borderColor: 'border.main',
          '& .MuiIconButton-root': {
            padding: '4px',
          },
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            //borderColor: 'border.main',
          },
        },
        '& .MuiTableCell-alignLeft .MuiBox-root': {
          gap: 0,
        },
        '& .MuiTableBody-root .MuiTableRow-root:hover td:after': {
          bgcolor: 'background.light',
        },

        '& .MuiPaper-root>.MuiBox-root:last-child': {
          '& span + .MuiBox-root': {
            right: '50%',
            transform: 'translateX(50%)',
          },
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default MRTExpandTableStyle;
