import OneSignal from 'react-onesignal';

export default async function runOneSignal() {
  await OneSignal.init({
    appId: '3c7813d8-27bc-46c4-9ece-d6ee263339fb',
    allowLocalhostAsSecureOrigin: true,
  });
  OneSignal.Slidedown.promptPush();

}
