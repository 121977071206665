// src/ThemeContext.js
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import baseTheme from './theme/theme';
import darkTheme from './theme/dark';
import CssBaseline from '@mui/material/CssBaseline';

const ThemeContext = createContext();
export const ThemeProvider = ({ children }) => {
  //const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  //const [mode, setMode] = useState(prefersDarkMode ? "dark" : "light");
  const [mode, setMode] = useState('light');

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  // useEffect(() => {
  //   setMode(prefersDarkMode ? "dark" : "light");
  // }, [prefersDarkMode]);

  useEffect(() => {
    if (mode === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [mode]);

  const theme = useMemo(
    () =>
      mode === 'light'
        ? createTheme({
            ...baseTheme,
            palette: {
              ...baseTheme.palette,
              mode,
            },
          })
        : createTheme({
            ...darkTheme,
            palette: {
              ...darkTheme.palette,
              mode,
            },
          }),
    [mode],
  );

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
