import React, { useEffect, useState } from 'react';
import Crud_Service from '../../../apis/CrudService.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import {
  StatusBadge,
  ViewField,
} from '../../../components/_form/FormElements.jsx';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import { Nav } from '../../../utils/index.jsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { toast } from 'react-toastify';

const MedicalInsurancePremisePurpose = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [loader, setLoader] = useState(false);
  const { setValue, control, reset, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const [openView, setOpenView] = useState(false);
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    searchKeyword,
    setRows,
    fetchData,
  } = useTableLogic('premisepurposes');
  const crud = new Crud_Service();

  const statusOptions = [
    { statusId: 2, statusName: 'Active' },
    { statusId: 1, statusName: 'Inactive' },
  ];

  useEffect(() => {
    setLoader(true);
  }, []);

  const handleSubmitMedicalInsurancePremisePurpose = async (values) => {
    startCreateLoading();
    await crud.create('premisepurposes', values, (err, res) => {
      if (res?.status === 201) {
        toast.success('Premise Purpose created successfully!');
        setOpenCreate(false);
        stopCreateLoading();
        reset();
        fetchData();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
      }
    });
  };

  //Delete companyinsurancepolicytypeofproducts
  const handleDeleteExpense = async (view) => {
    await crud.remove('premisepurposes', view?.premisePurposeId, (err, res) => {
      if (res?.status === 204) {
        toast.success('Premise Purposes deleted successfully!');
        setRows(rows?.filter((row) => row.id !== view?.premisePurposeId));
        fetchData();
      } else {
      }
    });
  };

  const handleEditExpense = async (view) => {
    setOpenUpdate(true);
    setLoading(true);
    await crud.getSingle(
      'premisepurposes',
      view?.premisePurposeId,
      (err, res) => {
        if (res?.status === 200) {
          Object.entries({
            ...res?.data,
          }).forEach(([key, value]) => {
            setValue(key, value);
          });
          setLoading(false);
        } else {
        }
      }
    );
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };

  const handleViewClickOpen = async (view) => {
    setOpenView(true);
    setLoading(true);
    await crud.getSingle(
      'premisepurposes',
      view?.premisePurposeId,
      (err, res) => {
        if (res?.status === 200) {
          setSelectedRowData(res?.data);
          setLoading(false);
        } else {
          setLoading(true);
        }
      }
    );
  };

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'premisePurposeName',
      headerName: 'Premise Purpose Name',
      flex: 1,
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditExpense(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDeleteExpense(props),
      danger: true,
    },
  ];
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };
  const handleViewClose = () => {
    setOpenView(false);
  };
  const handleUpdateMedicalInsurancePremisePurpose = async (values) => {
    startEditLoading();
    await crud.update(
      'premisepurposes',
      values?.premisePurposeId,
      values,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Premise Purpose updated successfully!');
          setOpenUpdate(false);
          stopEditLoading();
          fetchData();
        } else {
          stopEditLoading();
          setOpenUpdate(true);
        }
      }
    );
  };
  return (
    <DashboardLayout
      title='Premise Purpose'
      menu={Nav[3].child[2].children}
      hasSubmenu
    >
      <GlassCard>
        <Box sx={{ height: 'calc(100vh - 100px)' }}>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <CreateButton name='New' onClick={handleCreateClickOpen} />
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      <MuiDialogOne
        title='Create New'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form
          onSubmit={handleSubmit(handleSubmitMedicalInsurancePremisePurpose)}
        >
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='premisePurposeName'
                control={control}
                label=' Premise Purpose Name'
                rules={{
                  required: 'Premise Purpose Name is required',
                }}
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(
                  handleSubmitMedicalInsurancePremisePurpose
                )}
                onReset={reset}
                onCancel={handleCreateClose}
                submitLoading={createLoading}
                errorFields={false}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={3} className='mb-5'>
            <Grid item md={6}>
              <ViewField
                label=' Premise Purpose Name'
                title={selectedRowData?.premisePurposeName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Status' title={selectedRowData?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      {/* Update */}
      <MuiDialogOne
        title='Update'
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form
            onSubmit={handleSubmit(handleUpdateMedicalInsurancePremisePurpose)}
          >
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='premisePurposeName'
                  control={control}
                  label='Premise Purpose Name'
                  rules={{
                    required: 'Premise Purpose Name is required',
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>

              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(
                    handleUpdateMedicalInsurancePremisePurpose
                  )}
                  onReset={reset}
                  onCancel={() => setOpenUpdate(false)}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  //
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default MedicalInsurancePremisePurpose;
