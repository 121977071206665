import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../components/small/BackToButton.jsx';
import { Grid, Tab, Tabs } from '@mui/material';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusBadge } from '../../../../components/_form/FormElements.jsx';
import ViewContractCustomer from './ViewContractCustomer.tsx';
import ViewContractLocationList from './ViewContractLocationList.tsx';
import ViewContractProjectTeam from './ViewContractProjectTeam.tsx';
import ViewContractRevenue from './ViewContractRevenue.tsx';
import ViewContractCost from './ViewContractCost.tsx';
import ViewContractSubContract from './ViewContractSubContract.tsx';
import ViewContractSchedule from './ViewContractSchedule.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ViewDetailsCollapse from '../../../../components/view/ViewDetailsCollapse.tsx';
import ElementLoader from './../../../../components/page/ElementLoader.tsx';

const navMenu = [
  {
    id: 1,
    name: 'Customer',
  },
  {
    id: 2,
    name: 'Location',
  },
  {
    id: 3,
    name: 'Project Team',
  },
  // TODO: Add revenue and cost tabs when required
  // {
  //   id: 4,
  //   name: 'Revenue Tab',
  // },
  // {
  //   id: 5,
  //   name: 'Cost Tab',
  // },
  {
    id: 6,
    name: 'Sub Contractors',
  },
  {
    id: 7,
    name: 'Schedule',
  },
];

const ViewContracts = () => {
  const [value, setValue] = useState(navMenu[0]?.id || 0);
  const id = useParams().id;
  const crud = new Crud_Service();
  const navigation = useNavigate();
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };
  const [contractData, setContractData] = useState({});
  const [loader, setLoader] = useState(false);

  const fetchContractData = async () => {
    await crud.getSingle('contracts', id, (_err, res) => {
      if (res?.status === 200) {
        setContractData(res?.data);
        setLoader(false);
      } else {
      }
    });
  };
  useEffect(() => {
    document.title = 'View | Contract | Presales';
    fetchContractData();
    setLoader(true);
  }, []);

  return (
    <DashboardLayout
      title={contractData?.contractCode}
      actionButtons={
        <BackToButton
          onClick={() => navigation(-1)}
          title='Back to Contract'
          className='p-0'
        />
      }
      titleReverse
    >
      <GlassCard className='p-3 mb-2'>
        {loader ? (
          <div className='flex justify-center'>
            <ElementLoader />
          </div>
        ) : (
          <>
            <ViewDetailsCollapse>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <ViewTable
                    rows={[
                      {
                        label: 'Contract Code',
                        title: contractData?.contractCode,
                      },
                      {
                        label: 'Contract Name',
                        title: contractData?.contractName,
                      },
                      {
                        label: 'Project Code',
                        title: contractData?.projectCode,
                      },
                      {
                        label: 'Project Name',
                        title: contractData?.projectName,
                      },
                      {
                        label: 'Project Type Name',
                        title: contractData?.projectTypeName,
                      },
                      {
                        label: 'Division Name',
                        title: contractData?.divisionName,
                      },
                      {
                        label: 'Project In Charge Name',
                        title: contractData?.projectInChargeName,
                      },
                      {
                        label: 'Client Name',
                        title: contractData?.clientName,
                      },
                      {
                        label: 'Contact Person',
                        title: contractData?.contactPerson,
                      },
                      {
                        label: 'Contact Number',
                        title: contractData?.contactNumber,
                      },
                      {
                        label: 'Contact Email',
                        title: contractData?.contactEmailId,
                      },
                      {
                        label: 'Contract Cost',
                        title: contractData?.contractCost,
                      },
                    ]}
                  />
                </Grid>
                <Grid item md={4}>
                  <ViewTable
                    rows={[
                      {
                        label: 'Budget',
                        title: contractData?.budget,
                      },
                      {
                        label: 'Contract Terms',
                        title: contractData?.contractTerms,
                      },
                      {
                        label: 'Contract Start Date',
                        title: contractData?.contractStartDate,
                        date: true,
                      },
                      {
                        label: 'Contract End Date',
                        title: contractData?.contractEndDate,
                        date: true,
                      },
                      {
                        label: 'Pervious Contract Code',
                        title: contractData?.perviousContractCode,
                      },
                      {
                        label: 'Contract Mode Name',
                        title: contractData?.contractModeName,
                      },
                      {
                        label: 'Reference Number',
                        title: contractData?.referenceNumber,
                      },
                      {
                        label: 'Contract Type Name',
                        title: contractData?.contractTypeName,
                      },
                    ]}
                  />
                </Grid>
                <Grid item md={4}>
                  <ViewTable
                    rows={[
                      {
                        label: 'Contract Scope Name',
                        title: contractData?.contractScopeName || '-',
                      },
                      {
                        label: 'Billing Frequency Name',
                        title: contractData?.billingFrequencyName || '-',
                      },
                      {
                        label: 'Threshold Value',
                        title: contractData?.thresholdValue || '0',
                      },
                      {
                        label: 'Currency Name',
                        title: contractData?.currencyName || '-',
                      },
                      {
                        label: 'Guarantee Name',
                        title: contractData?.guaranteeName || '-',
                      },
                      {
                        label: 'Guarantee Start Date',
                        title: contractData?.guaranteeStartDate || 'null',
                        date: true,
                      },
                      {
                        label: 'Guarantee End Date',
                        title: contractData?.guaranteeEndDate || 'null',
                        date: true,
                      },
                      {
                        label: 'Guarantee Amount',
                        title: contractData?.guaranteeAmount || '-',
                      },
                      {
                        label: 'Remarks',
                        title: contractData?.remarks || '-',
                      },
                      {
                        label: 'Status Name',
                        title: (
                          <StatusBadge
                            title={contractData?.statusName}
                            type={contractData?.statusType}
                          />
                        ),
                      },
                      {
                        label: 'Created Date',
                        title: contractData?.createdDate || '19-09-2024',
                        date: true,
                      },
                      {
                        label: 'Contract Documents',
                        title: contractData?.contractDocuments || '-',
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </ViewDetailsCollapse>
          </>
        )}
      </GlassCard>
      <GlassCard className='mb-2'>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          indicatorColor='secondary'
          centered
        >
          {navMenu?.map((item) => {
            return <Tab value={item.id} label={item.name} key={item.name} />;
          })}
        </Tabs>
      </GlassCard>
      {value === 1 && <ViewContractCustomer contractId={id} />}
      {value === 2 && (
        <ViewContractLocationList
          contractId={id}
          projectId={contractData?.projectId}
        />
      )}
      {value === 3 && <ViewContractProjectTeam contractId={id} />}
      {value === 4 && <ViewContractRevenue />}
      {value === 5 && <ViewContractCost />}
      {value === 6 && <ViewContractSubContract contractId={id} />}
      {value === 7 && <ViewContractSchedule contractId={id} />}
    </DashboardLayout>
  );
};

export default ViewContracts;
