import React, { useEffect, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { StyledCard } from '../../../components/_form';
import { Grid } from '@mui/material';
import { ViewField } from '../../../components/_form/FormElements';
import Crud_Service from '../../../apis/CrudService';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { toast } from 'react-toastify';

const EmployeeProfile = (props) => {
  const crud = new Crud_Service();
  const [item, setItem] = useState(null);
  const { id, i, value } = props;

  useEffect(() => {
    getEmployee();
  }, []);

  //get Empoyee
  const getEmployee = async () => {
    crud.getSingle('employees', id, (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        const data = res.data;
        if (data?.maritalStatusName === '0') {
          data.maritalStatusName = 'N/A';
        }
        setItem(data);
      }
    });
  };

  // The given structure for display
  const displayStructure = {
    groupName: 'Employee profile',
    child: [
      { title: 'Employee Name', name: 'firstName' },
      { title: 'Employee Code', name: 'employeeCode' },
      { title: 'Designation', name: 'designationName' },
      { title: 'Visa Designation', name: 'visaDesignationName' },
      { title: 'Department', name: 'departmentName' },
      { title: 'Email', name: 'personalEmailId' },
      { title: 'Contact Number', name: 'mobileNumber' },
      { title: 'Native Contact Number', name: 'nativeMobileNumber' },
      { title: 'Date Of Joining', name: 'dateOfJoin' },
      { title: 'Revision date', name: 'revisionDate' },
      { title: 'Applicable Date', name: 'applicableDate' },
      { title: 'Gender', name: 'genderName' },
      { title: 'Position', name: 'positionName' },
      { title: 'Reporting To', name: 'reportingEmployeeName' },
      { title: 'Blood Group', name: 'bloodGroupName' },
      { title: 'Marital Status', name: 'maritalStatusName' },
      { title: 'Nationality', name: 'nationalityName' },
      { title: 'Date of Birth', name: 'dob' },
      { title: 'Place Of Birth', name: 'placeOfBirth' },
      { title: 'Contract Type', name: 'contractTypeName' },
      { title: 'Unified No', name: 'unifiedNo' },
      { title: 'EMP ID Card Provided', name: 'empIdCardProvided' },
      { title: 'Permanent Home Country Address', name: 'permanentAddress' },
      { title: 'Personal Email', name: 'personalEmailId' },
      { title: 'Grade', name: 'gradeName' },
      {
        title: 'Last Annual Leave Return Date',
        name: 'lastAnnualReturnLeaveDate',
      },
    ],
  };

  //Formatting Fields for display
  const formatFieldValue = (field, value) => {
    switch (field.name) {
      case 'empIdCardProvided':
        return value ? 'Yes' : 'No';
      default:
        return value || '';
    }
  };

  const renderFields = (data, structure) => {
    return structure.child.map((field, i) => {
      const value = data ? data[field.name] : '';
      return (
        <Grid item md={4} key={i}>
          {field.name === 'dob' ||
          field.name === 'lastAnnualLeaveReturnDate' ||
          field.name === 'dateOfJoin' ||
          field.name === 'revisionDate' ||
          field.name === 'applicableDate' ? (
            <FmViewDateField title={field.title} value={value} />
          ) : (
            <ViewField
              label={field.title}
              title={String(formatFieldValue(field, value))}
            />
          )}
        </Grid>
      );
    });
  };

  return (
    <GlassCard className={value === i ? 'w-full  p-4' : 'hidden'}>
      <StyledCard title={'Employee Profile'}>
        <Grid container spacing={1.5}>
          {renderFields(item, displayStructure)}
        </Grid>
      </StyledCard>
    </GlassCard>
  );
};

export default EmployeeProfile;
