import React, { useEffect } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import BackToButton from '../../../components/small/BackToButton.jsx';
import { Box } from '@mui/material';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import { useNavigate, useParams } from 'react-router-dom';

const ViewMaterialRequest = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'companyId',
    'contractId',
    'departmentId',
    'locationId',
    'materialRequisitionId',
    'materialRequisitionStatusId',
    'materialRequisitionFiles',
    'materialRequisitionItems',
    'materialRequisitionTypeId',
    'projectId',
    'regionId',
    'requesterId',
    'storeLocationId',
    'supplierId',
    'supplierSPOCId',
    'unitId',
    'inCharge',
    'updatedDate',
    'approvalDate',
    'status',
  ];

  return (
    <DashboardLayout title='View Material Request'>
      <Box className=''>
        <BackToButton
          title='Back to List'
          className='px-2 mb-4'
          onClick={() => navigate(-1)}
        />
        <GlassCard className='p-5'>
          <CommonView
            url='materialrequisitions'
            id={id}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default ViewMaterialRequest;
