import React from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageSubmenuBoxed from '../../../components/page/PageSubmenuBoxed';

const EmpAllMasters = () => {
  return (
    <DashboardLayout title='All Masters'>
      <PageSubmenuBoxed NavId={0} ChildId={8} />
    </DashboardLayout>
  );
};

export default EmpAllMasters;
