import React, { useEffect } from 'react';
import DashboardLayout from './../../../components/DashboardLayout.tsx';
import AddVendorLPOReceivable from './AddVendorLPOReceivable.tsx';
import ListVendorLPOReceivable from './ListVendorLPOReceivable.tsx';

const VendorLPOReceivable = () => {
  useEffect(() => {
    document.title = 'Vendor LPO Receivable | Financial';
  }, []);

  return (
    <DashboardLayout title={'Vendor LPO Receivable'}>
      <AddVendorLPOReceivable />
      <ListVendorLPOReceivable />
    </DashboardLayout>
  );
};

export default VendorLPOReceivable;
