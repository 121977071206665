import React from 'react';
import { Box } from '@mui/material';
import HeaderNotification from './helpers/HeaderNotification';
import HeaderHistoryDropdown from './helpers/HeaderHistoryDropdown';
import HeaderLanguage from './helpers/HeaderLanguage';
import SidebarSettingsDropdown from './helpers/SidebarSettingsDropdown';
import { IconButtonWithTooltip } from './small/Buttons';
import Icons from '../utils/Icon';
import { useTheme } from '../ThemeContext';

const HeaderGroupIcons = () => {
  const { toggleTheme, mode } = useTheme();

  return (
    <Box className='flex gap-2 justify-between items-center'>
      <HeaderHistoryDropdown />
      <HeaderLanguage />
      <IconButtonWithTooltip
        name={mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
        icon={mode === 'dark' ? Icons.sun : Icons.moon}
        onClick={toggleTheme}
      />
      <SidebarSettingsDropdown />
      <HeaderNotification />
    </Box>
  );
};

export default HeaderGroupIcons;
