import React, { useEffect, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { StyledCard } from '../../../components/_form';
import { Grid } from '@mui/material';
import { ViewField } from '../../../components/_form/FormElements';
import Crud_Service from '../../../apis/CrudService';
import { toast } from 'react-toastify';

const ProjectDetails = (props) => {
  const crud = new Crud_Service();
  const [item, setItem] = useState(null);
  const { id, i, value } = props;

  useEffect(() => {
    crud.getAll(`employees/${id}/project-details`, '', (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        setItem(res?.data?.data[0]);
      }
    });
  }, [id]);

  // The given structure for display
  const displayStructure = {
    groupName: 'Employee Project Details',
    child: [{ title: 'Project', name: 'projectName' }],
  };

  const renderFields = (data, structure) => {
    return structure.child.map((field, i) => {
      const value = data ? data[field.name] : '';
      return (
        <Grid item md={4} key={i}>
          <ViewField label={field.title} title={String(value ? value : '')} />
        </Grid>
      );
    });
  };

  return (
    <GlassCard className={value === i ? 'w-full  p-5' : 'hidden'}>
      <StyledCard title={'Employee Project Details'}>
        <Grid container spacing={1.5}>
          {renderFields(item, displayStructure)}
        </Grid>
      </StyledCard>
    </GlassCard>
  );
};

export default ProjectDetails;
