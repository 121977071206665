import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import loginReducer from './../redux/loginSlice.ts';
import menuReducer from './../redux/menuSlice.ts';
import paginationReducer from './paginationSlice.ts';
import loaderReducer from './loaderSlice.ts';
import tabsReducer from './tabsSlice.ts';
import settingsReducer from './settingsSlice.ts';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  pagination: paginationReducer,
  loader: loaderReducer,
  tabs: tabsReducer,
  settings: settingsReducer,
  auth: loginReducer,
  menu: menuReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
