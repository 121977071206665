import React from 'react';
import Box from '@mui/material/Box';

const PageHeader = ({ counters }) => {
  return (
    <>
      {/* page header */}
      <div className='w-full'>
        <div className='flex flex-row gap-1 items-center'>
          {counters.map((item, i) => {
            return (
              <Box
                key={i}
                className='p-1 px-4  flex flex-row gap-3 text-center items-center'
              >
                {/* <Box
                  className="inline-block text-2xl font-bold"
                  sx={{ color: "primary.main" }}
                >
                  {item.icon}
                </Box> */}

                <Box className='my-1' sx={{ color: 'text.main' }}>
                  {item.name}
                </Box>
                <Box
                  className='text-xl  font-semibold'
                  sx={{ color: 'text.dark' }}
                >
                  {item.count}
                </Box>
              </Box>
            );
          })}
        </div>
      </div>
      {/* page header */}
    </>
  );
};

export default PageHeader;
