import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { MaterialReactTable } from 'material-react-table';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import Icons from '../../../../utils/Icon.jsx';
import { Box, Grid, TextField, MenuItem, Button } from '@mui/material';
import MRTIconButton from '../../../../components/table/MRTIconButton.tsx';
import UploadModalBox from '../../../../components/upload/UploadModalBox.tsx';

const BoldBox = ({ title }) => {
  return <Box className='font-semibold'>{title}</Box>;
};

const AttachmentImage = () => {
  return (
    <Box
      sx={{
        '& img': {
          width: '30px',
          height: '30px',
          objectFit: 'cover',
        },
      }}
    >
      <img src='https://images.pexels.com/photos/19636646/pexels-photo-19636646/free-photo-of-man-with-a-virtual-reality-headset-on-his-head-standing-in-the-autumn-forest.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' />{' '}
    </Box>
  );
};

const MdItemValue = 2.4;

const generateData = () => {
  const data = [];
  for (let i = 1; i <= 10; i++) {
    data.push({
      id: i,
      vendorId: '123456',
      vendorName: 'Vendor Name 1',
      documentType: 'Type 1',
      documentRef: '3136558558',
      docDate: '12-10-2024',
      vendorRep: 'Vendor Rep 1',
      idNo: '123355',
      contactNo: '9865585577',
      responseDate: '21-05-2024',
      remarks: 'Payment to be made in three installments.',
      attachment: '',
    });
  }
  return data;
};

const initialRows = generateData();

const ViewVendorLPOList = () => {
  const [data, setData] = useState(initialRows);
  const [, setCreatingRow] = useState(null);

  const handleCreateNewRow = (newRow) => {
    setData((prevData) => [...prevData, newRow]);
    setCreatingRow(null);
  };

  // Define the columns
  const columns = [
    {
      accessorKey: 'vendorId',
      header: 'Vendor ID',
      Cell: ({ renderedCellValue }) => <BoldBox title={renderedCellValue} />,
    },
    {
      accessorKey: 'vendorName',
      header: 'Vendor Name',
    },
    {
      accessorKey: 'documentType',
      header: 'Document Type',
    },
    {
      accessorKey: 'documentRef',
      header: 'Document Ref',
    },
    {
      accessorKey: 'docDate',
      header: 'Document Date',
    },
    {
      accessorKey: 'vendorRep',
      header: 'Vendor Rep',
    },
    {
      accessorKey: 'idNo',
      header: 'ID #',
    },
    {
      accessorKey: 'contactNo',
      header: 'Contact No',
    },
    {
      accessorKey: 'responseDate',
      header: 'Response Date',
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
    },
    {
      accessorKey: 'attachment',
      header: 'Attachment',
      Cell: () => <AttachmentImage />,
    },
  ];

  return (
    <>
      <GlassCard className='my-4'>
        <Box className='p-3'>
          <Grid container spacing={2}>
            <Grid item md={MdItemValue}>
              <TextField label='Vendor ID' fullWidth variant='filled' select>
                <MenuItem value={10}>Vendor ID 1</MenuItem>
                <MenuItem value={20}>Vendor ID 2</MenuItem>
                <MenuItem value={30}>Vendor ID 3</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField label='Vendor Name' fullWidth variant='filled' select>
                <MenuItem value={10}>Vendor Name 1</MenuItem>
                <MenuItem value={20}>Vendor Name 2</MenuItem>
                <MenuItem value={30}>Vendor Name 3</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField
                label='Document Type'
                fullWidth
                variant='filled'
                select
              >
                <MenuItem value={10}>Document Type 1</MenuItem>
                <MenuItem value={20}>Document Type 2</MenuItem>
                <MenuItem value={30}>Document Type 3</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField label='Document Ref #' fullWidth variant='filled' />
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField label='Document Date' fullWidth variant='filled' />
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField label='Amount Payable' fullWidth variant='filled' />
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField label='LPO #' fullWidth variant='filled' select>
                <MenuItem value={10}>LPO 1</MenuItem>
                <MenuItem value={20}>LPO 2</MenuItem>
                <MenuItem value={30}>LPO 3</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField label='LPO Date' fullWidth variant='filled' />
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField label='LPO Amount' fullWidth variant='filled' />
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField label='Vendor Rep.' fullWidth variant='filled' />
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField label='ID #' fullWidth variant='filled' />
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField label='Contact No.' fullWidth variant='filled' />
            </Grid>
            <Grid item md={MdItemValue}>
              <TextField label='Response Date' fullWidth variant='filled' />
            </Grid>
            <Grid item md={MdItemValue * 2}>
              <TextField label='Remarks' fullWidth variant='filled' />
            </Grid>
            <Grid item md={MdItemValue}>
              <UploadModalBox />
            </Grid>
            <Grid item md={MdItemValue}>
              <Button fullWidth variant='contained'>
                Create New
              </Button>
            </Grid>
          </Grid>
        </Box>
        <MRTExpandTableStyle>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableHiding={false}
            enableColumnActions={false}
            enableDensityToggle={false}
            enableColumnFilters={false}
            enableGlobalFilter={false}
            enableFullScreenToggle={false}
            enableTopToolbar={false}
            enableEditing
            editDisplayMode='row'
            createDisplayMode='row'
            onRowSave={(newRow) => handleCreateNewRow(newRow)}
            onEditingRowSave={(newRow) => handleCreateNewRow(newRow)}
            icons={{
              EditIcon: () => Icons.edit,
              SaveIcon: () => Icons.save,
              CancelIcon: () => Icons.clear,
            }}
            muiEditTextFieldProps={(column) => ({
              variant: 'filled',
              label: column.column.columnDef.header,
            })}
            enableStickyHeader
            enableRowActions
            renderRowActions={({ row, table }) => (
              <Box className='flex gap-1 me-5'>
                <MRTIconButton
                  icon={Icons.edit}
                  title='Edit'
                  onClick={() => {
                    table.setEditingRow(row);
                  }}
                />
                <MRTIconButton
                  error
                  icon={Icons.delete}
                  title='Delete'
                  onClick={() => {
                    data.splice(row.index, 1);
                    setData([...data]);
                  }}
                />
              </Box>
            )}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ViewVendorLPOList;
