import { useState, useEffect } from 'react';
import Crud_Service from '../../apis/CrudService';
import { toast } from 'react-toastify';

const useCommonFetchApi = (endpoint, params = {}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const crud = new Crud_Service();

  useEffect(() => {
    const fetchData = async () => {
      if (!endpoint) return;
      setLoading(true);
      await crud.getAll(endpoint, params, (err, res) => {
        if (err) {
          toast.error(err);
        }
        if (res?.status === 200) {
          setData(res?.data?.data);
        } else {
        }
        setLoading(false);
      });
    };

    fetchData();
  }, [endpoint]);

  return { data, loading };
};

export default useCommonFetchApi;
