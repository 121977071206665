import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import Crud_Service from '../../../../apis/CrudService';
import { toast } from 'react-toastify';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from './../../../../components/FmMRTDataTable.tsx';
import { useParams } from 'react-router-dom';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { materialRequestItemSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';

const CartMaterialRequest = ({ locationId }) => {
  const crud = new Crud_Service();
  const { id } = useParams();
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const { handleSubmit, reset, setValue, control } = useForm({
    resolver: yupResolver(materialRequestItemSchema),
    mode: 'onChange',
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [viewdetails, setViewdetails] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(0);

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic('materialrequisitionitems', 'materialrequisitionid', id);


  const [selectedItem, setSelectedItem] = useState(null);
  const { data: item } = useCommonFetchApi('item');

  useEffect(() => {
    if (!selectedItem || !selectedItem?.itemId) return;

    const { itemId, brandName, modelName } = selectedItem;

    // Set the brand name when selectedItem changes
    setValue('brandName', brandName || '');
    setValue('category', modelName || '');

    // Fetch the item details
    const fetchItemDetails = async () => {
      crud.getAll(
        'inventoryitems',
        { storelocationId: locationId, itemId },
        (err, res) => {
          if (err) {
            toast.error('Failed to fetch item details. Please try again.');
            return;
          }
          if (res?.status === 200) {
            setAvailableQuantity(res?.data);
            setValue(
              'onHandQuantity',
              res?.data?.data[0]?.availableQuantity || 0
            );
          } else {
            toast.error('Failed to fetch item details. Please try again.');
          }
        }
      );
    };

    fetchItemDetails();
  }, [selectedItem, locationId]);

  const { data: salesOrder } = useCommonFetchApi('salesorders');

  const handleDelete = async (props) => {
    await crud.remove(
      'materialrequisitionitems',
      props?.materialRequisitionItemId,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Material Requisition Item Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleViewClick = (props) => {
    setViewdetails(props);
  };

  const handleEdit = async (row) => {
    setEditingRowId(row?.materialRequisitionItemId);
    setEditingRowData({ ...row });

    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const handleCancel = (row) => {
    if (row?.original?.materialRequisitionItemId === rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter(
          (r) => r?.materialRequisitionItemId !== rowState?.newRowId
        )
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleUpdateMaterialrequestItems = async (values) => {
    const convertedValues = {
      ...values,
      costCenterId: selectedItem?.costCenterId,
      quantity: Number(values.quantity),
      unitofmeasurementId: selectedItem?.unitOfMeasurementId,
      brandId: editingRowData?.brandId,
      onHandQuantity: Number(editingRowData?.onHandQuantity),
      materialRequisitionItemStatusId:
        availableQuantity < values.quantity ? 1 : 2,
      status: 2,
    };

    const combinedData = {
      materialRequisitionId: id,
      materialRequisitionItems: [convertedValues],
    };
    const updateCombinedData = {
      ...values,
      brandId: editingRowData?.brandId,
      onHandQuantity: Number(editingRowData?.onHandQuantity),
      materialRequisitionItemStatusId:
        availableQuantity < values.quantity ? 1 : 2,
      materialRequisitionId: id,
      status: 2,
    };
    if (rowState?.newRowId) {
      await crud.create(
        'materialrequisitionitems',
        combinedData,
        (_err, res) => {
          if (res?.status === 201) {
            toast.success('Material Requisition Item Created Successfully');
            fetchData();
            setRowState((prevState) => ({
              ...prevState,
              newRowId: null,
              isCreatingRow: false,
            }));
          } else {

          }
        }
      );
    } else {
      await crud.update(
        'materialrequisitionitems',
        editingRowId,
        updateCombinedData,
        (_err, res) => {
          if (res?.status === 200) {
            toast.success('Material Requisition Item Updated Successfully');
            fetchData();
            setEditingRowData(null);
            setEditingRowId(null);
          } else {

          }
        }
      );
    }
  };

  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  onClick={handleSubmit(handleUpdateMaterialrequestItems)}
                >
                  Save
                </Button>
                <Button onClick={() => handleCancel(row)}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(row?.original),
                }))}
              />
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name='salesOrderId'
            control={control}
            options={salesOrder}
            label='Sales Order'
            displayField='salesOrderName'
            optionFields={['salesOrderName']}
            valueKey='salesOrderId'
          />
        ) : (
          row?.original?.salesOrderCode || ''
        );
      },
    },
    {
      accessorKey: 'itemId',
      header: 'Item',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name='itemId'
            control={control}
            options={item}
            displayField='itemName'
            optionFields={['itemName']}
            valueKey='itemId'
            onChangeValue={true}
            onChange={(ev) => setSelectedItem(ev)}
          />
        ) : (
          row?.original?.itemName || ''
        );
      },
    },
    {
      accessorKey: 'brandName',
      header: 'Brand',
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='brandName'
            label='Brand'
            disabled={true}
            control={control}
          />
        ) : (
          row?.original?.brandName
        );
      },
    },

    {
      accessorKey: 'unitOfMeasurementId',
      header: 'Unit Of Measurement',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='unitOfMeasurementId'
            control={control}
            disabled={true}
            apiUrl='unitofmeasurements'
            valueField={'unitOfMeasurementId'}
            headerField={['Code', 'Name']}
            labelField={['unitOfMeasurementName']}
            showField={['unitOfMeasurementName']}
            pageSize={20}
            label={'Unit Of Measurement'}
            defaultValue={{
              unitOfMeasurementId:
                selectedItem?.unitOfMeasurementId ||
                row?.original?.unitOfMeasurementId,
              unitOfMeasurementName:
                selectedItem?.unitOfMeasurementName ||
                row?.original?.unitOfMeasurementName,
            }}
          />
        ) : (
          row?.original?.unitOfMeasurementName || ''
        );
      },
    },
    {
      accessorKey: 'costCenterId',
      header: 'Cost Center',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='costCenterId'
            control={control}
            apiUrl='costcenters'
            disabled={true}
            valueField={'costCenterId'}
            headerField={['Code', 'Name']}
            labelField={['costCenterName']}
            showField={['costCenterName']}
            pageSize={20}
            label={'Cost Center'}
            defaultValue={{
              costCenterId:
                selectedItem?.costCenterId || row?.original?.costCenterId,
              costCenterName:
                selectedItem?.costCenterName || row?.original?.costCenterName,
            }}
          />
        ) : (
          row?.original?.costCenterName || ''
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField name='quantity' label='Quantity' control={control} />
        ) : (
          row?.original?.quantity
        );
      },
    },
    {
      accessorKey: 'categoryId',
      header: 'Category',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='category'
            control={control}
            label='Category'
            readOnly
          />
        ) : (
          row?.original?.categoryName || ''
        );
      },
    },
    {
      accessorKey: 'onHandQuantity',
      header: 'On Hand Quantity',
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='onHandQuantity'
            label='On Hand Quantity'
            disabled={true}
            control={control}
          />
        ) : (
          row?.original?.onHandQuantity || ''
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            multiline={true}
            rows={2}
            maxRows={3}
          />
        ) : (
          row?.original?.remarks
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='description'
            control={control}
            multiline={true}
            rows={2}
            maxRows={3}
          />
        ) : (
          row?.original?.description
        );
      },
    },
  ];

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setRows((prevRows) => [
      {
        materialRequisitionItemId: newId,
        materialRequisitionId: id,
        salesOrderId: '',
        brandId: '',
        categoryId: '',
        unitOfMeasurementId: '',
        itemId: '',
        unitOfMeasurementId: '',
        costCenterId: '',
        quantity: '',
        remarks: '',
        description: '',
      },
      ...prevRows,
    ]);
    reset();
  };
  useEffect(() => {
    console.log(rows);
  }, [rows]);
  const excludeKeys = [
    'materialRequisitionId',
    'brandId',
    'categoryId',
    'costCenterId',
    'itemId',
    'materialRequisitionItemId',
    'salesOrderId',
    'unitOfMeasurementId',
    'status',
  ];

  return (
    <>
      <GlassCard>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
        />
        <MuiDialogOne
          title='View Details'
          open={viewdetails}
          onClose={() => setViewdetails(!viewdetails)}
        >
          {viewdetails && viewdetails?.materialRequisitionItemId && (
            <CommonView
              url='materialrequisitionitems'
              id={viewdetails?.materialRequisitionItemId}
              excludeKeys={excludeKeys}
              renderFields={(data, fields) => (
                <DynamicViewFields data={data} fields={fields} />
              )}
            />
          )}
        </MuiDialogOne>
      </GlassCard>
    </>
  );
};

export default CartMaterialRequest;
