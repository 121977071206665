import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid, TextField, MenuItem } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../components/_form';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const EditClients = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const validationSchema = Yup.object().shape({
    clientName: Yup.string()
      .required('Client Name is required')
      .min(2, 'Client Name must be at least 2 characters')
      .max(100, 'Client Name must not exceed 100 characters'),
    contactEmailId: Yup.string().email(
      'Contact email ID must be a valid email address'
    ),
    contactNumber: Yup.string()
      .min(8, 'Contact Number must be at least 8 characters')
      .max(12, 'Contact Number must be at less than 12 characters')
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      ),
  });
  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const { t } = useTranslation();

  useEffect(() => {
    getAllClients();
  }, []);

  //   Setting the updated client values
  const getAllClients = async () => {
    setLoading(true);
    await crud.getSingle('clients', id, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
      } else {
      }
    });
  };

  const handleSubmitProject = async (values) => {
    startCreateLoading();

    await crud.update(`clients`, id, values, (err, res) => {
      if (res?.status === 200) {
        toast.success(t('client updated successfully'));
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Edit Clients'>
      <Box className='mx-auto' sx={{ maxWidth: '800px' }}>
        <GlassCard className='p-5'>
          {loading ? (
            <CommonLoader />
          ) : (
            <form onSubmit={handleSubmit(handleSubmitProject)}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <FmTextField
                      name='clientName'
                      control={control}
                      label='Client Name'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='clientIndustry'
                      control={control}
                      label='Client Industry'
                    />
                  </Grid>
                  <Grid item md={12}>
                    <FmTextField
                      name='headOfficeAddress'
                      control={control}
                      label='Head Office Address'
                      multiline
                      rows={3}
                      maxRows={4}
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmTextField
                      name='contactPerson'
                      control={control}
                      label='Contact Person'
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmTextField
                      name='contactNumber'
                      control={control}
                      label='Contact Number'
                      type='number'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='contactEmailId'
                      control={control}
                      label='Contact Email'
                      type='email'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name='status'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Status'
                          fullWidth
                          select
                          variant='filled'
                        >
                          <MenuItem value={2}>Active</MenuItem>
                          <MenuItem value={1}>Inactive</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                </Grid>
                <br />
                <ActionButtons
                  onSubmit={handleSubmit(handleSubmitProject)}
                  onCancel={() => navigate(-1)}
                  onReset={reset}
                  submitLoading={createLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </LocalizationProvider>
            </form>
          )}
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default EditClients;
