import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import Icons from '../../../../../utils/Icon.jsx';
import { Box, Grid, TextField, MenuItem, Button } from '@mui/material';
import MRTIconButton from '../../../../../components/table/MRTIconButton.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReceiptKeyValidationSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import CreatereceiptKeys from './CreatereceiptKeys.tsx';

const BoldBox = ({ title }) => {
  return <Box className='font-semibold'>{title}</Box>;
};

const MdItemValue = 2;

const ReceiptKeysList = ({ CheckInFormId }) => {
  const navigate = useNavigate();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const {
    createLoading,
    startCreateLoading,
    startEditLoading,
    stopCreateLoading,
    stopEditLoading,
  } = useLoader();
  const crud = new Crud_Service();
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(ReceiptKeyValidationSchema),
    mode: 'onChange',
  });

  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('checkinformitems');
  const handleEdit = (row) => {
    setEditingRowId(row?.checkInFormItemId);
    setEditingRowData({ ...row });
    setEditingRowId(row?.checkInFormItemId);

    // createSetValue('codeNumber', row?.codeNumber || '');
    // createSetValue('quantity', row?.quantity || '');
    // createSetValue('remarks', row?.remarks || '');
    // createSetValue('checkInLocationId', row?.checkInLocationId || '');
    // createSetValue('propertyId', row?.propertyId || '');
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleDeleteItem = async (view) => {
    await crud.remove(
      'checkinformitems',
      view?.checkInFormItemId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('Receipt key Details Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleUpdateItem = async (values) => {
    startEditLoading();

    const combinedData = {
      ...values,
      propertyId: null,
      checkInLocationId: null,
      quantity:
        values.quantity !== '' ? values.quantity : editingRowData?.quantity,
      remarks: values.remarks !== '' ? values.remarks : editingRowData?.remarks,
      codeNumber:
        values.codeNumber !== ''
          ? values.codeNumber
          : editingRowData?.codeNumber,
      status: 2,
    };

    await crud.update(
      'checkinformitems',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          fetchData();
          toast.success('item Updated Successfully');
          setEditingRowData(null);
          setEditingRowId(null);
        } else {
          stopEditLoading();
        }
      }
    );
  };
  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteItem(props),
      danger: true,
    },
  ];
  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.checkInFormItemId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateItem)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => {
                  setEditingRowId(row.original.checkInFormItemId);
                  action.onClick(row?.original);
                },
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'codeNumber',
      header: 'Code Number',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormItemId ? (
          <FmTextField
            name='codeNumber'
            control={control}
            label='Code Number'
            defaultValue={editingRowData?.codeNumber}
          />
        ) : (
          row.original.codeNumber
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormItemId ? (
          <FmTextField
            name='quantity'
            control={control}
            label='Quantity'
            defaultValue={editingRowData?.quantity}
          />
        ) : (
          row.original.quantity
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormItemId ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            multiline={true}
            rows={2}
            maxRows={3}
            defaultValue={editingRowData?.remarks}
          />
        ) : (
          row.original.remarks
        );
      },
    },
  ];

  return (
    <>
      <CreatereceiptKeys fetchData={fetchData} CheckInFormId={CheckInFormId} />
      <GlassCard className='my-4'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
        />
      </GlassCard>
    </>
  );
};

export default ReceiptKeysList;
