import { Box, Stack, Divider } from '@mui/material';
import React from 'react';
import Breadcums from '../helpers/Breadcums';

const Title = ({ title, children, titleReverse }) => {
  return (
    <Box
      className={` ${
        titleReverse && 'flex-row-reverse'
      } flex flex-row items-center justify-between`}
      sx={{
        color: 'text.main',
        bgcolor: 'background.white',
        borderBottom: '1px solid',
        borderColor: 'border.main',
        padding: '5px 10px',
        minHeight: '41px',
      }}
    >
      {/* flex */}
      <Stack
        spacing={3}
        direction='row'
        divider={<Divider orientation='vertical' flexItem />}
      >
        <Box className='text-sm font-bold' sx={{ color: 'text.dark' }}>
          {title}
        </Box>
        <Box className='ms-5'>
          <Breadcums />
        </Box>
      </Stack>
      {/* flex */}
      <Box className='flex flex-row items-center gap-3'>{children}</Box>
    </Box>
  );
};

export default Title;
