import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { CBPettyCashValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';

const GridSize = 2.4;

const AddCashBankReceipt = (props) => {
  const { type, fetchData } = props;
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading } = useLoader();

  const { control, handleSubmit, reset,getValues,setValue } = useForm({
    resolver: yupResolver(CBPettyCashValidationSchema),
    mode: 'onChange',
  });
  const { createLoading } = useLoader();
  const [fileId, setFileId] = useState(null);
  const handleSubmitData = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      paymentStatusId: 1,
      payeeUserId: values.loginUserId,
      requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
      chequeDate: dayjs(values?.chequeDate).format('YYYY-MM-DD'),
      fileId: fileId,
      typeId: type,
    };
    await crud.create('financialtransactions', combinedData, (err, res) => {
      stopCreateLoading();
      if (err) {
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Added successfully');
        fetchData();
        reset();
      } else {
      }
    });
  };
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 33);

      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);
             
              setLoading(false);
            } else {
              reject(new Error('File upload failed'));
              setLoading(false);
            }
          });
        });

        setFileId(response?.data?.fileId);
        setValue(
          `documentName`,
          response?.data?.originalFileName
        );
      } catch (error) {

        setLoading(false);
      } finally {
      
        setLoading(false);
      }
    }
  };
  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmTextField
              name='financialTransactionCode'
              control={control}
              label='Financial Transaction Code'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmDatePicker
              name='requestDate'
              label='Request Date'
              control={control}
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='bankId'
              control={control}
              apiUrl='banks'
              valueField='bankId'
              headerField={['Bank Code', 'Bank Name']}
              labelField={['bankCode', 'bankName']}
              showField={['bankName']}
              pageSize={20}
              label='Bank'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='companyId'
              control={control}
              apiUrl='companies'
              valueField='companyId'
              headerField={['Company Code', 'Company Name']}
              labelField={['companyCode', 'companyName']}
              showField={['companyName']}
              pageSize={20}
              label='Company'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='branchId'
              control={control}
              apiUrl='emirates'
              valueField='emirateId'
              labelField={['emirateName']}
              showField={['emirateName']}
              pageSize={20}
              label={'Branch'}
            />
          </Grid>

          {(type === 2 || type === 3) && (
            <>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='placeOfSupplyId'
                  control={control}
                  apiUrl='emirates'
                  valueField='emirateId'
                  labelField={['emirateName']}
                  showField={['emirateName']}
                  pageSize={20}
                  label={'Place Of Supply'}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='jurisdictionId'
                  control={control}
                  apiUrl='emirates'
                  valueField='emirateId'
                  labelField={['emirateName']}
                  showField={['emirateName']}
                  pageSize={20}
                  label={'Jurisdiction'}
                />
              </Grid>
            </>
          )}

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='payeeUserName'
              control={control}
              headerField={['Code', 'Name']}
              apiUrl='loginusers'
              valueField='loginUserId'
              labelField={['emailId', 'loginUserName']}
              showField={['emailId', 'loginUserName']}
              pageSize={50}
              label='Payee User'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='currencyId'
              control={control}
              apiUrl='currencies'
              valueField='currencyId'
              headerField={['Currency Code', 'Currency Name']}
              labelField={['currencyCode', 'currencyName']}
              showField={['currencyName']}
              pageSize={20}
              label='Currency'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField name='fileId' control={control} label='File' />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='narration' control={control} label='Narration' />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='chequeNo' control={control} label='Cheque No' />
          </Grid>

          <Grid item md={GridSize}>
            <FmDatePicker
              name='chequeDate'
              label='Cheque Date'
              control={control}
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField name='pdcNo' control={control} label='PDC No' />
          </Grid>
        <Grid item md={GridSize}>
          <FmFileInput
                            name={`fileId`}
                            documentName={getValues(
                              `documentName`
                            )}
                            onChange={(e) => handleFileUpload( e)}
                            loading={loading}
                          />
          </Grid>
        </Grid>

        <ActionButtons
          onSubmit={handleSubmit(handleSubmitData)}
          onReset={reset}
          submitLoading={createLoading}
        />
      </form>
    </GlassCard>
  );
};

export default AddCashBankReceipt;
