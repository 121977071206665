import React, { useEffect } from 'react';
import DashboardLayout from './../../../components/DashboardLayout.tsx';
import GlassCard from './../../../components/small/GlassCard.tsx';
import { Button, TextField, Stack, MenuItem, Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import GoodsReceivedNoteDatagrid from './GoodsReceivedNoteDatagrid.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';

const FilterGoods = [
  {
    name: 'PO Number',
    type: 'select',
    children: [
      {
        name: 'Po Number 1',
        value: 'po_number_one',
      },
      {
        name: 'Po Number 2',
        value: 'po_number_two',
      },
      {
        name: 'Po Number 3',
        value: 'po_number_three',
      },
      {
        name: 'Po Number 4',
        value: 'po_number_four',
      },
    ],
  },
  {
    name: 'All MR Status ',
    type: 'select',
    children: [
      {
        name: 'All MR Status 1',
        value: 'po_number_one',
      },
      {
        name: 'All MR Status 2',
        value: 'po_number_two',
      },
      {
        name: 'All MR Status 3',
        value: 'po_number_three',
      },
      {
        name: 'All MR Status 4',
        value: 'po_number_four',
      },
    ],
  },
  {
    name: 'All Requester',
    type: 'select',
    children: [
      {
        name: 'All Requester 1',
        value: 'po_number_one',
      },
      {
        name: 'All Requester 2',
        value: 'po_number_two',
      },
      {
        name: 'All Requester 3',
        value: 'po_number_three',
      },
      {
        name: 'All Requester 4',
        value: 'po_number_four',
      },
    ],
  },
  {
    name: 'Data Range',
    type: 'date',
  },
];

const GoodsReceivedNoteList = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Goods Received Note  | Procurement`;
  }, []);

  return (
    <DashboardLayout
      title='Goods Received Note'
      actionButtons={
        <>
          <CreateButton name='New' onClick={() => navigate('create')} />
        </>
      }
    >
      <Box className='flex flex-col h-full'>
        {/* //TODO: for Future Use */}
        {/* <GlassCard className='p-4 mb-3'>
          <Stack container spacing={3} direction='row'>
            {FilterGoods?.map((item) => {
              return (
                <>
                  {item.type === 'select' && (
                    <Box key={item.name} className='w-full'>
                      <TextField fullWidth label={item.name} select>
                        {item.children?.length === 0 && (
                          <MenuItem value='0'>No Select Found</MenuItem>
                        )}
                        {item.children?.map((box) => {
                          return (
                            <MenuItem value={box.value}>{box.name}</MenuItem>
                          );
                        })}
                      </TextField>
                    </Box>
                  )}
                  {item.type === 'date' && (
                    <Box key={item.name} className='w-full'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label={item.name} sx={{ width: '100%' }} />
                      </LocalizationProvider>
                    </Box>
                  )}
                </>
              );
            })}

            <Button variant='contained' fullWidth className='font-bold'>
              Filter
            </Button>
          </Stack>
        </GlassCard> */}
        <GoodsReceivedNoteDatagrid />
      </Box>
    </DashboardLayout>
  );
};

export default GoodsReceivedNoteList;
