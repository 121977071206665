import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import PageHeader from '../../../components/PageHeader';
import { Icon } from '@iconify/react';
import { CreateButton } from '../../../components/small/Buttons';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import { StatusBadge, ViewField } from '../../../components/_form/FormElements';
import Crud_Service from '../../../apis/CrudService';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import apiInstance from '../../../apis/ApiService.jsx';
import { ToolbarBoxesFlex } from '../../../components/boxes/Boxes.jsx';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import MuiDialogOne from './../../../components/_mui/MuiDialogOne.jsx';
import TableActionButtons from './../../../components/_form/TableActionButtons';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';

const CompanyCertificate = () => {
  const navigate = useNavigate();
  const [viewData, setViewData] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const crud = new Crud_Service();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
    toggleLoading,
    searchKeyword,
  } = useTableLogic('companycertifications');

  useEffect(() => {
    document.title = `HRMS - Company Certificates`;
  }, []);

  //view
  const handleViewClick = async (view) => {
    setViewData(true);
    setViewLoading(true);
    await crud.getSingle(
      `companycertifications/${view?.companyCertificationId}`,
      '',
      (_err, res) => {
        if (res?.status === 200) {
          setSelectedRow(res?.data);
          setViewLoading(false);
        } else {
          setViewLoading(false);
        }
      }
    );
  };
  const downloadCertificates = async () => {
    toggleLoading(true);
    const downloadUrl = await apiInstance.getFiles(
      'companycertifications/exportexcel'
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Company-Certifications.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toggleLoading(false);
  };

  const handleEditClick = (view) => {
    navigate(`edit/${view?.companyCertificationId}`);
  };

  const handleDeleteClick = async (view) => {
    await crud.remove(
      'companycertifications',
      view?.companyCertificationId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
        } else {
        }
      }
    );
  };

  const handleDownloadClick = async (props) => {
    try {
      const imageUrl = await apiInstance.getFiles(
        `files/download/${props?.fileId}`
      );
      const link = document.createElement('a');
      link.href = imageUrl;
      link.setAttribute('download', `${props?.fileName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {}
  };

  const Actions = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Download',
      icon: <Icon icon='solar:download-outline' />,
      onClick: (props) => handleDownloadClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'column_sort',
      headerName: '',
      width: 150,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={Actions?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            setSelectedRow={setSelectedRow}
          />
        );
      },
    },

    {
      field: 'companyName',
      headerName: 'Company Name',
      width: 400,
      renderCell: (params) => {
        return <TableAvatar name={params.value} />;
      },
    },
    {
      field: 'certificationName',
      headerName: 'Certification Name',
      flex: 5,
      hideable: false,
      minWidth: 220,
    },
    {
      field: 'contractName',
      headerName: 'Contract Name',
      minWidth: 150,
    },
    {
      field: 'locationName',
      headerName: 'Location',
      minWidth: 150,
    },

    {
      field: 'startDate',
      headerName: 'Starting date',
      flex: 5,
      minWidth: 150,
      renderCell: (params) => {
        return <FmViewDateField value={params?.value} />;
      },
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry date',
      flex: 5,
      minWidth: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'lastRenewDate',
      headerName: 'Last Renew Date',
      flex: 5,
      minWidth: 180,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'reminderDays',
      headerName: 'Reminder Days',
      flex: 5,
      minWidth: 150,
    },
    {
      field: 'certificateStatusName',
      headerName: 'Certificate Status',
      flex: 5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value}
            type={params.value === 'Submitted' ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const counters = [];

  //Row Double Click
  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <DashboardLayout
      title='Company Certificates'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <PageHeader counters={counters} />

            <CreateButton name={'New'} onClick={() => navigate('create')} />
          </Box>
        </>
      }
      hasSubmenu
    >
      <GlassCard className='h-full-css'>
        <Box className='h-full-css'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            onRowDoubleClick={(e) => onRowDoubleClick(e)}
            getRowClassName={(params) =>
              `${
                params.row.workingStatus === 'InActive' &&
                'bg-red-500 bg-opacity-15'
              }`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <ToolbarBoxesFlex>
                  <TableSearch
                    placeholder='Search'
                    setSearchKeyword={setSearchKeyword}
                    searchValue={searchKeyword}
                  />
                  <DataTableToolbarButtons
                    handleExcelExport={downloadCertificates}
                  />
                </ToolbarBoxesFlex>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {viewData && (
        <>
          <MuiDialogOne
            open={viewData}
            title='View Details'
            onClose={() => setViewData(!viewData)}
          >
            {' '}
            {viewLoading ? (
              <CommonLoader />
            ) : (
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <ViewField
                    label='Company Name'
                    title={selectedRow?.companyName}
                  />
                </Grid>
                <Grid item md={6}>
                  <ViewField
                    label='Certificate Name'
                    title={selectedRow?.certificationName}
                  />
                </Grid>{' '}
                <Grid item md={6}>
                  <FmViewDateField
                    title='Expiry Date'
                    value={selectedRow?.expiryDate}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmViewDateField
                    title='Last Renewal Date'
                    value={selectedRow?.lastRenewDate}
                  />
                </Grid>
                <Grid item md={6}>
                  <ViewField
                    label='Location Name'
                    title={selectedRow?.locationName}
                  />
                </Grid>
                <Grid item md={6}>
                  <ViewField
                    label='Reminder Days'
                    title={selectedRow?.reminderDays}
                  />
                </Grid>
                <Grid item md={6}>
                  <ViewField
                    label='Certification Status'
                    title={selectedRow?.certificateStatusName}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmViewDateField
                    title='Start Date'
                    value={selectedRow?.startDate}
                  />
                </Grid>
                <Grid item md={6}>
                  <ViewField label='Status' title={selectedRow?.statusName} />
                </Grid>
                <Grid item md={6}>
                  <ViewField label='Remarks' title={selectedRow?.remarks} />
                </Grid>
              </Grid>
            )}
          </MuiDialogOne>
        </>
      )}
    </DashboardLayout>
  );
};

export default CompanyCertificate;
