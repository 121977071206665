import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../components/small/Buttons';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box, Grid } from '@mui/material';
import DataTable from '../../../components/DataTable.tsx';
import PageFilter from '../../PageFilter';
import { Icon } from '@iconify/react';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import Crud_Service from '../../../apis/CrudService';
import CommonLoader from '../../../components/page/CommonLoader';
import {
  StatusBadge,
  TextColor,
  ViewField,
} from '../../../components/_form/FormElements';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';

const TenderEnquiry = () => {
  const [selectedRow, setSelectedRow] = useState();
  const [showView, setShowView] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [loading, setLoading] = useState(false);
  const crud = new Crud_Service();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    document.title = `PreSales - Tender/Lead Enquiry`;
    getAllTenders();
  }, [pageNumber, pageSize, sortBy, sortDirection]);

  const getAllTenders = async () => {
    setLoading(true);
    await crud.getAll(
      'tenders',
      {
        pageSize,
        pageNumber,
        sortBy,
        sortDirection,
      },
      (err, res) => {
        if (err) {
          toast.error('Error fetching Tenders:', err);
          return;
        }
        if (res?.status === 200) {
          const transformedData = res?.data?.data?.map((row) => ({
            ...row,
            id: row?.tenderId,
          }));
          setRows(transformedData);
          setLoading(false);
        }
      }
    );
  };

  //Pagination and Sort Filter
  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    } else {
      setSortBy('');
      setSortDirection('');
    }
  };

  //setvalues to the Inputs
  const handleEditClick = async (props) => {
    navigate(`edit/${props?.tenderId}`);
  };

  //view the ShiftTiming
  const handleViewClick = async (view) => {
    setShowView(true);
    await crud.getSingle('subcontracts', view?.subContractId, (err, res) => {
      if (res?.status === 200) {
        setSelectedRow(res?.data);
      } else {
      }
    });
  };

  //handle Delete
  const handleDelete = async (props) => {
    await crud.remove('tenders', props?.tenderId, (err, res) => {
      if (res?.status === 204) {
        getAllTenders();
      } else {
      }
    });
  };

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      // onClick: () => setOpenCreate(!openCreate),
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'tenderCode',
      headerName: 'Tender Code',
      minWidth: 120,
    },

    {
      field: 'tenderName',
      headerName: 'Tender Name',
      minWidth: 120,
    },
    {
      field: 'tenderEnquiryTypeName',
      headerName: 'Tender Enquiry Type Name',
      minWidth: 120,
    },
    {
      field: 'clientName',
      headerName: 'Client Name',
      minWidth: 120,
    },
    {
      field: 'tenderProjectName',
      headerName: 'Tender Project Name',
      minWidth: 120,
    },
    {
      field: 'tenderFormatName',
      headerName: 'Tender Format Name',
      minWidth: 120,
    },
    {
      field: 'autoRenewal',
      headerName: 'Auto Renewal',
      minWidth: 120,
      renderCell: (params) => {
        return (
          <TextColor
            title={params.value === true ? 'Yes' : 'No'}
            color={params.value === true ? 'green' : 'red'}
          />
        );
      },
    },
    {
      field: 'bidBondValue',
      headerName: 'Bidbond Value (Fixed)',
      minWidth: 120,
    },
    {
      field: 'subContractRequired',
      headerName: 'Sub contract requried',
      minWidth: 120,
      renderCell: (params) => {
        return (
          <TextColor
            title={params.value === true ? 'Yes' : 'No'}
            color={params.value === true ? 'green' : 'red'}
          />
        );
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      minWidth: 120,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'lastDate',
      headerName: 'Last Date',
      minWidth: 120,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'bidBond',
      headerName: 'BidBond',
      minWidth: 120,
      renderCell: (params) => {
        return (
          <TextColor
            title={params.value === true ? 'Yes' : 'No'}
            color={params.value === true ? 'green' : 'red'}
          />
        );
      },
    },
    {
      field: 'expressOfInterest',
      headerName: 'Express of interest',
      minWidth: 120,
      renderCell: (params) => {
        return (
          <TextColor
            title={params.value === true ? 'Yes' : 'No'}
            color={params.value === true ? 'green' : 'red'}
          />
        );
      },
    },
    {
      field: 'buyTenderDocument',
      headerName: 'Buy tender document',
      minWidth: 120,
      renderCell: (params) => {
        return (
          <TextColor
            title={params.value === true ? 'Yes' : 'No'}
            color={params.value === true ? 'green' : 'red'}
          />
        );
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params?.value === 2 ? 'Active' : 'Inactive'}
            type={params?.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },

    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
  ];

  return (
    <>
      <DashboardLayout
        title='Tender/Lead Enquiry'
        actionButtons={
          <>
            <CreateButton name='New' onClick={() => navigate('create')} />
          </>
        }
      >
        <>
          {/* Listing */}
          <GlassCard>
            <Box className='box_height_1'>
              {loading ? (
                <CommonLoader />
              ) : (
                <DataTable
                  rows={rows}
                  columns={columns}
                  getRowClassName={(params) =>
                    `${
                      params.row.workingStatus === 'InActive' &&
                      'bg-red-500 bg-opacity-15'
                    }`
                  }
                  sortingMode='server'
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    handlePageSizeChange(newPageSize)
                  }
                  onSortModelChange={(model) => handleSortChange(model)}
                  page={pageNumber - 1}
                  pageSize={pageSize}
                  slots={{
                    toolbar: () => (
                      <PageFilter
                        SelectFilter={[]}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                      ></PageFilter>
                    ),
                  }}
                />
              )}
            </Box>
          </GlassCard>
          {/* Listing */}
        </>

        {/* view */}
        <MuiDialogOne
          title='View'
          open={showView}
          onClose={() => setShowView(false)}
        >
          <Grid container spacing={3}>
            <Grid item md={6}>
              <ViewField
                label='SubContract Name'
                title={selectedRow?.subContractName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='SubContract Code'
                title={selectedRow?.subContractCode}
              />
            </Grid>
            <Grid item md={12}>
              <ViewField label='Description' title={selectedRow?.description} />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='SubContract Visit Type Name'
                title={selectedRow?.subContractVisitTypeName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Supplier Name'
                title={selectedRow?.supplierName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Cost Of Year1'
                title={selectedRow?.costOfYear1}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Cost Of Year2'
                title={selectedRow?.costOfYear2}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Cost Of Year3'
                title={selectedRow?.costOfYear3}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Cost Of Year4'
                title={selectedRow?.costOfYear4}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Cost Of Year5'
                title={selectedRow?.costOfYear5}
              />
            </Grid>

            <Grid item md={12}>
              <ViewField label={t('status')} title={selectedRow?.statusName} />
            </Grid>
          </Grid>
        </MuiDialogOne>
        {/* view */}
      </DashboardLayout>
    </>
  );
};

export default TenderEnquiry;
