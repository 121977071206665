import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const TableTick = ({ type, title }) => {
  return (
    <Tooltip title={title} arrow>
      <Box className={`${type ? 'text-green-500' : 'text-red-500'} text-xl`}>
        {type ? (
          <Icon icon='material-symbols:check-circle-rounded' />
        ) : (
          <Icon icon='material-symbols:cancel-rounded' />
        )}
      </Box>
    </Tooltip>
  );
};

export default TableTick;
