import React from 'react';
import {
  Box,
  CircularProgress,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Radio,
  Typography,
} from '@mui/material';
import '@nosferatu500/react-sortable-tree/style.css';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';

const AddDocuments = (props) => {
  const {
    doc,
    createSubDivision,
    setCreateSubDivision,
    localLoader,
    documents,
    selectedDocumentIds,
    handleCheckboxChange,
    handleAddDocumentToType,
    createDivision,
    setCreateDivision,
    handleSubmit,
    createDocumentType,
    control,
  } = props;

  return (
    <>
      {/* Dialog for Adding Documents to a Document Type */}
      <MuiDialogOne
        title={`Add Documents to ${doc.documentTypeName}`}
        open={createSubDivision}
        onClose={() => setCreateSubDivision(false)}
      >
        {localLoader ? (
          <CircularProgress />
        ) : (
          <>
            <Stack>
              {/* List of Documents with Checkboxes */}
              {documents?.length > 0 ? (
                documents?.map((item) => (
                  <FormControlLabel
                    key={item.documentId}
                    control={
                      item.documentTypeId === 1 ? (
                        <Radio
                          checked={selectedDocumentIds.has(item.documentId)}
                          onChange={(event) =>
                            handleCheckboxChange(event, item.documentId, 1)
                          }
                        />
                      ) : (
                        <Checkbox
                          checked={selectedDocumentIds.has(item.documentId)}
                          onChange={(event) =>
                            handleCheckboxChange(event, item.documentId, 2)
                          }
                        />
                      )
                    }
                    label={item.documentName}
                    sx={{ fontSize: '15px' }}
                  />
                ))
              ) : (
                <Typography>No Documents Found</Typography>
              )}
            </Stack>

            <Box className='mt-5 text-end'>
              {/* Submit Button to Add Selected Documents to the Tree */}
              <Button
                variant='contained'
                className='font-bold min-w-36'
                onClick={handleAddDocumentToType}
              >
                {documents?.length > 0 ? 'Submit' : 'Cancel'}
              </Button>
            </Box>
          </>
        )}
      </MuiDialogOne>

      {/* Dialog for Creating a New Document Type */}
      <MuiDialogOne
        title='Create New Document Type'
        open={createDivision}
        onClose={() => setCreateDivision(false)}
      >
        <form onSubmit={handleSubmit(createDocumentType)}>
          <Stack spacing={3}>
            {/* Input Field for Document Type Name */}
            <FmTextField
              label='Document Type Name'
              name='documentTypeName'
              control={control}
            />
            <ActionButtons
              onSubmit={handleSubmit(createDocumentType)}
              onCancel={() => setCreateDivision(false)}
              submitLoading={false}
              cancelLoading={false}
            />
          </Stack>
        </form>
      </MuiDialogOne>
    </>
  );
};

export default AddDocuments;
