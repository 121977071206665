import React, { useEffect, useState } from 'react';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import { Box, Button } from '@mui/material';
import Icons from '../../../../../utils/Icon.jsx';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { TenantVehiclevalidationSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';

const TenantVehicleTable = (props) => {
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(TenantVehiclevalidationSchema),
    mode: 'onChange',
  });
  const { startEditLoading, stopEditLoading } = useLoader();

  const crud = new Crud_Service();

  const {
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
    checkInFormId,
  } = props;
  const handleEdit = (row) => {
    setEditingRowId(row?.checkInFormTenantVehicleId);
    setEditingRowData({ ...row });
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  useEffect(() => {
    if (editingRowData) {
      Object.entries({
        ...editingRowData,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);

  const handleDeleteEvent = async (view) => {
    await crud.remove(
      'checkinformtenantvehicles',
      view?.checkInFormTenantVehicleId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('Vehicle Details Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleUpdateVehicle = async (values) => {
    startEditLoading();

    const combinedData = {
      ...values,
      checkInFormId: checkInFormId,
      vehicleBrandId: values?.vehicleBrandId,
      vehicleColor: values?.vehicleColor,
      registrationNumber: values?.registrationNumber,
      parkingId: values?.parking,
    };

    await crud.update(
      'checkinformtenantvehicles',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          fetchData();
          toast.success('Vehicle Updated Successfully');
          setEditingRowData(null);
          setEditingRowId(null);
        } else {
          stopEditLoading();
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteEvent(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.checkInFormTenantVehicleId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateVehicle)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },

    {
      accessorKey: 'vehicleBrandId',
      header: 'Vehicle Brand Name',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormTenantVehicleId ? (
          <FmSearchableSelect
            name='vehicleBrandId'
            control={control}
            apiUrl='vehiclebrands'
            valueField='vehicleBrandId'
            headerField={['Code', 'Name']}
            defaultValue={{
              vehicleBrandId: editingRowData?.vehicleBrandId,
              vehicleBrandName: editingRowData?.vehicleBrandName,
            }}
            labelField={['vehicleBrandCode', 'vehicleBrandName']}
            showField={['vehicleBrandName']}
            pageSize={20}
            label={'Vehicle Brand'}
          />
        ) : (
          row.original.vehicleBrandName
        );
      },
    },

    {
      accessorKey: 'vehicleColor',
      header: 'Vehicle Color',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormTenantVehicleId ? (
          <FmTextField
            name='vehicleColor'
            control={control}
            defaultValue={editingRowData?.vehicleColor}
          />
        ) : (
          row.original.vehicleColor
        );
      },
    },

    {
      accessorKey: 'registrationNumber',
      header: 'RegistrationNumber',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormTenantVehicleId ? (
          <FmTextField
            name='registrationNumber'
            control={control}
            defaultValue={editingRowData?.registrationNumber}
          />
        ) : (
          row.original.registrationNumber
        );
      },
    },
    {
      accessorKey: 'parkingId',
      header: 'Parking',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormTenantVehicleId ? (
          <FmTextField
            name='parking'
            control={control}
            defaultValue={editingRowData?.parking}
          />
        ) : (
          row.original.parking
        );
      },
    },
  ];
  return (
    <>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </>
  );
};
export default TenantVehicleTable;
