import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react';

const colorClasses = {
  green: 'text-green-700 bg-green-200 dark:bg-green-800 dark:text-green-200',
  red: 'text-red-700 bg-red-200 dark:bg-red-800 dark:text-red-200',
  blue: 'text-blue-700 bg-blue-200 dark:bg-blue-800 dark:text-blue-200',
  black: 'text-gray-700 bg-gray-200 dark:bg-black dark:text-gray-200',
  sky: 'text-sky-700 bg-sky-200 dark:bg-sky-800 dark:text-sky-200',
  purple:
    'text-purple-700 bg-purple-200 dark:bg-purple-800 dark:text-purple-200',
  orange:
    'text-orange-700 bg-orange-200 dark:bg-orange-800 dark:text-orange-200',
  teal: 'text-teal-700 bg-teal-200 dark:bg-teal-800 dark:text-teal-200',
  indigo:
    'text-indigo-700 bg-indigo-200 dark:bg-indigo-800 dark:text-indigo-200',
  yellow:
    'text-yellow-700 bg-yellow-200 dark:bg-yellow-800 dark:text-yellow-200',
  active: 'active',
  // Add more colors as needed
};

const TextBasedColor = {
  present: 'green',
  absent: 'red',
  leave: 'red',
  holiday: 'blue',
  notavailable: 'black',
  sickleave: 'purple',
  draft: 'red',
  approved: 'green',
  pending: 'orange',
  rejected: 'red',
  paid: 'green',
  unpaid: 'red',
  partiallypaid: 'orange',
  cash: 'green',
  cheque: 'blue',
  banktransfer: 'purple',
  creditcard: 'indigo',
  postdatedcheque: 'orange',
  inwardpayment: 'purple',
  archived: 'blue',
  acknowledged: 'green',
  delivered: 'indigo',
  active: 'green',
  reconciled: 'green',
  notreconciled: 'red',
  paymentvouchergenerated: 'blue',
  submitted: 'yellow',
  paymentcreated: 'teal',
  reject: 'red',
  receiptsgenerated: 'purple',
  handovertoclient: 'sky',
  invoicegenerated: 'indigo',
  readytoinvoice: 'green',
  issued: 'teal',
};

const StatusNewBadge = ({ title, type, normal, dotRemove }) => {
  const TextType = type && type.toLowerCase().replace(/\s/g, '');
  const textColor = TextBasedColor[TextType || ''] || 'gray';
  const textColorClass = colorClasses[textColor] || 'text-gray-700 bg-gray-200';

  return (
    <Box
      className={`${textColorClass}  ${
        normal ? 'px-2 py-1' : 'px-1 py-0.5'
      }  gap-0.5   inline-flex items-center`}
      sx={{
        fontSize: normal ? 'inherit' : '11px',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontWeight: 500,
      }}
    >
      {!dotRemove ? (
        <span>
          <Icon icon='radix-icons:dot-filled' />
        </span>
      ) : null}
      {title}
    </Box>
  );
};

export default StatusNewBadge;
