import React, { useEffect, useState } from 'react';
import DashboardLayout from './../../../components/DashboardLayout.tsx';
import AddIncidentReport from './AddIncidentReport.tsx';
import IncidentReportList from './IncidentReportList.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons.jsx';

const IncidentReport = () => {
  const [signatureId, setSignatureId] = useState(null);
  const navigate=useNavigate();

  useEffect(() => {
    document.title = `Incident Report | CAFM`;
  }, []);


  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('incidentreports');

  return (
    <DashboardLayout title='Incident Report' 
    actionButtons={
      <>
        <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
          <CreateButton
            name='Add Incident Report'
            onClick={() => navigate('create')}
          />
        </Box>
      </>
    }>
      <IncidentReportList
        rows={rows}
        tableRecordCounts={tableRecordCounts}
        pagination={pagination}
        sorting={sorting}
        fetchData={fetchData}
        setPagination={setPagination}
        setSorting={setSorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        signatureId={signatureId} />
    </DashboardLayout>
  );
};

export default IncidentReport;
