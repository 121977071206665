import React, { useEffect, useState } from 'react';
import { Button, Menu, Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import NoDataAvailable from '../elements/NoDataAvailable.tsx';

interface FilterDropdownStatusProps {
  data?: { label: string; value: string }[];
  name?: string;
  status: string | null;
  setStatus: (value: string | null) => void;
}

const FilterDropdownStatus: React.FC<FilterDropdownStatusProps> = ({
  data = [],
  name = 'Filter',
  status = null,
  setStatus,
}) => {
  const [menu, setMenu] = useState(null);
  const [checkedItems, setCheckedItems] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const open = Boolean(menu);

  const handleClick = (event) => {
    setMenu(event.currentTarget);
  };

  const handleClose = () => {
    setMenu(null);
  };

  const handleCheckboxChange = (item, name, index) => {
    setCheckedItems(name);
    setActiveId(index);
    setStatus(item?.localPurchaseOrderStatusId);
    setMenu(null);
  };

  const handleClear = () => {
    setCheckedItems(null);
    setActiveId(null);
    setStatus(0);
  };

  useEffect(() => {
    if (status) {
      data.map((item, index) => {
        if (item?.localPurchaseOrderStatusId === status) {
          setActiveId(index);
          setCheckedItems(item?.localPurchaseOrderStatusName);
        }
      });
    }
  }, [status]);

  return (
    <>
      <Box className='flex items-center gap-2'>
        <Button
          onClick={handleClick}
          startIcon={<Icon icon='material-symbols:filter-list' />}
          sx={{
            border: '1px solid',
            borderColor: 'border.main',
            fontWeight: 500,
          }}
          className='p-1 px-4'
        >
          {name}

          {checkedItems && (
            <Box
              className='px-2 ms-2 font-semibold'
              sx={{ bgcolor: 'background.mainLight', borderRadius: '5px' }}
            >
              {checkedItems}
            </Box>
          )}
        </Button>

        {checkedItems && (
          <Button className='p-1 px-4' onClick={handleClear}>
            Clear All
          </Button>
        )}
      </Box>

      <Menu
        open={open}
        anchorEl={menu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: {
            boxShadow: 3,
            p: 1,
            '& .MuiList-root': {
              p: 0,
            },
          },
        }}
      >
        <Box className='flex flex-col gap-1' component={'li'}>
          {data?.length === 0 && (
            <>
              <NoDataAvailable />
            </>
          )}
          {data?.map((item, i) => (
            <Button
              key={item.localPurchaseOrderStatusName}
              className='flex items-start font-normal text-left justify-start py-0.5 px-2  min-w-[150px]'
              onClick={() =>
                handleCheckboxChange(item, item.localPurchaseOrderStatusName, i)
              }
              variant={i === activeId ? 'contained' : 'text'}
            >
              {item.localPurchaseOrderStatusName}
            </Button>
          ))}
        </Box>
      </Menu>
    </>
  );
};

export default FilterDropdownStatus;
