import React from 'react';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Nav } from '../../utils';

const PageSubmenuBoxed = ({ NavId, ChildId }) => {
  return (
    <>
      <Box className='flex flex-row flex-wrap gap-5'>
        {Nav[NavId].child[ChildId].children.map((value, i) => {
          return (
            <Box
              className='p-5 text-center relative flex justify-center items-center flex-col gap-5'
              sx={{
                bgcolor: 'background.white',
                border: '1px solid',
                borderColor: 'border.main',
                borderRadius: '15px',
                width: '23%',
                transition: '0.5s all',
                '&:hover': {
                  bgcolor: 'background.lightsm',
                },
              }}
              component={NavLink}
              to={value.path}
            >
              <Box className='text-center text-4xl opacity-60'>
                {Nav[NavId].child[ChildId].icon}
              </Box>
              <Box className='fw-bold text-center'>{value.name}</Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default PageSubmenuBoxed;
