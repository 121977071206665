import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '../../ThemeContext';

const Logo = () => {
  const { mode } = useTheme();

  return (
    <Box
      component='img'
      src={
        mode === 'dark' ? '/images/logo-white.png' : '/images/logo-black.png'
      }
      sx={{ maxWidth: '100%', height: '35px' }}
    />
  );
};

export const SidebarLogo = () => {
  return (
    <Box
      component='img'
      src={'/images/logo-white.png'}
      sx={{ maxWidth: '100%', height: 'auto' }}
    />
  );
};

export default Logo;
