import React, { useEffect } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import PurchaseVoucherList from './PurchaseVoucherList.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { Box } from '@mui/material';
import { CreateButton } from '../../../../components/small/Buttons.jsx';

const PurchaseVoucher = (props) => {
  const { type } = props;
  const location = useLocation();
  const getTitleName = Nav[7]?.child[2]?.children;
  const getNewTitle = getTitleName?.filter(
    (one) => one.path === location.pathname
  );
  const [getData, setGetData] = React.useState(false);

  useEffect(() => {
    document.title = `${getNewTitle[0]?.name} | Accounts Payable | Financial`;
  }, []);

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`purchasevouchers?purchaseVoucherTypeId=${type}`);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [type]);

  return (
    <>
      <DashboardLayout
        title={getNewTitle[0]?.name}
        hasSubmenu
        menu={Nav[7].child[2].children}
        parentMenu={Nav[7].child[2].name}
        actionButtons={
          <>
            <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
              <CreateButton name='Create' onClick={() => navigate('create')} />
            </Box>
          </>
        }
      >
        <PurchaseVoucherList
          type={type}
          rows={rows}
          isLoading={isLoading}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          tableRecordCounts={tableRecordCounts}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          fetchData={fetchData}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
        />
      </DashboardLayout>
    </>
  );
};

export default PurchaseVoucher;
