import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../components/small/BackToButton.jsx';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import { Grid } from '@mui/material';
import DashboardLayout from './../../../../components/DashboardLayout.tsx';
import ViewTotalValueList from './ViewTotalValueList.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ViewDetailsCollapse from '../../../../components/view/ViewDetailsCollapse.tsx';

const ViewSalesOrderDistribution = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [viewData, setViewData] = useState([]);
  const { id } = useParams();
  const crud = new Crud_Service();

  const viewDataFetch = async () => {
    crud.getSingle('salesorderdistributions', id, (_err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
      } else {
        setViewData([]);
      }
    });
  };
  const trimmedPath = location.pathname.split('/').slice(0, -1).join('/');
  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);

  useEffect(() => {
    document.title = 'View | Sales Order Distribution | Presales';
  });

  return (
    <>
      <DashboardLayout
        title='View Sales Order Distribution'
        actionButtons={
          <BackToButton
            title='Back to List'
            onClick={() => navigation(-1)}
            className='font-bold px-0'
          />
        }
        titleReverse
      >
        {/* glasscard */}
        <GlassCard className='p-3'>
          <ViewDetailsCollapse>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <ViewTable
                  rows={[
                    {
                      label: 'Company code',
                      title: viewData?.companyCode || 'N/A',
                    },
                    {
                      label: 'Client',
                      title: viewData?.clientName || 'N/A',
                    },
                    {
                      label: 'Project code',
                      title: viewData?.projectCode || 'N/A',
                    },
                    {
                      label: 'Contract code',
                      title: viewData?.contractCode || 'N/A',
                    },
                  ]}
                />
              </Grid>
              <Grid item md={4}>
                <ViewTable
                  rows={[
                    {
                      label: 'Total Contract Value',
                      title: viewData?.totalContractValue || 'N/A',
                    },
                    {
                      label: 'Total Budget Value',
                      title: viewData?.totalBudgetValue || 'N/A',
                    },
                    {
                      label: 'Budget value %',
                      title: viewData?.budgetValue || 'N/A',
                    },
                    {
                      label: 'Prepared By',
                      title: viewData?.preparedUserName || 'N/A',
                    },
                  ]}
                />
              </Grid>
              <Grid item md={4}>
                <ViewTable
                  rows={[
                    {
                      label: 'Approved By',
                      title: viewData?.approvedUserName || 'N/A',
                    },

                    {
                      label: 'Date',
                      title: viewData?.salesOrderDistribustionDate || 'N/A',
                      date: true,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </ViewDetailsCollapse>
        </GlassCard>
        {/* glasscard */}
        <ViewTotalValueList salesOrderDistributionId={id} viewData={viewData} />
      </DashboardLayout>
    </>
  );
};

export default ViewSalesOrderDistribution;
