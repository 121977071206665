import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';

const ArrowWithLink = ({ text, right, className, ...props }) => {
  return (
    <Link
      className={`flex items-center gap-2 justify-center ${className} `}
      {...props}
    >
      {!right && (
        <span className='text-2xl'>
          <Icon icon='mdi-light:arrow-left' />
        </span>
      )}

      {text}

      {right && (
        <span className='text-2xl'>
          <Icon icon='mdi-light:arrow-right' />
        </span>
      )}
    </Link>
  );
};

export default ArrowWithLink;
