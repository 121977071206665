// loaderSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoaderState {
  isLoading: boolean;
  createLoading: boolean;
  editLoading: boolean;
}

const initialState: LoaderState = {
  isLoading: true,
  createLoading: false,
  editLoading: false,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCreateLoading: (state, action: PayloadAction<boolean>) => {
      state.createLoading = action.payload;
    },
    setEditLoading: (state, action: PayloadAction<boolean>) => {
      state.editLoading = action.payload;
    },
  },
});

export const { setLoading, setCreateLoading, setEditLoading } =
  loaderSlice.actions;
export default loaderSlice.reducer;
