import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { MaterialReactTable } from 'material-react-table';
import MRTExpandTableStyle from '../../../../../components/table/MRTExpandTableStyle.tsx';
import Icons from '../../../../../utils/Icon.jsx';
import { Box, Grid, Button } from '@mui/material';
import MRTIconButton from '../../../../../components/table/MRTIconButton.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../../../components/_mui/FmAutoComplete.tsx';
import { useForm } from 'react-hook-form';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import { Icon } from '@iconify/react';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import FmViewDateField from '../../../../../components/_mui/FmViewDateField.tsx';

const CreateForm = (props) => {
  const { financialTransactionId, fetchData } = props;
  const { handleSubmit, control, reset } = useForm();
  const crud = new Crud_Service();

  const handleSubmitTransactionItem = async (values: any) => {
    const data = {
      ...values,
      financialTransactionId: financialTransactionId,
    };
    await crud.create('financialtransactionitems', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Added Successfully');
        reset();
        fetchData();
      } else {
      }
    });
  };

  return (
    <Box className='p-3'>
      <form onSubmit={handleSubmit(handleSubmitTransactionItem)}>
        <Grid container spacing={2}>
          <Grid item md={MdItemValue}>
            <FmSearchableSelect
              name='salesOrderId'
              control={control}
              apiUrl='salesorders'
              valueField='salesOrderId'
              headerField={['Code']}
              labelField={['salesOrderCode']}
              showField={['salesOrderCode']}
              pageSize={20}
              label='Sales Order Code'
              required
            />
          </Grid>
          <Grid item md={MdItemValue}>
            <FmSearchableSelect
              name='workOrderId'
              control={control}
              apiUrl='workorders'
              valueField='workOrderId'
              headerField={['User Name']}
              labelField={['workOrderName']}
              showField={['workOrderName']}
              pageSize={20}
              label='work Order'
              required
            />
          </Grid>
          <Grid item md={MdItemValue}>
            <FmSearchableSelect
              name='divisionId'
              control={control}
              apiUrl='divisions'
              valueField='divisionId'
              labelField={['divisionName']}
              showField={['divisionName']}
              label='Division'
            />
          </Grid>
          <Grid item md={MdItemValue}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              valueField='contractId'
              labelField={['contractCode', 'contractName']}
              showField={['contractCode', 'contractName']}
              pageSize={20}
              label={'Contract'}
            />
          </Grid>

          <Grid item md={MdItemValue}>
            <FmSearchableSelect
              name='employeeId'
              control={control}
              apiUrl='employees'
              valueField='employeeId'
              headerField={['Employee Code', 'Employee Name']}
              labelField={['employeeCode', 'firstName']}
              showField={['firstName']}
              pageSize={20}
              label='Employees'
            />
          </Grid>
          <Grid item md={MdItemValue}>
            <FmSearchableSelect
              name='inchargeId'
              control={control}
              apiUrl='loginusers'
              valueField='loginUserId'
              headerField={['Name']}
              labelField={['loginUserName']}
              showField={['loginUserName']}
              pageSize={20}
              label='In Charge'
            />
          </Grid>
          <Grid item md={MdItemValue}>
            <FmSearchableSelect
              name='costCenterId'
              control={control}
              apiUrl='costcenters'
              valueField='costCenterId'
              headerField={['Code', 'Name']}
              labelField={['costCenterCode', 'costCenterName']}
              showField={['costCenterName']}
              pageSize={20}
              label={'Cost Center'}
            />
          </Grid>
          <Grid item md={MdItemValue}>
            <FmSearchableSelect
              name='clientId'
              control={control}
              apiUrl='clients'
              valueField='clientId'
              headerField={['Client Code', 'Client Name']}
              labelField={['clientCode', 'clientName']}
              showField={['clientName']}
              pageSize={20}
              label='Client'
              required
            />
          </Grid>
          <Grid item md={MdItemValue}>
            <FmSearchableSelect
              name='taxId'
              control={control}
              apiUrl='taxcodes'
              valueField='taxCodeId'
              headerField={['Tax Code']}
              labelField={['taxCodeCode']}
              showField={['taxCodeCode']}
              pageSize={20}
              label='Tax Code'
            />
          </Grid>
          <Grid item md={MdItemValue}>
            <FmTextField
              label='Fixed Asset'
              name='fixedAsset'
              control={control}
            />
          </Grid>
          <Grid item md={MdItemValue}>
            <FmTextField label='Reference' name='reference' control={control} />
          </Grid>
          <Grid item md={MdItemValue}>
            <FmTextField label='Amount' name='amount' control={control} />
          </Grid>
          <Grid item md={MdItemValue * 2}>
            <FmTextField label='Remarks' name='remarks' control={control} />
          </Grid>
        </Grid>
        <ActionButtons
          onSubmit={handleSubmit(handleSubmitTransactionItem)}
          onReset={reset}
          submitLoading={false}
          cancelLoading={false}
        />
      </form>
    </Box>
  );
};

const BoldBox = ({ title }) => {
  return <Box className='font-semibold'>{title}</Box>;
};

const MdItemValue = 2.4;

const ViewCashBankReceiptList = (props) => {
  const { financialTransactionId } = props;
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading } = useLoader();
  const { control, setValue, handleSubmit } = useForm();
  const crud = new Crud_Service();
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('financialtransactionitems');

  const handleEdit = (row) => {
    setEditingRowId(row?.original?.financialTransactionItemId);
    setEditingRowData({ ...row?.original });
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'financialtransactionitems',
      props?.original?.financialTransactionItemId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('deleted successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleUpdateEvent = async (values) => {
    try {
      // Start loading indicator
      startEditLoading();
      // Prepare the data to be updated
      const combinedData = {
        ...values,

        Status: 2,
      };
      await crud.update(
        'financialtransactionitems',
        editingRowId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success('Updated Successfully');
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
            toast.error('Failed to update');
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating');
    }
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
    },
  ];
  const columns = [
    {
      field: 'action',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,

      Cell: (params) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId ===
            params?.row?.original?.financialTransactionItemId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateEvent)}>Save</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params.row),
                }))}
                selectedRow={params.row}
                setSelectedRow={() => {}}
              />
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionItemId ===
            cell?.row?.original?.financialTransactionItemId
        );
        return editingRowId ===
          cell?.row?.original?.financialTransactionItemId ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders'
            valueField='salesOrderId'
            headerField={['Code']}
            labelField={['salesOrderName']}
            showField={['salesOrderName']}
            pageSize={20}
            label='Sales Order Code'
            defaultValue={{
              salesOrderId: salesDetails?.salesOrderId,
              salesOrderName: salesDetails?.salesOrderName,
            }}
          />
        ) : (
          salesDetails?.salesOrderName || ''
        );
      },
    },
    {
      accessorKey: 'workOrderId',
      header: 'Work Order',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.financialTransactionItemId ===
            cell?.row?.original?.financialTransactionItemId
        );
        return editingRowId ===
          cell?.row?.original?.financialTransactionItemId ? (
          <FmSearchableSelect
            name='workOrderId'
            control={control}
            apiUrl='workorders'
            valueField='workOrderId'
            headerField={['User Name']}
            labelField={['workOrderName']}
            showField={['workOrderName']}
            pageSize={20}
            label='work Order'
            defaultValue={{
              workOrderId: editingRowData?.workOrderId,
              workOrderName: editingRowData?.workOrderName,
            }}
          />
        ) : (
          salesItemDetails?.workOrderName || ''
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionItemId ===
            cell?.row?.original?.financialTransactionItemId
        );
        return editingRowId ===
          cell?.row?.original?.financialTransactionItemId ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            labelField={['divisionName']}
            showField={['divisionName']}
            label='Division'
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
          />
        ) : (
          incidentReport?.divisionName || ''
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionItemId ===
            cell?.row?.original?.financialTransactionItemId
        );
        return editingRowId ===
          cell?.row?.original?.financialTransactionItemId ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            labelField={['contractCode', 'contractName']}
            showField={['contractCode', 'contractName']}
            pageSize={20}
            label={'Contract'}
            defaultValue={{
              contractId: editingRowData?.contractId,
              contractName: editingRowData?.contractName,
            }}
          />
        ) : (
          incidentReport?.contractName || ''
        );
      },
    },
    {
      accessorKey: 'employeeId',
      header: 'Employee',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionItemId ===
            cell?.row?.original?.financialTransactionItemId
        );
        return editingRowId ===
          cell?.row?.original?.financialTransactionItemId ? (
          <FmSearchableSelect
            name='employeeId'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            headerField={['Employee Code', 'Employee Name']}
            labelField={['employeeCode', 'firstName']}
            showField={['firstName']}
            pageSize={20}
            label='Employees'
            defaultValue={{
              employeeId: editingRowData?.employeeId,
              firstName: editingRowData?.employeeName,
            }}
          />
        ) : (
          incidentReport?.employeeName || ''
        );
      },
    },
    {
      accessorKey: 'inchargeId',
      header: 'In Charge',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionItemId ===
            cell?.row?.original?.financialTransactionItemId
        );
        return editingRowId ===
          cell?.row?.original?.financialTransactionItemId ? (
          <FmSearchableSelect
            name='inchargeId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            headerField={['Name']}
            labelField={['loginUserName']}
            showField={['loginUserName']}
            pageSize={20}
            label='In Charge'
            defaultValue={{
              loginUserId: editingRowData?.inchargeId,
              loginUserName: editingRowData?.inchargeName,
            }}
          />
        ) : (
          incidentReport?.inchargeName || ''
        );
      },
    },
    {
      accessorKey: 'clientId',
      header: 'Client',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionItemId ===
            cell?.row?.original?.financialTransactionItemId
        );
        return editingRowId ===
          cell?.row?.original?.financialTransactionItemId ? (
          <FmSearchableSelect
            name='clientId'
            control={control}
            apiUrl='clients'
            valueField='clientId'
            headerField={['Client Code', 'Client Name']}
            labelField={['clientCode', 'clientName']}
            showField={['clientName']}
            pageSize={20}
            label='Client'
            defaultValue={{
              clientId: editingRowData?.clientId,
              clientName: editingRowData?.clientName,
            }}
          />
        ) : (
          incidentReport?.clientName || ''
        );
      },
    },
    {
      accessorKey: 'fixedAsset',
      header: 'Narration',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionItemId ===
            cell?.row?.original?.financialTransactionItemId
        );
        return editingRowId ===
          cell?.row?.original?.financialTransactionItemId ? (
          <FmTextField
            name='fixedAsset'
            control={control}
            label='Fixed Asset'
          />
        ) : (
          incidentReport?.fixedAsset || ''
        );
      },
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionItemId ===
            cell?.row?.original?.financialTransactionItemId
        );
        return editingRowId ===
          cell?.row?.original?.financialTransactionItemId ? (
          <FmTextField name='amount' control={control} label='Amount' />
        ) : (
          incidentReport?.amount || ''
        );
      },
    },
    {
      accessorKey: 'reference',
      header: 'Reference',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.financialTransactionItemId ===
            cell?.row?.original?.financialTransactionItemId
        );
        return editingRowId ===
          cell?.row?.original?.financialTransactionItemId ? (
          <FmTextField name='reference' control={control} label='Reference' />
        ) : (
          incidentReport?.reference || ''
        );
      },
    },
  ];
  useEffect(() => {
    if (editingRowData) {
      Object.entries(editingRowData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);

  return (
    <>
      <GlassCard className='my-4'>
        <CreateForm
          fetchData={fetchData}
          financialTransactionId={financialTransactionId}
        />
        <MRTExpandTableStyle>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ViewCashBankReceiptList;
