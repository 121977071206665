import React from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { useNavigate } from 'react-router-dom';

const GridSize = 2.4;
const schema = yup.object().shape({
  companyId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Company is required'),

  workOrderName: yup.string().required('Work Order Name is required'),
  workOrderTypeId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Work Order Type is required'),

  amount: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Amount is required'),
});
const CreateWorkOrder = () => {
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const submitWorkOrder = async (values) => {
    const data = {
      ...values,
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
    };
    await crud.create('workorders', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Added Work Order Successfully');
        navigate(-1);
        reset();
      }
    });
  };
  const [selectedClient, setSelectedClient] = React.useState(null);

  const handleSelectedClient = (e) => {
    setSelectedClient(e);
  };
  const [selectedProject, setSelectedProject] = React.useState(null);
  return (
    <DashboardLayout
      title='Create Work Order'
      hasSubmenu
      menu={Nav[12].child[1].children}
    >
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(submitWorkOrder)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='companyId'
                label='Company'
                apiUrl={'companies'}
                valueField='companyId'
                labelField={['companyName', 'companyCode']}
                showField={['companyName', 'companyCode']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='clientId'
                label='Client'
                apiUrl='clients'
                valueField='clientId'
                onChangeProp={handleSelectedClient}
                labelField={['clientName', 'clientCode']}
                showField={['clientName', 'clientCode']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='projectId'
                label='Project'
                apiUrl={'projects'}
                queryparam='clientId'
                queryparamValue={selectedClient?.clientId}
                valueField='projectId'
                onChangeProp={setSelectedProject}
                labelField={['projectName', 'projectCode']}
                showField={['projectName', 'projectCode']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='contractId'
                label='Contract'
                apiUrl={'contracts'}
                queryparam='projectId'
                queryparamValue={selectedProject?.projectId}
                valueField='contractId'
                labelField={['contractName', 'contractCode']}
                showField={['contractName', 'contractCode']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='previousContractId'
                label='Previous Contract'
                apiUrl={'contracts'}
                valueField='contractId'
                labelField={['contractName', 'contractCode']}
                showField={['contractName', 'contractCode']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='workOrderName'
                required
                label='Name'
                control={control}
                pattern='Text'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='workOrderTypeId'
                label='Work Order Type'
                apiUrl={'workorders/types'}
                valueField='workOrderTypeId'
                required
                labelField={['workOrderTypeName']}
                showField={['workOrderTypeName']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='workOrderCode'
                label='Work Order Code'
                control={control}
              />{' '}
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='divisionId'
                label='Division'
                apiUrl={'divisions'}
                valueField='divisionId'
                labelField={['divisionName', 'divisionCode']}
                showField={['divisionName', 'divisionCode']}
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmDatePicker
                name='startDate'
                label='Start Date'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                label='Amount'
                name='amount'
                required
                pattern='Number'
                control={control}
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(submitWorkOrder)}
                onReset={reset}
                submitLoading={false}
                cancelLoading={false}
                cancelText='Go Back'
                onCancel={() =>
                  navigate('/fm-division/work-order/work-order-list')
                }
                submitText='Create'
              />
            </Grid>
          </Grid>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default CreateWorkOrder;
