import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';

const gridValue = 2.4;

const ViewContractCustomer = () => {
  const id = useParams().id;
  const crud = new Crud_Service();
  const { handleSubmit, setValue, control } = useForm();
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    await crud.getAll(`contractcustomers?contractId=${id}`, '', (_err, res) => {
      if (res?.status === 200) {
        if (res?.data) {
          setCustomer(res?.data?.data[0]);
          Object.entries({
            ...res?.data?.data[0],
          }).forEach(([key, value]) => {
            setValue(key, value);
          });
        }
      }
    });
  };
  // const handleDelete = async (props) => {
  //   await crud.remove(
  //     'contractcustomers',
  //     props?.contractCustomerId,
  //     (_err, res) => {
  //       if (res?.status === 204) {
  //         toast.success('Contract Deleted Successfully');

  //       }
  //     }
  //   );
  // };

  const onSubmit = async (data) => {
    const combinedData = {
      ...data,
      contractcustomerId: customer?.contractCustomerId || 0,
      contractId: id,
    };
    if (customer?.contractCustomerId) {
      await crud.update(
        'contractcustomers',
        customer?.contractCustomerId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            toast.success('Contract Customer Updated successfully');
          }
        }
      );
    } else {
      await crud.create('contractcustomers', combinedData, (_err, res) => {
        if (res?.status === 201) {
          toast.success('Contract Customer Created successfully');
        }
      });
    }
  };

  // const handleEdit = async (row) => {
  //   setEditingRowId(row?.contractCustomerId);
  //   setEditingRowData({ ...row });

  //   Object.entries({
  //     ...row,
  //   }).forEach(([key, value]) => {
  //     setValue(key, value);
  //   });
  // };
  // const handleCancel = (row) => {
  //   if (row?.original?.contractCustomerId === rowState?.newRowId) {

  //   } else {
  //     setEditingRowId(null);
  //   }
  //   reset();
  // };
  // const handleEditSubmit = async (data) => {
  //   await crud.update('contractcustomers', editingRowId, data, (err, res) => {
  //     if (err) {
  //       toast.error('Something went wrong:', err);
  //       return;
  //     }
  //     if (res?.status === 200) {
  //       toast.success('Contract Customer Updated successfully');

  //       setEditingRowData(null);
  //       setEditingRowId(null);
  //     } else {
  //     }
  //   });
  // };
  return (
    <>
      <GlassCard className='mb-3 p-3'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={gridValue}>
              <FmTextField
                label='Customer Name'
                control={control}
                required
                defaultValue={customer?.contractCustomerName}
                name='contractCustomerName'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                control={control}
                required
                name='groupTypeId'
                label='Group Type'
                options={[
                  { groupTypeId: 1, groupTypeName: 'GROUPCLIENT' },
                  { groupTypeId: 2, groupTypeName: 'INDIVIDUAL' },
                ]}
                defaultValue={{
                  groupTypeId: customer?.groupTypeId,
                  groupTypeName: customer?.groupTypeName,
                }}
                valueKey='groupTypeId'
                displayField='groupTypeName'
                optionFields={['groupTypeName']}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmSearchableSelect
                control={control}
                name='clientId'
                label='Client'
                apiUrl='clients'
                valueField='clientId'
                defaultValue={{
                  clientId: customer?.clientId,
                  clientName: customer?.clientName,
                }}
                showField={['clientName']}
                labelField={['clientName']}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmSearchableSelect
                control={control}
                name='locationId'
                label='Location'
                apiUrl='locations'
                defaultValue={{
                  locationId: customer?.locationId,
                  locationName: customer?.locationName,
                }}
                valueField='locationId'
                showField={['locationName']}
                labelField={['locationName']}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField label='Address' control={control} name='address' />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                label='Contact Number'
                control={control}
                name='customerContactNumber'
              />
            </Grid>
            <Grid item md>
              <ActionButtons
                onSubmit={handleSubmit(onSubmit)}
                cancelLoading={false}
              />
            </Grid>
          </Grid>
        </form>
      </GlassCard>
    </>
  );
};

export default ViewContractCustomer;
