import React, { useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { MaterialReactTable } from 'material-react-table';
import MRTExpandTableStyle from '../../../components/table/MRTExpandTableStyle.tsx';
import Icons from '../../../utils/Icon.jsx';
import { Box } from '@mui/material';
import MRTIconButton from '../../../components/table/MRTIconButton.tsx';
import { useNavigate, useLocation } from 'react-router-dom';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const ListVendorLPOReceivable = () => {
  const generateData = () => {
    const data = [];
    for (let i = 1; i <= 15; i++) {
      data.push({
        id: i,
        reference: '1255668',
        date: '13-12-2024',
        receiveBy: 'Receive 1',
        department: 'Department 1',
        designation: 'Designation 1',
      });
    }
    return data;
  };

  const initialRows = generateData();

  const [data, setData] = useState(initialRows);
  const [, setCreatingRow] = useState(null);
  const [view, setView] = useState(false);
  const [viewRow, setViewRow] = useState([]);
  const navigation = useNavigate();
  const location = useLocation();

  const handleCreateNewRow = (newRow) => {
    setData((prevData) => [...prevData, newRow]);
    setCreatingRow(null);
  };

  // Define the columns
  const columns = [
    {
      accessorKey: 'reference',
      header: 'Reference #',
    },
    {
      accessorKey: 'date',
      header: 'Date',
    },
    {
      accessorKey: 'receiveBy',
      header: 'Received By',
    },
    {
      accessorKey: 'department',
      header: 'Department',
      Cell: ({ renderedCellValue }) => <BoldBox title={renderedCellValue} />,
    },

    {
      accessorKey: 'designation',
      header: 'Designation',
      Cell: ({ renderedCellValue }) => <BoldBox title={renderedCellValue} />,
    },
  ];

  return (
    <>
      <GlassCard className='my-4'>
        <MRTExpandTableStyle>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnActions={false}
            enableDensityToggle={false}
            positionGlobalFilter='left'
            enableGlobalFilter={true}
            initialState={{
              showGlobalFilter: true,
              columnPinning: {
                left: ['mrt-row-actions'],
              },
            }}
            enableColumnPinning
            enableEditing
            editDisplayMode='row'
            createDisplayMode='row'
            onRowSave={(newRow) => handleCreateNewRow(newRow)}
            onEditingRowSave={(newRow) => handleCreateNewRow(newRow)}
            icons={{
              EditIcon: () => Icons.edit,
              SaveIcon: () => Icons.save,
              CancelIcon: () => Icons.clear,
            }}
            muiEditTextFieldProps={(column) => ({
              variant: 'filled',
              label: column.column.columnDef.header,
            })}
            enableStickyHeader
            enableRowActions
            renderRowActions={({ row, table }) => (
              <Box className='flex gap-1 me-2'>
                <MRTIconButton
                  icon={Icons.view}
                  title='View'
                  onClick={() => navigation(`${location.pathname}/view`)}
                />
                <MRTIconButton
                  icon={Icons.edit}
                  title='Edit'
                  onClick={() => {
                    table.setEditingRow(row);
                  }}
                />
                <MRTIconButton
                  error
                  icon={Icons.delete}
                  title='Delete'
                  onClick={() => {
                    data.splice(row.index, 1);
                    setData([...data]);
                  }}
                />
              </Box>
            )}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ListVendorLPOReceivable;
