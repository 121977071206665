export const statusOptions = [
  { statusId: 2, statusName: 'Active' },
  { statusId: 1, statusName: 'Inactive' },
];
export const pendingPaymentTypes = [
  { value: 0, label: 'All' },
  { value: 1, label: 'Fully Paid' },
  { value: 2, label: 'Partially Paid' },
  { value: 3, label: 'Not Paid' },
];
export const permissionId = {
  EDIT: 1,
  VIEW: 2,
  CREATE: 3,
  DELETE: 4,
  EXPORT: 5,
  IMPORT: 6,
};

export const projectTypes = [
  {
    projectTypeId: 1,
    projectTypeName: 'AMC',
  },
  {
    projectTypeId: 3,
    projectTypeName: 'Project',
  },
];
export const contractStatus = [
  {
    contractStatusId: 1,
    contractStatusName: 'Draft',
  },
  {
    contractStatusId: 2,
    contractStatusName: 'Approved',
  },
];
export const acknowledgementStatus = [
  {
    acknowledgmentStatusId: 1,
    acknowledgementStatusName: 'Pending',
  },
  {
    acknowledgmentStatusId: 2,
    acknowledgementStatusName: 'Delivered',
  },
  {
    acknowledgmentStatusId: 3,
    acknowledgementStatusName: 'Acknowledged',
  },
];
export const acknowledgementStatusEnum = {
  pending: 1,
  delivered: 2,
  acknowledged: 3,
};

export const attachments = [
  { id: 1, file: null, fileName: '', fileId: null },
  { id: 2, file: null, fileName: '', fileId: null },
  { id: 3, file: null, fileName: '', fileId: null },
];
export const PaymentStatusEnum = {
  Draft: 1,
};

export const statementFilterOptions = [
  { value: 'all', label: 'All' },
  { value: 'false', label: 'Debit' },
  { value: 'true', label: 'Credit' },
];
export const transactionFilterOptions = [
  { value: 'all', label: 'All' },
  { value: 'true', label: 'Account Payable' },
  { value: 'false', label: 'Account Receivable' },
];

export enum PaymentType {
  INWARD = 1,
  CASH = 2,
  POST_DATED_CHEQUE = 3,
  CURRENT_DATED_CHEQUE = 4,
  OTHER = 5,
}

export enum PaymentTypeNew {
  PETTYCASH = 1,
  SUPPLIERS = 2,
  OTHERS = 3,
}

export const BooleanOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
export const GoodsReceivedNoteTypeStatus = [
  { value: 1, label: ' DELIVERED' },
  { value: 2, label: 'SHORTCLOSE' },
  { value: 3, label: 'DELIVERYTERMS' },
];
export const StoreType = [
  { value: 1, label: ' Main Store' },
  { value: 2, label: 'Sub Store' },
];

export const PurchaseVoucherStatus = [
  { value: 1, label: ' Draft' },
  { value: 2, label: 'Pending' },
  { value: 3, label: 'Approved' },
];
export const PaymentStatus = [
  { value: 1, label: ' Draft' },
  { value: 2, label: 'Approved' },
  { value: 3, label: 'Paid' },
  { value: 4, label: 'Reject' },
];
export const QuotationStatusEnum = [
  { value: 1, label: ' Pending' },
  { value: 2, label: 'Approved' },
  { value: 3, label: 'Draft' },
];

export const YesNoOptions = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
];

export const BooleanOptionsWithInt = [
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
];
export const FloorAreaUOMEnum = [
  { value: 1, label: ' Square Feet' },
  { value: 2, label: 'Square Meter' },
];

export const paymentStatus = [
  {
    value: 1,
    statusName: 'Paid',
  },
  {
    value: 2,
    statusName: 'Draft',
  },
];
export const formatStatus = [
  {
    value: 1,
    label: 'Specific',
  },
  {
    value: 2,
    label: 'Bank Standard Format',
  },
];

export const estimationStatusOptions = [
  { statusId: 1, statusName: 'In-Progress' },
  { statusId: 2, statusName: 'Approved' },
  { statusId: 3, statusName: 'Rejected' },
];

export const requisitionDuration = [
  {
    label: 'Temporary',
    value: 1,
  },
  {
    label: 'Permanent',
    value: 2,
  },
];

export const gender = [
  { genderId: 1, genderName: 'Male' },
  { genderId: 2, genderName: 'Female' },
];

export const candidateCategories = [
  {
    label: 'Work man',
    value: 1,
  },
  {
    label: 'Staff',
    value: 2,
  },
];

export const candidateRegion = [
  {
    label: 'Overseas',
    value: 1,
  },
  {
    label: 'Local',
    value: 2,
  },
];
export const passportSubmissionStatus = [
  {
    label: 'Normal',
    value: 1,
  },
  {
    label: 'Renewal',
    value: 2,
  },
];
export const drivingLicense = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const CommonTrueFalse = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const ticketsNoteStatusOptions = [
  { statusId: 1, statusName: 'Waiting for Customer Response' },
  { statusId: 2, statusName: 'Waiting for Technician Response' },
  { statusId: 3, statusName: 'Waiting for Approval' },
  { statusId: 4, statusName: 'Inspection Scheduled' },
  { statusId: 5, statusName: 'Materials Ordered' },
  { statusId: 6, statusName: 'Pending' },
  { statusId: 7, statusName: 'Resolved' },
];
export const InOutOptions = [
  { value: 1, label: 'In' },
  { value: 2, label: 'Out' },
];
export const ItemFlag = [
  { value: 1, label: 'Normal ' },
  { value: 2, label: 'Priority ' },
];
export const attendanceMinDate = 31;
export const vendorDocumentStatusOptions = {
  Submitted: 1,
  DocumentTransferred: 2,
  Acknowledged: 3,
  UploadDocument: 4,
  DeliveryNotesSubmitted: 5,
  Verified: 6,
  SiteTeamApproved: 7,
  SiteTeamRejected: 8,
  FinanceTeamApproved: 9,
  FinanceTeamRejected: 10,
  FinanceTeamAcknowledged: 11,
  Rejected: 12,
};
export const contractStatusEnum = {
  Draft: 1,
  Approved: 2,
};
export const projectStatusEnum = {
  AMC: 1,
  Projects: 2,
};
export const salesInvoiceStatusEnum = {
  Draft: 1,
  Approved: 2,
  ReceiptGenerated: 3,
  Acknowledge: 6
};
export const journalStatusEnum = {
  Approved: 3,
};
export const creditNoteStatusEnum = {
  Approved: 3,
};
export const paymentStatusEnum = {
  Approved: 3,
  Paid: 4,
};
export const pettyCashStatusEnum = {
  Approved: 3,
  Paid: 5,
};
export const debitNoteStatusEnum = {
  Approved: 3,
};

export const salesOrderStatusEnum = {
  Approved: 2,
};
export const internalInvoiceStatusEnum = {
  Draft: 1,
  ReadyToInvoice: 2,
  Approved: 3,
  InvoiceGenerated: 4,
};
export const previousVisaStatusOptions = [
  { statusId: 1, statusName: 'Visit visa' },
  { statusId: 2, statusName: 'Cancelled' },
  { statusId: 3, statusName: 'Outside country visa' },
  { statusId: 4, statusName: 'Visa hold for family' },
];
export const installationStatusEnum = [
  { statusId: 1, statusName: 'Pending' },
  { statusId: 2, statusName: 'Review' },
  { statusId: 3, statusName: 'Draft' },
  { statusId: 4, statusName: 'Ready' },
  { statusId: 5, statusName: 'Close' },
];
export const InspectionTypeEnum = [
  {
    label: 'MONTHLY_OR_ROUTINE',
    value: 1,
  },
  {
    label: 'WEEKLY',
    value: 2,
  },
];

export const OperationalStatusEnum = [
  { value: 1, label: ' GOOD ' },
  { value: 2, label: 'ACTIVE' },
  { value: 3, label: 'PENDING' },
  { value: 4, label: 'READY' },
];
export const ItemConditionEnum = [
  { value: 1, label: 'New' },
  { value: 2, label: 'Used' },
  { value: 3, label: 'Refurbished' },
  { value: 4, label: 'Damaged' },
  { value: 5, label: 'Expired' },
  { value: 6, label: 'Defective' },
];

export const InventoryItemStatus = [
  { value: 1, label: 'In Stock' },
  { value: 2, label: 'Out of Stock' },
  { value: 3, label: 'Low Stock ' },
  { value: 4, label: 'Backordered ' },
  { value: 5, label: 'Discontinued ' },
];
export const StorageCondition = [
  { value: 1, label: 'Room Temperature' },
  { value: 2, label: 'Refrigerated ' },
  { value: 3, label: 'Low Stock ' },
  { value: 4, label: 'Frozen' },
  { value: 5, label: 'Humidity Controlled' },
];
export const OrderFrequency = [
  { value: 1, label: 'Weekly' },
  { value: 2, label: 'Monthly' },
  { value: 2, label: 'Quarterly ' },
];

export const insuranceEnum = [
  {
    value: 1,
    label: 'SELF',
  },
  {
    value: 2,
    label: 'SELF AND FAMILY',
  },
];

export const SkillAssessmentEnum = [
  {
    value: 1,
    label: 'VERYGOOD',
  },
  {
    value: 2,
    label: 'GOOD',
  },
  {
    value: 3,
    label: 'AVERAGE',
  },
  {
    value: 4,
    label: 'POOR',
  },
];

export const LOAStatusOptions = [
  { statusId: 1, statusName: 'Pending' },
  { statusId: 2, statusName: 'Approved' },
  { statusId: 3, statusName: 'Rejected' },
  { statusId: 4, statusName: 'Cancelled' },
  { statusId: 5, statusName: 'Completed' },
];

export enum CoaTxnType {
  BANK_RELATED = 1, // Any, Bank Charges, Project Expense (Bank), Bank
  PETTY_CASH_AND_CLIENTS = 2, // Project Expense (Petty Cash), Costs, Clients (PDC)
  VAT_OUTPUT_AND_SUPPLIER = 3, // VAT Output, Supplier
  REVENUE_AND_CLIENTS_REVENUE = 4, // Revenue (VAT Output), Clients Revenue
  PREPAYENT = 5,
  SUPPLIERS_AND_VAT = 6, // Suppliers, VAT
  CUSTOMERS_AND_REVENUE_7 = 7, // Customers, Revenue (PDC), Project Revenue (ID 7)
  CUSTOMERS_AND_REVENUE_8 = 8, // Customers, Revenue (PDC), Project Revenue (ID 8)
  RETENTION = 9,
}

export enum SubmitStatus { //For Submit recordwith specific status
  DRAFT = 1,
  SUBMITTED = 2,
  APPROVED = 3,
  REJECTED = 4,
  ACKNOWLEDGED = 5,
  SUBMITTED_FOR_BANK_PROCESS = 6,
  HANDOVER = 7,
  RECEIVED = 8,
  HAVE_ISSUES_IN_BB_OR_SC = 9,
  SUBMITTED_TO_CLIENT = 10,
  RECEIVED_FROM_CLIENT = 11,
  CLIENT_LOST_THE_DOCUMENT = 12,
  SUBMITTED_TO_ACCOUNTS_TEAM = 13,
}
export enum VendorDocumentStatusEnum {
  SUBMITTED = 1,
  DOCUMENT_TRANSFERRED = 2,
  ACKNOWLEDGED = 3,
  UPLOAD_DOCUMENT = 4,
  DELIVERY_NOTES_SUBMITTED = 5,
  VERIFIED = 6,
  SITE_TEAM_APPROVED = 7,
  SITE_TEAM_REJECTED = 8,
  FINANCE_TEAM_APPROVED = 9,
  REJECTED = 12,
}
export enum PurchaseVoucherStatusId {
  DRAFT = 1,
  SUBMITTED = 2,
  APPROVED = 3,
  PAYMENT_VOUCHER_GENERATED = 5,
  PAID = 6,
  REJECT = 7,
}
export enum TenderBidbondSecurityCheckStatus {
  SUBMITTED = 2,
  APPROVED = 3,
  REJECTED = 4,
  ACKNOWLEDGED = 5,
  SUBMITTED_FOR_BANK_PROCESS = 6,
  HANDOVER = 7,
  RECEIVED = 8,
  HAVE_ISSUES_IN_BB_OR_SC = 9,
  SUBMITTED_TO_CLIENT = 10,
  RECEIVED_FROM_CLIENT = 11,
}

export const settlementStatusEnum = [
  { value: 1, label: 'IN_PROGRESS' },
  { value: 2, label: 'SETTLED' },
  { value: 3, label: 'DISPUTED' },
  { value: 4, label: 'CLOSED' },
];

export const maritalStatus = [
  { maritalStatus: 1, maritalStatusName: 'Married' },
  { maritalStatus: 2, maritalStatusName: 'Unmarried' },
];

export const NavigationEnum = {
  SALES_INVOICE: 1,
  FINANCIAL_TRANSACTION_RECEIPT: 2,
  CREDIT_NOTE: 3,
  DEBIT_NOTE: 4,
  PAYMENT: 5,
  PURCHASE_VOUCHER: 6,
  JOURNAL_ENTRY: 7,
  PETTY_CASH: 8,
};
export const tenderBidbondStatusOptions = {
  DRAFT: 1,
  Submitted: 2,
  Approved: 3,
  Rejected: 4,
  Acknowledged: 5,
  'Submitted for bank process': 6,
  Handover: 7,
  Received: 8,
  'Have issues in BB or SC': 9,
  'Submitted to Client': 10,
  'Received from Client': 11,
  'Client lost the Document': 12,
  'Submitted to Accounts Team': 13,
};
export enum SalesInvoiceStatus {
  DRAFT = 1,
  SUBMITTED = 2,
  APPROVED = 3,
  DELIVERED_TO_COLLECTION_AGENT = 4,
  HANDOVER_TO_CLIENT = 5,
  ACKNOWLEDGED = 6,
  RECEIPTS_GENERATED = 7,
  REJECT = 8,
}

export const ReportTypes = {
  SALES_INVOICE_REPORT: 4,
  INTERNAL_INVOICE_REPORT: 5,
  SALES_RETENTION_REPORT: 3,
  SALES_RETURN_REPORT: 4,
  SALES_ORDER_REPORT: 3,
  DEFAULT_REPORT: 2,
  ALL_REPORTS: 'All Reports',
  BIDDING_REPORT: 'Bidding Report',
  LOA_REPORT: 'LOA Report',
  SETTLEMENT_REPORT: 'Settlement Report',
  TENDER_BIDBOND_REPORT: 'Tender & Bond Bond Report',
  PURCHASE_VOUCHER_REPORT: 'Purchase Voucher Report',
  TENDER_BIDBOND_SUMMARY_REPORT: 'Tender & Bond Bond Summary Report',
  TENDER_BIDBOND_DETAILS_REPORT: 'Tender & Bond Bond Details Report',
};
export const tenderBidbondStatusColors = {
  DRAFT: 'gray',
  Submitted: 'blue',
  Approved: 'green',
  Rejected: 'red',
  Acknowledged: 'purple',
  'Submitted for bank process': 'orange',
  Handover: 'teal',
  Received: 'cyan',
  'Client lost the Document': 'black',
  'Have issues in BB or SC': 'red',
  'Submitted to Client': 'blue',
  'Received from Client': 'green',
  'Submitted to Accounts Team': 'green',
};

export const materialRequestTypeIdEnum = {
  salesorder: 1,
  helpdesk: 16,
};

export const materialRequestStatusEnum = {
  Draft: 1,
  Pending: 2,
  Approved: 3,
  Rejected: 4,
  Closed: 5,
  Cancelled: 6,
};
