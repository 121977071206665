import React from 'react';
import { Box, Button } from '@mui/material';
import { colorClasses } from './../../utils/Colors/ColorClasses.tsx';

const EnquiryButtonShape = ({ name, icon, count, active, color, onClick }) => {
  const colorClassAdd = colorClasses[color];
  return (
    <Box
      className={`${colorClassAdd} flex gap-2 items-center relative`}
      sx={{
        bgcolor: active ? 'primary.main' : 'background.light',
        padding: '5px 10px',
        borderRadius: '5px',
        fontWeight: 400,
        color: active ? 'text.white' : 'text.main',
        zIndex: active && 1,
        '& .count': {
          fontWeight: 700,
        },
        '&:hover': {
          bgcolor: active ? 'secondary.main' : 'background.white',
        },
      }}
      component={Button}
      type='button'
      onClick={onClick}
    >
      <Box className='text-xl'>{icon}</Box>
      <Box>{name}</Box>
      <Box className='count'>({count})</Box>
    </Box>
  );
};

export default EnquiryButtonShape;
