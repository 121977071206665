import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import { Box } from '@mui/material';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import { useParams } from 'react-router-dom';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const ViewProjectContractsList = () => {
  const [, setCreatingRow] = useState(null);
  const [view, setView] = useState(false);
  const [viewRow, setViewRow] = useState([]);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const [viewId, setViewId] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [editingRowData, setEditingRowData] = useState(null);
  const projectId = useParams();

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic('contracts', 'projectId', projectId?.id);

  const handleViewClick = (props) => {
    setViewModal(true);
    setViewId(props?.contractId);
  };
  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
  ];

  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 50,
      Cell: ({ row }) => {
        return (
          <Box className='flex gap-2'>
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          </Box>
        );
      },
    },
    {
      accessorKey: 'contractName',
      header: 'Contract Name',
    },
    {
      accessorKey: 'contractScopeName',
      header: 'Contract Scope',
    },

    {
      accessorKey: 'contractEndDate',
      header: 'Contract End Date',
      Cell: (params) => {
        const date = params?.cell?.row?.original?.contractEndDate;
        if (date === null) {
          return '';
        }
        return (
          <FmViewDateField
            value={params?.cell?.row?.original?.contractEndDate}
          />
        );
      },
    },
    {
      accessorKey: 'perviousContractCode',
      header: 'Prev Contract Code',
    },
    {
      accessorKey: 'guaranteeName',
      header: 'Guarantee Name',
    },
    {
      accessorKey: 'guaranteeStartDate',
      header: 'Guarantee Start Date',

      Cell: (params) => {
        const date = params?.cell?.row?.original?.guaranteeStartDate;
        if (date === null) {
          return '';
        }
        return (
          <FmViewDateField
            value={params?.cell?.row?.original?.guaranteeStartDate}
          />
        );
      },
    },
    {
      accessorKey: 'guaranteeEndDate',
      header: 'Guarantee End Date',
      Cell: (params) => {
        const date = params?.cell?.row?.original?.guaranteeEndDate;
        if (date === null) {
          return '';
        }
        return (
          <FmViewDateField
            value={params?.cell?.row?.original?.guaranteeEndDate}
          />
        );
      },
    },

    {
      accessorKey: 'guaranteeAmount',
      header: 'Guarantee Amount',
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
    },
  ];
  const excludeKeys = [
    'contractId',
    'projectId',
    'divisionId',
    'projectInChargeId',
    'createdDate',
    'contractTypeId',
    'projectCode',
    'projectName',
    'projectTypeId',
    'projectTypeName',
    'divisionName',
    'clientId',
    'clientName',
    'contactPerson',
    'contractTerms',
    'budget',
    'contractCost',
    'contactNumber',
    'contactEmailId ',
    'contractModeName',
    'guaranteeId',
    'currencyName',
    'referenceNumber',
    'currencyId',
    'contractDocuments',
    'statusName',
    'contractTypeId',
    'contractTypeName',
    'projectInChargeName',
    'updatedDate',
    'status',
    'billingFrequencyId',
    'contractScopeId',
    'contractModeId',
    'contactEmailId',
    'contractCode',
    'billingFrequencyName',
    'thresholdValue',
  ];
  return (
    <>
      <GlassCard>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          isCreatingRow={rowState?.isCreatingRow}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          muiTableContainerProps={{
            sx: {
              maxHeight: '350px',
            },
          }}
        />
      </GlassCard>

      <MuiDialogOne
        open={viewModal}
        onClose={() => setViewModal(false)}
        title='View Details'
      >
        <CommonView
          url='contracts'
          id={viewId}
          excludeKeys={excludeKeys}
          renderFields={(data, fields) => (
            <DynamicViewFields data={data} fields={fields} />
          )}
        />
      </MuiDialogOne>
    </>
  );
};

export default ViewProjectContractsList;
