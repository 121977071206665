import React, { useEffect } from 'react';
import DashboardLayout from './../../../components/DashboardLayout.tsx';
import AddSalesOrderDistribution from './AddSalesOrderDistribution.tsx';
import ListSalesOrderDistribution from './ListSalesOrderDistribution.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import { Box } from '@mui/material';

const SalesOrderDistribution = () => {
  useEffect(() => {
    document.title = 'Sales Order Distribution | Presales';
  }, []);
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('salesorderdistributions');

  return (
    <DashboardLayout title={'Sales Order Distribution'} removeSpace>
      <Box className='flex flex-col h-full'>
        <AddSalesOrderDistribution fetchData={fetchData} />

        <ListSalesOrderDistribution
          rows={rows}
          isLoading={isLoading}
          pagination={pagination}
          fetchData={fetchData}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          tableRecordCounts={tableRecordCounts}
          setPagination={setPagination}
          setGlobalFilter={setGlobalFilter}
          setColumnFilters={setColumnFilters}
          setSorting={setSorting}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
        />
      </Box>
    </DashboardLayout>
  );
};

export default SalesOrderDistribution;
