import React from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';

interface DataTableProps {
  rows: any[];
  columns: any[];
  pageSize?: number;
  pageSizeOptions?: number[];
  onPaginationModelChange?: (model: { page: number; pageSize: number }) => void;
  onSortModelChange?: (model: any[]) => void;
  sortingMode?: 'client' | 'server';
  paginationMode?: 'client' | 'server';
  getRowClassName?: (params: any) => string;
  toolbarComponent?: React.ReactNode;
  columnHeaderHeight?: number;
  onRowDoubleClick?: (e: any) => void;
  page?: number;
  initialPageSize?: number;
  rowCount?: number;
  sortableKeys?: Record<string, string>;
  [key: string]: any;
}
const DataTable = (props: DataTableProps) => {
  const {
    rows,
    columns,
    pageSize = 50,
    pageSizeOptions = [10, 25, 50, 100],
    onPaginationModelChange,
    onSortModelChange,
    sortingMode,
    paginationMode,
    getRowClassName,
    toolbarComponent,
    columnHeaderHeight,
    onRowDoubleClick,
    page = 0,
    initialPageSize,
    rowCount = 0,
    sortableKeys,
    ...rest
  } = props;

  const autosizeOptions = {
    includeOutliers: true,
  };

  const apiRef = useGridApiRef();
  apiRef.current?.autosizeColumns &&
    apiRef.current?.autosizeColumns(autosizeOptions);

  const handlesortModelChange = (sortModel) => {
    onSortModelChange(sortModel);
  };

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 50,
          },
        },
      }}
      pageSizeOptions={pageSizeOptions} // Pass pageSizeOptions directly
      sortingMode={sortingMode}
      paginationMode={paginationMode}
      getRowClassName={getRowClassName}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={handlesortModelChange}
      onRowDoubleClick={onRowDoubleClick}
      page={page}
      disableColumnMenu={true}
      rowHeight={32}
      getRowHeight={() => 'auto'}
      localeText={{
        toolbarColumns: '',
        toolbarExport: '',
      }}
      disableRowSelectionOnClick
      disableVirtualization
      columnHeaderHeight={columnHeaderHeight}
      rowCount={rowCount || 0}
      sx={{
        '&.MuiDataGrid-root': {
          color: 'text.main',
          border: 'none',
          background: 'none',
          fontSize: '13px',

          '& .MuiDataGrid-cellContent': {
            whiteSpace: 'nowrap',
            wordBreak: 'break-word',
            lineHeight: 1.2,
          },

          '& .MuiDataGrid-columnHeaderTitleContainer': {
            position: 'relative',
            minHeight: '30px',
            '& .MuiDataGrid-iconButtonContainer': {
              position: 'absolute',
              right: 0,
              top: '50%',
              zIndex: 1,
              transform: 'translateY(-50%)',
              '& .MuiIconButton-root': {
                padding: '1px',
              },
            },
          },
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 600,
          whiteSpace: 'pre-line',
          wordBreak: 'break-word',
          textOverflow: 'inherit',
        },
        '& .MuiDataGrid-cell': {
          border: '1px solid',
          borderColor: 'border.main',
          borderTop: 'none',
          padding: '2px 10px',
          borderLeft: 'none',
          '& .MuiInputBase-root': {
            fontSize: 'inherit',
            '& .MuiOutlinedInput-notchedOutline': {
              display: 'none',
            },
          },
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'inherit!important',
        },
        '& .MuiDataGrid-cell--editable:focus': {
          outline: 'inherit !important',
        },
        '& .MuiDataGrid-withBorderColor': {
          borderColor: 'border.main',
        },
        '& .MuiDataGrid-columnHeaders': {
          bgcolor: 'background.light',
        },
        '& .MuiDataGrid-columnHeader': {
          border: '1px solid',
          borderTop: 'none',
          borderLeft: 'none',
          borderColor: 'border.main',
          outline: 'inherit !important',
          color: 'text.dark',
        },
        '& .MuiCheckbox-root': {
          '& .MuiSvgIcon-root': {
            fontSize: '20px',
          },
        },
        '& .MuiDataGrid-cellCheckbox': {
          outline: 'inherit !important',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-footerContainer': {
          justifyContent: 'center',
        },
        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-root, & .MuiTablePagination-displayedRows':
          {
            fontSize: 'inherit',
          },
        '& .MuiDataGrid-row.Mui-hovered': {
          bgcolor: 'background.light',
        },
      }}
      slotProps={{
        loadingOverlay: {
          variant: 'linear-progress',
          noRowsVariant: 'linear-progress',
        },

        basePopper: {
          placement: 'bottom-end',
        },
        columnsPanel: {
          sx: {
            '& .MuiDataGrid-columnsPanelRow': {
              padding: '4px 0',
            },
            '& .MuiDataGrid-columnsPanel': {
              p: '20px',
            },
          },
        },
      }}
      {...rest}
    />
  );
};

export default DataTable;
