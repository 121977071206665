import React, { useEffect, useState } from 'react';
import GlassCard from './../../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import Nav from '../../../../../utils/Nav.tsx';
import { useNavigate, useParams } from 'react-router-dom';
const GridSize = 2.4;
const schema = yup.object().shape({
  salesOrderName: yup.string().required('Sales Order Name is required'),

  regionId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Region is required'),
  locationId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Location is required'),

  orderStatusId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Order Status is required'),
  customerId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Customer is required'),
});

const EditSalesOrder = (props) => {
  const { setGetData, type } = props;
  const crud = new Crud_Service();
  const navigate=useNavigate()
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [selectedOrder, setSelectedOrder] = useState({});
  const [editingRowData, setEditingRowData] = useState(null);

  useEffect(() => {
    reset();
    setSelectedOrder({});
  }, [type]);

  const id=useParams().id
  useEffect(() => {
    if (id) {
      (async () => {
        await crud.getSingle('salesorders',id, (err, res) => {
          if (res?.status === 200) {
            setEditingRowData(res.data);
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
          } else {
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);

  useEffect(() => {
    if (selectedOrder?.salesQuotationId) {
      setValue('contactPersonId', selectedOrder?.contactPersonId);
      setValue('deliveryTerms', selectedOrder?.deliveryTerms);
      setValue('locationId', selectedOrder?.locationId);
      setValue('narration', selectedOrder?.narration);
      setValue('notes', selectedOrder?.notes);
      setValue('validity', selectedOrder?.validity);
      setValue('paymentTerms', selectedOrder?.paymentTerms);
      setValue('subject', selectedOrder?.subject);
      setValue('regionId', selectedOrder?.regionId);
      setValue('remarks', selectedOrder?.remarks);
      setValue('attn', selectedOrder?.attn);
      setValue('loginUserId', selectedOrder?.contactPersonId);
    }
  }, [selectedOrder]);

  const submitSalesOrder = async (values) => {
    const combinedValues = {
      ...values,
      //TODO: Need to Do Dynamic in future
      salesOrderTypeId: 1,
      orderDate: values?.orderDate
        ? dayjs(values?.orderDate).format('YYYY-MM-DD')
        : null,
    };
    await crud.update('salesorders',id, combinedValues, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Updated Sales Order Successfully');
        setGetData(true);
        reset();
        setSelectedOrder({});
      } else {
      }
    });
  };

  const handleReset = () => {
    reset();
    setSelectedOrder({});
  };

  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(submitSalesOrder)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmTextField
              name='salesOrderName'
              label='Sales Order Name'
              control={control}
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmDatePicker
              name='orderDate'
              label='Order Date'
              control={control}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='salesQuotationId'
              label='Sales Quotation'
              apiUrl={'salesquotations'}
              valueField='salesQuotationId'
              onChangeProp={(value) => setSelectedOrder(value)}
              labelField={['salesQuotationName', 'salesQuotationCode']}
              showField={['salesQuotationName', 'salesQuotationCode']}
              control={control}
              defaultValue={{
                salesQuotationId: editingRowData?.salesQuotationId,
                salesQuotationName: editingRowData?.salesQuotationCode,
              }}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='customerId'
              label='Customer'
              apiUrl={'customers'}
              valueField='customerId'
              labelField={['customerName', 'customerCode']}
              required
              showField={['customerName', 'customerCode']}
              control={control}
              defaultValue={{
                customerId: editingRowData?.customerId,
                customerName: editingRowData?.customerName,
              }}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='regionId'
              label='Region'
              required
              apiUrl={'regions'}
              valueField='regionId'
              labelField={['regionName', 'regionCode']}
              defaultValue={{
                regionId: selectedOrder?.regionId||editingRowData?.regionId,
                regionName: selectedOrder?.regionName||editingRowData?.regionName,
              }}
              showField={['regionName', 'regionCode']}
              control={control}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='locationId'
              label='Location'
              apiUrl={'locations'}
              required
              valueField='locationId'
              labelField={['locationName']}
              showField={['locationName']}
              defaultValue={{
                locationId: selectedOrder?.locationId||editingRowData?.locationId,
                locationName: selectedOrder?.locationName||editingRowData?.locationName,
              }}
              control={control}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='paymentTerms'
              label='Payment Terms'
              control={control}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='narration' label='Narration' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='deliveryTerms'
              label='Delivery Terms'
              control={control}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='subject' label='Subject' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='notes' label='Notes' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='contactPersonId'
              label='Contact Person'
              apiUrl={'loginusers'}
              valueField='loginUserId'
              defaultValue={{
                loginUserId: selectedOrder?.contactPersonId|| editingRowData?.contactPersonId,
                loginUserName: selectedOrder?.contactPersonName|| editingRowData?.contactPersonName,
              }}
              labelField={['loginUserName']}
              showField={['loginUserName']}
              control={control}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField name='validity' label='Validity' control={control} />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField
              name='attn'
              label='Attention (Attn)'
              control={control}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='orderStatusId'
              label='Order Status'
              apiUrl={'salesorders/statuses'}
              required
              valueField='salesOrderStatusId'
              labelField={['salesOrderStatusName']}
              showField={['salesOrderStatusName']}
              control={control}
              defaultValue={{
                orderStatusId:selectedOrder.orderStatusId|| editingRowData?.orderStatusId,
                salesOrderStatusName:selectedOrder.orderStatusName || editingRowData?.orderStatusName,
              }}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='remarks'
              multiline
              rows={4}
              label='Remarks'
              control={control}
            />
          </Grid>

          <Grid item md={12}>
            <ActionButtons
              onSubmit={handleSubmit(submitSalesOrder)}
              onReset={handleReset}
              submitLoading={false}
              cancelLoading={false}
              onCancel={()=>navigate(-1)}
              cancelText='Go Back'
              submitText='Update'
            />
          </Grid>
        </Grid>
      </form>
    </GlassCard>
  );
};

export default EditSalesOrder;
