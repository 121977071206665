import React from 'react';
import { Box, LinearProgress } from '@mui/material';

const CardDashboard = (props) => {
  const { action, title, color, children } = props;
  return (
    <Box
      sx={{
        bgcolor: 'background.white',
        borderRadius: '10px',
        minHeight: '100%',
        overflow: 'hidden',
        border: '1px solid',
        borderColor: 'border.main',
        '& .MuiTable-root': {
          border: 'none',
          '& .MuiTableHead-root .MuiTableRow-root': {
            bgcolor: 'background.white',
          },
        },
      }}
    >
      {title && (
        <Box
          className={`flex justify-between items-center p-1 px-2`}
          sx={{
            borderBottom: '1px solid',
            borderColor: 'border.main',
            minHeight: '40px',
          }}
        >
          <h2 className='font-semibold text-sm'>{title}</h2>
          {action}
        </Box>
      )}
      <Box>{children}</Box>
    </Box>
  );
};

const TextCountBox = (props) => {
  const { name, count, color } = props;
  return (
    <Box className={`text-center ${color}`}>
      <Box className='text-4xl font-bold'>{count}</Box>
      <Box>{name}</Box>
    </Box>
  );
};

const ProgressWithLabel = ({ value, text, light, dark }) => {
  return (
    <Box className='flex items-center gap-3 w-full'>
      <Box className='flex-1'>
        <LinearProgress
          variant='determinate'
          value={value}
          sx={{ height: '12px' }}
          classes={{
            root: light,
            bar: dark,
          }}
        />
      </Box>
      <Box sx={{ minWidth: '75px', textAlign: 'right' }}>{text}</Box>
    </Box>
  );
};

export { CardDashboard, TextCountBox, ProgressWithLabel };
