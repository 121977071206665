import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { StatusBadge, ViewField } from '../../../components/_form/FormElements';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import PageHeader from '../../../components/PageHeader';
import { CreateButton } from '../../../components/small/Buttons';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { DocumentType } from '../../../interfaces/EmployeeMasterInterfaces';
import { toast } from 'react-toastify';
import { Nav } from '../../../utils';

const DocumentTypeCategoryMasters = () => {
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [viewData, setViewData] = useState();
  const [editModal, setEditModal] = useState(false);
  const crud = new Crud_Service();
  const { t } = useTranslation();
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('documenttypecategories');
  const { handleSubmit, setValue, control, reset } = useForm<DocumentType>();
  const navigate = useNavigate();
  const [loading, setLoader] = useState(false);
  const statusOptions = [
    { statusId: 2, statusName: 'Active' },
    { statusId: 1, statusName: 'Inactive' },
  ];

  useEffect(() => {
    document.title = `HRMS-Docuement Type category`;
  }, []);

  //create DocumentType
  const handleSubmitDocumentType: SubmitHandler<DocumentType> = async (
    values
  ) => {
    startCreateLoading();
    await crud.create('documenttypecategories', values, (err, res) => {
      if (res?.status === 201) {
        setOpenCreate(false);
        fetchData();
        stopCreateLoading();
      } else {
        setOpenCreate(true);
        stopCreateLoading();
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };

  const handleViewClickOpen = async (view) => {
    setOpenView(true);
    setLoader(true);
    await crud.getSingle(
      'documenttypecategories',
      view?.documentTypeCategoryId,
      (err, res) => {
        if (res?.status === 200) {
          setViewData(res?.data);
          setLoader(false);
        } else {
        }
      }
    );
  };

  //setFormValues
  const handleEditClick = async (view) => {
    setEditModal(true);
    setLoader(true);
    await crud.getSingle(
      'documenttypecategories',
      view?.documentTypeCategoryId,
      (err, res) => {
        if (res?.status === 200) {
          Object.entries({
            ...res?.data,
          }).forEach(([key, value]) => {
            setValue(key, value);
          });
          setLoader(false);
        } else {
          setLoader(true);
        }
      }
    );
  };

  //Update DocumentTypes
  const handleEditDocumentType = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
    };

    await crud.update(
      'documenttypecategories',
      values?.documentTypeCategoryId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          setEditModal(false);
          fetchData();
          stopEditLoading();
        } else {
          setEditModal(true);
          stopEditLoading();
        }
      }
    );
  };

  //Delete DocumentTypes
  const handleDeleteClick = async (view) => {
    await crud.remove(
      'documenttypecategories',
      view?.documentTypeCategoryId,
      (err, res) => {
        if (res?.status === 204) {
          fetchData();
        } else {
        }
      }
    );
  };

  const handleViewClose = () => {
    setOpenView(false);
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const ActionData = [
    {
      name: t('view'),
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: t('edit'),
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: t('delete'),
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'documentTypeCategoryCode',
      headerName: 'Document Type Category Code',
      flex: 1,
    },
    {
      field: 'documentTypeCategoryName',
      headerName: 'Document Type Category Name',
      flex: 1,
    },

    {
      field: 'status',
      headerName: t('status'),
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  return (
    <DashboardLayout
      title='Document Type Category'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={Nav[0].child[5].children}
      parentMenu={Nav[0].child[5].name}
    >
      <GlassCard className='h-full'>
        <Box className='h-full'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <CreateButton name='New' onClick={handleCreateClickOpen} />
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create */}

      <MuiDialogOne
        title={t('create')}
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitDocumentType)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='documentTypeCategoryName'
                control={control}
                label='Document Type Category Name'
                rules={{
                  required: 'Document Type Category Name is required',
                }}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitDocumentType)}
            submitLoading={createLoading}
            cancelLoading={false}
            onReset={reset}
            onCancel={() => navigate(-1)}
          />
        </form>
      </MuiDialogOne>

      {/* view */}

      <MuiDialogOne
        title={t('viewDetails')}
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={6}>
              <ViewField
                label={'Document Type Category Code'}
                title={viewData?.documentTypeCategoryCode}
              />
            </Grid>

            <Grid item md={6}>
              <ViewField
                label={'Document Type Category Name'}
                title={viewData?.documentTypeCategoryName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField label={t('status')} title={viewData?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>
      {/* view */}

      {/*  edit */}
      <MuiDialogOne
        title={t('updateDocumentTypes')}
        open={editModal}
        onClose={() => setEditModal(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditDocumentType)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='documentTypeCategoryName'
                  control={control}
                  label='Document Type Category Name'
                  rules={{
                    required: 'Document Type Category Name is required',
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditDocumentType)}
                  onReset={reset}
                  onCancel={() => setEditModal(false)}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default DocumentTypeCategoryMasters;
