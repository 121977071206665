import React, { useState } from 'react';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { contractScheduleSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';

const ContractScheduleListTable = (props) => {
  const {
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;
  const crud = new Crud_Service();
  const { control, setValue, handleSubmit } = useForm({
    resolver: yupResolver(contractScheduleSchema),
    mode: 'onChange',
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [viewDetails, setViewdetails] = useState(false);
  const excludeKeys = ['createdDate', 'updatedDate', 'status'];
  const { startEditLoading, stopEditLoading } = useLoader();

  const handleEdit = (row) => {
    setEditingRowId(row?.original?.contractScheduleId);
    setEditingRowData({ ...row?.original });
    Object.entries({
      ...row?.original,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'contractschedules',
      props?.original?.contractScheduleId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Contract Schedule deleted successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleUpdateEvent = async (values) => {
    try {
      startEditLoading();
      const combinedData = {
        ...values,
        startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(values?.endDate).format('YYYY-MM-DD'),
        Status: 2,
      };
      await crud.update(
        'contractschedules',
        editingRowId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success('Contract Schedule Updated Successfully');
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
            toast.error('Failed to update the Incident Report');
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating the Incident Report');
    }
  };

  const ActionData = [
    //   TODO : Add View Details
    // {
    //     name: 'View Details',
    //     icon: <Icon icon='solar:eye-linear' />,
    //     onClick: (props) => handleViewClick(props),
    // },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const columns = [
    {
      field: 'action',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,

      Cell: (params) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId === params?.row?.original?.contractScheduleId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateEvent)}>Save</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params.row),
                }))}
                selectedRow={params.row}
                setSelectedRow={() => {}}
              />
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) =>
            item?.contractScheduleId === cell?.row?.original?.contractScheduleId
        );
        return editingRowId === cell?.row?.original?.contractScheduleId ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            defaultValue={editingRowData}
            labelField={['contractName']}
            showField={['contractName']}
            pageSize={20}
            label='Contract'
            required
          />
        ) : (
          subcontractItems?.contractName || ''
        );
      },
    },
    {
      accessorKey: 'billingFrequencyId',
      header: 'Billing Frequency Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) =>
            item?.contractScheduleId === cell?.row?.original?.contractScheduleId
        );
        return editingRowId === cell?.row?.original?.contractScheduleId ? (
          <FmSearchableSelect
            name='billingFrequencyId'
            control={control}
            apiUrl='billingfrequencies'
            label='Billing Frequency'
            defaultValue={editingRowData}
            labelField={['billingFrequencyName']}
            required
            showField={['billingFrequencyName']}
            valueField='billingFrequencyId'
          />
        ) : (
          subcontractItems?.billingFrequencyName || ''
        );
      },
    },
    {
      accessorKey: 'startDate',
      header: 'Start Date',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) =>
            item?.contractScheduleId === cell?.row?.original?.contractScheduleId
        );
        return editingRowId === cell?.row?.original?.contractScheduleId ? (
          <FmDatePicker
            name='startDate'
            label='Start Date'
            control={control}
            required
          />
        ) : (
          <FmViewDateField value={subcontractItems?.startDate} />
        );
      },
    },
    {
      accessorKey: 'endDate',
      header: 'End Date',
      enableEditing: true,
      Cell: ({ cell }) => {
        const subcontractItems: any = rows?.find(
          (item) =>
            item?.contractScheduleId === cell?.row?.original?.contractScheduleId
        );
        return editingRowId === cell?.row?.original?.contractScheduleId ? (
          <FmDatePicker
            name='endDate'
            label='End Date'
            control={control}
            required
          />
        ) : (
          <FmViewDateField value={subcontractItems?.endDate} />
        );
      },
    },
    {
      accessorKey: 'amountToBeInvoiced',
      header: 'Amount To Be Invoiced',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.contractScheduleId ? (
          <FmTextField
            name='amountToBeInvoiced'
            control={control}
            required
            defaultValue={row?.original?.amountToBeInvoiced}
          />
        ) : (
          row.original.amountToBeInvoiced
        );
      },
    },
    {
      accessorKey: 'invoicedAmount',
      header: 'Invoiced Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.contractScheduleId ? (
          <FmTextField
            name='invoicedAmount'
            control={control}
            required
            defaultValue={row?.original?.invoicedAmount}
          />
        ) : (
          row.original.invoicedAmount
        );
      },
    },
    {
      accessorKey: 'invoiceRef',
      header: 'Invoice Ref',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.contractScheduleId ? (
          <FmTextField
            name='invoiceRef'
            control={control}
            required
            defaultValue={row?.original?.invoiceRef}
          />
        ) : (
          row.original.invoiceRef
        );
      },
    },
    {
      accessorKey: 'paymentAmount',
      header: 'Payment Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.contractScheduleId ? (
          <FmTextField
            name='paymentAmount'
            control={control}
            required
            defaultValue={row?.original?.paymentAmount}
          />
        ) : (
          row.original.paymentAmount
        );
      },
    },
    {
      accessorKey: 'paymentRef',
      header: 'Payment Ref',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.contractScheduleId ? (
          <FmTextField
            name='paymentRef'
            control={control}
            required
            defaultValue={row?.original?.paymentRef}
          />
        ) : (
          row.original.paymentRef
        );
      },
    },
    {
      accessorKey: 'deductionAmount',
      header: 'Deduction Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.contractScheduleId ? (
          <FmTextField
            name='deductionAmount'
            control={control}
            required
            defaultValue={row?.original?.deductionAmount}
          />
        ) : (
          row.original.deductionAmount
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.contractScheduleId ? (
          <FmTextField
            name='remarks'
            control={control}
            defaultValue={row?.original?.remarks}
          />
        ) : (
          row.original.remarks
        );
      },
    },
  ];

  return (
    <>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        setSearchKeyword={setSearchKeyword}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        muiTableContainerProps={{
          sx: {
            maxHeight: '300px',
          },
        }}
      />
      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && (
          <CommonView
            url='contractschedules'
            id={viewDetails?.contractScheduleId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>
    </>
  );
};

export default ContractScheduleListTable;
