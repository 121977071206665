import { createSlice } from '@reduxjs/toolkit';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('menu');
    return serializedState !== null ? JSON.parse(serializedState) : 0;
  } catch (err) {
    return 0; // Default value if there's an error
  }
};

const initialState = loadState();
const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveTab(state, action) {
      return action.payload;
    },
  },
});

// Export action creators
export const { setActiveTab } = tabsSlice.actions;

// Export reducer
export default tabsSlice.reducer;
