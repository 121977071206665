import React from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import HRMSEmployeeDashboard from './HRMSEmployeeDashboard';
import Nav from '../../../utils/Nav.tsx';
import { useTranslation } from 'react-i18next';
const HRMSEmployeeMasterDashboard = () => {
  const { t } = useTranslation();

  return (
    <DashboardLayout
      title={t('employee_dashboard')}
      menu={
        Nav &&
        Nav[0] &&
        Nav[0].child &&
        Nav[0].child[1] &&
        Nav[0].child[1].children
      }
      hasSubmenu
    >
      <HRMSEmployeeDashboard />
    </DashboardLayout>
  );
};

export default HRMSEmployeeMasterDashboard;
