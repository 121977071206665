import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import MuiDatePickerOne from '../../../components/_mui/MuiDatePickerOne.tsx';
import DataTable from '../../../components/DataTable.tsx';
import { Icon } from '@iconify/react';
import Crud_Service from '../../../apis/CrudService.jsx';
import { Controller, useForm } from 'react-hook-form';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import dayjs from 'dayjs';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { toast } from 'react-toastify';

const generateData = () => {
  const grnType = ['Short Close', 'Delivery'];
  const attachmentes = [true, false];
  const data = [];

  for (let i = 1; i <= 10; i++) {
    data.push({
      id: i,
      itemCode: `LT5456A6${i}`,
      item: `Keyboard`,
      desc: 'Servers Store Initiated',
      ordered: `10${i}`,
      received: `${i}`,
      difference: `3${i}`,
      attachment: attachmentes[i % 2],
      grnType: grnType[i % 2],
      delDate: `05/07/2024`,
      reason: 'Remarks with Goods',
    });
  }

  return data;
};

const initialRows = generateData();

const GRNViewDetails = (props) => {
  const { viewData } = props;
  const { control, handleSubmit, setValue,reset } = useForm();
  const [grnFiles, setGrnFiles] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();

  useEffect(() => {
    if (viewData) {
      setLoading(true);
      Object.entries({
        ...viewData,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });

      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [viewData]);

  const columns = [
    {
      field: 'itemCode',
      headerName: 'Item Code',
      minWidth: 150,
    },
    {
      field: 'item',
      headerName: 'Item',
      minWidth: 150,
      cellClassName: 'text-sky-600 font-semibold',
    },
    {
      field: 'desc',
      headerName: 'Description',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'ordered',
      headerName: 'Ordered',
      minWidth: 70,
    },
    {
      field: 'received',
      headerName: '✎ Received',
      minWidth: 70,
      editable: true,
    },
    {
      field: 'difference',
      headerName: 'Difference',
      minWidth: 70,
      editable: true,
    },
    {
      field: 'reason',
      headerName: '✎ Reason',
      minWidth: 130,
      flex: 1,
      editable: true,
    },
  ];

  //Fetch GetAll Apis
  const { data: materialrequisitions } = useCommonFetchApi(
    'materialrequisitions',
  );
  const { data: localpurchaseorders } = useCommonFetchApi(
    'localpurchaseorders',
  );
  const { data: suppliers } = useCommonFetchApi('suppliers');

  //File Uplaod
  const handleFileUpload = async (e) => {
    const files = e.target.files[0];
    setUploadLoading(true);
    if (files) {
      const fileFormData = new FormData();
      fileFormData.append('file', files);
      fileFormData.append('fileTypeId', 39);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setGrnFiles(res?.data);
        } else {
          setUploadLoading(false);
          toast.error('something_Went_Wrong');
        }
      });
    }
  };

  const handleSubmitGRN = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      deliveryDate: dayjs(values.deliveryDate).format('YYYY-MM-DD'),
      grnDate: dayjs(values.grnDate).format('YYYY-MM-DD'),
      fileId: grnFiles?.fileId || viewData?.fileId,
      createGoodsReceievedNoteItems: [],
    };

    await crud.update(
      'goodsreceivednotes',
      viewData?.goodsReceivedNoteId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Goods Received Note Updated Successfully');
          stopCreateLoading();
        } else {
          stopCreateLoading();
        }
      },
    );
  };

  return (
    <>
      {/* < For Future Use */}
      {/* <GlassCard className='flex justify-between items-center p-3 bg-blue-50 dark:bg-blue-900'>
        <Box>
          <h3 className='font-bold text-base'>{viewData?.supplierName}</h3>
          <p className='text-green-700'>{viewData?.localPurchaseOrderNumber}</p>
        </Box>
        <Box>
          <Stack spacing={3} direction='row'>
            <Box sx={{ width: '230px' }}>
              <TextField defaultValue='delivered' select fullWidth>
                <MenuItem value='delivered'>Delivered</MenuItem>
                <MenuItem value='shortclose'>Short Close</MenuItem>
              </TextField>
            </Box>
            <Button variant='outlined' color='error'>
              Cancel
            </Button>
          </Stack>
        </Box>
      </GlassCard>

      <GlassCard className='my-4 p-4'>
        <Stack spacing={3} direction='row'>
          <TextField label='Notes' fullWidth />
          <Box className='w-full'>
            <MuiDatePickerOne
              label='Received Date'
              TextFieldVariant='outlined'
            />
          </Box>
          <Box className='w-full'>
            <Box
              component='label'
              htmlFor='uploadI'
              className='cursor-pointer text-center'
            >
              <Box className='font-bold' sx={{ color: 'primary.main' }}>
                Click to Upload Or Drag and Drop
              </Box>
              <p className='opacity-70'>SVG, PNG, JPG or GIF (Max 7 MB)</p>
            </Box>
            <input type='file' className='hidden' id='uploadI' />
          </Box>
        </Stack>
      </GlassCard> */}
      <GlassCard className='p-6'>
        <form onSubmit={handleSubmit(handleSubmitGRN)}>
          <Grid spacing={2} container>
            <Grid item md={2.4}>
              <FmAutoComplete
                name='materialRequisitionId'
                control={control}
                options={materialrequisitions}
                label='Material Requisition'
                displayField='materialRequisitionCode'
                optionFields={['materialRequisitionCode']}
                valueKey='materialRequisitionId'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmAutoComplete
                name='localPurchaseOrderId'
                control={control}
                options={localpurchaseorders}
                label='Local Purchase Order'
                displayField='localPurchaseOrderNumber'
                optionFields={['localPurchaseOrderNumber']}
                valueKey='localPurchaseOrderId'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmAutoComplete
                name='supplierId'
                control={control}
                options={suppliers}
                label='Supplier'
                displayField='officialSupplierName'
                optionFields={['officialSupplierName']}
                valueKey='supplierId'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmTextField
                name='deliveryChallanNumber'
                control={control}
                label='Delivery Challan Number'
              />
            </Grid>
            <Grid item md={2.4}>
              <Controller
                name='deliveryDate'
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <MuiDatePickerOne
                    {...field}
                    label='Delivery Date'
                    value={field?.value ? dayjs(field?.value) : null}
                    onChange={field?.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item md={2.4}>
              <Controller
                name='grnDate'
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <MuiDatePickerOne
                    {...field}
                    label='GRN Date'
                    value={field?.value ? dayjs(field?.value) : null}
                    onChange={field?.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item md={2}>
              <FmFileInput
                name='fileId'
                control={control}
                documentName={grnFiles?.originalFileName}
                onChange={handleFileUpload}
                loading={uploadLoading}
              />
            </Grid>
            <Grid item md={7.6}>
              <FmTextField
                name='remarks'
                control={control}
                label='Remarks'
                multiline
                rows={2}
                maxRows={4}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitGRN)}
            submitLoading={createLoading}
            submitText='Update'
            cancelLoading={false}
            onReset={reset}
          />
        </form>

        {/* <GoodsReceivedNoteItem /> */}
      </GlassCard>
      <GlassCard className='overflow-hidden w-full'>
        <Box sx={{ height: '300px', width: '100%' }}>
          <DataTable rows={initialRows} columns={columns} />
        </Box>
      </GlassCard>

      <Box className='text-end mt-5'>
        <Button
          variant='contained'
          startIcon={<Icon icon='hugeicons:package-receive' />}
        >
          Receive All
        </Button>
      </Box>
    </>
  );
};

export default GRNViewDetails;
