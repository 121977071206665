import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { MaterialReactTable } from 'material-react-table';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import Icons from '../../../../utils/Icon.jsx';
import MRTIconButton from '../../../../components/table/MRTIconButton.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import { Box } from '@mui/material';
import {
  ViewTable,
  ViewField,
} from '../../../../components/_form/FormElements.jsx';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const generateData = () => {
  const data = [];
  for (let i = 1; i <= 15; i++) {
    data.push({
      id: i,
      contract: 'C-1246',
      contractName: 'Khalifa University DC',
      contractValue: ' 12,00,000 ',
      invoice: 'KU-24-001',
      invoiceDate: '01-07-2024',
      inovoiceAmount: ' 1,00,000.00 ',
      paymentRef: 'C-1224',
      paymentReceived: ' 1,00,000 ',
      remarks: 'Chq # 24_ADB_19245',
    });
  }
  return data;
};

const initialRows = generateData();

const ViewContractRevenue = () => {
  const [data, setData] = useState(initialRows);
  const [view, setView] = useState(false);
  const [viewRow, setViewRow] = useState([]);

  const columns = [
    {
      accessorKey: 'contract',
      header: 'Contract',
      Cell: ({ renderedCellValue }) => <BoldBox title={renderedCellValue} />,
    },
    {
      accessorKey: 'contractName',
      header: 'Contract Name',
    },
    {
      accessorKey: 'contractValue',
      header: 'Contract Value',
    },

    {
      accessorKey: 'invoice',
      header: 'Invoice',
    },

    {
      accessorKey: 'invoiceDate',
      header: 'Invoice Date',
    },

    {
      accessorKey: 'inovoiceAmount',
      header: 'Invoice Amount',
    },
    {
      accessorKey: 'paymentRef',
      header: 'Payment Ref',
    },
    {
      accessorKey: 'paymentReceived',
      header: 'Payment Received (AED)',
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
    },
  ];

  return (
    <>
      <GlassCard className='p-2 flex gap-3 items-center justify-between mb-4'>
        <ViewField label='Contract Value' title='1000000' />
        <ViewField label='Budgeted Value' title='800000' />
        <ViewField label='Revenue MTD' title='1,00,000 ' />
        <ViewField label='Revenue YTD' title='1,00,000' />
        <ViewField label='Total Revenue' title='1,00,000' />
      </GlassCard>

      <GlassCard>
        <MRTExpandTableStyle>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnActions={false}
            enableDensityToggle={false}
            positionGlobalFilter='left'
            enableGlobalFilter={true}
            enableHiding={false}
            enableFilters={false}
            initialState={{
              showGlobalFilter: true,
            }}
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => {
                setViewRow(row.original);
                setView(!view);
              },
              className: 'cursor-pointer',
            })}
            muiEditTextFieldProps={(column) => ({
              variant: 'filled',
              label: column.column.columnDef.header,
            })}
            enableStickyHeader
            enableRowActions
            renderRowActions={({ row, table }) => (
              <Box className='flex gap-1'>
                <MRTIconButton
                  icon={Icons.view}
                  title='View'
                  onClick={() => {
                    setViewRow(row.original);
                    setView(!view);
                  }}
                />
              </Box>
            )}
          />
        </MRTExpandTableStyle>
      </GlassCard>

      <MuiDialogOne
        title='View details'
        open={view}
        onClose={() => setView(!view)}
      >
        <ViewTable
          rows={[
            {
              label: 'Contract',
              title: viewRow?.contract,
            },
            {
              label: 'Contract Name',
              title: viewRow?.contractName,
            },
            {
              label: 'Contract Value',
              title: viewRow?.contractValue,
            },
            {
              label: 'Invoice',
              title: viewRow?.invoice,
            },
            {
              label: 'Invoice Date',
              title: viewRow?.invoiceDate,
            },
            {
              label: 'Inovoice Amount',
              title: viewRow?.inovoiceAmount,
            },
            {
              label: 'Payment Ref',
              title: viewRow?.paymentRef,
            },
            {
              label: 'Payment Received (AED)',
              title: viewRow?.paymentReceived,
            },

            {
              label: 'Remarks',
              title: viewRow?.remarks,
            },
          ]}
        />
      </MuiDialogOne>
    </>
  );
};

export default ViewContractRevenue;
