import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../../components/small/BackToButton.jsx';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ViewTable } from '../../../../../components/_form/FormElements.jsx';
import { Grid } from '@mui/material';
import DashboardLayout from './../../../../../components/DashboardLayout.tsx';
import ViewListCheckoutForm from './ViewListCheckoutForm.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import EditCheckOutForm from '../../CreateCheckOutForm/View/EditCheckOutForm.tsx';

const CreateCheckOutView = () => {
  const navigation = useNavigate();
  const [viewData, setViewData] = useState([]);
  const location = useLocation();
  const trimmedPath = location.pathname.split('/').slice(0, -1).join('/');
  const { id } = useParams();
  const crud = new Crud_Service();
  const viewDataFetch = async () => {
    crud.getSingle('checkoutforms', id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
      } else {
        setViewData([]);
      }
    });
  };
  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);

  useEffect(() => {
    document.title = 'View |Check Out Form | Snag Report | CAFM';
  });

  return (
    <>
      <DashboardLayout title='View Check Out Form'>
        {/* glasscard */}
        <EditCheckOutForm/>
        {/* glasscard */}
        <ViewListCheckoutForm checkOutFormId={id} />
      </DashboardLayout>
    </>
  );
};

export default CreateCheckOutView;
