import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Icon } from '@iconify/react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const FilterButtonSearch = ({ name, data, column, handleCheckboxChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterData(query);
  };

  const filterData = (query) => {
    if (!query) {
      setFilteredData(data);
    } else {
      const lowercasedQuery = query.toLowerCase();
      const filtered = data.filter((item) =>
        item.value.toLowerCase().includes(lowercasedQuery),
      );
      setFilteredData(filtered);
    }
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        className='capitalize flex items-center py-2 px-3'
        sx={{
          color: open ? 'primary.main' : 'text.main',
          fontWeight: 500,
        }}
      >
        <span>{name}</span>
        <span className='ms-1 text-2xl'>
          <Icon icon={open ? 'uil:angle-up' : 'uil:angle-down'} />
        </span>
      </Button>

      <Popper id={id} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClick}>
          <Box
            sx={{
              bgcolor: 'background.white',
              marginTop: '10px',
              fontSize: 'inherit',
              color: 'text.main',
              borderRadius: '10px',
              boxShadow:
                '0 1px 3px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24)',
            }}
            className='overflow-hidden'
          >
            <Box className='w-full p-3'>
              <TextField
                placeholder={`Search ${name}`}
                id='outlined-size-small'
                size='small'
                fullWidth
                autoFocus={true}
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '22px' }}>
                      <Icon icon='material-symbols:search' />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    bgcolor: 'background.light',
                    height: '45px',
                    borderRadius: '10px',
                    fontSize: 'inherit',
                    border: '1px solid',
                    borderColor: 'border.main',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              />
            </Box>

            <Box
              className='w-full'
              sx={{ maxHeight: '600px', overflow: 'auto' }}
            >
              <Box className='p-3 pb-6'>
                <div
                  className={
                    column
                      ? `grid grid-cols-${column} gap-3`
                      : 'grid grid-cols-1 gap-3'
                  }
                >
                  {data?.map((item, i) => {
                    return (
                      <div key={i}>
                        <FormControlLabel
                          key={item.id}
                          control={
                            <Checkbox
                              sx={{ p: 0, marginRight: '6px' }}
                              name={`name${i}`}
                              checked={item.checked}
                            />
                          }
                          label={item.value}
                          sx={{
                            m: 0,
                            color: 'inherit',
                            '& .MuiButtonBase-root': {
                              color: 'inherit',
                            },
                            '& .MuiTypography-root': {
                              color: 'inherit',
                              fontSize: 'inherit',
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: '1.5rem',
                            },
                          }}
                          onChange={() => handleCheckboxChange(item.id)}
                        />
                      </div>
                    );
                  })}
                </div>
              </Box>
            </Box>

            {/* teal */}
            {/* <div className="p-1 px-2 flex flex-row justify-end bg-teal-700 gap-3">
              <Button
                className="capitalize text-white opacity-50 hover:opacity-100 text-base"
                onClick={handleClick}
              >
                Cancel
              </Button>
              <Button
                className="capitalize text-white font-bold text-base"
                onClick={handleApplyNow}
              >
                Apply Now
              </Button>
            </div> */}

            {/* teal */}
          </Box>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default FilterButtonSearch;
