import React from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { TextField, Button, Grid, MenuItem } from '@mui/material';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { Controller, useForm } from 'react-hook-form';
import Crud_Service from '../../../../apis/CrudService.jsx';
import MuiDatePickerOne from '../../../../components/_mui/MuiDatePickerOne.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { statusOptions } from '../../../../utils/CommonVariables.tsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
const GridSize = 2.4;

const AddCheckOutForm = ({ fetchData }) => {
  const crud = new Crud_Service();
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();

  const { handleSubmit, control, setValue, getValues, reset } = useForm();

  const handleSubmitCheckoutForm = async (data) => {
    startCreateLoading();
    const combinedData = {
      ...data,
      checkOutDate: dayjs(data?.checkOutDate).format('YYYY-MM-DD'),
      inChargeId: Number(data?.loginUserId),
    };
    await crud.create('checkoutforms', combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Item Created successfully');
        fetchData();
        reset();
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Add Check Out Form'
    hasSubmenu>
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(handleSubmitCheckoutForm)}>
        <Grid container spacing={2}>
          {/* <Grid item md={GridSize}>
            <Controller
              name='checkOutDate'
              control={control}
              defaultValue={null}
              render={({ field: { onChange, value } }) => (
                <MuiDatePickerOne
                  label='checkOutDate'
                  value={value}
                  TextRequired={true}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                />
              )}
            />
          </Grid> */}
          <Grid item md={GridSize}>
            <FmDatePicker
              name='checkOutDate'
              label='Check Out Date'
              control={control}
              required
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='loginUserId'
              control={control}
              apiUrl='loginusers'
              valueField='loginUserId'
              headerField={['In Charge Name']}
              labelField={['loginUserName']}
              showField={['loginUserName']}
              pageSize={20}
              label='In Charge Name'
              required
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              valueField='contractId'
              headerField={['Contract Code']}
              labelField={['contractCode']}
              showField={['contractCode']}
              pageSize={20}
              label='Contract'
              required
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='companyId'
              control={control}
              apiUrl='companies'
              valueField='companyId'
              headerField={['Code']}
              labelField={['companyCode']}
              showField={['companyCode']}
              pageSize={20}
              label='Company'
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='clientId'
              control={control}
              apiUrl='clients'
              valueField='clientId'
              headerField={['Client Name']}
              labelField={['clientName']}
              showField={['clientName']}
              pageSize={20}
              label='Client'
              required
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='projectId'
              control={control}
              apiUrl='projects'
              valueField='projectId'
              headerField={['Project Code']}
              labelField={['projectCode']}
              showField={['projectCode']}
              pageSize={20}
              label='Project Code'
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='workOrderId'
              control={control}
              apiUrl='workorders'
              valueField='workOrderId'
              headerField={['User Name']}
              labelField={['workOrderName']}
              showField={['workOrderName']}
              pageSize={20}
              label='work Order'
              required
            />
          </Grid>
        </Grid>
        <Grid item md={GridSize}>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitCheckoutForm)}
            // onCancel={() => navigate(-1)}
            onReset={reset}
            // submitLoading={createLoading}
            cancelLoading={false}
          />
        </Grid>
      </form>
    </GlassCard>
    </DashboardLayout>
  );
};

export default AddCheckOutForm;
