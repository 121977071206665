import React from 'react';
import { Box } from '@mui/material';
import TableDropDown from '../helpers/TableDropDown.tsx';
import { Icon } from '@iconify/react';

export const CardsBox = ({ name, count, handleStatusClick, active }) => {
  return (
    <Box
      className={`p-4 relative rounded-none min-h-16 flex items-center w-full relative ps-5 cursor-pointer ${
        active && 'activeClass'
      } `}
      sx={{
        borderRight: '1px solid',
        borderColor: 'border.main',
        bgcolor: 'background.white',
        '&:hover': {
          bgcolor: 'background.mainLightBase',
        },
        '&:before, &:after': {
          content: "''",
          borderTop: '15px solid transparent',
          borderBottom: '15px solid transparent',
          borderLeft: '15px solid #d1d5db',
          position: 'absolute',
          right: -15,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
        },
        '&:after': {
          right: -14,
          zIndex: 1,
          borderLeft: '15px solid #fff',
        },
        '&.activeClass': {
          bgcolor: 'background.mainLightBase',
          color: 'primary.main',
          '& .font-bold': {
            color: 'primary.main',
          },
        },
        '&:hover:after, &.activeClass:after': {
          borderLeftColor: 'background.mainLightBase',
        },
      }}
      onClick={handleStatusClick}
    >
      <Box>
        <Box className='font-bold text-4xl mb-3' sx={{ color: 'text.dark' }}>
          {count}
        </Box>
        <Box>{name}</Box>
      </Box>
    </Box>
  );
};

export const FileCardGrid = ({
  name,
  size,
  format,
  Actions,
  selectedRow,
  setSelectedRow,
}) => {
  return (
    <Box
      className='flex gap-2 items-center p-2 justify-between'
      sx={{
        bgcolor: 'background.white',
        border: '1px solid',
        borderColor: 'border.main',
        borderRadius: '10px',
        '&:hover': {
          bgcolor: 'background.light',
        },
      }}
    >
      <Box className='text-2xl'>
        <Icon icon='icon-park-outline:doc-success' />
      </Box>
      <Box sx={{ width: 'calc(100% - 76px)' }}>
        <p className='font-bold  w-full text-ellipsis whitespace-nowrap overflow-hidden'>
          {name}
        </p>
        <p className='flex gap-5 items-center opacity-70'>
          <span>size : {size}</span> <span>Format : {format}</span>
        </p>
      </Box>

      <TableDropDown
        Actions={Actions}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </Box>
  );
};
