import React, { useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../components/small/Buttons';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../../components/DataTable.tsx';
import { Icon } from '@iconify/react';
import { Box, Menu, MenuItem } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { StatusBadge } from '../../../components/_form/FormElements';
import Crud_Service from '../../../apis/CrudService.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import apiInstance from '../../../apis/ApiService.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { toast } from 'react-toastify';
import DataTableTopBar from '../../../components/datagrid/DataTableTopBar.tsx';

const ProposalList = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [downloadData, setDownloadData] = useState(null);
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('tenderproposals');

  //handle Delete
  const handleDelete = async (_event, props) => {
    await crud.remove(
      `tenderproposals/${props?.tenderProposalId}`,
      '',
      (err, res) => {
        if (err) {
          toast.error('Error deleting Proposal:', err);
          return;
        }
        if (res?.status === 204) {
          fetchData();
          toast.success('Proposal deleted successfully');
        } else {
          toast.error('Error deleting Proposal:', res);
        }
      }
    );
  };

  const handleViewClick = async (_event, view, type) => {
    navigate(`${type}/${view?.tenderProposalId}`);
  };

  //Handle Download
  const handleDownload = async (event, props) => {
    setMenuAnchor(event.currentTarget);
    setDownloadData(props);
  };

  const handleMenuItemClick = async (typeId) => {
    if (downloadData) {
      try {
        const fileUrl = await apiInstance.postFiles(
          `tenderproposals/downloadtenderproposal/${downloadData?.tenderProposalId}?downloadOutputType=${typeId}`,
          {}
        );
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'file');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        toast.error('Error downloading file:', err);
      }
    } else {
      toast.error('No download data available.');
    }

    setMenuAnchor(null);
  };

  const ActionData = [
    {
      name: 'Download',
      icon: <Icon icon='solar:download-linear' />,
      onClick: (event, props) => handleDownload(event, props),
    },
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (event, props) => handleViewClick(event, props, 'view'),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (event, props) => handleViewClick(event, props, 'edit'),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (event, props) => handleDelete(event, props),
      danger: () => { },
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: (event) => action.onClick(event, params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'clientName',
      headerName: 'Client Name',
      flex: 1,
    },

    {
      field: 'tenderName',
      headerName: 'Tender Name',
      flex: 1,
    },
    {
      field: 'validity',
      headerName: 'Expiry Date',
      flex: 1,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },

    {
      field: 'createdDate',
      headerName: 'Created Date',
      flex: 1,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },

    {
      field: 'statusName',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value}
            type={params.value === 'ACTIVE' ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleViewClick(e, e?.row, 'edit');
    }
  };

  return (
    <DashboardLayout
      title='Generate Proposal'
      actionButtons={
        <>
          <CreateButton
            name='New'
            onClick={() => navigate('/presales/proposal/create')}
          />
        </>
      }
    >
      <GlassCard className='h-full-css'>
        <Box className='h-full-css'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            onRowDoubleClick={(e) => onRowDoubleClick(e)}
            getRowClassName={(params) =>
              `${params.row.workingStatus === 'InActive' &&
              'bg-red-500 bg-opacity-15'
              }`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <DataTableTopBar
                  setSearchKeyword={setSearchKeyword}
                  searchKeyword={searchKeyword}
                />
              ),
            }}
          />
        </Box>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={() => setMenuAnchor(null)}
        >
          <MenuItem onClick={() => handleMenuItemClick(1)}>
            Download Single
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick(2)}>
            Download Zip
          </MenuItem>
        </Menu>
      </GlassCard>
    </DashboardLayout>
  );
};

export default ProposalList;
