import React from 'react';
import ViewIncidentLayout from './ViewIncidentLayout.tsx';
import InjuriesTableList from './ViewInjuriesList/Injuries/InjuriesTableList.tsx';
import TypeAccidentList from './ViewInjuriesList/TypeOfAccident/TypeAccidentList.tsx';
import WitnessList from './ViewInjuriesList/Witness/WitnessList.tsx';

const ViewInjuries = () => {

 
  return (
    <ViewIncidentLayout title='View Incident Report'>
      <InjuriesTableList  />
      <TypeAccidentList />
      <WitnessList  />
    </ViewIncidentLayout>
  );
};

export default ViewInjuries;
