import React from 'react'
import CheckOutListTable from '../CheckOutListTable.tsx';

const ViewCheckoutTable = ({
    rows,
    columns,
    tableRecordCounts,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    setSearchKeyword,
    searchKeyword,
    fetchData
}) => {

    return (
        <>
            <CheckOutListTable
                rows={rows}
                columns={columns}
                editingRowId={null}
                tableRecordCounts={tableRecordCounts}
                isLoading={isLoading}
                pagination={pagination}
                sorting={sorting}
                setPagination={setPagination}
                setSorting={setSorting}
                columnFilters={columnFilters}
                globalFilter={globalFilter}
                setColumnFilters={setColumnFilters}
                setGlobalFilter={setGlobalFilter}
                setSearchKeyword={setSearchKeyword}
                searchKeyword={searchKeyword}
                fetchData={fetchData}
            />

        </>
    )
}

export default ViewCheckoutTable
