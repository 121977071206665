import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { Icon } from '@iconify/react';
import MuiMenuItem from '../_mui/MuiMenuItem';
import ConfirmationButton from '../_form/DeleteConfimation.tsx';

const TableDropDown = (props) => {
  const { Actions, selectedRow, setSelectedRow } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setSelectedRow(selectedRow);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick} sx={{ p: 1, fontSize: '18px' }}>
        <Icon icon='mage:dots-horizontal' />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPaper-root': {
            boxShadow: 1,
            bgcolor: 'background.white',
            border: '1px solid',
            borderColor: 'border.main',
            color: 'text.main',
          },
          '& .MuiList-root': {
            padding: 0,
          },
        }}
      >
        {Actions?.map((item, i) => {
          if (item.name === 'Delete') {
            return (
              <ConfirmationButton
                key={i}
                onConfirm={() => {
                  if (item.onClick) {
                    item.onClick(selectedRow, 'TableDropDown'); // Safe call
                  }
                  setAnchorEl(null);
                }}
                context='TableDropDown'
                row={selectedRow}
                name={item.name}
                icon={item.icon}
              />
            );
          }
          return (
            <MuiMenuItem
              onClick={() => {
                item.onClick(item, selectedRow);
                setAnchorEl(null);
              }}
              key={i}
              sx={{
                py: 0.8,
                color: 'inherit',
                bgcolor: item.danger ? 'red' : '',
                fontWeight: 700,
                '& svg': {
                  fontSize: '16px',
                },
                '&:hover': {
                  bgcolor: 'background.light',
                },
              }}
              name={item.name}
              icon={item.icon}
              index={i}
            ></MuiMenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default TableDropDown;
