import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import Icons from '../../../../utils/Icon.jsx';
import { Box, Button } from '@mui/material';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { PurchaseVoucherSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import MRTIconButton from '../../../../components/table/MRTIconButton.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import apiInstance from '../../../../apis/ApiService.jsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';

const BoldBox = ({ title }) => {
  return <Box>{title}</Box>;
};

const PurchaseVoucherList = (props) => {
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = props;
  const [editingRowData, setEditingRowData] = useState<any>(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const crud = new Crud_Service();
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(PurchaseVoucherSchema),
    mode: 'onChange',
  });

  const handleEditItem = async (values) => {
    const data = {
      ...values,
      status: 2,
      purchaseVoucherDate: dayjs(values?.purchaseVoucherDate).format(
        'YYYY-MM-DD'
      ),
      paymentDueDate: dayjs(values?.paymentDueDate).format('YYYY-MM-DD'),
      invoiceDate: dayjs(values?.invoiceDate).format('YYYY-MM-DD'),
    };

    await crud.update(
      'purchasevouchers',
      editingRowData?.purchaseVoucherId,
      data,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Purchase Voucher Updated Successfully');
          fetchData();
          setEditingRowId(null);
          setEditingRowData(null);
        } else {
        }
      }
    );
  };

  const handleDeleteVoucher = async (view) => {
    await crud.remove(
      'purchasevouchers',
      view?.purchaseVoucherId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Purchase Voucher Deleted Successfully');
        } else {
        }
      }
    );
  };

  const handleEdit = (id) => {
    navigate(`edit/${id}`);
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props.purchaseVoucherId);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteVoucher(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.purchaseVoucherId ? (
            <>
              <Button onClick={handleSubmit(handleEditItem)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },

    {
      accessorKey: 'purchaseVoucherName',
      header: 'Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmTextField
            name='purchaseVoucherName'
            control={control}
            label='Name'
          />
        ) : (
          <BoldBox title={order?.purchaseVoucherName} />
        );
      },
    },
    {
      accessorKey: 'purchaseVoucherCode',
      header: 'Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmTextField
            name='purchaseVoucherCode'
            control={control}
            label='Code'
          />
        ) : (
          <BoldBox title={order?.purchaseVoucherCode} />
        );
      },
    },
    {
      accessorKey: 'purchaseVoucherDate',
      header: 'Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.purchaseVoucherId ? (
          <FmDatePicker
            name='purchaseVoucherDate'
            control={control}
            label='Date'
          />
        ) : row?.original?.purchaseVoucherDate ? (
          <FmViewDateField value={row?.original?.purchaseVoucherDate} />
        ) : (
          ''
        );
      },
    },

    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders'
            valueField='salesOrderId'
            defaultValue={editingRowData}
            labelField={['salesOrderCode', 'salesOrderName']}
            showField={['salesOrderName']}
            label={'Sales Order'}
          />
        ) : (
          <BoldBox title={order?.salesOrderName} />
        );
      },
    },
    {
      accessorKey: 'vendorId',
      header: 'Vendor',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='vendorId'
            control={control}
            apiUrl='suppliers'
            valueField='supplierId'
            labelField={['supplierCode', 'officialSupplierName']}
            showField={['supplierCode', 'officialSupplierName']}
            label={'Vendor'}
            defaultValue={{
              officialSupplierName: cell?.row?.original?.vendorName,
            }}
          />
        ) : (
          <BoldBox title={order?.vendorName} />
        );
      },
    },
    {
      accessorKey: 'currencyId',
      header: 'Currency',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='currencyId'
            control={control}
            apiUrl='currencies'
            valueField='currencyId'
            defaultValue={editingRowData}
            labelField={['currencyName']}
            showField={['currencyName']}
            label={'Currency'}
          />
        ) : (
          <BoldBox title={order?.currencyName} />
        );
      },
    },
    {
      accessorKey: 'locationId',
      header: 'Location',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            valueField='locationId'
            defaultValue={editingRowData}
            labelField={['locationCode', 'locationName']}
            showField={['locationName']}
            label={'Location'}
          />
        ) : (
          <BoldBox title={order?.locationName} />
        );
      },
    },
    {
      accessorKey: 'supplyLocationId',
      header: 'Supply Location',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='supplyLocationId'
            control={control}
            apiUrl='locations'
            valueField='supplyLocationId'
            defaultValue={editingRowData}
            labelField={['locationCode', 'locationName']}
            showField={['locationName']}
            label={'Supply Location'}
          />
        ) : (
          <BoldBox title={order?.supplyLocationName} />
        );
      },
    },
    {
      accessorKey: 'companyId',
      header: 'Company',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            defaultValue={editingRowData}
            labelField={['companyCode', 'companyName']}
            showField={['companyName']}
            label={'Company'}
          />
        ) : (
          <BoldBox title={order?.companyName} />
        );
      },
    },
    {
      accessorKey: 'inCharge',
      header: 'In Charge',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='inCharge'
            control={control}
            apiUrl='loginUsers'
            valueField='loginUserId'
            defaultValue={{
              loginUserName: order?.inChargeUserName,
            }}
            labelField={['loginUserName', 'loginUserCode']}
            showField={['loginUserName']}
            label={'In Charge'}
          />
        ) : (
          <BoldBox title={order?.inChargeUserName} />
        );
      },
    },
    {
      accessorKey: 'requestorId',
      header: 'Requestor',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='requestorId'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            defaultValue={{
              employeeName: order?.requestorName,
            }}
            labelField={['employeeCode', 'employeeName']}
            showField={['employeeName']}
            label={'Requestor'}
          />
        ) : (
          <BoldBox title={order?.requestorName} />
        );
      },
    },

    {
      accessorKey: 'paymentTermId',
      header: 'Payment Terms',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='paymentTermId'
            control={control}
            apiUrl='paymentTerms'
            valueField='paymentTermId'
            defaultValue={editingRowData}
            labelField={['paymentTermCode', 'paymentTermName']}
            showField={['paymentTermName']}
            label={'Payment Terms'}
          />
        ) : (
          <BoldBox title={order?.paymentTermName} />
        );
      },
    },
    {
      accessorKey: 'paymentTypeId',
      header: 'Payment Type',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='paymentTypeId'
            control={control}
            apiUrl='paymentTypes'
            valueField='paymentTypeId'
            defaultValue={editingRowData}
            labelField={['paymentTypeCode', 'paymentTypeName']}
            showField={['paymentTypeName']}
            label={'Payment Type'}
          />
        ) : (
          <BoldBox title={order?.paymentTypeName} />
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            defaultValue={editingRowData}
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionName']}
            label={'Division'}
          />
        ) : (
          <BoldBox title={order?.divisionName} />
        );
      },
    },
    {
      accessorKey: 'approvedBy',
      header: 'Approved By',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmSearchableSelect
            name='approvedBy'
            control={control}
            apiUrl='users'
            valueField='userId'
            defaultValue={{
              userName: order?.approvedByName,
            }}
            labelField={['userCode', 'userName']}
            showField={['userName']}
            label={'Approved By'}
          />
        ) : (
          <BoldBox title={order?.approvedByName} />
        );
      },
    },
    {
      accessorKey: 'paymentDueDate',
      header: 'Payment Due Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.purchaseVoucherId ? (
          <FmDatePicker
            name='paymentDueDate'
            control={control}
            label='Payment Due Date'
          />
        ) : row?.original?.paymentDueDate ? (
          <FmViewDateField
            name='paymentDueDate'
            value={row?.original?.paymentDueDate}
          />
        ) : (
          ''
        );
      },
    },

    {
      accessorKey: 'invoiceDate',
      header: 'Invoice Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.purchaseVoucherId ? (
          <FmDatePicker
            name='invoiceDate'
            control={control}
            label='Invoice Date'
          />
        ) : row?.original?.invoiceDate ? (
          <FmViewDateField
            name='invoiceDate'
            value={row?.original?.invoiceDate}
          />
        ) : (
          ''
        );
      },
    },
    {
      accessorKey: 'invoiceNo',
      header: 'Invoice No',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmTextField name='invoiceNo' control={control} label='Invoice No' />
        ) : (
          <BoldBox title={order?.invoiceNo} />
        );
      },
    },
    {
      accessorKey: 'billStatus',
      header: 'Bill Status',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmTextField
            name='billStatus'
            control={control}
            label='Bill Status'
          />
        ) : (
          <BoldBox title={order?.billStatus} />
        );
      },
    },
    {
      accessorKey: 'exchangeRate',
      header: 'Exchange Rate',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        const options = { maximumFractionDigits: 2 };
        const formattedNumber = Intl.NumberFormat('en-AE', options).format(
          order?.exchangeRate
        );
        return (
          <Box className='flex justify-between'>
            <BoldBox title={'AED'} />
            <BoldBox title={formattedNumber} />
          </Box>
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return editingRowId === cell?.row?.original?.purchaseVoucherId ? (
          <FmTextField name='remarks' control={control} label='Remarks' />
        ) : (
          <BoldBox title={order?.remarks} />
        );
      },
    },
    {
      accessorKey: 'fileId',
      header: 'File',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
        );
        return (
          <MRTIconButton
            icon={<Icon icon='akar-icons:download' />}
            onClick={() => {
              if (order?.fileId) {
                try {
                  const imageUrl = apiInstance.getFiles(
                    `files/download/${order?.fileId}`
                  );
                  const link = document.createElement('a');
                  link.href = imageUrl;
                  link.setAttribute('download', `${order?.fileName}`);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                } catch (error) {
                  toast.error('File not found');
                }
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <GlassCard className='h-full'>
        <FmMRTDataTable
          columns={columns}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          handleRowDoubleClick={(ev) =>
            handleEdit(ev.original.purchaseVoucherId)
          }
        />
      </GlassCard>
    </>
  );
};

export default PurchaseVoucherList;
