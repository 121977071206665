import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FmMRTDataTable from '../../../../../../components/FmMRTDataTable.tsx';
import { Box, Button } from '@mui/material';
import TableActionButtons from '../../../../../../components/_form/TableActionButtons';
import FmTextField from '../../../../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../../../../components/_mui/FmAutoComplete.tsx';
import { YesNoOptions } from '../../../../../../utils/CommonVariables.tsx';

const BoldBox = ({ title }) => {
  return <Box className='font-semibold'>{title}</Box>;
};
const TypeOfAccidentDataTable = (props) => {
  const {
    rows,
    setRows,
    tableRecordCounts,
    editingRowId,
    editingRowData,
    ActionData,
    handleCancel,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    crud,
    handleupdateTypeOfAccident,
  } = props;
  const { handleSubmit, control, setValue, reset } = useForm();

  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.accidentStepsTakenId ? (
            <>
              <Button onClick={handleSubmit(handleupdateTypeOfAccident)}>
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },

    {
      accessorKey: 'typeOfAccident',
      header: 'Type Of Accident',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.accidentStepsTakenId ? (
          <FmTextField
            name='typeOfAccident'
            control={control}
            defaultValue={editingRowData?.typeOfAccident}
          />
        ) : (
          <BoldBox title={row?.original?.typeOfAccident} />
        );
      },
    },
    {
      accessorKey: 'accidentStepsTakenDetail',
      header: 'Accident Steps Taken',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.accidentStepsTakenId ? (
          <FmTextField
            name='accidentStepsTakenDetail'
            control={control}
            defaultValue={editingRowData?.accidentStepsTakenDetail}
          />
        ) : (
          row?.original?.accidentStepsTakenDetail
        );
      },
    },
    {
      accessorKey: 'clientInformedStatusId',
      header: 'Client Informed Status',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.accidentStepsTakenId ? (
          <FmAutoComplete
            name='clientInformedStatusId'
            label='Client Informed Status'
            control={control}
            options={YesNoOptions}
            displayField='label'
            optionFields={['label']}
            valueKey='value'
          />
        ) : (
          rows?.find((item) => item?.clientInformedStatusId === cell.getValue())
            ?.clientOutformedStatusName || ''
        );
      },
    },
  ];

  return (
    <div>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </div>
  );
};

export default TypeOfAccidentDataTable;
