import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { ListItemButton } from '@mui/material';
import { Icon } from '@iconify/react';
import ModalWithSearch from './helpers/ModalWithSearch';
import { Nav } from '../utils';
import { Link } from 'react-router-dom';

const SearchModal = ({ openprop, onCloseprop }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [data] = useState(Nav);
  const listInnerRef = useRef();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter the data for both parent and child exact matches
  const filteredData = [];

  data.forEach((item) => {
    if (item?.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
      filteredData.push({
        name: item.name,
        path: item.path,
        parent: true,
        parentIcon: item.icon,
      });
    }

    const matchingChildren = item?.child?.filter((child) =>
      child?.name?.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    if (matchingChildren?.length > 0) {
      matchingChildren.forEach((child) => {
        filteredData.push({
          name: child.name,
          path: child.path,
          parent: false,
          parentName: item.name,
          parentIcon: item.icon,
        });
      });
    }
  });

  // Scroll to the first filtered item
  useEffect(() => {
    if (filteredData.length > 0 && listInnerRef.current) {
      listInnerRef.current.scrollTo(0, 0); // Scroll to the top initially
    }
  }, [filteredData]);

  // Component to display when no results are found
  const NoSearch = () => {
    return (
      <Box
        className='flex flex-col items-center justify-center text-center'
        sx={{ minHeight: 400 }}
      >
        <Box className='text-6xl opacity-50'>
          <Icon icon='fluent-mdl2:folder-search' />
        </Box>
        <p className='text-xl mt-4 font-light'>
          <span className='opacity-50'>No results found for </span>
          <span className='font-bold'>{searchTerm}</span>
        </p>
      </Box>
    );
  };

  // Component to render individual search results
  const ItemLoop = ({ item }) => {
    return (
      <ListItemButton
        sx={{
          py: 1.5,
          '& .hide-shown': { opacity: 0, transition: '0.5s all' },
          '&:hover .hide-shown': { opacity: 1 },
          '&:hover': {
            bgcolor: 'background.light',
          },
        }}
        component={Link}
        to={item.path}
      >
        <div className='text-2xl me-5 opacity-50'>{item.parentIcon}</div>
        <div className='flex-1'>
          <p className='font-bold'>
            {item.parent ? item.name : `${item.parentName} > ${item.name}`}
          </p>
        </div>
        <div className='text-xl me-2 hide-shown'>
          <Icon icon='lucide:external-link' />
        </div>
      </ListItemButton>
    );
  };

  return (
    <>
      <ModalWithSearch
        open={openprop}
        onClose={onCloseprop}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        disableRestoreFocus
        searchValue={searchTerm}
        searchHandleChange={handleSearchChange}
        searchPlaceholder='Search'
        modalWidth={550}
      >
        <Box
          sx={{
            height: '400px',
            overflow: 'auto',
          }}
          ref={listInnerRef}
        >
          <ul>
            {filteredData.length === 0 ? (
              <li>
                <NoSearch />
              </li>
            ) : (
              filteredData?.map((item, index) => (
                <li key={index}>
                  <ItemLoop item={item} />
                </li>
              ))
            )}
          </ul>
        </Box>
      </ModalWithSearch>
    </>
  );
};

SearchModal.propTypes = {
  onCloseprop: PropTypes.func,
  openprop: PropTypes.bool,
};

export default SearchModal;
