import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import DashboardLayout from '../../components/DashboardLayout.tsx';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UnderConstruction = () => {
  const navigation = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'Under Construction';
  }, []);

  return (
    <DashboardLayout>
      <Box
        className='w-full  flex justify-center items-center flex-row text-sm'
        sx={{ minHeight: 'calc(100vh - 120px)' }}
      >
        <Box sx={{ maxWidth: 500 }}>
          <Box
            component='img'
            src='/images/under_cons.png'
            sx={{ filter: 'grayscale(1)' }}
          />

          <Box className='text-center'>
            <Box className='font-bold text-base'> Under Construction </Box>
            <Box className='opacity-80 my-4'>
              Our page is currently undergoing scheduled maintenance. We Should
              be back shortly. Thank you for your patience.
            </Box>
            <Button
              onClick={() => navigation(-1)}
              variant='contained'
              className='px-10'
            >
              {t('back_top_page')}
            </Button>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default UnderConstruction;
