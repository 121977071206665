import React, { useEffect } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import ListSalesRetention from './ListSalesRetention.tsx';
import AddSalesRetention from './AddSalesRetention.tsx';
import { useLocation } from 'react-router-dom';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';

const SalesRetention = () => {
  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('salesretentions');
  const location = useLocation();
  const getTitleName = Nav[7].child[3].children;
  const getNewTitle = getTitleName.filter(
    (one) => one.path === location.pathname
  );

  useEffect(() => {
    document.title = `${getNewTitle[0].name} | Accounts Payable | Financial`;
  }, []);

  return (
    <>
      <DashboardLayout
        title={getNewTitle[0].name}
        hasSubmenu
        menu={Nav[7].child[3].children}
      >
        <AddSalesRetention fetchData={fetchData} />
        <ListSalesRetention
          rows={rows}
          tableRecordCounts={tableRecordCounts}
          pagination={pagination}
          sorting={sorting}
          fetchData={fetchData}
          setPagination={setPagination}
          setSorting={setSorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword} />
      </DashboardLayout>
    </>
  );
};

export default SalesRetention;
