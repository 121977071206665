/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Autocomplete } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ActionButtons } from '../../../components/_form';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { EstimationMEPSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import MuiDatePickerOne from '../../../components/_mui/MuiDatePickerOne.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';

const CreateEstimationSheetForMEP = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [loginUsers, setLoginUser] = useState([]);
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EstimationMEPSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    getProjects();
    getClients();
    getBuildings();
    getLoginUsers();
  }, []);

  //get All Projects
  const getProjects = async () => {
    await crud.getAll(`projects`, '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setProjects(res?.data?.data);
      }
    });
  };

  //get All Clients
  const getClients = async () => {
    await crud.getAll('clients', '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setClients(res?.data?.data);
      }
    });
  };

  //get All Login Users
  const getLoginUsers = async () => {
    await crud.getAll('loginusers', '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setLoginUser(res?.data?.data);
      }
    });
  };

  //get All Buildings
  const getBuildings = async () => {
    await crud.getAll('buildings', '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setBuildings(res?.data?.data);
      } else {
      }
    });
  };

  //Create For Estimation Sheets AMC
  const handleSubmitAMC = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      dateOfEnquiry: dayjs(values?.dateOfEnquiry).format('YYYY-MM-DD'),
      contractPeriod: Number(values?.contractPeriod),
      otherPercent: Number(values?.otherPercent),
      discountOverAllPercent: Number(values?.discountOverAllPercent),
    };

    await crud.create('estimationmepsheets', combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Estimation Sheet MEP Created Successfully');
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();

      }
    });
  };

  return (
    <>
      <DashboardLayout title='Create New Estimation Sheet MEP'>
        <Box className='mt-10 mx-auto' sx={{ maxWidth: '800px' }}>
          <GlassCard className='p-5'>
            <form onSubmit={handleSubmit(handleSubmitAMC)}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='tenderId'
                      control={control}
                      apiUrl='tenders'
                      valueField='tenderId'
                      headerField={['Code', 'Name']}
                      labelField={['tenderCode', 'tenderName']}
                      showField={['tenderName']}
                      pageSize={20}
                      label='Tenders'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name='projectId'
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={projects}
                          getOptionLabel={(option) => option?.projectName}
                          onChange={(event, value) =>
                            field.onChange(value?.projectId)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Projects'
                              variant='filled'
                              fullWidth
                              error={Boolean(errors.projectId)} // Set error prop
                              helperText={
                                errors.projectId ? errors.projectId.message : ''
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name='clientId'
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={clients}
                          getOptionLabel={(option) => option?.clientName}
                          onChange={(event, value) =>
                            field.onChange(value?.clientId)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Clients'
                              variant='filled'
                              fullWidth
                              required
                              error={Boolean(errors.clientId)} // Set error prop
                              helperText={
                                errors.clientId ? errors.clientId.message : ''
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={6}>
                    <Controller
                      name='buildingId'
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={buildings}
                          getOptionLabel={(option) => option?.buildingName}
                          onChange={(event, value) =>
                            field.onChange(value?.buildingId)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Buildings'
                              variant='filled'
                              fullWidth
                              required
                              error={Boolean(errors.buildingId)} // Set error prop
                              helperText={
                                errors.buildingId
                                  ? errors.buildingId.message
                                  : ''
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmDatePicker
                      name='dateOfEnquiry'
                      label='Date Of Enquiry'
                      control={control}
                      required
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmTextField
                      name='contractPeriod'
                      control={control}
                      label='Contract Period'
                      required
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmTextField
                      name='otherPercent'
                      control={control}
                      label='Other Percent(%)'
                      defaultValue={0}
                      pattern='Percentage'
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmTextField
                      name='discountOverAllPercent'
                      control={control}
                      label='Discount Percent(%)'
                      defaultValue={0}
                      pattern='Percentage'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='existContract'
                      control={control}
                      label='Exist Contract'
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='siteVisitDoneBy'
                      control={control}
                      apiUrl='employees'
                      valueField='employeeId'
                      headerField={['Employee Code', 'Employee Name']}
                      labelField={['employeeCode', 'firstName']}
                      showField={['employeeCode', 'firstName']}
                      pageSize={20}
                      label='Site Vist Done BY'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name='preparedBy'
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={loginUsers}
                          getOptionLabel={(option) => option?.loginUserName}
                          onChange={(event, value) =>
                            field.onChange(value?.loginUserId)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Prepared By'
                              variant='filled'
                              fullWidth
                              required
                              error={Boolean(errors.preparedBy)} // Set error prop
                              helperText={
                                errors.preparedBy
                                  ? errors.preparedBy.message
                                  : ''
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <ActionButtons
                  onSubmit={handleSubmit(handleSubmitAMC)}
                  onCancel={() => navigate(-1)}
                  onReset={reset}
                  submitLoading={createLoading}
                  cancelLoading={false}
                />
              </LocalizationProvider>
            </form>
          </GlassCard>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default CreateEstimationSheetForMEP;
