import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FmAutoComplete from '../../../../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../../../components/_form/ActionButtons.tsx';
import UploadModalBox from '../../../../../../components/upload/UploadModalBox.tsx';
import dayjs from 'dayjs';
import Crud_Service from '../../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { BooleanOptions } from '../../../../../../utils/CommonVariables.tsx';
import useLoader from '../../../../../../components/helpers/UseLoader.tsx';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { yupResolver } from '@hookform/resolvers/yup';
import { InjuriesValidationSchema } from '../../../../../../utils/CommonvalidationSchemas.tsx';
import FmSearchableSelect from '../../../../../../components/_mui/FmSearchableSelect.tsx';

const MdItemValue = 2;
const AddInjuries = (props) => {
  const { fetchData } = props;
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      dateOfSafetyInduction: dayjs(),
    },
    resolver: yupResolver(InjuriesValidationSchema),
    mode: 'onChange',
  });
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();
  const [files, setFiles] = useState([]);

  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };

  const handleSUbmitInjuries = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      dateOfSafetyInduction: dayjs(values?.dateOfSafetyInduction).format(
        'YYYY-MM-DD'
      ),
      fileUploadIds: uploadedFileIds,
      incidentReportId: 4,
    };

    await crud.create('injuriesdetails', combinedData, (err, res) => {
      if (res?.status === 201) {
        stopCreateLoading();
        toast.success('Injuries Created Successfully');
        reset();
        fetchData();
        setFiles([]);
        setUploadedFileIds([]);
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <div>
      <Box className='p-3'>
        <form onSubmit={handleSubmit(handleSUbmitInjuries)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='injuriesUserName'
                  control={control}
                  label='Injuries UserName'
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmSearchableSelect
                  name='employeeId'
                  control={control}
                  apiUrl='employees'
                  valueField='employeeId'
                  headerField={['Employee Code', 'Employee Name']}
                  labelField={['employeeCode', 'firstName']}
                  showField={['firstName']}
                  pageSize={20}
                  label='Employees'
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmSearchableSelect
                  name='companyId'
                  control={control}
                  apiUrl='companies'
                  valueField='companyId'
                  headerField={['Company Code', 'Company Name']}
                  labelField={['companyCode', 'companyName']}
                  showField={['companyName']}
                  pageSize={20}
                  label='Companies'
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmSearchableSelect
                  name='positionId'
                  control={control}
                  apiUrl='positions'
                  valueField='positionId'
                  headerField={['Position Code', 'Position Name']}
                  labelField={['positionCode', 'positionName']}
                  showField={['positionName']}
                  pageSize={20}
                  label='Positions'
                />
              </Grid>

              <Grid item md={MdItemValue}>
                <Controller
                  name='dateOfSafetyInduction'
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label='Date of Safety Resumption'
                      value={value ? dayjs(value) : null}
                      format='DD/MM/YYYY'
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          variant: 'filled',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmAutoComplete
                  name='hadIPBriefing'
                  control={control}
                  options={BooleanOptions}
                  label='Had IP Briefing'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>
              <Grid item md={4}>
                <FmTextField
                  name='description'
                  control={control}
                  label='Description'
                  multiline={true}
                  rows={2}
                  maxRows={3}
                />
              </Grid>
              <Grid item md={4}>
                <FmTextField
                  name='remarks'
                  control={control}
                  label='Remarks'
                  multiline={true}
                  rows={2}
                  maxRows={3}
                />
              </Grid>

              <Grid item md={MdItemValue}>
                <UploadModalBox
                  onSubmitFiles={handleFileIds}
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleSUbmitInjuries)}
              onCancel={() => {
                reset();
                setFiles([]);
                setUploadedFileIds([]);
              }}
              submitLoading={createLoading}
            />
          </LocalizationProvider>
        </form>
      </Box>
    </div>
  );
};

export default AddInjuries;
