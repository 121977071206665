import React, { useState } from 'react';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import { Box, Button } from '@mui/material';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import { useForm } from 'react-hook-form';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';

const ListOwnVehicles = (props) => {
  const { control, setValue, handleSubmit } = useForm();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading } = useLoader();
  const crud = new Crud_Service();
  const {
    isLoading,
    pagination,
    rows,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;
  // const [editingRowId] = useState(null);

  // const columns = [
  //   {
  //     accessorKey: 'ownVehicleCode',
  //     header: 'Own Vehicle Code',
  //     width: 200,
  //   },
  //   { accessorKey: 'vehicleName', header: 'Vehicle Name', width: 200 },
  //   {
  //     accessorKey: 'vehicleBrandName',
  //     header: 'Vehicle Brand',
  //     width: 200,
  //   },
  //   {
  //     accessorKey: 'vehicleCategoryName',
  //     header: 'Vehicle Category',
  //     width: 200,
  //   },
  //   { accessorKey: 'vehicleTypeName', header: 'Vehicle Type', width: 200 },
  //   {
  //     accessorKey: 'vehiclePlateTypeName',
  //     header: 'Plate Type',
  //     width: 200,
  //   },
  //   { accessorKey: 'vehiclePlateNo', header: 'Plate Number', width: 200 },
  //   {
  //     accessorKey: 'vehicleRegisteredName',
  //     header: 'Registered Name',
  //     width: 200,
  //   },
  //   {
  //     accessorKey: 'presentlyLocatedName',
  //     header: 'Present Location',
  //     width: 200,
  //   },
  //   { accessorKey: 'vehicleModel', header: 'Vehicle Model', width: 200 },
  //   {
  //     accessorKey: 'registeredDate',
  //     header: 'Registered Date',
  //     width: 200,
  //   },
  //   {
  //     accessorKey: 'registeredExpiryDate',
  //     header: 'Registered Expiry Date',
  //     width: 200,
  //   },
  //   {
  //     accessorKey: 'insuranceExpiryDate',
  //     header: 'Insurance Expiry Date',
  //     width: 200,
  //   },
  //   { accessorKey: 'trafficCodeNo', header: 'Traffic Code No', width: 200 },
  //   { accessorKey: 'insuranceName', header: 'Insurance Name', width: 200 },
  //   { accessorKey: 'driverName', header: 'Driver Name', width: 200 },
  //   {
  //     accessorKey: 'driverContactNumber',
  //     header: 'Driver Contact Number',
  //     width: 200,
  //   },
  //   { accessorKey: 'divisionName', header: 'Division Name', width: 200 },
  //   { accessorKey: 'permitNumber', header: 'Permit Number', width: 200 },
  // ];
  const handleEdit = (row) => {
    setEditingRowId(row?.ownVehicleId);
    setEditingRowData({ ...row });
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });

  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleDelete = async (props) => {
    console.log(props, 'delete-own vehicle');
    await crud.remove(
      'ownvehicles',
      props?.ownVehicleId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Own Vehicle deleted successfully');
          fetchData();
        } else {
          toast.error('something Went Wrong!');
        }
      }
    );
  };

  // const handleUpdateEvent = async (values) => {
  //   try {
  //     startEditLoading();
  //     const combinedData = {
  //       ...values,
  //       Status: 2,
  //     };
  //     await crud.update(
  //       'ownvehicles',
  //       editingRowId,
  //       combinedData,
  //       (_err, res) => {
  //         if (res?.status === 200) {
  //           stopEditLoading();
  //           fetchData();
  //           toast.success('Own Vehicle Updated Successfully');
  //           setEditingRowData(null);
  //           setEditingRowId(null);
  //         } else {
  //           stopEditLoading();
  //           toast.error('Failed to update the own vehicle');
  //         }
  //       }
  //     );
  //   } catch (error) {
  //     stopEditLoading();
  //     toast.error('An error occurred while updating the own vehicle');
  //   }
  // };

  const handleUpdateEvent = async (values) => {
    const combinedData = {
      ...values,
      Status: 2,
    };
    await crud.update(
      'ownvehicles',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Own Vechicles updated successfully');
          fetchData();
          setEditingRowId(null);
          setEditingRowData(null);
          stopEditLoading();
        } else {

        }
      }
    );
  };


  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.ownVehicleId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateEvent)}>
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'vehicleId',
      header: 'Vehicle Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        const vehicle: any = rows?.find(
          (item) =>
            item?.ownVehicleId === cell?.row?.original?.ownVehicleId
        );
        return editingRowId === cell?.row?.original?.ownVehicleId ? (
          <FmSearchableSelect
            name='vehicleId'
            control={control}
            apiUrl='vehicles'
            valueField='vehicleId'
            headerField={['Vehicle Name']}
            labelField={['vehicleName']}
            showField={['vehicleName']}
            pageSize={20}
            label='Vehicle'
            defaultValue={{ vehicleId: editingRowData?.vehicleId, vehicleName: editingRowData?.vehicleName }}
          />

        ) : (
          vehicle?.vehicleName || ''
        );
      },
    },

    {
      accessorKey: 'trafficCodeNo',
      header: 'Traffic Code No',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.ownVehicleId ? (
          <FmTextField
            name='trafficCodeNo'
            control={control}
            defaultValue={editingRowData?.trafficCodeNo}

          />
        ) : (
          cell?.row?.original?.trafficCodeNo
        );
      },
    },
    {
      accessorKey: 'insuranceId',
      header: 'Insurance',
      enableEditing: true,
      Cell: ({ cell }) => {
        const vehicle: any = rows?.find(
          (item) =>
            item?.ownVehicleId === cell?.row?.original?.ownVehicleId
        );
        return editingRowId === cell?.row?.original?.ownVehicleId ? (
          <FmSearchableSelect
            name='insuranceId'
            control={control}
            apiUrl='vehicleinsurances'
            valueField='insuranceId'
            headerField={[' Insurance Name']}
            labelField={['insuranceName']}
            showField={['insuranceName']}
            pageSize={20}
            label='Vehicle'
            defaultValue={{ insuranceId: editingRowData?.insuranceId, insuranceName: editingRowData?.insuranceName }}
          />

        ) : (
          vehicle?.insuranceName || ''
        );
      },
    },
    {
      accessorKey: 'driverId',
      header: 'Driver',
      enableEditing: true,
      Cell: ({ cell }) => {
        const vehicle: any = rows?.find(
          (item) =>
            item?.ownVehicleId === cell?.row?.original?.ownVehicleId
        );
        return editingRowId === cell?.row?.original?.ownVehicleId ? (
          <FmSearchableSelect
            name='driverId'
            label='Driver'
            apiUrl={'drivers'}
            required
            valueField='driverId'
            labelField={['driverCode', 'driverName']}
            showField={['driverCode', 'driverName']}
            control={control}
            defaultValue={{ driverId: editingRowData?.driverId, driverName: editingRowData?.driverName }}
          />

        ) : (
          vehicle?.driverName || ''
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ cell }) => {
        const vehicle: any = rows?.find(
          (item) =>
            item?.ownVehicleId === cell?.row?.original?.ownVehicleId
        );
        return editingRowId === cell?.row?.original?.ownVehicleId ? (
          <FmSearchableSelect
            name='divisionId'
            label='Division'
            apiUrl={'divisions'}
            required
            valueField='divisionId'
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            control={control}
            defaultValue={{ divisionId: editingRowData?.divisionId, divisionName: editingRowData?.divisionName }}
          />

        ) : (
          vehicle?.divisionName || ''
        );
      },
    },
    {
      accessorKey: 'vehicleAdvertisementId',
      header: 'Advertisement',
      enableEditing: true,
      Cell: ({ cell }) => {
        const vehicle: any = rows?.find(
          (item) =>
            item?.ownVehicleId === cell?.row?.original?.ownVehicleId
        );
        return editingRowId === cell?.row?.original?.ownVehicleId ? (
          <FmSearchableSelect
            name='vehicleAdvertisementId'
            label='Advertisement'
            apiUrl={'divisions'}
            required
            valueField='vehicleAdvertisementId'
            labelField={['vehicleAdvertisementCode', 'permitNumber']}
            showField={['vehicleAdvertisementCode', 'permitNumber']}
            control={control}
            defaultValue={{ vehicleAdvertisementId: editingRowData?.vehicleAdvertisementId, permitNumber: editingRowData?.permitNumber }}
          />

        ) : (
          vehicle?.permitNumber || ''
        );
      },
    },

  ];
  return (
    <FmMRTDataTable
      rows={rows}
      columns={columns}
      enableRowSelection={false}
      rowCount={tableRecordCounts}
      editingRow={editingRowId}
      pagination={pagination}
      sorting={[]}
      columnFilters={columnFilters}
      globalFilter={globalFilter}
      setPagination={setPagination}
      setSorting={setSorting}
      setColumnFilters={setColumnFilters}
      setGlobalFilter={setGlobalFilter}
      isLoading={isLoading}
      setSearchKeyword={setSearchKeyword}
      searchKeyword={searchKeyword}
    />
  );
};

export default ListOwnVehicles;
