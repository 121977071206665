import { lazy, useEffect } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import AddAccomCamps from './pages/Admin/Accommodation/AccomCamps/AddAccomCamps.tsx';
import EditAccomCamps from './pages/Admin/Accommodation/AccomCamps/EditAccomCamps.tsx';
import ViewAccomCamps from './pages/Admin/Accommodation/AccomCamps/ViewAccomCamps.tsx';
import AddAccommodation from './pages/Admin/Accommodation/AddAccommodation.tsx';
import EditAccommodation from './pages/Admin/Accommodation/EditAccommodation.tsx';
import ViewAccommodation from './pages/Admin/Accommodation/ViewAccommodation.tsx';
import Drivers from './pages/Admin/AllMasters/Drivers.tsx';
import Floor from './pages/Admin/AllMasters/Floor.tsx';
import Level from './pages/Admin/AllMasters/Level.tsx';
import TrafficSource from './pages/Admin/AllMasters/TrafficSource.tsx';
import VehicleAdvertisement from './pages/Admin/AllMasters/VehicleAdvertisement.tsx';
import VehicleBrands from './pages/Admin/AllMasters/VehicleBrands.tsx';
import VehicleCategory from './pages/Admin/AllMasters/VehicleCategory.tsx';
import VehicleInsurance from './pages/Admin/AllMasters/VehicleInsurance.tsx';
import Vehicles from './pages/Admin/AllMasters/Vehicles.tsx';
import VehicleStatuses from './pages/Admin/AllMasters/VehicleSatuses.tsx';
import VehicleSuppliers from './pages/Admin/AllMasters/VehicleSuppliers.tsx';
import AddCompanyInsurancePolicy from './pages/Admin/CompanyInsurancePolicy/AddCompanyInsurancePolicy.tsx';
import AddComparisonPolicy from './pages/Admin/CompanyInsurancePolicy/ComparisonPolicy/AddComparisonPolicy.tsx';
import EditComparisonPolicy from './pages/Admin/CompanyInsurancePolicy/ComparisonPolicy/EditComparisonPolicy.tsx';
import ViewComparisonPolicy from './pages/Admin/CompanyInsurancePolicy/ComparisonPolicy/ViewComparisonPolicy.tsx';
import EditCompanyInsurancePolicy from './pages/Admin/CompanyInsurancePolicy/EditCompanyInsurancePolicy.tsx';
import ViewCompanyInsurancePolicy from './pages/Admin/CompanyInsurancePolicy/ViewCompanyInsurancePolicy.tsx';
import EditCompanyMedicalInsurancePolicy from './pages/Admin/CompanyMedicalInsurancePolicy/CompanyMedicalInsurancePolicy/EditCompanyMedicalInsurancePolicy.tsx';
import ViewCompanyMedicalInsurancePolicy from './pages/Admin/CompanyMedicalInsurancePolicy/CompanyMedicalInsurancePolicy/ViewCompanyMedicalInsurancePolicy.tsx';
import AddEmployeeWiseMIPCalculator from './pages/Admin/CompanyMedicalInsurancePolicy/EmployeeWiseMIPCalculator/AddEmployeeWiseMIPCalculator.tsx';
import EditEmployeeWiseMIPCalculator from './pages/Admin/CompanyMedicalInsurancePolicy/EmployeeWiseMIPCalculator/EditEmployeeWiseMIPCalculator.tsx';
import ViewEmployeeWiseMIPCalculator from './pages/Admin/CompanyMedicalInsurancePolicy/EmployeeWiseMIPCalculator/ViewEmployeeWiseMIPCalculator.tsx';
import AddMIPPremiumCalculator from './pages/Admin/CompanyMedicalInsurancePolicy/MIPPremiumCalculator/AddMIPPremiumCalculator.tsx';
import EditMIPPremiumCalculator from './pages/Admin/CompanyMedicalInsurancePolicy/MIPPremiumCalculator/EditMIPPremiumCalculator.tsx';
import ViewMIPPremiumCalculator from './pages/Admin/CompanyMedicalInsurancePolicy/MIPPremiumCalculator/ViewMIpPremiumCalculator.tsx';
import AddEventsCalendar from './pages/Admin/EventsCalendar/AddEventsCalendar.tsx';
import EditEventsCalendar from './pages/Admin/EventsCalendar/EditEventsCalendar.tsx';
import AddUniformRequistions from './pages/Admin/UniformAccessories/AddUniformRequistions.tsx';
import EditUniformRequisition from './pages/Admin/UniformAccessories/EditUniformRequisition.tsx';
import ViewUniformRequisition from './pages/Admin/UniformAccessories/ViewUniformRequisition.tsx';
import AddUniformReturn from './pages/Admin/uniformReturn/AddUniformReturn.tsx';
import EditUniformReturn from './pages/Admin/uniformReturn/EditUniformReturn.tsx';
import UniformReturn from './pages/Admin/uniformReturn/UniformReturn.tsx';
import ViewUniformReturn from './pages/Admin/uniformReturn/ViewUnifromReturn.tsx';
import AddUniforms from './pages/Admin/uniforms/AddUniforms.tsx';
import EditUniforms from './pages/Admin/uniforms/EditUniforms.tsx';
import Uniforms from './pages/Admin/uniforms/uniforms.tsx';
import ViewUniforms from './pages/Admin/uniforms/ViewUniforms.tsx';
import AddAdvertisement from './pages/Admin/Vehicles/Advertisement/AddAdvertisement.tsx';
import Advertisement from './pages/Admin/Vehicles/Advertisement/Advertisement.tsx';
import EditAdvertisement from './pages/Admin/Vehicles/Advertisement/EditAdvertisement.tsx';
import ViewAdvertisement from './pages/Admin/Vehicles/Advertisement/ViewAdvertisement.tsx';
import Asateel from './pages/Admin/Vehicles/Asateel.tsx';
import AddHiredVechicles from './pages/Admin/Vehicles/hiredVechicles/AddHiredVechicles.tsx';
import EditHiredVehicles from './pages/Admin/Vehicles/hiredVechicles/EditHiredVehicles.tsx';
import HiredVechicles from './pages/Admin/Vehicles/hiredVechicles/Hiredvechicles.tsx';
import ViewHiredOwnVehicles from './pages/Admin/Vehicles/hiredVechicles/ViewHiredVehicles.tsx';
import AddMonthUpdate from './pages/Admin/Vehicles/MonthUpdates/AddMonthUpdate.tsx';
import EditMonthUpdate from './pages/Admin/Vehicles/MonthUpdates/EditMonthUpdate.tsx';
import MonthUpdate from './pages/Admin/Vehicles/MonthUpdates/MonthUpdate.tsx';
import ViewMonthUpdate from './pages/Admin/Vehicles/MonthUpdates/ViewMonthUpdate.tsx';
import AddOwnVehicle from './pages/Admin/Vehicles/Ownvechicles/AddOwnVehicle.tsx';
import EditOwnVehicles from './pages/Admin/Vehicles/Ownvechicles/EditOwnVehicles.tsx';
import OwnVehicles from './pages/Admin/Vehicles/Ownvechicles/OwnVehicles.tsx';
import ViewOwnvehicle from './pages/Admin/Vehicles/Ownvechicles/ViewOwnvehicle.tsx';
import AddIncidentReport from './pages/Cafm/IncidentReport/AddIncidentReport.tsx';
import IncidentReport from './pages/Cafm/IncidentReport/IncidentReport.tsx';
import ViewIncident from './pages/Cafm/IncidentReport/View/ViewIncident.tsx';
import ViewInjuries from './pages/Cafm/IncidentReport/View/ViewInjuries.tsx';
import ViewIncidentRemarks from './pages/Cafm/IncidentReport/View/ViewRemarks.tsx';
import AddInspectionReport from './pages/Cafm/InspectionReport/AddInspectionReport.tsx';
import InspectionReport from './pages/Cafm/InspectionReport/InspectionReport.tsx';
import ViewInspectionReport from './pages/Cafm/InspectionReport/View/ViewInspectionReport.tsx';
import StoreLocations from './pages/Procurement/inventory/storeLocations/StoreLocations.tsx';
import AddSnagReport from './pages/Cafm/SnagReport/AddSnagReport.tsx';
import AddCheckInForm from './pages/Cafm/SnagReport/CreateCheckInForm/AddCheckInForm.tsx';
import AddCheckOutForm from './pages/Cafm/SnagReport/CreateCheckInForm/AddCheckOutForm.tsx';
import CreateCheckInForm from './pages/Cafm/SnagReport/CreateCheckInForm/CreateCheckInForm.tsx';
import CreateCheckoutView from './pages/Cafm/SnagReport/CreateCheckInForm/View/CreateCheckOutView.tsx';
import ViewCreateCheckInForm from './pages/Cafm/SnagReport/CreateCheckOutForm/View/ViewCreateCheckInForm.tsx';
import SnagReport from './pages/Cafm/SnagReport/SnagReport.tsx';
import SnagTab from './pages/Cafm/SnagReport/View/SnagTab.tsx';
import ViewDetailTicket from './pages/Cafm/Ticket/ViewDetailTicket.tsx';
import AddSalesOrder from './pages/Cafm/WorkOrderMaster/CreateSalesOrder/AddSalesOrder.tsx';
import CreateSalesOrder from './pages/Cafm/WorkOrderMaster/CreateSalesOrder/CreateSalesOrder.tsx';
import AddCreateSalesQuotation from './pages/Cafm/WorkOrderMaster/CreateSalesQuotation/AddCreateSalesQuotation.tsx';
import CreateSalesQuotation from './pages/Cafm/WorkOrderMaster/CreateSalesQuotation/CreateSalesQuotation.tsx';
import ViewCreateSalesQuotation from './pages/Cafm/WorkOrderMaster/CreateSalesQuotation/View/ViewCreateSalesQuotation.tsx';
import ViewSalesQuotation from './pages/Cafm/WorkOrderMaster/CreateSalesQuotation/View/ViewSalesQuotation.tsx';
import CreateWorkOrder from './pages/Cafm/WorkOrderMaster/CreateWorkOrder/CreateWorkOrder.tsx';
import EditWorkOrder from './pages/Cafm/WorkOrderMaster/CreateWorkOrder/EditWorkOrder.tsx';
import ViewWorkOrder from './pages/Cafm/WorkOrderMaster/CreateWorkOrder/VIewWorkOrder.tsx';
import WorkOrder from './pages/Cafm/WorkOrderMaster/CreateWorkOrder/WorkOrder.tsx';
import Dashboard from './pages/Dashboard';
import UnderConstruction from './pages/ErrorPage/UnderConstruction.tsx';
import CreatePurchaseVoucher from './pages/Financial/AccountsPayable/PurchaseVoucher/CreatePurchaseVoucher.tsx';
import PurchaseVoucher from './pages/Financial/AccountsPayable/PurchaseVoucher/PurchaseVoucher.tsx';
import {
  default as EditPurchaseVoucherList,
  default as ViewPurchaseVoucherList,
} from './pages/Financial/AccountsPayable/PurchaseVoucher/View/EditPurchaseVoucherList.tsx';
import CreateSalesRetention from './pages/Financial/AccountsPayable/SalesRetention/CreateSalesRetention.tsx';
import EditSalesRetention from './pages/Financial/AccountsPayable/SalesRetention/EditSalesRetention.tsx';
import SalesRetention from './pages/Financial/AccountsPayable/SalesRetention/SalesRetention.tsx';
import SalesRetentionItem from './pages/Financial/AccountsPayable/SalesRetention/view/SalesRetentionItem.tsx';
import SalesInvoicesAR from './pages/Financial/AccountsReceivable/SalesInvoicesAR/SalesInvoicesAR.tsx';
import PaymentPendingBills from './pages/Financial/CashAndBank/Payments/PendingBills/PaymentsPendingBills.tsx';
import AddCashBankReceipt from './pages/Financial/CashAndBank/Receipts/AddCashBankReceipt.tsx';
import CashBankReceipts from './pages/Financial/CashAndBank/Receipts/CashBankReceipts.tsx';
import ViewCashBankReceipt from './pages/Financial/CashAndBank/Receipts/View/ViewCashBankReceipt.tsx';
import CBAList from './pages/Financial/CBA/CBAList.tsx';
import GLAList from './pages/Financial/GLA/GLAList.tsx';
import BloodGroupMasters from './pages/Hrms/AllMasters/BloodGroupMasters.tsx';
import CancellationTypeMasters from './pages/Hrms/AllMasters/CancellationTypeMasters.tsx';
import CertificateTypeMasters from './pages/Hrms/AllMasters/CertificateTypeMasters.tsx';
import ContractTypeMasters from './pages/Hrms/AllMasters/ContractTypeMasters.tsx';
import DesignationMasters from './pages/Hrms/AllMasters/DesignationMasters.tsx';
import Division from './pages/Hrms/AllMasters/Division.tsx';
import DocumentTypeCategoryMasters from './pages/Hrms/AllMasters/DocumentTypeCategoryMasters.tsx';
import DocumentTypeMasters from './pages/Hrms/AllMasters/DocumentTypeMasters.tsx';
import Emirates from './pages/Hrms/AllMasters/Emirates.tsx';
import ExperienceTypeMasters from './pages/Hrms/AllMasters/ExperienceTypeMasters.tsx';
import EmpAllMasters from './pages/Hrms/AllMasters/index.jsx';
import LocationMasters from './pages/Hrms/AllMasters/LocationMasters.tsx';
import NationalityMasters from './pages/Hrms/AllMasters/NationalityMasters.tsx';
import RegionMasters from './pages/Hrms/AllMasters/RegionMasters.tsx';
import AttendanceSummary from './pages/Hrms/EmployeeMaster/EmployeeAttendence/AttendanceSummary.jsx';
import CompanyCertificate from './pages/Hrms/EmployeeMaster/CompanyCertificates/CompanyCertificate.jsx';
import CreateCompanyCertificates from './pages/Hrms/EmployeeMaster/CompanyCertificates/CreateCompanyCertificate.jsx';
import EditCompanyCertificates from './pages/Hrms/EmployeeMaster/CompanyCertificates/EditCompanyCertificate.jsx';
import ViewCompanyCertificate from './pages/Hrms/EmployeeMaster/CompanyCertificates/ViewCompanyCertificate.tsx';
import EmployeeCreate from './pages/Hrms/EmployeeMaster/Create/Create';
import HRMSEmployeeMaster from './pages/Hrms/EmployeeMaster/HRMSEmployeeMaster.jsx';
import HRMSEmployeeMasterDashboard from './pages/Hrms/EmployeeMaster/HRMSEmployeeMasterDashboard.tsx';
import CreateShiftTime from './pages/Hrms/ShiftTimeMaster/CreateShiftTime.tsx';
import Login from './pages/Login';
import MedicalInsurancePolicies from './pages/Pr/AllMasters/CompanyInsurancePolicies.jsx';
import CompanyPolicies from './pages/Pr/AllMasters/CompanyPolicies.jsx';
import CompanyPolicyTypes from './pages/Pr/AllMasters/CompanyPolicyTypes.jsx';
import EndUsers from './pages/Pr/AllMasters/EndUsers.jsx';
import Insurer from './pages/Pr/AllMasters/Insurer.jsx';
import MedicalInsuranceAgeBands from './pages/Pr/AllMasters/MedicalInsuranceAgeBands.jsx';
import MedicalInsuranceDependencies from './pages/Pr/AllMasters/MedicalInsuranceDependencies.jsx';
import MedicalInsurancePremisePurpose from './pages/Pr/AllMasters/MedicalInsurancePremisePurpose.jsx';
import MedicalInsurancePremiseTypes from './pages/Pr/AllMasters/MedicalInsurancePremiseTypes.jsx';
import MedicalInsuranceSalaryBands from './pages/Pr/AllMasters/MedicalInsuranceSalaryBand.jsx';
import MedicalInsuranceStatus from './pages/Pr/AllMasters/MedicalInsuranceStatus.jsx';
import PolicyPremium from './pages/Pr/AllMasters/PolicyPremium.jsx';
import PremiseLocation from './pages/Pr/AllMasters/PremiseLocation.jsx';
import PremiseLocations from './pages/Pr/AllMasters/PremiseLocations.jsx';
import TypeOfProducts from './pages/Pr/AllMasters/TypeOfProducts.jsx';
import UniformSizes from './pages/Pr/AllMasters/UniformSizes.jsx';
import UniformTypes from './pages/Pr/AllMasters/UniformTypes.jsx';
import EIDApplication from './pages/Pr/Eid/EIDApplication.tsx';
import BillingFrequency from './pages/Presales/AllMasters/BillingFrequency.tsx';
import Branch from './pages/Presales/AllMasters/Branch.tsx';
import ContactModesMasters from './pages/Presales/AllMasters/ContactModes.tsx';
import ContactScopes from './pages/Presales/AllMasters/ContactScopes.tsx';
import CountryMaster from './pages/Presales/AllMasters/Country.tsx';
import Guarantees from './pages/Presales/AllMasters/Guarantees.tsx';
import Jurisdiction from './pages/Presales/AllMasters/Jurisdiction.tsx';
import PlaceOfSupply from './pages/Presales/AllMasters/PlaceOfSupply.tsx';
import ProjectTeamRole from './pages/Presales/AllMasters/ProjectTeamRole.tsx';
import SupplierTypes from './pages/Presales/AllMasters/SupplierTypes.tsx';
import TenderEnquiryTypes from './pages/Presales/AllMasters/TenderEnquiryTypes.tsx';
import TenderFollowUpTypesMasters from './pages/Presales/AllMasters/TenderFollowUpTypes.tsx';
import TenderFormats from './pages/Presales/AllMasters/TenderFormat.tsx';
import TenderProjectTypes from './pages/Presales/AllMasters/TenderProjectTypes.tsx';
import TenderStatusMasters from './pages/Presales/AllMasters/TenderStatus.tsx';
import Clients from './pages/Presales/Clients/Clients.tsx';
import Createclient from './pages/Presales/Clients/CreateClients.tsx';
import EditClients from './pages/Presales/Clients/EditClients.tsx';
import CreateContracts from './pages/Presales/Contract/CreateContracts.tsx';
import EditContracts from './pages/Presales/Contract/EditContracts.tsx';
import ManageContracts from './pages/Presales/Contract/ManageContracts.tsx';
import ViewContracts from './pages/Presales/Contract/ViewContract/ViewContracts.tsx';
import CreateEnquiry from './pages/Presales/EnquiryMaster/CreateEnquiry.tsx';
import CreateEnquiryDocumentUpload from './pages/Presales/EnquiryMaster/CreateEnquiryDocumentupload.tsx';
import EditEnquiry from './pages/Presales/EnquiryMaster/EditEnquiry.tsx';
import Enquiry from './pages/Presales/EnquiryMaster/Enquiry.tsx';
import FollowupCreate from './pages/Presales/EnquiryMaster/FollowupCreate.tsx';
import FollowupEdit from './pages/Presales/EnquiryMaster/FollowupEdit.tsx';
import ViewEnquiry from './pages/Presales/EnquiryMaster/ViewEnquiry.jsx';
import CreateEstimationSheetForAMC from './pages/Presales/EstimationSheetForAMC/CreateEstimationSheetForAMC.tsx';
import EditEstimationSheet from './pages/Presales/EstimationSheetForAMC/EditEstimationSheet.tsx';
import EstimationSheetForAMC from './pages/Presales/EstimationSheetForAMC/EstimationSheetForAMC.tsx';
import CreateEstimationSheetForMEP from './pages/Presales/EstimationSheetForMEP/CreateEstimationMEP.tsx';
import EditEstimationSheetMEP from './pages/Presales/EstimationSheetForMEP/EditEstimationMEP.tsx';
import EstimationSheetForMEP from './pages/Presales/EstimationSheetForMEP/EstimationSheetsMEP.tsx';
import ViewEstimationMEP from './pages/Presales/EstimationSheetForMEP/ViewEstimationMEP.tsx';
import CreateProjects from './pages/Presales/Projects/CreateProjects.tsx';
import EditProjects from './pages/Presales/Projects/EditProjects.tsx';
import Projects from './pages/Presales/Projects/Projects.tsx';
import ViewProjects from './pages/Presales/Projects/ViewProjects/ViewProjects.tsx';
import ProposalCreate from './pages/Presales/ProposalLineItemsMaster/Create.tsx';
import ProposalList from './pages/Presales/ProposalLineItemsMaster/List.tsx';
import ProposalView from './pages/Presales/ProposalLineItemsMaster/View.tsx';
import SalesorderDistribution from './pages/Presales/SalesOrderDistribution/SalesOrderDistribution.tsx';
import ViewSalesOrderDistribution from './pages/Presales/SalesOrderDistribution/View/ViewSalesOrderDistribution.tsx';
import ExampleWithProviders from './pages/Presales/sample/ExampleDataGrid.jsx';
import CreateSuppliers from './pages/Presales/Suppliers/CreateSuppliers.tsx';
import EditSuppliers from './pages/Presales/Suppliers/EditSuppliers.tsx';
import SupplierDocuments from './pages/Presales/Suppliers/SupplierDocuments.tsx';
import Suppliers from './pages/Presales/Suppliers/Suppliers.tsx';
import SuppliersSpoc from './pages/Presales/Suppliers/SupplierSpoc.tsx';
import ViewSuppliers from './pages/Presales/Suppliers/ViewSuppliers.tsx';
import CreateTenderEnquiry from './pages/Presales/TenderEnquiry/CreateTenderEnquiry.tsx';
import EditTenderEnquiry from './pages/Presales/TenderEnquiry/EditTenderEnquiry.tsx';
import TenderEnquiry from './pages/Presales/TenderEnquiry/TenderEnquiry.tsx';
import CreateTenderInspectionChecklist from './pages/Presales/TenderInspectionChecklist/CreateTenderInspectionChecklist.tsx';
import EditTenderInspectionChecklist from './pages/Presales/TenderInspectionChecklist/EditTenderInspectionChecklist.tsx';
import TenderInspectionChecklist from './pages/Presales/TenderInspectionChecklist/TenderInspectionChecklist.tsx';
import ViewTenderCheckListDetail from './pages/Presales/TenderInspectionChecklist/ViewTenderChecklistDetail.tsx';
import GoodsReceivedNoteList from './pages/Procurement/GoodsReceivedNote/GoodsReceivedNoteList.tsx';
import Brand from './pages/Procurement/Masters/Brands.tsx';
import Category from './pages/Procurement/Masters/Category.tsx';
import Currencies from './pages/Procurement/Masters/Currencies.tsx';
import GroupTypes from './pages/Procurement/Masters/GroupTypes.tsx';
import ItemGroup from './pages/Procurement/Masters/ItemGroup.tsx';
import ItemTypesMasters from './pages/Procurement/Masters/ItemTypes.tsx';
import LocalPurchaseOrderStatus from './pages/Procurement/Masters/LocalPurchaseOrderStatus.tsx';
import MaterialRequisitionStatus from './pages/Procurement/Masters/MaterialrequisitionStatus.tsx';
import MaterialRequisitionType from './pages/Procurement/Masters/MaterialRequisitionType.tsx';
import PaymentTerms from './pages/Procurement/Masters/PaymentTerms.tsx';
import SupplierItems from './pages/Procurement/Masters/SupplierItems.jsx';
import TaxCodeMasters from './pages/Procurement/Masters/TaxCode.tsx';
import UnitOfMeasurement from './pages/Procurement/Masters/UnitOfMeasurement.tsx';
import ValuationMethod from './pages/Procurement/Masters/ValuationMethod.tsx';
import CreateMaterialRequest from './pages/Procurement/MaterialRequest/CreateMaterialRequest.tsx';
import MaterialRequestList from './pages/Procurement/MaterialRequest/MaterialRequestList.tsx';
import ViewMaterialRequest from './pages/Procurement/MaterialRequest/ViewMaterialRequest.tsx';
import CreatePurchaseOrderView from './pages/Procurement/PurchaseOrderView/CreatePurchaseOrderView.tsx';
import PurchaseOrderPreview from './pages/Procurement/PurchaseOrderView/PurchaseOrderPreview.tsx';
import PurchaseOrderViewList from './pages/Procurement/PurchaseOrderView/PurchaseOrderViewList.tsx';
import CreateRequestQuote from './pages/Procurement/RequestQuoteManagement/CreateQuoteRequest.tsx';
import RequestQuoteManagement from './pages/Procurement/RequestQuoteManagement/RequestQuoteManagement.tsx';
import Areas from './pages/QAC/Masters/Areas.tsx';
import Customers from './pages/QAC/Masters/Customers.tsx';
import SubAreas from './pages/QAC/Masters/SubAreas.tsx';
import CreateUser from './pages/QuickLinks/RoleManagement/Create/CreateUser.tsx';
import CreatePermissions from './pages/QuickLinks/RoleManagement/CreatePermissions.tsx';
import CreateRoles from './pages/QuickLinks/RoleManagement/CreateRoles.tsx';
import runOneSignal from './utils/OneSignal.tsx';
import { SettingsProvider } from './utils/SettingsContext.jsx';
import PassportStatuses from './pages/Pr/AllMasters/PassportStatuses.tsx';
import AddVendorDocuments from './pages/Financial/VendorInvoiceReceivable/AddVendorDocuments.tsx';
import VendorDocuments from './pages/Financial/VendorInvoiceReceivable/VendorDocuments.tsx';
import EditVendorDocuments from './pages/Financial/VendorInvoiceReceivable/EditVendorDocuments.tsx';
import ViewVendorDocuments from './pages/Financial/VendorInvoiceReceivable/ViewVendorDocuments.tsx';
import CreateBuildingImages from './pages/Presales/TenderInspectionChecklist/ViewBuilding/CreateBuildingImages.tsx';
import IssuePassportCreate from './pages/Hrms/PassportRequest/IssuePassportCreate.tsx';
import SubmissionCreate from './pages/Hrms/PassportRequest/SubmissionCreate.tsx';
import CreateAttendanceForm from './pages/Hrms/EmployeeMaster/EmployeeAttendence/CreateAttendanceForm.tsx';
import EmployeePassportTracker from './pages/Pr/Trackers/EmployeePassportTracker.tsx';
import VisaTracker from './pages/Pr/Trackers/VisaTracker.tsx';
import AllTracker from './pages/Pr/Trackers/AllTracker.tsx';
import Accruals from './pages/Hrms/Accruals/Accruals.tsx';
import InternalBudget from './pages/Cafm/WorkOrderMaster/CreateSalesOrder/internalBudget/InternalBudget.tsx';
import InternalInvoicesAR from './pages/Financial/AccountsReceivable/InternalSalesInvoice/InternalInvoicesAR.tsx';
import CreateInternalInvoicesAR from './pages/Financial/AccountsReceivable/InternalSalesInvoice/Create/CreateInternalInvoicesAR.tsx';
import EditInternalInvoiceAR from './pages/Financial/AccountsReceivable/InternalSalesInvoice/Edit/EditInternalInvoiceAR.tsx';
import EditARReceipts from './pages/Financial/AccountsReceivable/ARReceipts/Edit/EditARReceipts.tsx';
import ZoneMaster from './pages/Presales/AllMasters/ZoneMaster.tsx';
import SiteTypeMaster from './pages/Cafm/AllMaster/SiteTypeMaster.tsx';
import BuildingCategoryMaster from './pages/Cafm/AllMaster/BuildingCategoryMaster.tsx';
import BuildingsMaster from './pages/Cafm/AllMaster/BuildingsMaster.tsx';
import SitesMaster from './pages/Cafm/AllMaster/SitesMaster.tsx';
import OwnershipTypesMaster from './pages/Cafm/AllMaster/OwnershipTypesMaster.tsx';
import SiteUsageMaster from './pages/Cafm/AllMaster/SiteUsageMaster.tsx';
import BuildingSystemMaster from './pages/Cafm/AllMaster/BuildingSystemMaster.tsx';
import FloorTypeMaster from './pages/Cafm/AllMaster/FloorTypeMaster.tsx';
import WingMaster from './pages/Cafm/AllMaster/WingMaster.tsx';
import ComplianceCertificateMaster from './pages/Cafm/AllMaster/ComplianceCertificateMaster.tsx';
import UnitUsageMaster from './pages/Cafm/AllMaster/UnitUsageMaster.tsx';
import UnitConfigurationMaster from './pages/Cafm/AllMaster/UnitConfigurationMaster.tsx';
import UnitTypeMaster from './pages/Cafm/AllMaster/UnitTypeMaster.tsx';
import PriorityMaster from './pages/Cafm/AllMaster/PriorityMaster.tsx';
import UsageStatusMaster from './pages/Cafm/AllMaster/UsageStatusMaster.tsx';
import AssetOwnershipTypeMaster from './pages/Cafm/AllMaster/AssetOwnershipTypeMaster.tsx';
import AssetCurrentConditionMaster from './pages/Cafm/AllMaster/AssetCurrentConditionMaster.tsx';
import AssetTypeMaster from './pages/Cafm/AllMaster/AssetTypeMaster.tsx';
import SubUnitTypeMaster from './pages/Cafm/AllMaster/SubUnitTypeMaster.tsx';
import AssetGroupMaster from './pages/Cafm/AllMaster/AssetGroupMaster.tsx';
import ReportDesigner from './components/ReportDesigner/ReportDesigner.tsx';
import PettyCash from './pages/Financial/CashAndBank/PettyCash/PettyCash.tsx';
import AddPettyCash from './pages/Financial/CashAndBank/PettyCash/AddPettyCash.tsx';
import ViewPettyCash from './pages/Financial/CashAndBank/PettyCash/View/ViewPettyCash.tsx';
import BidBondManagement from './pages/Presales/BidBondManagement/BidBondManagement.tsx';
import TenderInspectionCheckListCreate from './pages/Presales/EnquiryMaster/ViewTenderInspectionChecklist/TenderInspectionCheckListCreate.tsx';
import TenderInspectionCheckListEdit from './pages/Presales/EnquiryMaster/ViewTenderInspectionChecklist/TenderInpectionCheckListEdit.tsx';
import EditGaranteeManagement from './pages/Financial/GaranteeManagement/EditGaranteeManagement.tsx';
import CreateCreditNote from './pages/Financial/JournalEntries/CreditNotes/CreateCreditNote.tsx';
import FieldsCreateCreditJournal from './pages/Financial/JournalEntries/CreditNotes/FieldsCreateCreditJournal.tsx';
import ListCreditNote from './pages/Financial/JournalEntries/CreditNotes/ListCreditNote.tsx';
import EditCreditNote from './pages/Financial/JournalEntries/CreditNotes/EditCreditNote.tsx';
import CreateDebitNote from './pages/Financial/JournalEntries/DebitNotes/CreateDebitNote.tsx';
import EditDebitNote from './pages/Financial/JournalEntries/DebitNotes/EditDebitNote.tsx';
import ListReportTemplates from './components/ReportDesigner/ListReportTemplates.tsx';
import InvoiceCollectionAgent from './pages/Financial/InvoiceCollectionAgent/InvoiceCollectionAgent.tsx';
import EditCollectionAgent from './pages/Financial/InvoiceCollectionAgent/EditCollectionAgent.tsx';
import BankStatements from './pages/Pr/PRReconciliation/BankStatements/BankStatements.tsx';
import PRReconciliationForm from './pages/Pr/PRReconciliation/Create/PRReconciliationForm.tsx';
import PRReconciliationMatch from './pages/Pr/PRReconciliation/Edit/PRReconciliationMatch.tsx';
import AddItems from './pages/Procurement/ItemManagement/AddItems.tsx';
import EditItems from './pages/Procurement/ItemManagement/EditItems.tsx';
import InventoryTransferList from './pages/Procurement/inventory/inventoryTransfer/InventoryTransferList.tsx';
import CreateInvetoryTransfer from './pages/Procurement/inventory/inventoryTransfer/Create/CreateInvetoryTransfer.tsx';
import EditInventoryTransfer from './pages/Procurement/inventory/inventoryTransfer/EditInventoryTransfer.tsx';
import PendingPayments from './pages/Financial/AccountsReceivable/PendingPayments/PendingPayments.tsx';
import BaseUnit from './pages/Cafm/AllMaster/BaseUnit.tsx';
const ForgotPassword = lazy(() => import('./pages/Login/ForgotPassword.tsx'));
const ResetPassword = lazy(() => import('./pages/Login/ResetPassword.tsx'));

const PRReconciliationPage = lazy(() =>
  import(
    './pages/Pr/PRReconciliation/PRReconciliationPage/PRReconciliationPage.tsx'
  )
);

const FinalSettlement = lazy(() =>
  import('./pages/Hrms/FinalSettlements/FinalSettlement.tsx')
);
const LeaveTransaction = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveTransaction')
);
const LeaveJoin = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveJoin/LeaveJoin.tsx')
);
const DepartmentMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/DepartmentMasters.tsx')
);
const CreateTrainingAndEvents = lazy(() =>
  import('./pages/Hrms/TrainingAndEvents/TrainingAndEventsCreate.tsx')
);
const AttendanceCalculations = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/AttendanceCalculations.tsx')
);
const ShiftTimeMaster = lazy(() =>
  import('./pages/Hrms/ShiftTimeMaster/ShiftTime.jsx')
);

const EditShiftTime = lazy(() =>
  import('./pages/Hrms/ShiftTimeMaster/EditShiftTime.tsx')
);

const ExpenseTypePr = lazy(() =>
  import('./pages/Pr/AllMasters/ExpenseType.tsx')
);
const TrainingAndEvents = lazy(() =>
  import('./pages/Hrms/TrainingAndEvents/TrainingAndEvents.tsx')
);
const Certificates = lazy(() =>
  import('./pages/Pr/certificates/Certificates.tsx')
);
const DocumentManagement = lazy(() =>
  import('./pages/Hrms/DocumentManagement/DocumentManagement.tsx')
);
const LeaveRequest = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveRequest/LeaveRequest.tsx')
);
const EmployementCancellation = lazy(() =>
  import('./pages/Hrms/EmployementCancellation/EmployementCancellation.tsx')
);
const CashAccountMgmt = lazy(() =>
  import('./pages/Pr/CashAccountManagement/CashAccountMgmt.tsx')
);
const TransactionManagement = lazy(() =>
  import('./pages/Pr/TransactionManagement/TransactionManagement.tsx')
);
const EmployeeStudies = lazy(() => import('./pages/Hrms/EmployeeStudies.tsx'));
const TrainingTransaction = lazy(() =>
  import('./pages/Hrms/TraningAndTranaction/TrainingTransaction.tsx')
);
const VehicleMaster = lazy(() =>
  import('./pages/Pr/VehicleMaster/VehicleMaster.tsx')
);
const Candidates = lazy(() => import('./pages/Pr/Candidates/Candidates.tsx'));
const JobRequisition = lazy(() =>
  import('./pages/Hrms/JobRequisition/JobRequisition.tsx')
);
const JobRecruitments = lazy(() =>
  import('./pages/Hrms/JobRecruitments/JobRecruitments.tsx')
);
const CreatePassportRequest = lazy(() =>
  import('./pages/Hrms/PassportRequest/PassportRequestCreate.tsx')
);
const CreateTraningAndTransaction = lazy(() =>
  import('./pages/Hrms/TraningAndTranaction/TraningTransactionCreate.tsx')
);
const EditTraningAndTransaction = lazy(() =>
  import('./pages/Hrms/TraningAndTranaction/TraningTransactionEdit.tsx')
);
const EditPassportRequest = lazy(() =>
  import('./pages/Hrms/PassportRequest/PassportRequestEdit.tsx')
);
const PassportRequest = lazy(() =>
  import('./pages/Hrms/PassportRequest/PassportRequest.tsx')
);
const CreateCertificates = lazy(() =>
  import('./pages/Pr/certificates/CertificatesCreate.tsx')
);
const EditCertificates = lazy(() =>
  import('./pages/Pr/certificates/CertificatesEdit.tsx')
);
const CreateCandidates = lazy(() =>
  import('./pages/Pr/Candidates/CandidateCreate.tsx')
);
const EditCandidates = lazy(() =>
  import('./pages/Pr/Candidates/CandidateEdit.tsx')
);
const CreateVehicleMaster = lazy(() =>
  import('./pages/Pr/VehicleMaster/VehicleMasterCreate.tsx')
);
const EditVehicleMaster = lazy(() =>
  import('./pages/Pr/VehicleMaster/VehicleMasterEdit.tsx')
);
const EssLogin = lazy(() => import('./pages/Login/EssLogin.tsx'));
const EssDashboard = lazy(() => import('./pages/Dashboard/EssDashboard.tsx'));
const CreateJobRequisition = lazy(() =>
  import('./pages/Hrms/JobRequisition/JobRequisitionCreate.tsx')
);
const EditJobRequisition = lazy(() =>
  import('./pages/Hrms/JobRequisition/JobRequisitionEdit.tsx')
);
const CreateJobRecruitment = lazy(() =>
  import('./pages/Hrms/JobRecruitments/JobReCruitmentsCreate.tsx')
);
const EditJobRecruitment = lazy(() =>
  import('./pages/Hrms/JobRecruitments/JobReCruitmentsEdit.jsx')
);
const SettingsForm = lazy(() => import('./utils/SettingsForm.jsx'));
const CreateFinalSettlement = lazy(() =>
  import('./pages/Hrms/FinalSettlements/FinalSettlementCreate.tsx')
);
const EditFinalSettlement = lazy(() =>
  import('./pages/Hrms/FinalSettlements/FinalSettlementEdit.tsx')
);
const WpsMasters = lazy(() => import('./pages/Hrms/AllMasters/WpsMasters.tsx'));
const EditTrainingAndEvents = lazy(() =>
  import('./pages/Hrms/TrainingAndEvents/TrainingAndEventsEdit.tsx')
);
const CreateLeaveRequest = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveRequest/CreateLeaveRequest.tsx')
);
const EditLeaveRequest = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveRequest/EditLeaveRequest.tsx')
);

const LeaveRequestSettlementDetails = lazy(() =>
  import(
    './pages/Hrms/EmployeeMaster/LeaveRequest/LeaveRequestSettlementDetails.tsx'
  )
);

const LeaveRequestApprovalDetails = lazy(() =>
  import(
    './pages/Hrms/EmployeeMaster/LeaveRequest/LeaveRequestApprovalDetails.tsx'
  )
);

const PassportAndVisa = lazy(() =>
  import('./pages/Pr/PassportAndVisa/PassportAndVisa.tsx')
);
const DocumentsList = lazy(() =>
  import('./pages/Fm/GeneralDocuments/DocumentsList.jsx')
);
const CreateDocuments = lazy(() =>
  import('./pages/Fm/GeneralDocuments/CreateDocuments.tsx')
);
const PositionsMaster = lazy(() =>
  import('./pages/Hrms/AllMasters/PositionsMaster.tsx')
);
const LeaveJoinCreate = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveJoin/LeaveJoinCreate.tsx')
);
const CreateCashAccountManagement = lazy(() =>
  import('./pages/Pr/CashAccountManagement/CreateCashAccount.tsx')
);
const EditCashAccountManagement = lazy(() =>
  import('./pages/Pr/CashAccountManagement/EditCashAccount.jsx')
);
const CompanyMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/CompanyMasters.tsx')
);
const DepartmentShifts = lazy(() =>
  import('./pages/Hrms/DepartmentShifts/DepatmentShifts.tsx')
);

const CreateDepartmentShift = lazy(() =>
  import('./pages/Hrms/DepartmentShifts/CreateDepartmentShift.tsx')
);

const CreateEmpCancellation = lazy(() =>
  import('./pages/Hrms/EmployementCancellation/CreateEmpCancellation.tsx')
);
const EditEmpCancellation = lazy(() =>
  import('./pages/Hrms/EmployementCancellation/EditEmpCancellation.tsx')
);
const ApprovalNode = lazy(() =>
  import('./pages/Fm/ApprovalNode/ApprovalNode.tsx')
);
const HolidaysList = lazy(() => import('./pages/Fm/Holidays/HolidaysList.tsx'));
const CreateHolidays = lazy(() =>
  import('./pages/Fm/Holidays/CreateHolidays.tsx')
);
const LeaveJoinEdit = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveJoin/LeaveJoinEdit.tsx')
);
const EditHolidays = lazy(() => import('./pages/Fm/Holidays/EditHoliday.tsx'));
const CalendarBooking = lazy(() =>
  import('./pages/Fm/CalendarBooking/CalendarBooking.tsx')
);
const CardTypes = lazy(() => import('./pages/Pr/AllMasters/CardTypes.jsx'));
const ExampleComponent = lazy(() => import('./ExampleComponent.jsx'));
const SettingsForms = lazy(() => import('./utils/SettingsForm.js'));
const CreatePassportAndVisa = lazy(() =>
  import('./pages/Pr/PassportAndVisa/CreatePassportAndVisa.tsx')
);
const EditPassportAndVisa = lazy(() =>
  import('./pages/Pr/PassportAndVisa/EditPassportAndVisa.tsx')
);
const CreateEidApplication = lazy(() =>
  import('./pages/Pr/Eid/CreateEidApplication.tsx')
);
const EditEidApplication = lazy(() =>
  import('./pages/Pr/Eid/EditEidApplication.tsx')
);
const CafmTicket = lazy(() => import('./pages/Cafm/Ticket/CafmTicket.tsx'));
const CreateTicket = lazy(() => import('./pages/Cafm/Ticket/CreateTicket.tsx'));
const EmployeeUpdate = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/EmployeeUpdate/EmployeeUpdate.tsx')
);
const TemplateList = lazy(() =>
  import('./pages/QuickLinks/Template/TemplateList.tsx')
);
const CreateTemplate = lazy(() =>
  import('./pages/QuickLinks/Template/CreateTemplate.tsx')
);
const QuestionsBuilderList = lazy(() =>
  import('./pages/QuickLinks/QuestionsBuilder/QuestionsBuilderList.tsx')
);
const CreateQuestionsBuilder = lazy(() =>
  import('./pages/QuickLinks/QuestionsBuilder/CreateQuestionsBuilder.tsx')
);
const Building = lazy(() => import('./pages/Procurement/Masters/Building.tsx'));
const Levels = lazy(() => import('./pages/Procurement/Masters/Levels.tsx'));
const LevelUnits = lazy(() =>
  import('./pages/Procurement/Masters/LevelUnits.tsx')
);
const CostCenters = lazy(() =>
  import('./pages/Procurement/Masters/CostCenters.tsx')
);
const ItemManagementList = lazy(() =>
  import('./pages/Procurement/ItemManagement/ItemManagementList.tsx')
);
const PayGroupMaster = lazy(() =>
  import('./pages/Hrms/AllMasters/PayGroupMasters.tsx')
);
const TinyMCEEditor = lazy(() => import('./pages/TextEditor.jsx'));
const PurchaseOrderViewSendLPO = lazy(() =>
  import('./pages/Procurement/PurchaseOrderView/PurchaseOrderViewSendLPO.tsx')
);
const LeaveConfiguration = lazy(() =>
  import('./pages/Hrms/LeaveConfiguration.jsx')
);
const QuotaEmployeesList = lazy(() =>
  import('./pages/Pr/QuotaEmployeesSummary/QuotaEmployeesList.tsx')
);
const ManpowerRateCardList = lazy(() =>
  import('./pages/Hrms/ManpowerRatecard/ManpowerRateCardList.tsx')
);
const EditManpowerRateCard = lazy(() =>
  import('./pages/Hrms/ManpowerRatecard/EditManpowerrateCard.tsx')
);
const PRDashboard = lazy(() => import('./pages/Dashboard/PRDashboard.tsx'));
const CreateTransactionManagement = lazy(() =>
  import('./pages/Pr/TransactionManagement/CreateTransactionManagement.tsx')
);
const EditTransactionManagement = lazy(() =>
  import('./pages/Pr/TransactionManagement/EditTransactionManagement.tsx')
);
const ViewTransactionManagement = lazy(() =>
  import('./pages/Pr/TransactionManagement/ViewTransactionManagement.tsx')
);
const InterviewAssessmentForm = lazy(() =>
  import('./pages/Pr/Candidates/InterviewAssessmentForm.tsx')
);
const CandidateConfirmation = lazy(() =>
  import('./pages/Pr/Candidates/CandidateConfirmation.tsx')
);
const EmployeeSalaryCalculations = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/EmployeeSalaryCalculations.tsx')
);
const DocumentCollection = lazy(() =>
  import('./pages/Pr/Candidates/DocumentCollection.tsx')
);

const GoodsReceivedNotesCreate = lazy(() =>
  import('./pages/Procurement/GoodsReceivedNote/GoodsReceivedNotesCreate.tsx')
);

const ProjectDivisionList = lazy(() =>
  import('./pages/Cafm/ProjectDivision/ProjectDivsion.tsx')
);

const ManageAssigneeList = lazy(() =>
  import('./pages/Cafm/ProjectDivision/ManageAssignee/ManageAssigneeList.tsx')
);

const ProjectPlanList = lazy(() =>
  import('./pages/Cafm/ProjectDivision/ProjectPlan/ProjectPlanList.tsx')
);

const ProjectActivityList = lazy(() =>
  import('./pages/Cafm/ProjectDivision/ProjectActivity/ProjectActivityList.tsx')
);

const BoqHeadMasterList = lazy(() =>
  import('./pages/Cafm/AllMaster/BoqHeadMasterList.tsx')
);

const BoqPackageMaster = lazy(() =>
  import('./pages/Cafm/AllMaster/BoqPackageMaster.tsx')
);

const BoqSubHeadMaster = lazy(() =>
  import('./pages/Cafm/AllMaster/BoqSubHead/BoqSubHeadMaster.tsx')
);

const BoqLineItemMaster = lazy(() =>
  import('./pages/Cafm/AllMaster/BoqLineItemMaster.tsx')
);

const ProjectMainCost = lazy(() =>
  import(
    './pages/Cafm/ProjectDivision/ProjectCostTracking/ProjectMainCost/ProjectMainCost.tsx'
  )
);

const BudgetProjectCostTracking = lazy(() =>
  import(
    './pages/Cafm/ProjectDivision/ProjectCostTracking/Budget/BudgetProjectCostTracking.tsx'
  )
);

const ManpowerProjectCostTracking = lazy(() =>
  import(
    './pages/Cafm/ProjectDivision/ProjectCostTracking/Manpower/ManpowerProjectCostTracking.tsx'
  )
);

const PettyCashProjectCostTracking = lazy(() =>
  import(
    './pages/Cafm/ProjectDivision/ProjectCostTracking/Pettycash/PettyCashProjectCostTracking.tsx'
  )
);
//inventory
const InventoryList = lazy(() =>
  import('./pages/Procurement/inventory/InventoryList.tsx')
);
const InventoryView = lazy(() =>
  import('./pages/Procurement/inventory/InventoryView.tsx')
);
const AddInventory = lazy(() =>
  import('./pages/Procurement/inventory/AddInventory.tsx')
);
const AssetHistory = lazy(() =>
  import('./pages/Cafm/AssetHistory/AssetHistoryList.tsx')
);
const AssetHistoryView = lazy(() =>
  import('./pages/Cafm/AssetHistory/View/AssetHistoryView.tsx')
);

const CreateAssetHistory = lazy(() =>
  import('./pages/Cafm/AssetHistory/Create/CreateAssetHistory.tsx')
);

const CompanyInsurancePolicyList = lazy(() =>
  import('./pages/Admin/CompanyInsurancePolicy/CompanyInsurancePolicyList.tsx')
);

const ComparisonPolicy = lazy(() =>
  import(
    './pages/Admin/CompanyInsurancePolicy/ComparisonPolicy/ComparisonPolicy.tsx'
  )
);

const CompanyMedicalInsurancePolicy = lazy(() =>
  import(
    './pages/Admin/CompanyMedicalInsurancePolicy/CompanyMedicalInsurancePolicy/CompanyMedicalInsurancePolicy.tsx'
  )
);

const CreateCompanyMedicalInsurance = lazy(() =>
  import(
    './pages/Admin/CompanyMedicalInsurancePolicy/CompanyMedicalInsurancePolicy/CreateCompanyMedicalInsurance.tsx'
  )
);

const MIPPremiumCalculator = lazy(() =>
  import(
    './pages/Admin/CompanyMedicalInsurancePolicy/MIPPremiumCalculator/MIPPremiumCalculator.tsx'
  )
);

const EmployeeWiseMIPCalculator = lazy(() =>
  import(
    './pages/Admin/CompanyMedicalInsurancePolicy/EmployeeWiseMIPCalculator/EmployeeWiseMIPCalculator.tsx'
  )
);

const AccommodationList = lazy(() =>
  import('./pages/Admin/Accommodation/AccommodationList.tsx')
);

const UniformRequistionList = lazy(() =>
  import('./pages/Admin/UniformAccessories/UniformRequistionList.tsx')
);

const EventsCalender = lazy(() =>
  import('./pages/Admin/EventsCalendar/EventsCalendar.tsx')
);

const ViewEventsCalendar = lazy(() =>
  import('./pages/Admin/EventsCalendar/View/ViewEventsCalendar.tsx')
);

const PendingBillsPostDatedReceipts = lazy(() =>
  import(
    './pages/Financial/CashAndBank/PostDatedReceipts/PendingBills/PendingBillsPostDatedReceipts.tsx'
  )
);

const PendingBillsPDCPayments = lazy(() =>
  import(
    './pages/Financial/CashAndBank/PendingBillsPDCPayments/PendingBillsPDCPayments.tsx'
  )
);

const PendingBillsMaturedPDCPV = lazy(() =>
  import(
    './pages/Financial/CashAndBank/MaturedPDCPV/PendingBills/PendingBillsMaturedPDCPV.tsx'
  )
);

const PettyCashProjectsView = lazy(() =>
  import(
    './pages/Financial/CashAndBank/PettyCashProjectsView/PettyCashProjectsView.tsx'
  )
);

const FATrialBalance = lazy(() =>
  import('./pages/Financial/FinalAccounts/TrialBalance/FATrialBalance.tsx')
);

const ProfitAndLoss = lazy(() =>
  import('./pages/Financial/FinalAccounts/ProfitAndLoss/ProfitAndLoss.tsx')
);

const TradingAccount = lazy(() =>
  import('./pages/Financial/FinalAccounts/TradingAccount/TradingAccount.tsx')
);

const TradingAndProfitAndLoss = lazy(() =>
  import(
    './pages/Financial/FinalAccounts/TradingAndProfitAndLoss/TradingAndProfitAndLoss.tsx'
  )
);

const BalanceSheet = lazy(() =>
  import('./pages/Financial/FinalAccounts/BalanceSheet/BalanceSheet.tsx')
);

const FASchedules = lazy(() =>
  import('./pages/Financial/FinalAccounts/FASchedules/FASchedules.tsx')
);

const FundsFlow = lazy(() =>
  import('./pages/Financial/FinalAccounts/FundsFlow/FundsFlow.tsx')
);

const CashFlow = lazy(() =>
  import('./pages/Financial/FinalAccounts/CashFlow/CashFlow.tsx')
);
const CashFlowAnalysis = lazy(() =>
  import(
    './pages/Financial/FinalAccounts/CashFlowAnalysis/CashFlowAnalysis.tsx'
  )
);

const AdvanceCashFlow = lazy(() =>
  import('./pages/Financial/FinalAccounts/AdvanceCashFlow/AdvanceCashFlow.tsx')
);

const IncomeExpenseTrends = lazy(() =>
  import(
    './pages/Financial/FinalAccounts/IncomeExpenseTrends/IncomeExpenseTrends.tsx'
  )
);

const ReceivablePayableReport = lazy(() =>
  import('./pages/Financial/FinalAccounts/ReceivablePayableReport/index.tsx')
);

const CashFlowCalenderIndex = lazy(() =>
  import('./pages/Financial/FinalAccounts/CashFlowCalender/index.tsx')
);

const BalanceSheetReports = lazy(() =>
  import('./pages/Reports/BalanceSheetReports/BalanceSheetReports.tsx')
);

const SAPAccountsPayableOpenItemsReport = lazy(() =>
  import(
    './pages/Reports/SAPAccountsPayableOpenItemsReport/SAPAccountsPayableOpenItemsReport.tsx'
  )
);

const AccountsPayableReconciliation = lazy(() =>
  import(
    './pages/Reports/AccountsPayableReconciliation/AccountsPayableReconciliation.tsx'
  )
);

const AccountsPayableTrialBalance = lazy(() =>
  import(
    './pages/Reports/AccountsPayableTrialBalance/AccountsPayableTrialBalance.tsx'
  )
);

const FinancialStatementOfAccounts = lazy(() =>
  import(
    './pages/Reports/FinancialStatementOfAccounts/FinancialStatementOfAccounts.tsx'
  )
);

const TrialBalanceReport = lazy(() =>
  import('./pages/Reports/TrialBalanceReport/TrialBalanceReport.tsx')
);

const FixedAssetsDepreciationReport = lazy(() =>
  import(
    './pages/Reports/FixedAssetsDepreciationReport/FixedAssetsDepreciationReport.tsx'
  )
);

const AccountsReceivableReconciliation = lazy(() =>
  import(
    './pages/Reports/AccountsReceivableReconciliation/AccountsReceivableReconciliation.tsx'
  )
);

const BankReconciliationStatement = lazy(() =>
  import(
    './pages/Reports/BankReconciliationStatement/BankReconciliationStatement.tsx'
  )
);

const CashTrialBalance = lazy(() =>
  import('./pages/Reports/CashTrialBalance/CashTrialBalance.tsx')
);

const CostAuditReport = lazy(() =>
  import('./pages/Reports/CostAuditReport/CostAuditReport.tsx')
);

const CostCenterReport = lazy(() =>
  import('./pages/Reports/CostCenterReport/CostCenterReport.tsx')
);

const AccomBlock = lazy(() =>
  import('./pages/Admin/Accommodation/AccomBlock/AccomBlock.tsx')
);

const AccomRoom = lazy(() =>
  import('./pages/Admin/Accommodation/AccomRoom/AccomRoom.tsx')
);

const AccomRoomCapacity = lazy(() =>
  import('./pages/Admin/Accommodation/AccomRoomCapacity/AccomRoomCapacity.tsx')
);

const AccomCamps = lazy(() =>
  import('./pages/Admin/Accommodation/AccomCamps/AccomCamps.tsx')
);

const VehicleTypes = lazy(() =>
  import('./pages/Admin/AllMasters/VehicleTypes.tsx')
);

const QACModule = lazy(() => import('./pages/QAC/QACModule/QACModule.tsx'));

const CustomerSatisfaction = lazy(() =>
  import('./pages/QAC/CustomerSatisfaction/CustomerSatisfaction.tsx')
);

const ViewCustomerSatisfaction = lazy(() =>
  import('./pages/QAC/CustomerSatisfaction/View/ViewCustomerSatisfaction.tsx')
);

const CustomerSatisfactionReport = lazy(() =>
  import(
    './pages/QAC/CustomerSatisfactionReport/CustomerSatisfactionReport.tsx'
  )
);

const QuestionsTemplate = lazy(() =>
  import('./pages/QuickLinks/QuestionsTemplate/QuestionsTemplate.tsx')
);

const CreateQuestionsTemplate = lazy(() =>
  import(
    './pages/QuickLinks/QuestionsTemplate/Create/CreateQuestionsTemplate.tsx'
  )
);

const ViewQACModule = lazy(() =>
  import('./pages/QAC/QACModule/View/ViewQACModule.tsx')
);

const ComparisonRequestQuoteManagement = lazy(() =>
  import(
    './pages/Procurement/RequestQuoteManagement/Comparison/ComparisonRequestQuoteManagement.tsx'
  )
);

const HseCommittee = lazy(() =>
  import('./pages/Hse/HseCommittee/HseCommittee.tsx')
);

const ViewHseCommittee = lazy(() =>
  import('./pages/Hse/HseCommittee/View/ViewHseCommittee.tsx')
);

const PPEIssuedReport = lazy(() =>
  import('./pages/Hse/PPEIssuedReport/PPEIssuedReport.tsx')
);

const SafetyInduction = lazy(() =>
  import('./pages/Hse/SafetyInduction/SafetyInduction.tsx')
);

const SafetyViolationSlip = lazy(() =>
  import('./pages/Hse/SafetyViolationSlip/SafetyViolationSlip.tsx')
);

const ViewSafetyViolationSlip = lazy(() =>
  import('./pages/Hse/SafetyViolationSlip/View/ViewSafetyViolationSlip.tsx')
);

const HseHotWorkPermit = lazy(() =>
  import('./pages/Hse/HseHotWorkPermit/HseHotWorkPermit.tsx')
);

const HseHotWorkPermitCreate = lazy(() =>
  import('./pages/Hse/HseHotWorkPermit/Create/HseHotWorkPermitCreate.tsx')
);

const CreateJournalEntries = lazy(() =>
  import('./pages/Financial/JournalEntries/Create/CreateJournalEntries.tsx')
);

const NonJournal = lazy(() =>
  import('./pages/Financial/JournalEntries/NonJournal.tsx')
);

const CreditNotes = lazy(() =>
  import('./pages/Financial/JournalEntries/CreditNotes.tsx')
);

const DebitNotes = lazy(() =>
  import('./pages/Financial/JournalEntries/DebitNotes.tsx')
);

const EditJournalEntries = lazy(() =>
  import('./pages/Financial/JournalEntries/Edit/EditJournalEntries.tsx')
);

const CreateSalesInvoicesAR = lazy(() =>
  import(
    './pages/Financial/AccountsReceivable/SalesInvoicesAR/Create/CreateSalesInvoicesAR.tsx'
  )
);

const EditSalesInvoicesAR = lazy(() =>
  import(
    './pages/Financial/AccountsReceivable/SalesInvoicesAR/Edit/EditSalesInvoicesAR.tsx'
  )
);

const FinancialReportsTabLayout = lazy(() =>
  import('./pages/Financial/Reports/FinancialReportsTabLayout.tsx')
);

const FAClientReports = lazy(() =>
  import('./pages/Financial/ClientReports/FAClientReports.tsx')
);

const FADashboard = lazy(() =>
  import('./pages/Financial/Dashboard/FADashboard.tsx')
);

const RoleManagement = lazy(() =>
  import('./pages/QuickLinks/RoleManagement/RoleManagement.tsx')
);

const CreateRoleManagement = lazy(() =>
  import('./pages/QuickLinks/RoleManagement/Create/CreateRoleManagement.tsx')
);

const HelpDeskTicket = lazy(() =>
  import('./pages/Cafm/HelpDeskTicket/HelpDeskTicket.tsx')
);

const CreateHelpDeskTicket = lazy(() =>
  import('./pages/Cafm/HelpDeskTicket/Create/CreateHelpDeskTicket.tsx')
);

const ViewHelpDeskTicket = lazy(() =>
  import('./pages/Cafm/HelpDeskTicket/View/ViewHelpDeskTicket.tsx')
);

const TableTree = lazy(() => import('./pages/Fm/TableTree/TableTree.tsx'));

const ChartOfAccount = lazy(() =>
  import('./pages/Financial/ChartOfAccount/ChartOfAccount.tsx')
);

const CandidatesPROtherConditions = lazy(() =>
  import('./pages/Pr/Candidates/CandidatesPROtherConditions.tsx')
);

const CandidatesVisaProcessing = lazy(() =>
  import('./pages/Pr/Candidates/CandidatesVisaProcessing.tsx')
);

const CompanyCertificatesCreateNew = lazy(() =>
  import(
    './pages/Hrms/EmployeeMaster/CompanyCertificates/CompanyCertificatesCreateNew.tsx'
  )
);

const AttendanceSummaryNew = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/AttendanceSummaryNew.tsx')
);

const EmployeeLeaveCalendar = lazy(() =>
  import(
    './pages/Hrms/EmployeeMaster/EmployeeLeaveCalendar/EmployeeLeaveCalendar.tsx'
  )
);

const CreateNewTransactionManagement = lazy(() =>
  import('./pages/Pr/TransactionManagement/CreateNewTransactionManagement.tsx')
);

const PRReconciliation = lazy(() =>
  import('./pages/Pr/PRReconciliation/PRReconciliation.tsx')
);

const ARReceipts = lazy(() =>
  import('./pages/Financial/AccountsReceivable/ARReceipts/ARReceipts.tsx')
);

const CreateARReceipts = lazy(() =>
  import(
    './pages/Financial/AccountsReceivable/ARReceipts/Create/CreateARReceipts.tsx'
  )
);

const PettyCashManagement = lazy(() =>
  import('./pages/Financial/PettyCashManagement/PettyCashManagement.tsx')
);

const CashBankPayments = lazy(() =>
  import('./pages/Financial/CashAndBank/Payments/CashBankPayments.tsx')
);

const AddCashBankPayments = lazy(() =>
  import('./pages/Financial/CashAndBank/Payments/AddCashBankPayments.tsx')
);

const EditCashBankPaymentsPage = lazy(() =>
  import(
    './pages/Financial/CashAndBank/Payments/Edit/EditCashBankPaymentsPage.tsx'
  )
);

const CreateSalesQuotationInternalBudget = lazy(() =>
  import(
    './pages/Cafm/WorkOrderMaster/CreateSalesQuotation/CreateSalesQuotationInternalBudget.tsx'
  )
);

const EmployeeMedicalCertificate = lazy(() =>
  import(
    './pages/Hrms/EmployeeMaster/EmployeeMedicalCertificate/EmployeeMedicalCertificate.tsx'
  )
);

const EmployeeBulkAttendance = lazy(() =>
  import(
    './pages/Hrms/EmployeeMaster/EmployeeBulkAttendance/EmployeeBulkAttendance.tsx'
  )
);

const PRReconciliationEdit = lazy(() =>
  import('./pages/Pr/PRReconciliation/Edit/PRReconciliationEdit.tsx')
);

const CreateStoreLocations = lazy(() =>
  import(
    './pages/Procurement/inventory/storeLocations/Create/CreateStoreLocations.tsx'
  )
);

const ViewItemManagement = lazy(() =>
  import(
    './pages/Procurement/ItemManagement/ViewItemManagement/ViewItemManagement.tsx'
  )
);

const Dimension = lazy(() =>
  import('./pages/Financial/Dimension/Dimension.tsx')
);

const App = () => {
  useEffect(() => {
    runOneSignal();
  }, []);

  return (
    <>
      <SettingsProvider>
        <Router>
          <Routes>
            <Route path='/DataGrid-inline' element={<ExampleWithProviders />} />
            <Route path='*' element={<UnderConstruction />} />
            <Route path='/' element={<Navigate replace to='/login' />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='user-master' element={<SettingsForm />} />
            <Route path='SettingsForms' element={<SettingsForms />} />
            <Route path='ExampleComponent' element={<ExampleComponent />} />
            <Route path='text-editor' element={<TinyMCEEditor />} />
            {/* ------------------FM-------------------- */}
            <Route path='fm'>
              <Route
                index
                element={<Navigate replace to='/fm/general-documents' />}
              />
              <Route path='report-builder'>
                <Route index element={<ListReportTemplates />} />
                <Route path='create' element={<ReportDesigner isAdmin />} />
                {/* <Route path='edit/:type' element={<ReportDesigner isAdmin />} />
                <Route path='view/:type' element={<ReportDesigner isAdmin />} /> */}
              </Route>

              <Route path='general-documents'>
                <Route index element={<DocumentsList />} />
                <Route path='create' element={<CreateDocuments />} />
              </Route>
              <Route path='approval-node' element={<ApprovalNode />} />
              <Route path='holidays'>
                <Route index element={<HolidaysList />} />
                <Route path='create' element={<CreateHolidays />} />
                <Route path='edit' element={<EditHolidays />} />
              </Route>
              <Route path='calendar-booking' element={<CalendarBooking />} />
              <Route path='role-management'>
                <Route index element={<RoleManagement />} />
                <Route path='Create-Roles' element={<CreateRoles />} />
                <Route
                  path='Create-Permissions'
                  element={<CreatePermissions />}
                />

                <Route path='create' element={<CreateRoleManagement />} />
                <Route path='edit/:id' element={<CreateRoleManagement />} />

                <Route path='create-user' element={<CreateUser />} />
              </Route>

              <Route path='template'>
                <Route index element={<TemplateList />} />
                <Route path='create' element={<CreateTemplate />} />
              </Route>
              <Route path='questions'>
                <Route index element={<QuestionsBuilderList />} />
                <Route path='create' element={<CreateQuestionsBuilder />} />
              </Route>
              <Route path='questions-template'>
                <Route index element={<QuestionsTemplate />} />
                <Route path='create' element={<CreateQuestionsTemplate />} />
              </Route>
              <Route path='table-tree' element={<TableTree />} />
            </Route>
            {/* -----------------------HRMS------------------------- */}
            <Route path='hrms'>
              <Route
                index
                element={<Navigate replace to='/hrms/dashboard' />}
              />
              <Route path='accruals' element={<Accruals />} />

              <Route path='dashboard' element={<Dashboard />} />
              <Route path='company-certificate'>
                <Route index element={<CompanyCertificate />} />
                <Route path='create' element={<CreateCompanyCertificates />} />
                <Route
                  path='create-new'
                  element={<CompanyCertificatesCreateNew />}
                />

                <Route path='edit/:id' element={<EditCompanyCertificates />} />
                <Route path='view/:id' element={<ViewCompanyCertificate />} />
              </Route>
              <Route path='employee'>
                <Route
                  index
                  element={<Navigate replace to='/hrms/employee/dashboard' />}
                />
                <Route
                  path='dashboard'
                  element={<HRMSEmployeeMasterDashboard />}
                />
                <Route path='master-list'>
                  <Route index element={<HRMSEmployeeMaster />} />
                  <Route path='create' element={<EmployeeCreate />} />
                  <Route path='edit/:id' element={<EmployeeUpdate />} />
                </Route>
                <Route path='attendance' element={<AttendanceCalculations />} />
                <Route path='leave-request'>
                  <Route index element={<LeaveRequest />} />
                  <Route path='create' element={<CreateLeaveRequest />} />
                  <Route path='edit/:id' element={<EditLeaveRequest />} />
                  <Route
                    path='leave-settlement/:id'
                    element={<LeaveRequestSettlementDetails />}
                  />
                  <Route
                    path='leave-approval/:id'
                    element={<LeaveRequestApprovalDetails />}
                  />
                </Route>
                <Route
                  path='leave-transaction'
                  element={<LeaveTransaction />}
                />

                <Route path='leave-join'>
                  <Route index element={<LeaveJoin />} />
                  <Route path='create' element={<LeaveJoinCreate />} />
                  <Route path='edit/:id' element={<LeaveJoinEdit />} />
                </Route>
                <Route path='passport-request'>
                  <Route index element={<PassportRequest type={'HRMS'} />} />
                  <Route path='create' element={<CreatePassportRequest />} />
                  <Route
                    path='create-issue-passport'
                    element={<IssuePassportCreate />}
                  />
                  <Route
                    path='create-submission'
                    element={<SubmissionCreate />}
                  />
                  <Route path='edit/:id' element={<EditPassportRequest />} />
                </Route>

                <Route path='cancellation'>
                  <Route index element={<EmployementCancellation />} />
                  <Route path='create' element={<CreateEmpCancellation />} />
                  <Route path='edit/:id' element={<EditEmpCancellation />} />
                </Route>
                <Route path='final-settlement'>
                  <Route index element={<FinalSettlement />} />
                  <Route path='create' element={<CreateFinalSettlement />} />
                  <Route path='edit/:id' element={<EditFinalSettlement />} />
                </Route>
                <Route path='employee-studies' element={<EmployeeStudies />} />
                <Route path='training-transaction'>
                  <Route
                    index
                    element={<TrainingTransaction type={'HRMS'} />}
                  />
                  <Route
                    path='create'
                    element={<CreateTraningAndTransaction />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditTraningAndTransaction />}
                  />
                </Route>
                <Route path='certificates'>
                  <Route index element={<Certificates type={'HRMS'} />} />
                  <Route path='create' element={<CreateCertificates />} />
                  <Route path='edit/:id' element={<EditCertificates />} />
                </Route>
                <Route path='candidates'>
                  <Route index element={<Candidates type={'HRMS'} />} />
                  <Route
                    path='create'
                    element={<CreateCandidates type={'HRMS'} />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditCandidates type={'HRMS'} />}
                  />
                </Route>
                <Route path='candidates' element={<Candidates type={1} />} />
                <Route
                  path='employee-salary-calculations'
                  element={<EmployeeSalaryCalculations />}
                />
                <Route path='attendance-summary'>
                  <Route index element={<AttendanceSummary />} />
                  <Route path='create' element={<CreateAttendanceForm />} />
                  <Route
                    path='create-bulk'
                    element={<EmployeeBulkAttendance />}
                  />
                </Route>

                <Route
                  path='attendance-summary-new'
                  element={<AttendanceSummaryNew />}
                />
                <Route
                  path='employee-leave-calendar'
                  element={<EmployeeLeaveCalendar />}
                />
                <Route
                  path='employee-medical-certificate'
                  element={<EmployeeMedicalCertificate />}
                />
              </Route>
              <Route path='accruals' element={<Accruals />} />
              <Route path='job-requisition'>
                <Route
                  index
                  element={
                    <Navigate replace to='/hrms/job-requisition/candidates' />
                  }
                />
                <Route path='list' element={<JobRequisition type={'HRMS'} />} />
                <Route path='list/create' element={<CreateJobRequisition />} />
                <Route path='list/edit/:id' element={<EditJobRequisition />} />
                <Route path='jobrecruitments'>
                  <Route index element={<JobRecruitments />} />
                  <Route path='create' element={<CreateJobRecruitment />} />
                  <Route path='edit/:id' element={<EditJobRecruitment />} />
                </Route>
                <Route path='candidates'>
                  <Route index element={<Candidates type={'HRMS'} />} />
                  <Route
                    path='create'
                    element={<CreateCandidates type={'HRMS'} />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditCandidates type={'HRMS'} />}
                  />
                  <Route
                    path='document-collection'
                    element={<DocumentCollection type={'HRMS'} />}
                  />
                  <Route
                    path='interview-assessment'
                    element={<InterviewAssessmentForm />}
                  />
                  <Route
                    path='candidate-confirmation/:id'
                    element={<CandidateConfirmation />}
                  />
                </Route>
              </Route>

              <Route path='training-event'>
                <Route
                  index
                  element={<Navigate replace to='/hrms/training-event/list' />}
                />
                <Route
                  path='list'
                  element={<TrainingAndEvents type={'HRMS'} />}
                />
                <Route
                  path='list/create'
                  element={<CreateTrainingAndEvents />}
                />
                <Route
                  path='list/edit/:id'
                  element={<EditTrainingAndEvents />}
                />
              </Route>

              <Route path='masters'>
                <Route
                  index
                  element={<Navigate replace to='/hrms/masters/department' />}
                />
                <Route index element={<EmpAllMasters />} />
                <Route path='department' element={<DepartmentMasters />} />
                <Route path='designation' element={<DesignationMasters />} />
                <Route path='wps' element={<WpsMasters />} />
                <Route path='region' element={<RegionMasters />} />
                <Route path='location' element={<LocationMasters />} />
                <Route path='nationality' element={<NationalityMasters />} />
                <Route
                  path='experience-type'
                  element={<ExperienceTypeMasters />}
                />
                <Route
                  path='contract-type'
                  element={<ContractTypeMasters type={'hrms'} />}
                />
                <Route path='blood-group' element={<BloodGroupMasters />} />
                <Route
                  path='certificate-type'
                  element={<CertificateTypeMasters />}
                />
                <Route path='document-type' element={<DocumentTypeMasters />} />
                <Route
                  path='document-type-category'
                  element={<DocumentTypeCategoryMasters />}
                />

                <Route
                  path='cancellation-type'
                  element={<CancellationTypeMasters />}
                />
                <Route path='positions' element={<PositionsMaster />} />
                <Route path='companies' element={<CompanyMasters />} />
                <Route path='pay-groups' element={<PayGroupMaster />} />
                <Route
                  path='cost-centers'
                  element={<CostCenters />}
                  type={'hrms'}
                />
                <Route path='emirates' element={<Emirates type={'hrms'} />} />
                <Route path='division' element={<Division />} />
              </Route>

              <Route path='shift-time-master'>
                <Route index element={<ShiftTimeMaster />} />
                <Route path='create' element={<CreateShiftTime />} />
                <Route path='edit/:id' element={<EditShiftTime />} />
              </Route>
              <Route path='department-shifts'>
                <Route index element={<DepartmentShifts />} />
                <Route path='create' element={<CreateDepartmentShift />} />
                <Route path='edit/:id' element={<EditShiftTime />} />
              </Route>
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='employee'>
                <Route
                  index
                  element={<Navigate replace to='/pr/employee/list' />}
                />
              </Route>

              <Route
                path='approval-workflow'
                element={<HRMSEmployeeMaster />}
              />
              <Route path='letter-management' />
              <Route
                path='leave-configuration'
                element={<LeaveConfiguration />}
              />
              <Route
                path='manpower-ratecard'
                element={<ManpowerRateCardList />}
              />
              <Route
                path='manpower-ratecard/edit/:id'
                element={<EditManpowerRateCard />}
              />
              <Route path='document-management'>
                <Route
                  index
                  element={
                    <DocumentsList type={process.env.REACT_APP_ENUM_HRMS} />
                  }
                />
                <Route
                  path='create'
                  element={
                    <CreateDocuments type={process.env.REACT_APP_ENUM_HRMS} />
                  }
                />
              </Route>
            </Route>
            {/* ----------------------PR---------------------------- */}
            <Route>
              <Route path='pr'>
                <Route
                  index
                  element={<Navigate replace to='/pr/dashboard' />}
                />
                <Route path='dashboard' element={<PRDashboard />} />
                <Route path='employee'>
                  <Route
                    index
                    element={<Navigate replace to='/pr/employee/list' />}
                  />
                  <Route
                    path='list'
                    element={<HRMSEmployeeMaster type={'PR'} />}
                  />
                  <Route
                    path='list/create'
                    element={<EmployeeCreate type={'PR'} />}
                  />
                  <Route
                    path='list/edit/:id'
                    element={<EmployeeUpdate type={'PR'} />}
                  />
                  <Route path='passport-request'>
                    <Route index element={<PassportRequest type={'PR'} />} />
                    <Route
                      path='create'
                      element={<CreatePassportRequest type={'PR'} />}
                    />
                    <Route
                      path='edit/:id'
                      element={<EditPassportRequest type={'PR'} />}
                    />
                  </Route>
                  <Route path='certificates'>
                    <Route index element={<Certificates type={'PR'} />} />
                    <Route
                      path='create'
                      element={<CreateCertificates type={'PR'} />}
                    />
                    <Route
                      path='edit/:id'
                      element={<EditCertificates type={'PR'} />}
                    />
                  </Route>
                  <Route path='candidates'>
                    <Route index element={<Candidates type={'PR'} />} />
                    <Route path='create' element={<CreateCandidates />} />
                    <Route path='edit/:id' element={<EditCandidates />} />
                    <Route
                      path='document-collection'
                      element={<DocumentCollection type={'PR'} />}
                    />
                    <Route
                      path='interview-assessment'
                      element={<InterviewAssessmentForm />}
                    />
                    <Route
                      path='candidate-confirmation/:id'
                      element={<CandidateConfirmation />}
                    />
                    <Route
                      path='pr-other-conditions/:id'
                      element={<CandidatesPROtherConditions />}
                    />
                    <Route
                      path='visa-processing'
                      element={<CandidatesVisaProcessing />}
                    />
                  </Route>
                  <Route path='passport-visa'>
                    <Route index element={<PassportAndVisa />} />
                    <Route path='create' element={<CreatePassportAndVisa />} />
                    <Route path='edit/:id' element={<EditPassportAndVisa />} />
                  </Route>

                  <Route path='eid'>
                    <Route index element={<EIDApplication />} />
                    <Route path='create' element={<CreateEidApplication />} />
                    <Route path='edit/:id' element={<EditEidApplication />} />
                  </Route>

                  <Route path='training-transaction'>
                    <Route
                      index
                      element={
                        <Navigate replace to='/pr/training-transaction/list' />
                      }
                    />
                    <Route
                      path='list'
                      element={<TrainingTransaction type={'PR'} />}
                    />
                    <Route
                      path='create'
                      element={<CreateTraningAndTransaction />}
                    />
                    <Route
                      path='edit/:id'
                      element={<EditTraningAndTransaction />}
                    />
                  </Route>
                </Route>
                <Route path='trackers'>
                  <Route
                    index
                    element={<Navigate replace to='/pr/trackers/all-tracker' />}
                  />
                  <Route path='all-tracker'>
                    <Route index element={<AllTracker type={'PR'} />} />
                  </Route>
                  <Route path='passport-tracker'>
                    <Route
                      index
                      element={<EmployeePassportTracker type={'PR'} />}
                    />
                  </Route>
                  <Route path='visa-tracker'>
                    <Route index element={<VisaTracker type={'PR'} />} />
                  </Route>
                </Route>
                <Route path='masters'>
                  <Route
                    index
                    element={<Navigate replace to='/pr/masters/card-types' />}
                  />
                  <Route path='card-types' element={<CardTypes />} />
                  <Route path='expense-type' element={<ExpenseTypePr />} />
                  <Route path='policies' element={<CompanyPolicies />} />
                  <Route path='policy-type' element={<CompanyPolicyTypes />} />
                  <Route path='end-user' element={<EndUsers />} />
                  <Route path='type-of-product' element={<TypeOfProducts />} />
                  <Route
                    path='premise-location-name'
                    element={<PremiseLocations />}
                  />
                  <Route
                    path='passport-status'
                    element={<PassportStatuses />}
                  />
                  <Route
                    path='premise-location'
                    element={<PremiseLocation />}
                  />
                  <Route
                    path='medical-insurance-status'
                    element={<MedicalInsuranceStatus />}
                  />
                  <Route
                    path='medical-insurance-age-band'
                    element={<MedicalInsuranceAgeBands />}
                  />
                  <Route
                    path='medical-insurance-salary-band'
                    element={<MedicalInsuranceSalaryBands />}
                  />
                  <Route
                    path='medical-insurance-policy'
                    element={<MedicalInsurancePolicies />}
                  />
                  <Route path='policy-premiums' element={<PolicyPremium />} />
                  <Route
                    path='medical-insurance-dependencies'
                    element={<MedicalInsuranceDependencies />}
                  />
                  <Route
                    path='medical-insurance-premise-purpose'
                    element={<MedicalInsurancePremisePurpose />}
                  />
                  <Route
                    path='medical-insurance-premise-types'
                    element={<MedicalInsurancePremiseTypes />}
                  />
                  <Route path='uniform-sizes' element={<UniformSizes />} />
                  <Route path='uniform-types' element={<UniformTypes />} />
                  <Route path='insurer' element={<Insurer />} />
                  <Route path='division' element={<Division type={'pr'} />} />
                  <Route path='emirates' element={<Emirates type={'pr'} />} />
                  <Route
                    path='cost-center'
                    element={<CostCenters type={'pr'} />}
                  />
                </Route>
                <Route path='vechicle-master'>
                  <Route index element={<VehicleMaster />} />
                  <Route path='create' element={<CreateVehicleMaster />} />
                  <Route path='edit/:id' element={<EditVehicleMaster />} />
                </Route>
                {/* <Route path='document-management'>
                  <Route index element={<DocumentManagement type={'PR'} />} />
                </Route> */}
                <Route path='quota-employees-summary'>
                  <Route index element={<QuotaEmployeesList />} />
                </Route>
                <Route path='cash-account-management'>
                  <Route
                    index
                    element={
                      <Navigate replace to='/pr/cash-account-management/list' />
                    }
                  />
                  <Route path='list' element={<CashAccountMgmt />} />
                  <Route
                    path='list/create'
                    element={<CreateCashAccountManagement />}
                  />
                  <Route
                    path='list/edit/:id'
                    element={<EditCashAccountManagement />}
                  />
                </Route>
                <Route path='transaction'>
                  <Route index element={<TransactionManagement />} />
                  <Route
                    path='create'
                    element={<CreateTransactionManagement />}
                  />

                  <Route
                    path='edit/:id'
                    element={<EditTransactionManagement />}
                  />
                  <Route
                    path='view/:id'
                    element={<ViewTransactionManagement />}
                  />
                </Route>
                <Route path='document-management'>
                  <Route
                    index
                    element={
                      <DocumentsList type={process.env.REACT_APP_ENUM_PR} />
                    }
                  />
                  <Route
                    path='create'
                    element={
                      <CreateDocuments type={process.env.REACT_APP_ENUM_PR} />
                    }
                  />
                </Route>
              </Route>
            </Route>
            {/* --------------------PreSales-------------------- */}
            <Route path='presales'>
              <Route
                index
                element={<Navigate replace to='/presales/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='enquiry'>
                <Route index element={<Enquiry />} />
                <Route path='create' element={<CreateEnquiry />} />
                <Route path='view/:id' element={<ViewEnquiry />} />
                <Route
                  path='view/:id/upload-document'
                  element={<CreateEnquiryDocumentUpload />}
                />
                <Route
                  path='view/:id/create'
                  element={<TenderInspectionCheckListCreate />}
                />
                <Route
                  path='view/:id/edit'
                  element={<TenderInspectionCheckListEdit />}
                />
                <Route path='edit/:id' element={<EditEnquiry />} />
                <Route
                  path='view/:id/followup-create'
                  element={<FollowupCreate />}
                />
                <Route
                  path='view/:id/edit/:id/followup-edit'
                  element={<FollowupEdit />}
                />
              </Route>
              <Route path='manage-contracts'>
                <Route index element={<ManageContracts />} />
                <Route path='create' element={<CreateContracts />} />
                <Route path='edit/:id' element={<EditContracts />} />
                <Route path='view/:id' element={<ViewContracts />} />
              </Route>

              <Route path='clients'>
                <Route index element={<Clients />} />
                <Route path='create' element={<Createclient />} />
                <Route path='edit/:id' element={<EditClients />} />
              </Route>

              <Route path='suppliers'>
                <Route index element={<Suppliers />} />
                <Route path='create' element={<CreateSuppliers />} />
                <Route path='view/:id' element={<ViewSuppliers />} />
                <Route path='edit/:id' element={<EditSuppliers />} />
                <Route path='add-contacts/:id' element={<SuppliersSpoc />} />
                <Route
                  path='add-documents/:id'
                  element={<SupplierDocuments />}
                />
              </Route>
              <Route path='projects'>
                <Route index element={<Projects />} />
                <Route path='create' element={<CreateProjects />} />
                <Route path='view/:id' element={<ViewProjects />} />
                <Route path='edit/:id' element={<EditProjects />} />
              </Route>
              <Route path='tender-lead-enquiry'>
                <Route index element={<TenderEnquiry />} />
                <Route path='create' element={<CreateTenderEnquiry />} />
                <Route path='edit/:id' element={<EditTenderEnquiry />} />
              </Route>
              <Route path='tender-inspection-checklist'>
                <Route index element={<TenderInspectionChecklist />} />
                <Route
                  path='create'
                  element={<CreateTenderInspectionChecklist />}
                />
                <Route
                  path='view/:id'
                  element={<ViewTenderCheckListDetail />}
                />
                <Route
                  path='edit/:id'
                  element={<EditTenderInspectionChecklist />}
                />
                <Route
                  path='view/:id/building-images/create'
                  element={<CreateBuildingImages />}
                />
              </Route>
              <Route path='estimation-sheet-amc'>
                <Route index element={<EstimationSheetForAMC />} />
                <Route
                  path='create'
                  element={<CreateEstimationSheetForAMC />}
                />
                <Route path='edit/:id' element={<EditEstimationSheet />} />
              </Route>
              <Route path='estimation-sheet-mep'>
                <Route index element={<EstimationSheetForMEP />} />
                <Route
                  path='create'
                  element={<CreateEstimationSheetForMEP />}
                />
                <Route path='view/:id' element={<ViewEstimationMEP />} />
                <Route path='edit/:id' element={<EditEstimationSheetMEP />} />
              </Route>
              <Route path='CRUDEnquiry' />
              <Route
                path='manage-document'
                element={<DocumentManagement type={3} />}
              />
              <Route path='BidbondNotification' />
              <Route path='proposal'>
                <Route index element={<ProposalList />} />
                <Route path='create' element={<ProposalCreate />} />
                <Route
                  path='view/:id'
                  element={<ProposalView type={'view'} />}
                />
                <Route
                  path='edit/:id'
                  element={<ProposalView type={'edit'} />}
                />
              </Route>
              <Route path='document-management'>
                <Route
                  index
                  element={
                    <DocumentsList type={process.env.REACT_APP_ENUM_PRESALES} />
                  }
                />
                <Route
                  path='create'
                  element={
                    <CreateDocuments
                      type={process.env.REACT_APP_ENUM_PRESALES}
                    />
                  }
                />
              </Route>
              <Route path='bidbond-management'>
                <Route index element={<BidBondManagement />} />
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/presales/masters/tender-enquiry-types'
                    />
                  }
                />
                <Route
                  path='tender-enquiry-types'
                  element={<TenderEnquiryTypes />}
                />
                <Route
                  path='tender-project-types'
                  element={<TenderProjectTypes />}
                />
                <Route path='tender-formats' element={<TenderFormats />} />
                <Route
                  path='countries'
                  element={<CountryMaster type={'presales'} />}
                />
                <Route path='supplier-types' element={<SupplierTypes />} />
                <Route
                  path='tender-followup-types'
                  element={<TenderFollowUpTypesMasters />}
                />
                <Route path='tender-status' element={<TenderStatusMasters />} />
                <Route path='contact-modes' element={<ContactModesMasters />} />
                <Route
                  path='regions'
                  element={<RegionMasters type={'presales'} />}
                />
                <Route
                  path='buildings'
                  element={<Building type={'presales'} />}
                />
                <Route
                  path='locations'
                  element={<LocationMasters type={'presales'} />}
                />
                <Route
                  path='departments'
                  element={<DepartmentMasters type={'presales'} />}
                />
                <Route
                  path='emirates'
                  element={<Emirates type={'presales'} />}
                />
                <Route
                  path='division'
                  element={<Division type={'presales'} />}
                />
                <Route path='contract-scope' element={<ContactScopes />} />
                <Route
                  path='billing-frequency'
                  element={<BillingFrequency />}
                />
                <Route
                  path='contract-type'
                  element={<ContractTypeMasters type={'presales'} />}
                />
                <Route path='branch' element={<Branch />} />
                <Route path='place-of-supply' element={<PlaceOfSupply />} />
                <Route path='jurisdiction' element={<Jurisdiction />} />
                <Route path='guarantees' element={<Guarantees />} />
                <Route path='project-team-role' element={<ProjectTeamRole />} />
              </Route>
              <Route path='sales-order-distribution'>
                <Route index element={<SalesorderDistribution />} />
                <Route
                  path='view/:id'
                  element={<ViewSalesOrderDistribution />}
                />
              </Route>
            </Route>
            {/* --------------------Cafm-------------------- */}
            <Route path='cafm'>
              <Route
                index
                element={<Navigate replace to='/cafm/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='tickets'>
                <Route index element={<CafmTicket />} />
                <Route path='create' element={<CreateTicket />} />
                <Route path='view/:id' element={<ViewDetailTicket />} />
              </Route>

              <Route path='inventory-management'>
                <Route index element={<InventoryList />} />
                <Route path='create' element={<AddInventory />} />
                <Route path='edit/:id' element={<AddInventory />} />
                <Route path='view/:id' element={<InventoryView />} />
              </Route>

              <Route path='asset-history'>
                <Route index element={<AssetHistory />} />
                <Route path='view/:id' element={<AssetHistoryView />} />
                <Route path='edit/:id' element={<AssetHistoryView />} />
                <Route path='create' element={<CreateAssetHistory />} />
              </Route>
              <Route path='helpdesk-ticket'>
                <Route index element={<HelpDeskTicket />} />
                <Route path='create' element={<CreateHelpDeskTicket />} />
                <Route path='view/:id' element={<ViewHelpDeskTicket />} />
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={<Navigate replace to='/cafm/masters/countries' />}
                />
                <Route
                  path='countries'
                  element={<CountryMaster type={'cafm'} />}
                />
                <Route path='emirates' element={<Emirates type={'cafm'} />} />
                <Route
                  path='regions'
                  element={<RegionMasters type={'cafm'} />}
                />
                <Route path='zones' element={<ZoneMaster />} />
                <Route
                  path='locations'
                  element={<LocationMasters type={'cafm'} />}
                />
                <Route path='site-types' element={<SiteTypeMaster />} />
                <Route
                  path='ownership-types'
                  element={<OwnershipTypesMaster />}
                />
                <Route path='siteusage' element={<SiteUsageMaster />} />
                <Route
                  path='buildingcategory'
                  element={<BuildingCategoryMaster />}
                />
                <Route path='buildings' element={<BuildingsMaster />} />
                <Route path='sites' element={<SitesMaster />} />
                <Route
                  path='building-system'
                  element={<BuildingSystemMaster />}
                />
                <Route path='floor-type' element={<FloorTypeMaster />} />
                <Route
                  path='unit-of-measurement'
                  element={<UnitOfMeasurement type={'cafm'} />}
                />
                <Route path='wings' element={<WingMaster />} />
                <Route
                  path='compliance-certificates'
                  element={<ComplianceCertificateMaster />}
                />
                <Route path='unit-usages' element={<UnitUsageMaster />} />
                <Route
                  path='unit-configuration'
                  element={<UnitConfigurationMaster />}
                />
                <Route path='unit-types' element={<UnitTypeMaster />} />

                <Route path='priority' element={<PriorityMaster />} />
                <Route path='usage-statuses' element={<UsageStatusMaster />} />
                <Route
                  path='asset-ownership-types'
                  element={<AssetOwnershipTypeMaster />}
                />
                <Route
                  path='asset-current-conditions'
                  element={<AssetCurrentConditionMaster />}
                />
                <Route path='asset-types' element={<AssetTypeMaster />} />
                <Route path='sub-unit-types' element={<SubUnitTypeMaster />} />
                <Route path='asset-groups' element={<AssetGroupMaster />} />
                <Route path='floor' element={<Floor type={'cafm'} />} />
                <Route path='block' element={<AccomBlock type={'cafm'} />} />
                <Route path='base-unit' element={<BaseUnit />} />
              </Route>
            </Route>
            {/* --------------------Procurement-------------------- */}
            <Route path='procurement'>
              <Route
                index
                element={<Navigate replace to='/procurement/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='material-request'>
                <Route index element={<MaterialRequestList />} />
                <Route path='create' element={<CreateMaterialRequest />} />
                <Route path='edit/:id' element={<CreateMaterialRequest />} />
                <Route path='view/:id' element={<ViewMaterialRequest />} />
              </Route>

              <Route path='inventory-management'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/procurement/inventory-management/store-locations'
                    />
                  }
                />

                <Route path='store-locations'>
                  <Route index element={<StoreLocations />} />
                  <Route path='create' element={<CreateStoreLocations />} />
                  <Route path='edit/:id' element={<CreateStoreLocations />} />
                </Route>
                <Route path='inventory-items'>
                  <Route index element={<InventoryList />} />
                  <Route path='create' element={<AddInventory />} />
                  <Route path='edit/:id' element={<AddInventory />} />
                  <Route path='view/:id' element={<InventoryView />} />
                </Route>
                <Route path='inventory-transfer'>
                  <Route index element={<InventoryTransferList />} />
                  <Route path='create' element={<CreateInvetoryTransfer />} />
                  <Route path='edit/:id' element={<EditInventoryTransfer />} />
                </Route>
              </Route>
              <Route path='suppliers'>
                <Route index element={<Suppliers />} />
                <Route path='create' element={<CreateSuppliers />} />
                <Route path='view/:id' element={<ViewSuppliers />} />
                <Route path='edit/:id' element={<EditSuppliers />} />
                <Route path='add-contacts/:id' element={<SuppliersSpoc />} />
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={
                    <Navigate replace to='/procurement/masters/building' />
                  }
                />
                <Route path='building' element={<Building />} />
                <Route path='levels' element={<Levels />} />
                <Route path='levelUnits' element={<LevelUnits />} />
                <Route
                  path='costCenters'
                  element={<CostCenters type={'procurement'} />}
                />
                <Route path='supplier-items' element={<SupplierItems />} />
                <Route path='brand' element={<Brand />} />
                <Route path='tax-code' element={<TaxCodeMasters />} />
                <Route
                  path='unit-of-measurement'
                  element={<UnitOfMeasurement type={'procurement'} />}
                />
                <Route path='item-types' element={<ItemTypesMasters />} />
                <Route path='valuation-method' element={<ValuationMethod />} />
                <Route path='item-group' element={<ItemGroup />} />
                <Route path='group-types' element={<GroupTypes />} />
                <Route
                  path='companies'
                  element={<CompanyMasters type={'procurement'} />}
                />
                <Route
                  path='regions'
                  element={<RegionMasters type={'procurement'} />}
                />
                <Route
                  path='department'
                  element={<DepartmentMasters type={'procurement'} />}
                />
                <Route
                  path='location'
                  element={<LocationMasters type={'procurement'} />}
                />
                <Route
                  path='material-requisition-types'
                  element={<MaterialRequisitionType />}
                />
                <Route
                  path='material-requisition-status'
                  element={<MaterialRequisitionStatus />}
                />
                <Route path='payment-terms' element={<PaymentTerms />} />
                <Route path='currencies' element={<Currencies />} />
                <Route
                  path='local-purchase-order-status'
                  element={<LocalPurchaseOrderStatus />}
                />
                <Route path='category' element={<Category />} />
              </Route>
              <Route path='item-management'>
                <Route index element={<ItemManagementList />} />
                <Route path='create' element={<AddItems />} />
                <Route path='edit/:id' element={<EditItems />} />
                <Route path='view/:id' element={<ViewItemManagement />} />
              </Route>
              <Route path='purchase-order-view'>
                <Route index element={<PurchaseOrderViewList />} />
                <Route path='create' element={<CreatePurchaseOrderView />} />
                <Route path='edit/:id' element={<CreatePurchaseOrderView />} />
                <Route path='preview/:id' element={<PurchaseOrderPreview />} />
                <Route path='send/:id' element={<PurchaseOrderViewSendLPO />} />
              </Route>
              <Route path='request-for-quote-management'>
                <Route index element={<RequestQuoteManagement />} />
                <Route path='create' element={<CreateRequestQuote />} />
                <Route path='edit/:id' element={<CreateRequestQuote />} />
                <Route
                  path='request-for-quote-management-comparison/:id'
                  element={<ComparisonRequestQuoteManagement />}
                />
                {/* <Route path='preview/:id' element={<PurchaseOrderPreview />} />
                <Route path='send' element={<PurchaseOrderViewSendLPO />} /> */}
              </Route>
              <Route path='goods-received-note'>
                <Route index element={<GoodsReceivedNoteList />} />
                <Route path='create' element={<GoodsReceivedNotesCreate />} />
                <Route path='edit/:id' element={<GoodsReceivedNotesCreate />} />
              </Route>
              <Route path='document-management'>
                <Route
                  index
                  element={
                    <DocumentsList
                      type={process.env.REACT_APP_ENUM_PROCUREMENT}
                    />
                  }
                />
                <Route
                  path='create'
                  element={
                    <CreateDocuments
                      type={process.env.REACT_APP_ENUM_PROCUREMENT}
                    />
                  }
                />
              </Route>
            </Route>
            {/*------------------Budgeting------------------*/}
            <Route path='/Budgeting/dashboard'>
              <Route index element={<UnderConstruction />} />
            </Route>
            {/*------------------Budgeting------------------*/}
            {/*------------------Financial------------------*/}
            <Route path='/financial'>
              <Route
                index
                element={<Navigate replace to='/financial/dashboard' />}
              />
              <Route path='dashboard' element={<FADashboard />} />
              <Route path='general-ledger-accounting' element={<GLAList />} />
              <Route path='bank-accounting' element={<CBAList />} />
              <Route path='accounts-payable'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to={'/financial/accounts-payable/purchase-voucher'}
                    />
                  }
                />
                <Route path='purchase-voucher'>
                  <Route
                    path='create'
                    element={<CreatePurchaseVoucher type={1} />}
                  />
                  <Route index element={<PurchaseVoucher type={1} />} />
                  <Route
                    path='edit/:id'
                    element={<EditPurchaseVoucherList type={1} />}
                  />
                </Route>
                <Route path='purchase-return-voucher'>
                  <Route index element={<PurchaseVoucher type={3} />} />
                  <Route
                    path='create'
                    element={<CreatePurchaseVoucher type={3} />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditPurchaseVoucherList type={3} />}
                  />
                </Route>
                <Route path='direct-purchase-order'>
                  <Route index element={<PurchaseVoucher type={2} />} />
                  <Route
                    path='create'
                    element={<CreatePurchaseVoucher type={2} />}
                  />
                  <Route
                    path='edit/:id'
                    element={<ViewPurchaseVoucherList type={2} />}
                  />
                </Route>
                <Route path='supplier-invoice'>
                  <Route index element={<VendorDocuments type={2} />} />
                  <Route
                    path='create'
                    element={<AddVendorDocuments type={2} />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditVendorDocuments type={2} />}
                  />
                  <Route
                    path='view/:id'
                    element={<ViewVendorDocuments type={2} />}
                  />
                </Route>
              </Route>
              <Route path='accounts-receivable'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to={'/financial/accounts-receivable/sales-order'}
                    />
                  }
                />
                <Route path='sales-order'>
                  <Route index element={<CreateSalesOrder type={2} />} />
                  <Route path='create' element={<AddSalesOrder type={2} />} />
                  <Route path='edit/:id' element={<AddSalesOrder type={2} />} />

                  <Route
                    path='view/:id'
                    element={<ViewCreateSalesQuotation type='order' />}
                  />
                  <Route
                    path='internal-budget/:id'
                    element={<InternalBudget />}
                  />
                </Route>
                <Route path='sales-return'>
                  <Route index element={<CreateSalesOrder type={3} />} />

                  <Route path='create' element={<AddSalesOrder type={3} />} />
                  <Route path='edit/:id' element={<AddSalesOrder type={3} />} />
                  <Route
                    path='view/:id'
                    element={<ViewCreateSalesQuotation type='order' />}
                  />
                </Route>
                <Route path='sales-retention'>
                  <Route index element={<SalesRetention />} />
                  <Route path='create' element={<CreateSalesRetention />} />
                  <Route path='edit/:id' element={<EditSalesRetention />} />

                  <Route path='view/:id' element={<SalesRetentionItem />} />
                </Route>
                <Route path='sales-invoices'>
                  <Route index element={<SalesInvoicesAR />} />
                  <Route path='create' element={<CreateSalesInvoicesAR />} />
                  <Route path='edit/:id' element={<EditSalesInvoicesAR />} />
                  {/* <Route path='view/:id' element={<SalesRetentionItem />} /> */}
                </Route>
                <Route path='internal-invoice'>
                  <Route index element={<InternalInvoicesAR />} />
                  <Route path='create' element={<CreateInternalInvoicesAR />} />
                  <Route path='edit/:id' element={<EditInternalInvoiceAR />} />
                  {/* <Route path='view/:id' element={<SalesRetentionItem />} /> */}
                </Route>
                <Route path='receipts'>
                  <Route index element={<ARReceipts />} />
                  <Route path='create' element={<CreateARReceipts />} />
                  <Route path='edit/:id' element={<EditARReceipts />} />
                </Route>
                <Route path='pending-payments'>
                  <Route index element={<PendingPayments />} />
                </Route>
              </Route>
              <Route path='vendor-invoice-receivable'>
                <Route index element={<VendorDocuments type={1} />} />
                <Route
                  path='create'
                  element={<AddVendorDocuments type={1} />}
                />
                <Route
                  path='edit/:id'
                  element={<EditVendorDocuments type={1} />}
                />
                <Route
                  path='view/:id'
                  element={<ViewVendorDocuments type={1} />}
                />
              </Route>
              <Route path='bidbond-management'>
                <Route index element={<BidBondManagement />} />
                <Route path='edit/:id' element={<EditGaranteeManagement />} />
              </Route>

              <Route path='cash-and-bank'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to={'/financial/cash-and-bank/payments'}
                    />
                  }
                />
                <Route path='receipts'>
                  <Route index element={<CashBankReceipts type={1} />} />
                  <Route
                    path='create'
                    element={<AddCashBankReceipt type={1} />}
                  />

                  <Route path='view/:id' element={<ViewPettyCash type={1} />} />
                </Route>
                <Route path='payments'>
                  <Route index element={<CashBankPayments />} />
                  <Route path='create' element={<AddCashBankPayments />} />
                  <Route
                    path='edit/:id'
                    element={<EditCashBankPaymentsPage />}
                  />
                </Route>
                <Route
                  path='pending-bills-payments'
                  element={<PaymentPendingBills />}
                />
                <Route path='petty-cash'>
                  <Route index element={<PettyCash />} />
                  <Route path='create' element={<AddPettyCash />} />

                  <Route path='view/:id' element={<ViewPettyCash type={2} />} />
                </Route>
                <Route path='post-dated-receipts'>
                  <Route index element={<CashBankReceipts type={4} />} />
                  <Route
                    path='create'
                    element={<AddCashBankReceipt type={4} />}
                  />

                  <Route
                    path='view/:id'
                    element={<ViewCashBankReceipt type={4} />}
                  />
                </Route>
                <Route
                  path='pending-bills-post-dated-receipts'
                  element={<PendingBillsPostDatedReceipts />}
                />
                <Route path='post-dated-payments'>
                  <Route index element={<CashBankReceipts type={5} />} />
                  <Route
                    path='create'
                    element={<AddCashBankReceipt type={5} />}
                  />

                  <Route
                    path='view/:id'
                    element={<ViewCashBankReceipt type={5} />}
                  />
                </Route>
                <Route
                  path='pending-bills-pdc-payments'
                  element={<PendingBillsPDCPayments />}
                />
                <Route path='petty-cash-projects'>
                  <Route index element={<CashBankReceipts type={6} />} />
                  <Route
                    path='create'
                    element={<AddCashBankReceipt type={6} />}
                  />

                  <Route
                    path='view/:id'
                    element={<ViewCashBankReceipt type={6} />}
                  />
                </Route>

                <Route path='matured-pdc-pv'>
                  <Route index element={<CashBankReceipts type={7} />} />
                  <Route
                    path='create'
                    element={<AddCashBankReceipt type={7} />}
                  />

                  <Route
                    path='view/:id'
                    element={<ViewCashBankReceipt type={7} />}
                  />
                </Route>

                <Route
                  path='pending-bills-matured-pdc-pv'
                  element={<PendingBillsMaturedPDCPV />}
                />
                <Route
                  path='petty-cash-projects-view'
                  element={<PettyCashProjectsView />}
                />
              </Route>

              <Route path='final-accounts'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to={'/financial/final-accounts/trial-balance'}
                    />
                  }
                />
                <Route path='trial-balance' element={<FATrialBalance />} />
                <Route path='profit-and-loss' element={<ProfitAndLoss />} />
                <Route path='trading-account' element={<TradingAccount />} />
                <Route
                  path='trading-and-profit-and-loss'
                  element={<TradingAndProfitAndLoss />}
                />
                <Route path='balance-sheet' element={<BalanceSheet />} />
                <Route
                  path='final-account-schedules'
                  element={<FASchedules />}
                />
                <Route path='funds-flow' element={<FundsFlow />} />
                <Route path='cash-flow' element={<CashFlow />} />
                <Route
                  path='cash-flow-analysis'
                  element={<CashFlowAnalysis />}
                />
                <Route path='advance-cash-flow' element={<AdvanceCashFlow />} />
                <Route
                  path='income-expense-trends'
                  element={<IncomeExpenseTrends />}
                />
                <Route
                  path='receivable-payable-report'
                  element={<ReceivablePayableReport />}
                />
                <Route
                  path='cash-flow-calender'
                  element={<CashFlowCalenderIndex />}
                />
              </Route>

              <Route path='journal-entries'>
                <Route index element={<NonJournal />} />
                <Route path='create' element={<CreateJournalEntries />} />
                <Route path='edit/:id' element={<EditJournalEntries />} />

                <Route
                  path='non-journal/create'
                  element={<CreateJournalEntries />}
                />
                <Route
                  path='non-journal/edit/:id'
                  element={<EditJournalEntries />}
                />

                <Route path='credit-notes'>
                  <Route index element={<CreditNotes />} />
                  <Route path='create' element={<CreateCreditNote />} />
                  <Route path='edit/:id' element={<EditCreditNote />} />
                </Route>

                <Route path='debit-notes'>
                  <Route index element={<DebitNotes />} />
                  <Route path='create' element={<CreateDebitNote />} />
                  <Route path='edit/:id' element={<EditDebitNote />} />
                </Route>
              </Route>
              <Route path='reports' element={<FinancialReportsTabLayout />} />
              <Route path='client-reports' element={<FAClientReports />} />
              <Route path='chart-of-account' element={<ChartOfAccount />} />
              <Route
                path='petty-cash-management'
                element={<PettyCashManagement />}
              />
              <Route path='collection-agent'>
                <Route index element={<InvoiceCollectionAgent />} />
                <Route path='edit/:id' element={<EditCollectionAgent />} />
              </Route>

              <Route path='reconciliation'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/financial/reconciliation/bank-statements'
                    />
                  }
                />
                <Route path='bank-statements'>
                  <Route index element={<PRReconciliation />} />
                  <Route path='create' element={<PRReconciliationForm />} />
                  <Route
                    path='bank-statements/:id'
                    element={<BankStatements />}
                  />
                </Route>

                <Route path='reconciliation'>
                  <Route index element={<PRReconciliationPage />} />
                </Route>
              </Route>
              <Route path='dimension' element={<Dimension />} />
            </Route>
            {/*------------------Financial------------------*/}
            {/*------------------HSE------------------*/}
            <Route path='/hse'>
              <Route index element={<Navigate replace to='/hse/dashboard' />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='hse-committee'>
                <Route index element={<HseCommittee />} />
                <Route path='view' element={<ViewHseCommittee />} />
              </Route>
              <Route path='ppe-issued-report' element={<PPEIssuedReport />} />
              <Route path='safty-induction' element={<SafetyInduction />} />
              <Route path='safty-violation-slip'>
                <Route index element={<SafetyViolationSlip />} />
                <Route path='view' element={<ViewSafetyViolationSlip />} />
              </Route>
              <Route path='hot-work-permit'>
                <Route index element={<HseHotWorkPermit />} />
                <Route path='view' element={<ViewSafetyViolationSlip />} />
                <Route path='create' element={<HseHotWorkPermitCreate />} />
              </Route>
            </Route>
            {/*------------------HSE------------------*/}
            {/*------------------Admin------------------*/}
            <Route path='/admin'>
              <Route
                index
                element={<Navigate replace to='/admin/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='company-insurance-policy'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/admin/company-insurance-policy/list'
                    />
                  }
                />
                <Route path='list'>
                  <Route index element={<CompanyInsurancePolicyList />} />
                  <Route
                    path='create'
                    element={<AddCompanyInsurancePolicy />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditCompanyInsurancePolicy />}
                  />
                  <Route
                    path='view/:id'
                    element={<ViewCompanyInsurancePolicy />}
                  />
                </Route>
                <Route path='comparison'>
                  <Route index element={<ComparisonPolicy />} />
                  <Route path='create' element={<AddComparisonPolicy />} />
                  <Route path='edit/:id' element={<EditComparisonPolicy />} />
                  <Route path='view/:id' element={<ViewComparisonPolicy />} />
                </Route>
              </Route>
              <Route path='company-medical-insurance-policy'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/admin/company-medical-insurance-policy/list'
                    />
                  }
                />
                <Route path='list'>
                  <Route index element={<CompanyMedicalInsurancePolicy />} />
                  <Route
                    path='create'
                    element={<CreateCompanyMedicalInsurance />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditCompanyMedicalInsurancePolicy />}
                  />
                  <Route
                    path='view/:id'
                    element={<ViewCompanyMedicalInsurancePolicy />}
                  />
                </Route>
                <Route path='medical-insurance-policy-premium-calculator'>
                  <Route index element={<MIPPremiumCalculator />} />
                  <Route path='create' element={<AddMIPPremiumCalculator />} />
                  <Route
                    path='edit/:id'
                    element={<EditMIPPremiumCalculator />}
                  />
                  <Route
                    path='view/:id'
                    element={<ViewMIPPremiumCalculator />}
                  />
                </Route>
                <Route path='employee-wise-premium-calculator'>
                  <Route index element={<EmployeeWiseMIPCalculator />} />
                  <Route
                    path='create'
                    element={<AddEmployeeWiseMIPCalculator />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditEmployeeWiseMIPCalculator />}
                  />
                  <Route
                    path='view/:id'
                    element={<ViewEmployeeWiseMIPCalculator />}
                  />
                </Route>
              </Route>
              <Route path='accommodation'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to={'/admin/accommodation/accomodations'}
                    />
                  }
                />

                {/* TODO: For Feature Use <Route path='summary' element={<AccomSummary />} /> */}
                <Route path='accomodations'>
                  <Route index element={<AccommodationList />} />
                  <Route path='create' element={<AddAccommodation />} />
                  <Route path='edit/:id' element={<EditAccommodation />} />
                  <Route path='view/:id' element={<ViewAccommodation />} />
                </Route>
                <Route path='block' element={<AccomBlock type={'admin'} />} />
                <Route path='room' element={<AccomRoom />} />
                <Route path='room-capacity' element={<AccomRoomCapacity />} />
                <Route path='camp'>
                  <Route index element={<AccomCamps />} />
                  <Route path='create' element={<AddAccomCamps />} />
                  <Route path='edit/:id' element={<EditAccomCamps />} />
                  <Route path='view/:id' element={<ViewAccomCamps />} />
                </Route>
              </Route>
              <Route path='uniform-accessories-requistions'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/admin/uniform-accessories-requistions/list'
                    />
                  }
                />
                <Route path='list'>
                  <Route index element={<UniformRequistionList />} />
                  <Route path='create' element={<AddUniformRequistions />} />
                  <Route path='edit/:id' element={<EditUniformRequisition />} />
                  <Route path='view/:id' element={<ViewUniformRequisition />} />
                </Route>
                <Route path='uniforms'>
                  <Route index element={<Uniforms />} />
                  <Route path='create' element={<AddUniforms />} />
                  <Route path='edit/:id' element={<EditUniforms />} />
                  <Route path='view/:id' element={<ViewUniforms />} />
                </Route>
                <Route path='return'>
                  <Route index element={<UniformReturn />} />
                  <Route path='create' element={<AddUniformReturn />} />
                  <Route path='edit/:id' element={<EditUniformReturn />} />
                  <Route path='view/:id' element={<ViewUniformReturn />} />
                </Route>
              </Route>
              <Route path='events-calendar'>
                <Route index element={<EventsCalender />} />
                <Route path='create' element={<AddEventsCalendar />} />
                <Route path='edit/:id' element={<EditEventsCalendar />} />
                <Route path='view/:id' element={<ViewEventsCalendar />} />
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/admin/masters/admin-vehicle-master'
                    />
                  }
                />
                <Route path='vehicles' element={<Vehicles />} />
                <Route path='admin-vehicle-master' element={<VehicleTypes />} />
                <Route path='vehicle-category' element={<VehicleCategory />} />
                <Route path='vehicle-brand' element={<VehicleBrands />} />
                <Route path='vehicle-statuses' element={<VehicleStatuses />} />
                <Route path='drivers' element={<Drivers />} />
                <Route path='insurance' element={<VehicleInsurance />} />
                <Route
                  path='vehicle-advertisement'
                  element={<VehicleAdvertisement />}
                />
                <Route path='traffic-sources' element={<TrafficSource />} />
                <Route
                  path='vehicle-suppliers'
                  element={<VehicleSuppliers />}
                />
                <Route path='floor' element={<Floor />} type={'admin'} />
                <Route path='level' element={<Level />} />
              </Route>

              <Route path='vehicles'>
                <Route
                  index
                  element={
                    <Navigate replace to='/admin/vehicles/own-vehicles' />
                  }
                />
                <Route path='own-vehicles'>
                  <Route index element={<OwnVehicles />} />
                  <Route path='create' element={<AddOwnVehicle />} />
                  <Route path='edit/:id' element={<EditOwnVehicles />} />
                  <Route path='view/:id' element={<ViewOwnvehicle />} />
                </Route>
                <Route path='hired-vehicles'>
                  <Route index element={<HiredVechicles />} />
                  <Route path='create' element={<AddHiredVechicles />} />
                  <Route path='edit/:id' element={<EditHiredVehicles />} />
                  <Route path='view/:id' element={<ViewHiredOwnVehicles />} />
                </Route>
                <Route path='month-updates'>
                  <Route index element={<MonthUpdate />} />
                  <Route path='create' element={<AddMonthUpdate />} />
                  <Route path='edit/:id' element={<EditMonthUpdate />} />
                  <Route path='view/:id' element={<ViewMonthUpdate />} />
                </Route>
                <Route path='asateel' element={<Asateel />} />
                {/* <Route path='traffic-fines' element={<TrafficFines />} />
                <Route
                  path='invoice-submission'
                  element={<InvoiceSubmission />}
                /> */}
                <Route path='advertisement'>
                  <Route index element={<Advertisement />} />
                  <Route path='create' element={<AddAdvertisement />} />
                  <Route path='edit/:id' element={<EditAdvertisement />} />
                  <Route path='view/:id' element={<ViewAdvertisement />} />
                </Route>
              </Route>
            </Route>
            {/*------------------Project Division------------------*/}
            <Route path='/project-Division'>
              <Route
                index
                element={<Navigate replace to='/project-division/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='project-division'>
                <Route index element={<ProjectDivisionList />} />
                <Route
                  path='manage-assignee'
                  element={<ManageAssigneeList />}
                />
                <Route path='project-plan/:id' element={<ProjectPlanList />} />
                <Route
                  path='add-project-activity/:id'
                  element={<ProjectActivityList />}
                />
                <Route path='project-cost-tracking'>
                  <Route
                    index
                    element={
                      <Navigate
                        replace
                        to='/project-division/project-division/project-cost-tracking/project-main-cost'
                      />
                    }
                  />
                  <Route
                    path='project-main-cost'
                    element={<ProjectMainCost />}
                  />
                  <Route
                    path='budget'
                    element={<BudgetProjectCostTracking />}
                  />
                  {/* This is Common Component for all */}
                  <Route
                    path='Materials/:id'
                    element={<ManpowerProjectCostTracking type={'materials'} />}
                  />
                  <Route
                    path='manpower/:id'
                    element={<ManpowerProjectCostTracking type={'manpower'} />}
                  />
                  <Route
                    path='machineries/:id'
                    element={
                      <ManpowerProjectCostTracking type={'machineries'} />
                    }
                  />
                  <Route
                    path='general/:id'
                    element={<ManpowerProjectCostTracking type={'general'} />}
                  />
                  <Route
                    path='others/:id'
                    element={<ManpowerProjectCostTracking type={'others'} />}
                  />
                  {/* This is Common Component for all */}
                  <Route
                    path='PettyCash/:id'
                    element={<PettyCashProjectCostTracking />}
                  />
                </Route>
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/project-division/masters/project-boq-head-master'
                    />
                  }
                />
                <Route
                  path='project-boq-head-master'
                  element={<BoqHeadMasterList />}
                />
                <Route
                  path='project-boq-package-master'
                  element={<BoqPackageMaster />}
                />
                <Route
                  path='project-boq-sub-head-master'
                  element={<BoqSubHeadMaster />}
                />
                <Route
                  path='boq-line-item-master'
                  element={<BoqLineItemMaster />}
                />
              </Route>
            </Route>
            {/*------------------ELV------------------*/}
            <Route path='/elv'>
              <Route index element={<Navigate replace to='/elv/dashboard' />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='inspection-report'>
                <Route index element={<InspectionReport />} />
                <Route path='create' element={<AddInspectionReport />} />
                <Route path='view/:id' element={<ViewInspectionReport />} />
              </Route>
              <Route path='incident-report'>
                <Route index element={<IncidentReport />} />
                <Route path='create' element={<AddIncidentReport />} />
                <Route path='edit/:id' element={<AddIncidentReport />} />
                <Route path='incident/:id' element={<ViewIncident />} />
                <Route path='injuries/:id' element={<ViewInjuries />} />
                <Route path='remarks/:id' element={<ViewIncidentRemarks />} />
              </Route>
              <Route path='enquiry'>
                <Route index element={<Enquiry />} />
                <Route path='create' element={<CreateEnquiry />} />
                <Route path='view/:id' element={<ViewEnquiry />} />
                <Route path='edit/:id' element={<EditEnquiry />} />
              </Route>
              <Route path='site-enquiry'>
                <Route index element={<Enquiry />} />
                <Route path='create' element={<CreateEnquiry />} />
                <Route path='view/:id' element={<ViewEnquiry />} />
                <Route path='edit/:id' element={<EditEnquiry />} />
              </Route>
              <Route path='estimation-sheet-amc'>
                <Route index element={<EstimationSheetForAMC />} />
                <Route
                  path='create'
                  element={<CreateEstimationSheetForAMC />}
                />
                <Route path='edit/:id' element={<EditEstimationSheet />} />
              </Route>
              <Route path='snag'>
                <Route
                  index
                  element={<Navigate replace to='/elv/snag/snag-report' />}
                />
                <Route path='snag-report'>
                  <Route index element={<SnagReport module={1} />} />
                  <Route path='snag-tab/:id' element={<SnagTab type={1} />} />
                  <Route
                    path='functional-test-comments-tab/:id'
                    element={<SnagTab type={2} />}
                  />
                </Route>
              </Route>
              <Route path='create-sales-quotation'>
                <Route index element={<CreateSalesQuotation type={4} />} />
                <Route
                  path='view/:id'
                  element={<ViewCreateSalesQuotation type='quotation' />}
                />
                <Route
                  path='internal-budget/:id'
                  element={<CreateSalesQuotationInternalBudget />}
                />
              </Route>
              <Route path='create-sales-order'>
                <Route index element={<CreateSalesOrder type={4} />} />
                <Route path='create' element={<AddSalesOrder type={4} />} />
                <Route path='edit/:id' element={<AddSalesOrder type={4} />} />

                <Route
                  path='view/:id'
                  element={<ViewCreateSalesQuotation type='order' />}
                />
              </Route>
              <Route path='material-request'>
                <Route index element={<MaterialRequestList />} />
                <Route path='create' element={<CreateMaterialRequest />} />
                <Route path='edit/:id' element={<CreateMaterialRequest />} />
                <Route path='view/:id' element={<ViewMaterialRequest />} />
              </Route>
              <Route path='certificates'>
                <Route index element={<Certificates type={'ELV'} />} />
                <Route path='create' element={<CreateCertificates />} />
                <Route path='edit/:id' element={<EditCertificates />} />
              </Route>
              <Route path='company-certificate'>
                <Route index element={<CompanyCertificate />} />
                <Route path='create' element={<CreateCompanyCertificates />} />
                <Route path='edit/:id' element={<EditCompanyCertificates />} />
              </Route>
              <Route path='job-requisition'>
                <Route
                  index
                  element={<Navigate replace to='/elv/job-requisition/list' />}
                />
                <Route path='list' element={<JobRequisition type={'ELV'} />} />
                <Route path='create' element={<CreateJobRequisition />} />
              </Route>
            </Route>
            {/*------------------FM Division------------------*/}
            <Route path='/fm-division'>
              <Route
                index
                element={<Navigate replace to='/fm-division/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='enquiry'>
                <Route index element={<Enquiry />} />
                <Route path='create' element={<CreateEnquiry />} />
                <Route path='view/:id' element={<ViewEnquiry />} />
                <Route path='edit/:id' element={<EditEnquiry />} />
              </Route>
              <Route path='work-order'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/fm-division/work-order/work-order-list'
                    />
                  }
                />
                <Route path='work-order-list'>
                  <Route index element={<WorkOrder />} />
                  <Route path='create' element={<CreateWorkOrder />} />
                  <Route path='view/:id' element={<ViewWorkOrder />} />
                  <Route path='edit/:id' element={<EditWorkOrder />} />
                </Route>

                <Route path='create-check-in-form'>
                  <Route
                    index
                    element={<CreateCheckInForm type='checkinforms' />}
                  />
                  <Route path='create' element={<AddCheckInForm />} />
                  <Route path='view/:id' element={<ViewCreateCheckInForm />} />
                </Route>
                <Route path='create-check-out-form'>
                  <Route
                    index
                    element={<CreateCheckInForm type='checkoutforms' />}
                  />
                  <Route path='create' element={<AddCheckOutForm />} />
                  <Route path='view/:id' element={<CreateCheckoutView />} />
                </Route>
              </Route>
              <Route path='snag-report'>
                <Route index element={<SnagReport module={2} />} />
                <Route path='create' element={<AddSnagReport />} />
                <Route path='edit/:id' element={<AddSnagReport />} />
                <Route path='snag-tab/:id' element={<SnagTab type={1} />} />
                <Route
                  path='functional-test-comments-tab/:id'
                  element={<SnagTab type={2} />}
                />
              </Route>
              <Route path='create-sales-quotation'>
                <Route index element={<CreateSalesQuotation type={3} />} />
                <Route path='create' element={<AddCreateSalesQuotation />} />
                <Route path='edit/:id' element={<ViewSalesQuotation />} />
                <Route
                  path='view/:id'
                  element={<ViewCreateSalesQuotation type='quotation' />}
                />
                <Route
                  path='internal-budget/:id'
                  element={<CreateSalesQuotationInternalBudget />}
                />
              </Route>
              <Route path='create-sales-order'>
                <Route index element={<CreateSalesOrder type={1} />} />
                <Route path='create' element={<AddSalesOrder type={1} />} />
                <Route path='edit/:id' element={<AddSalesOrder type={1} />} />
                <Route
                  path='view/:id'
                  element={<ViewCreateSalesQuotation type='order' />}
                />
              </Route>
              <Route path='material-request'>
                <Route index element={<MaterialRequestList />} />
                <Route path='create' element={<CreateMaterialRequest />} />
                <Route path='edit/:id' element={<CreateMaterialRequest />} />
                <Route path='view/:id' element={<ViewMaterialRequest />} />
              </Route>
              <Route path='certificates'>
                <Route index element={<Certificates type={'FM'} />} />
                <Route path='create' element={<CreateCertificates />} />
                <Route path='edit/:id' element={<EditCertificates />} />
              </Route>
              <Route path='company-certificate'>
                <Route index element={<CompanyCertificate />} />
                <Route path='create' element={<CreateCompanyCertificates />} />
                <Route path='edit/:id' element={<EditCompanyCertificates />} />
              </Route>
              <Route path='job-requisition'>
                <Route
                  index
                  element={
                    <Navigate replace to='/fm-division/job-requisition/list' />
                  }
                />
                <Route path='list' element={<JobRequisition type={'FM'} />} />
                <Route path='create' element={<CreateJobRequisition />} />
              </Route>
              <Route path='estimation-sheet-amc'>
                <Route index element={<EstimationSheetForAMC />} />
                <Route
                  path='create'
                  element={<CreateEstimationSheetForAMC />}
                />
                <Route path='edit/:id' element={<EditEstimationSheet />} />
              </Route>
            </Route>
            {/*------------------Reports Start ------------------*/}
            <Route path='/reports'>
              <Route
                index
                element={<Navigate replace to='/reports/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route
                path='sap-accounts-payable-open-items-report'
                element={<SAPAccountsPayableOpenItemsReport />}
              />

              <Route
                path='accounts-payable-reconciliation'
                element={<AccountsPayableReconciliation />}
              />

              <Route
                path='accounts-payable-trial-balance'
                element={<AccountsPayableTrialBalance />}
              />

              <Route
                path='accounts-receivable-reconciliation'
                element={<AccountsReceivableReconciliation />}
              />

              <Route
                path='bank-reconciliation-statement'
                element={<BankReconciliationStatement />}
              />

              <Route path='cash-trial-balance' element={<CashTrialBalance />} />

              <Route path='cost-audit-report' element={<CostAuditReport />} />

              <Route path='cost-center-report' element={<CostCenterReport />} />

              <Route
                path='fixed-assets-depreciation-report'
                element={<FixedAssetsDepreciationReport />}
              />

              <Route
                path='balance-sheet-report'
                element={<BalanceSheetReports />}
              />
              <Route
                path='trial-balance-report'
                element={<TrialBalanceReport />}
              />

              <Route
                path='financial-statement-of-accounts'
                element={<FinancialStatementOfAccounts />}
              />
            </Route>
            {/*------------------Reports End ------------------*/}
            {/* QA and QC */}
            <Route path='/qaqc'>
              <Route
                index
                element={<Navigate replace to='/qaqc/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='qa-and-qc-module'>
                <Route index element={<QACModule />} />
                <Route path='view/:id' element={<ViewQACModule />} />
              </Route>
              <Route path='customer-satisfaction'>
                <Route index element={<CustomerSatisfaction />} />
                <Route path='view/:id' element={<ViewCustomerSatisfaction />} />
              </Route>
              <Route path='customer-satisfaction-report'>
                <Route index element={<CustomerSatisfactionReport />} />
                <Route path='view' element={<ViewCustomerSatisfaction />} />
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={<Navigate replace to='/qaqc/masters/customers' />}
                />
                <Route path='customers' element={<Customers />} />
                <Route path='areas' element={<Areas />} />
                <Route path='sub-areas' element={<SubAreas />} />
              </Route>
            </Route>
            {/* QA and QC */}
            {/*------------------ESS Portal------------------*/}
            <Route path='essPortal'>
              <Route index element={<EssLogin />} />
              <Route path='dashboard' element={<EssDashboard />} />
            </Route>{' '}
          </Routes>
        </Router>{' '}
      </SettingsProvider>
    </>
  );
};

export default App;
