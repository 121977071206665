import React, { useState, useEffect } from 'react';
import FilterButtonSearch from '../FilterButtonSearch';
import { Stack } from '@mui/material';
import { Icon } from '@iconify/react';

// ];

const CheckboxSearchFilter = ({ data }) => {
  const [options, setOptions] = useState([]);
  const [updatedsoptions, setUpdatedsOptions] = useState([]);

  const handleCheckboxChange = (id, i) => {
    const updatedOptions = [...options];
    updatedOptions[i].child = options[i].child.map((option) =>
      option.id === id ? { ...option, checked: !option.checked } : option,
    );
    setOptions(updatedOptions);
    setUpdatedsOptions(updatedOptions[i].child);
  };

  const handleDelete = () => {
    setOptions(data);
    setUpdatedsOptions([]);
  };

  useEffect(() => {
    setOptions(data);
  }, []);

  return (
    <Stack spacing={1} direction='row' alignItems='center'>
      {options.map((select, i) => {
        return (
          <FilterButtonSearch
            name={select.name}
            data={select.child}
            key={i}
            column={2}
            handleCheckboxChange={(id) => handleCheckboxChange(id, i)}
          />
        );
      })}
    </Stack>
  );
};

export default CheckboxSearchFilter;
