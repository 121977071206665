import React from 'react';
import PreviewSupplierAddForm from './PreviewSupplierAddForm.tsx';
import PreviewSuppliersItemList from './PreviewSuppliersItemList.tsx';

const PreviewSuppliersDetail = ({
  item,
  supplierData,
  supplierSingleData,
  getSuppliers,
  loading,
}) => {
  return (
    <>
      <PreviewSupplierAddForm
        supplierData={supplierData}
        supplierSingleData={supplierSingleData}
        getSuppliers={getSuppliers}
        loading={loading}
      />
      <PreviewSuppliersItemList
        item={item}
        supplierData={supplierData}
        supplierSingleData={supplierSingleData}
        getSuppliers={getSuppliers}
      />
    </>
  );
};

export default PreviewSuppliersDetail;
