import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import ProgressCircleStep from '../../../components/page/ProgressCircleStep.tsx';
import { Tab, Box, Button } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import PreviewSuppliersDetail from './Form/PreviewSuppliersDetail.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';

const PurchaseOrderPreview = () => {
  const [value, setValue] = useState('');
  const [supplierData, setSupplierData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [supplierSingleData, setSupplierSingleData] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const crud = new Crud_Service();

  useEffect(() => {
    document.title = 'Purchase Order Preview | Procurement';
    getSuppliers();
  }, []);


  //Set INitially SupplierData To The Form Fields
  useEffect(() => {
    if (value) {
      handleTabChangeApiCall(value);
    }
  }, [value]);

  const getSuppliers = async () => {
    await crud.getAll(
      'lposuppliers',
      { localPurchaseOrderId: id },
      (err, res) => {
        if (res?.status === 200) {
          const fetchedData = res?.data?.data;
          setSupplierData(fetchedData);
          // Set the initial tab to the first supplier's lpoSupplierId
          if (fetchedData.length > 0) {
            setValue(fetchedData[0]?.lpoSupplierId);
          }
        } else {
          toast.error('something_Went_Wrong');
        }
      }
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleTabChangeApiCall(newValue);
  };

  const handleTabChangeApiCall = async (lpoSupplierId) => {
    setSupplierSingleData({})
    setLoading(true);
    await crud.getSingle('lposuppliers', lpoSupplierId, (err, res) => {
      if (res?.status === 200) {
        const fetchedData = res?.data || {};
        setSupplierSingleData(fetchedData);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error('something_Went_Wrong');
      }
    });
  };

  const handleNext = () => {
    const currentIndex = supplierData?.findIndex(
      (tab) => tab?.lpoSupplierId.toString() === value
    );
    const nextIndex = currentIndex + 1;
    if (nextIndex < supplierData.length) {
      const nextSupplierId = supplierData[nextIndex]?.lpoSupplierId.toString();
      setValue(nextSupplierId);
      handleTabChangeApiCall(nextSupplierId);
    }
  };

  const handlePrevious = () => {
    const currentIndex = supplierData?.findIndex(
      (tab) => tab?.lpoSupplierId.toString() === value
    );
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      const prevSupplierId = supplierData[prevIndex]?.lpoSupplierId.toString();
      setValue(prevSupplierId);
      handleTabChangeApiCall(prevSupplierId);
    }
  };

  const currentIndex = supplierData?.findIndex(
    (tab) => tab.lpoSupplierId.toString() === value
  );

  return (
    <DashboardLayout
      title='Purchase Order Preview'
      actionButtons={
        <CreateButton
          name='Send LPO'
          color='secondary'
          onClick={() =>
            navigate(`/procurement/purchase-order-view/send/${id}`)
          }
        />
      }
    >
      <ProgressCircleStep numElements={6} />

      <Box sx={{ width: '100%', '& .MuiTabs-indicator': { display: 'none' } }}>
        <TabContext value={value.toString()}>
          <Box
            className='flex justify-between items-center p-2'
            sx={{
              bgcolor: 'background.light',
              borderRadius: '8px',
              border: '1px solid',
              borderColor: 'border.main',
            }}
          >
            <Button onClick={handlePrevious} disabled={currentIndex === 0}>
              Previous
            </Button>

            <TabList
              onChange={handleChange}
              sx={{
                minHeight: 'inherit',
                '& .MuiButtonBase-root': {
                  bgcolor: 'background.white',
                  fontWeight: 700,
                  margin: '0px 10px',
                  transition: '0.5s all',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'text.white',
                  },
                },
              }}
            >
              {supplierData?.map((supplier) => (
                <Tab
                  key={supplier?.lpoSupplierId}
                  label={supplier?.officialSupplierName}
                  value={supplier?.lpoSupplierId.toString()}
                />
              ))}
            </TabList>

            <Button
              onClick={handleNext}
              disabled={currentIndex === supplierData?.length - 1}
            >
              Next
            </Button>
          </Box>

          {supplierData?.map((supplier) => (
            <TabPanel
              key={supplier?.lpoSupplierId}
              value={supplier?.lpoSupplierId.toString()}
              className='p-0 my-5'
            >
              <PreviewSuppliersDetail
                item={supplier}
                supplierData={supplierData}
                supplierSingleData={supplierSingleData}
                getSuppliers={getSuppliers}
                loading={loading}
              />
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </DashboardLayout>
  );
};

export default PurchaseOrderPreview;
