import React from 'react';
import { Box } from '@mui/material';

const LoginLayout = ({ children, lottieFiles, lottieHide }) => {
  return (
    <Box className={`flex h-screen`}>
      <Box
        className={`flex overflow-hidden rounded-xl sm:flex-col sm:rounded-none w-full h-screen`}
      >
        {!lottieHide && (
          <>
            <Box className='w-full p-5 flex  overflow-hidden flex-1 flex-col'>
              <Box>{lottieFiles}</Box>
            </Box>
          </>
        )}
        <Box className='w-full flex justify-center py-10 items-center bg-white sm:p-5 flex-1'>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginLayout;
