import React, { useEffect } from 'react';
import DashboardLayout from './../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import AddCreateSalesQuotation from './AddCreateSalesQuotation.tsx';
import CreateSalesQuotationList from './CreateSalesQuotationList.tsx';
import { Box } from '@mui/material';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';

const CreateWorkOrder = (props) => {
  const { type } = props;
  const [getData, setGetData] = React.useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    document.title =
      type === 2
        ? 'Sales Order | Accounts Receivable'
        : type === 3
          ? 'Sales Return | Accounts Receivable'
          : 'Sales Quotation | FM Division';
  });

  return (
    <DashboardLayout
      title={`Sales ${type === 2 ? 'Order' : type === 3 ? 'Return' : 'Quotation'
        }`}
      hasSubmenu={type !== 4}
      menu={type === 1 ? Nav[12].child[1].children : Nav[7].child[3].children}
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create Sales Quotation'
              onClick={() => navigate('create')}
            />
          </Box>
        </>
      }
    >
      <CreateSalesQuotationList type={type} getData={getData} />
    </DashboardLayout>
  );
};

export default CreateWorkOrder;
