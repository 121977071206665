import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../../components/small/BackToButton.jsx';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ViewTable } from '../../../../../components/_form/FormElements.jsx';
import { Grid } from '@mui/material';
import DashboardLayout from './../../../../../components/DashboardLayout.tsx';
import ViewCashBankReceiptList from './ViewCashBankReceiptList.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const ViewCashBankReceipt = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const crud = new Crud_Service();
  const [viewData, setViewData] = useState({});

  const { control, handleSubmit, register, setValue, reset, watch } = useForm();
  const viewDataFetch = async () => {
    crud.getSingle('financialtransactions', id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
      } else {
        setViewData([]);
      }
    });
  };
  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);


  useEffect(() => {
    document.title = 'View | Receipts| Cash and Bank | Financial';
  });

  return (
    <>
      <DashboardLayout title='View Cash and Bank Receipt'>
        {/* glasscard */}
        <GlassCard className='p-3 mb-4'>
          <BackToButton
            title='Back to List'
            onClick={() => navigation(-1)}
            className='font-bold mb-3 px-1'
          />

          <Grid container spacing={3}>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Finacial Transaction Code',
                    title: viewData?.financialTransactionCode || 'N/A',
                  },
                  {
                    label: 'Cheque Date',
                    title: viewData?.chequeDate || 'N/A',
                  },
                  {
                    label: 'Payee User',
                    title: viewData?.payeeUserName || 'N/A',
                  },
                ]}
              />
            </Grid>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Request Date',
                    title: viewData?.requestDate,
                  },
                  {
                    label: 'Currency',
                    title: viewData?.currencyName || 'N/A',
                  },
                  {
                    label: 'PDC No',
                    title: viewData?.pdcNo || 'N/A',
                  },
                ]}
              />
            </Grid>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Bank Name',
                    title: viewData?.bankName || 'N/A',
                  },

                ]}
              />
            </Grid>
          </Grid>
        </GlassCard>
        {/* glasscard */}
        <ViewCashBankReceiptList financialTransactionId={id} />
      </DashboardLayout>
    </>
  );
};

export default ViewCashBankReceipt;
