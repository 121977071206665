import React from 'react';
import MuiDialogOne from './../../../components/_mui/MuiDialogOne';
import { ViewTable } from '../../../components/_form/FormElements';

const Dialogs = ({ productView, setProductView, viewData }) => {
  return (
    <>
      <MuiDialogOne
        title='View Details'
        open={productView}
        onClose={() => setProductView(!productView)}
      >
        {productView && <ViewTable rows={viewData} />}
      </MuiDialogOne>
    </>
  );
};

export default Dialogs;
