import React from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { Button, Grid } from '@mui/material';
import MuiDatePickerOne from '../../../../../components/_mui/MuiDatePickerOne.tsx';
import FmAutoComplete from '../../../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';

const GridSize = 2.4;

const AddPendingBillsPayments = () => {
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
  };

  return (
    <GlassCard
      className='p-3'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item md={GridSize}>
          <FmTextField label='Voucher #' name='voucher' control={control} />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            label='Account Name'
            name='accountname'
            control={control}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField label='Amount' name='amount' control={control} />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField label='Cheque No' name='chequeno' control={control} />
        </Grid>
        <Grid item md={GridSize}>
          <FmAutoComplete
            label='Branch'
            name='branch'
            control={control}
            options={[
              {
                label: 'Branch 1',
                value: 1,
              },
              {
                label: 'Branch 2',
                value: 2,
              },
              {
                label: 'Branch 3',
                value: 2,
              },
            ]}
            displayField='label'
            optionFields={['label']}
            valueKey='value'
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmTextField label='PDC No' name='pdcno' control={control} />
        </Grid>

        <Grid item md={GridSize}>
          <FmTextField label='Created By' name='createdby' control={control} />
        </Grid>

        <Grid item md={GridSize}>
          <FmTextField
            label='Modified By'
            name='modifiedBy'
            control={control}
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmAutoComplete
            label='Authorization Status'
            name='authStatus'
            control={control}
            options={[
              {
                label: 'Authorization Status 1',
                value: 1,
              },
              {
                label: 'Authorization Status 2',
                value: 2,
              },
              {
                label: 'Authorization Status 3',
                value: 2,
              },
            ]}
            displayField='label'
            optionFields={['label']}
            valueKey='value'
          />
        </Grid>

        <Grid item md={GridSize}>
          <MuiDatePickerOne label='Create Date and Time' />
        </Grid>

        <Grid item md={GridSize}>
          <MuiDatePickerOne label='Modified Date and Time ' />
        </Grid>

        <Grid item md={GridSize}>
          <FmTextField label='Suspened' name='suspened' control={control} />
        </Grid>

        <Grid item md={GridSize}>
          <FmTextField label='Balance' name='balance' control={control} />
        </Grid>

        <Grid item md={GridSize}>
          <Button variant='contained' fullWidth type='submit'>
            Create
          </Button>
        </Grid>
      </Grid>
    </GlassCard>
  );
};

export default AddPendingBillsPayments;
