import React, { useState } from 'react';
import ViewSnagReportLayout from './ViewSnagReportLayout.tsx';
import { Grid, Button, Box } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import { useLocation } from 'react-router-dom';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import { snagReportvalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const SnagTab = ({ type }) => {
  const [, setCreatingRow] = useState(null);
  const crud = new Crud_Service();
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const location = useLocation();
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('snagreportdetails');

  const {
    handleSubmit: createSubmit,
    control: createControl,
    reset: resetSnagReportDetails,
  } = useForm({
    resolver: yupResolver(snagReportvalidationSchema),
    mode: 'onChange',
  });

  const { handleSubmit, control, setValue } = useForm();

  const handleEdit = (row) => {
    setEditingRowId(row?.snagReportDetailId);
    setEditingRowData({ ...row });

    // Use setValue from react-hook-form for setting form values
    Object.keys(row).forEach((key) => {
      setValue(key, row[key]); // If using react-hook-form's setValue
    });
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  // Define the columns

  const handleEditItem = async (values) => {
    // Extract projectDivisionId from the original row data
    const combinedData = {
      ...values,
      tabTypeId: type,
      status: 2,
    };
    await crud.update(
      'snagreportdetails',
      editingRowData?.snagReportDetailId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Snag Updated Successfully');
          fetchData();
          setEditingRowId(null);
          setEditingRowData(null);
        } else {
        }
      }
    );
  };

  const handleDeleteItems = async (values) => {
    crud.remove('snagreportdetails', values.snagReportDetailId, (err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Item deleted Successfully');
      } else {
      }
    });
  };

  const handleSubmitProject = async (values) => {
    startCreateLoading();
    const combinedData = {
      tabTypeId: type,
      ...values,
    };
    await crud.create('snagreportdetails', combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Snag Report Details Created successfully');

        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDeleteItems(props),
      danger: true,
    },
  ];
  const columns = [
    {
      accessorKey: 'snagReportDetailName',
      header: 'Snag Report Detail Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmTextField
            name='snagReportDetailName'
            control={control}
            label='Snag Report Detail Name'
            required
          />
        ) : (
          cell.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'equipment',
      header: 'Equipment',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmTextField name='equipment' control={control} label='Equipment' />
        ) : (
          cell.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmTextField
            name='description'
            control={control}
            label='Description'
            required
          />
        ) : (
          cell.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'comments',
      header: 'Comments',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmTextField name='comments' control={control} label='Comments' />
        ) : (
          cell.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'blockName',
      header: 'Block Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmTextField name='blockName' control={control} label='Block Name' />
        ) : (
          cell.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'floorName',
      header: 'Floor Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmTextField name='floorName' control={control} label='Floor Name' />
        ) : (
          cell.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'divisionName',
      header: 'Division Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmTextField
            name='divisionName'
            control={control}
            label='Division Name'
          />
        ) : (
          cell.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'condition',
      header: 'Condition',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmTextField name='condition' control={control} label='Condition' />
        ) : (
          cell.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'areaName',
      header: 'Area Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmTextField name='areaName' control={control} label='Area Name' />
        ) : (
          cell.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'priorityId',
      header: 'Priority',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmSearchableSelect
            name='priorityId'
            control={control}
            apiUrl='priorities'
            valueField='priorityId'
            headerField={['Priority']}
            labelField={['priorityName']}
            showField={['priorityName']}
            pageSize={20}
            label='Priority'
            defaultValue={{
              priorityId: cell.row.original.priorityId,
              priorityName: cell.row.original.priorityName,
            }}
          />
        ) : (
          cell?.row?.original?.priorityName
        );
      },
    },
    {
      accessorKey: 'tabTypeId',
      header: 'Tab Type',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmSearchableSelect
            name='tabTypeId'
            control={control}
            apiUrl='tabTypes'
            valueField='tabTypeId'
            headerField={['Tab Type']}
            labelField={['tabTypeName']}
            showField={['tabTypeName']}
            pageSize={20}
            label='Tab Type'
            defaultValue={{
              tabTypeId: cell.row.original.tabTypeId,
              tabTypeName: cell.row.original.tabTypeName,
            }}
          />
        ) : (
          cell?.row?.original?.tabTypeName || ''
        );
      },
    },
    {
      accessorKey: 'snagReportId',
      header: 'Snag Report',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmSearchableSelect
            name='snagReportId'
            control={control}
            apiUrl='snagReports'
            valueField='snagReportId'
            headerField={['Snag Report']}
            labelField={['snagReportName']}
            showField={['snagReportName']}
            pageSize={20}
            label='Snag Report'
            required
            defaultValue={{
              snagReportId: cell.row.original?.snagReportDto?.snagReportId,
              snagReportName: cell.row?.original?.snagReportDto?.snagReportName,
            }}
          />
        ) : (
          cell?.row?.original?.snagReportDto?.snagReportName || ''
        );
      },
    },
    {
      accessorKey: 'unitId',
      header: 'Unit',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmSearchableSelect
            name='unitId'
            control={control}
            apiUrl='units'
            valueField='unitId'
            headerField={['Unit']}
            labelField={['unitName']}
            showField={['unitName']}
            pageSize={20}
            label='Unit'
            defaultValue={{
              unitId: cell.row.original.unitId,
              unitName: cell.row.original.unitName,
            }}
          />
        ) : (
          cell?.row?.original?.unitName || ''
        );
      },
    },
    {
      accessorKey: 'subUnitId',
      header: 'Sub Unit',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmSearchableSelect
            name='subUnitId'
            control={control}
            apiUrl='subUnits'
            valueField='subUnitId'
            headerField={['Sub Unit']}
            labelField={['subUnitName']}
            showField={['subUnitName']}
            pageSize={20}
            label='Sub Unit'
            defaultValue={{
              subUnitId: cell.row.original.subUnitId,
              subUnitName: cell.row.original.subUnitName,
            }}
          />
        ) : (
          cell?.row?.original?.subUnitName || ''
        );
      },
    },
    {
      accessorKey: 'snagTakenBy',
      header: 'Snag Taken By',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmSearchableSelect
            name='snagTakenBy'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            headerField={['Employee']}
            labelField={['employeeName']}
            showField={['employeeName']}
            pageSize={20}
            label='Snag Taken By'
            defaultValue={{
              employeeId: cell.row.original.snagTakenBy,
              employeeName: cell.row.original.snagTakenUserName,
            }}
          />
        ) : (
          cell?.row?.original?.snagTakenUserName || ''
        );
      },
    },
    {
      accessorKey: 'approvedBy',
      header: 'Approved By',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportDetailId ? (
          <FmSearchableSelect
            name='approvedBy'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            headerField={['Employee']}
            labelField={['employeeName']}
            showField={['employeeName']}
            pageSize={20}
            label='Approved By'
            defaultValue={{
              employeeId: cell.row.original.approvedBy,
              employeeName: cell.row.original.approvedUserName,
            }}
          />
        ) : (
          cell?.row?.original?.approvedUserName || ''
        );
      },
    },
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.snagReportDetailId ? (
            <>
              <Button
                onClick={handleSubmit(handleEditItem)}
                loading={isLoading}
              >
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
  ];

  return (
    <ViewSnagReportLayout
      title={
        location.pathname ===
        '/elv/snag/snag-report/functional-test-comments-tab'
          ? 'Functional Test Comments Tab'
          : 'Snag Tab'
      }
    >
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(handleSubmitProject)}>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <FmTextField
                name='snagReportDetailName'
                control={createControl}
                label='Snag Report Detail Name'
                required
              />
            </Grid>
            <Grid item md={2.4}>
              <FmTextField
                name='equipment'
                control={createControl}
                label='Equipment'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmTextField
                name='description'
                control={createControl}
                label='Description'
                required
              />
            </Grid>
            <Grid item md={2.4}>
              <FmTextField
                name='comments'
                control={createControl}
                label='Comments'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmTextField
                name='blockName'
                control={createControl}
                label='Block Name'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmTextField
                name='floorName'
                control={createControl}
                label='Floor Name'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmTextField
                name='divisionName'
                control={createControl}
                label='Division Name'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmTextField
                name='condition'
                control={createControl}
                label='Condition'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmTextField
                name='areaName'
                control={createControl}
                label='Area Name'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmTextField
                name='price'
                control={createControl}
                label='Price'
                pattern='Number'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmTextField
                name='negotiatedPrice'
                control={createControl}
                label='Negotiated Price'
                pattern='Number'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmSearchableSelect
                name='priorityId'
                control={createControl}
                apiUrl='priorities'
                valueField='priorityId'
                headerField={['Priority']}
                labelField={['priorityName']}
                showField={['priorityName']}
                pageSize={20}
                label='Priority'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmSearchableSelect
                name='snagReportId'
                control={createControl}
                apiUrl='snagReports'
                valueField='snagReportId'
                headerField={['Snag Report']}
                labelField={['snagReportName']}
                showField={['snagReportName']}
                pageSize={20}
                label='Snag Report'
                required
              />
            </Grid>
            <Grid item md={2.4}>
              <FmSearchableSelect
                name='unitId'
                control={createControl}
                apiUrl='unitofmeasurements'
                valueField='unitOfMeasurementId'
                headerField={['Unit']}
                labelField={['unitOfMeasurementName']}
                showField={['unitOfMeasurementName']}
                pageSize={20}
                label='Unit'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmSearchableSelect
                name='subUnitId'
                control={createControl}
                apiUrl='unitofmeasurements'
                valueField='unitOfMeasurementId'
                headerField={['Unit']}
                labelField={['unitOfMeasurementName']}
                showField={['unitOfMeasurementName']}
                pageSize={20}
                label='Sub Unit'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmSearchableSelect
                name='snagTakenBy'
                control={createControl}
                apiUrl='loginusers'
                valueField='loginUserId'
                headerField={['Employee']}
                labelField={['loginUserName']}
                showField={['loginUserName']}
                pageSize={20}
                label='Snag Taken By'
              />
            </Grid>
            <Grid item md={2.4}>
              <FmSearchableSelect
                name='approvedBy'
                control={createControl}
                apiUrl='loginusers'
                valueField='loginUserId'
                headerField={['Employee']}
                labelField={['loginUserName']}
                showField={['loginUserName']}
                pageSize={20}
                label='Approved By'
              />
            </Grid>
          </Grid>
        </form>
        <ActionButtons
          onSubmit={createSubmit(handleSubmitProject)}
          onReset={resetSnagReportDetails}
          submitLoading={createLoading}
          cancelLoading={false}
        />
      </GlassCard>

      <GlassCard className='my-4'>
        <MRTExpandTableStyle>
          <FmMRTDataTable
            rows={rows}
            columns={columns}
            enableRowSelection={false}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </ViewSnagReportLayout>
  );
};

export default SnagTab;
