import React from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import { ActionButtons } from '../../../components/_form/index.jsx';
import { vehicelsSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const GridSize = 2.4;

const AddOwnVehicles = (props) => {
  const { setGetData } = props;
  const crud = new Crud_Service();
  const [adRequired, setAdRequired] = React.useState(false);
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(vehicelsSchema),
    mode: 'onChange',
    defaultValues: {},
  });

  const handleReset = () => {
    reset();
    setValue('advertisement', false);
    setValue('vehicleId', '');
    setValue('insuranceId', '');
    setValue('driverId', '');
    setValue('divisionId', '');
  };
  const submitVehicle = async (values) => {
    await crud.create('ownvehicles', values, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Own Vehicle Added Successfully');
        setGetData(true);
        reset();
        setValue('advertisement', false);
        setValue('vehicleId', '');
        setValue('insuranceId', '');
        setValue('driverId', '');
        setValue('divisionId', '');
      } else {
        reset();
      }
    });
  };

  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(submitVehicle)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='vehicleId'
              label='Vehicle'
              apiUrl={'vehicles'}
              valueField='vehicleId'
              required
              labelField={['vehicleCode', 'vehicleName']}
              showField={['vehicleCode', 'vehicleName']}
              control={control}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='trafficCodeNo'
              required
              label='Traffic Code No'
              control={control}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='insuranceId'
              label='Insurance'
              required
              apiUrl={'vehicleinsurances'}
              valueField='vehicleInsuranceId'
              labelField={[
                'vehicleInsuranceCode',
                'policyNumber',
                'premiumAmount',
              ]}
              showField={[
                'vehicleInsuranceCode',
                'policyNumber',
                'premiumAmount',
              ]}
              control={control}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='driverId'
              label='Driver'
              apiUrl={'drivers'}
              required
              valueField='driverId'
              labelField={['driverCode', 'driverName']}
              showField={['driverCode', 'driverName']}
              control={control}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='divisionId'
              label='Division'
              apiUrl={'divisions'}
              required
              valueField='divisionId'
              labelField={['divisionCode', 'divisionName']}
              showField={['divisionCode', 'divisionName']}
              control={control}
            />
          </Grid>
          <Grid item md={GridSize}>
            <Controller
              name='advertisement'
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  title='Advertisement'
                  {...field}
                  onChange={(ev) => {
                    field.onChange(ev);
                    setAdRequired(ev.target.value === 'true');
                  }}
                >
                  {'Advertisement'}
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label='No'
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label='Yes'
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          {adRequired && (
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='advertisementId'
                label='Advertisement'
                apiUrl={'vehicleadvertisements'}
                required
                valueField='vehicleAdvertisementId'
                labelField={['vehicleAdvertisementCode', 'permitNumber']}
                showField={['vehicleAdvertisementCode', 'permitNumber']}
                control={control}
              />
            </Grid>
          )}

          <Grid item md={12}>
            <ActionButtons
              onSubmit={handleSubmit(submitVehicle)}
              onReset={handleReset}
              submitLoading={false}
              submitText='Create'
            />
          </Grid>
        </Grid>
      </form>
    </GlassCard>
  );
};

export default AddOwnVehicles;
