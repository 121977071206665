import React, { useEffect, useState } from 'react';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { contractLocationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';

const ContractLocationListTable = (props) => {
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading } = useLoader();
  const [viewDetails, setViewdetails] = useState(false);
  const { control, setValue, handleSubmit } = useForm({
    resolver: yupResolver(contractLocationSchema),
    mode: 'onChange',
  });

  const {
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;
  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const columns = [
    {
      field: 'Actions',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,

      Cell: (params) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId === params?.row?.original?.projectLocationDetailId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateLocation)}>
                  Save
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params.row),
                }))}
                selectedRow={params.row}
                setSelectedRow={() => {}}
              />
            )}
          </Box>
        );
      },
    },

    {
      accessorKey: 'regionId',
      header: 'Region',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.projectLocationDetailId ===
            cell?.row?.original?.projectLocationDetailId
        );
        return editingRowId === cell?.row?.original?.projectLocationDetailId ? (
          <FmSearchableSelect
            name='regionId'
            control={control}
            apiUrl='regions'
            valueField='regionId'
            headerField={['Name']}
            labelField={['regionName']}
            showField={['regionName']}
            pageSize={20}
            required
            label='Regions'
            defaultValue={{
              regionId: editingRowData?.regionId,
              regionName: editingRowData?.regionName,
            }}
          />
        ) : (
          incidentReport?.regionName || ''
        );
      },
    },
    {
      accessorKey: 'emirateId',
      header: 'Emirate',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.projectLocationDetailId ===
            cell?.row?.original?.projectLocationDetailId
        );
        return editingRowId === cell?.row?.original?.projectLocationDetailId ? (
          <FmSearchableSelect
            name='emirateId'
            control={control}
            apiUrl='emirates'
            valueField='emirateId'
            headerField={['Name']}
            labelField={['emirateName']}
            showField={['emirateName']}
            pageSize={20}
            required
            label='Emirates'
            defaultValue={{
              emirateId: editingRowData?.emirateId,
              emirateName: editingRowData?.emirateName,
            }}
          />
        ) : (
          incidentReport?.emirateName || ''
        );
      },
    },
    {
      accessorKey: 'zoneId',
      header: 'Zone',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.projectLocationDetailId ===
            cell?.row?.original?.projectLocationDetailId
        );
        return editingRowId === cell?.row?.original?.projectLocationDetailId ? (
          <FmSearchableSelect
            name='zoneId'
            control={control}
            apiUrl='zones'
            valueField='zoneId'
            headerField={['Name']}
            labelField={['zoneName']}
            showField={['zoneName']}
            pageSize={20}
            required
            label='Zones'
            defaultValue={{
              zoneId: editingRowData?.zoneId,
              zoneName: editingRowData?.zoneName,
            }}
          />
        ) : (
          incidentReport?.zoneName || ''
        );
      },
    },
    {
      accessorKey: 'locationId',
      header: 'Location',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.projectLocationDetailId ===
            cell?.row?.original?.projectLocationDetailId
        );
        return editingRowId === cell?.row?.original?.projectLocationDetailId ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            valueField='locationId'
            headerField={['Name']}
            labelField={['locationName']}
            showField={['locationName']}
            pageSize={20}
            required
            label='Locations'
            defaultValue={{
              locationId: editingRowData?.locationId,
              locationName: editingRowData?.locationName,
            }}
          />
        ) : (
          incidentReport?.locationName || ''
        );
      },
    },
    {
      accessorKey: 'buildingId',
      header: 'Building',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.projectLocationDetailId ===
            cell?.row?.original?.projectLocationDetailId
        );
        return editingRowId === cell?.row?.original?.projectLocationDetailId ? (
          <FmSearchableSelect
            name='buildingId'
            control={control}
            apiUrl='buildings'
            valueField='buildingId'
            headerField={['Name']}
            labelField={['buildingName']}
            showField={['buildingName']}
            pageSize={20}
            required
            label='Buildings'
            defaultValue={{
              buildingId: editingRowData?.buildingId,
              buildingName: editingRowData?.buildingName,
            }}
          />
        ) : (
          incidentReport?.buildingName || ''
        );
      },
    },
  ];
  const handleEdit = async (row) => {
    setEditingRowId(row?.original?.projectLocationDetailId);
    setEditingRowData({ ...row?.original });
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'projectlocationdetails',
      props?.original?.projectLocationDetailId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('Location deleted successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  const handleUpdateLocation = async (values) => {
    try {
      startEditLoading();
      await crud.update(
        'projectlocationdetails',
        editingRowId,
        values,
        (err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success('Location Updated Successfully');
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
            toast.error('Failed to update the Team');
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating the Incident Report');
    }
  };
  useEffect(() => {
    if (editingRowData) {
      Object.entries({
        ...editingRowData,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);
  return (
    <>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </>
  );
};

export default ContractLocationListTable;
