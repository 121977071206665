import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { materialRequestItemSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';
import { ViewField } from '../../../components/_form/FormElements.jsx';

const TableCode = ({ title, desc }) => {
  return (
    <TableAvatar name={title} desc={desc && `Code: ${desc}`} removeImage />
  );
};

const scrollToColumn = (columnIndex) => {
  const tableContainer = document.querySelector('.MuiTableContainer-root'); // Adjust the selector based on your setup
  if (tableContainer) {
    const cell = tableContainer.querySelector(
      `[data-colindex="${columnIndex}"]`
    );
    if (cell) {
      cell.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }
};

const PurchaseOrderViewGroupItems = ({ editData }) => {
  const crud = new Crud_Service();
  const { id } = useParams();
  const tableRef = useRef(null);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const {
    reset,
    setValue,
    getValues,
    register,
    control,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(materialRequestItemSchema),
    mode: 'all',
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const { data: item } = useCommonFetchApi('item');
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [createNewOpen, setCreateNewOpen] = useState(false);
  const [handleBlurLoading, setHandleBlurLoading] = useState(false);

  const [tableInstance, setTableInstance] = useState(null);

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setGlobalFilter,
    fetchData,
  } = useTableLogic(
    `localpurchaseorderitems?localPurchaseOrderId=${editData?.localPurchaseOrderId}`
  );

  useEffect(() => {
    if (!selectedItem || !selectedItem?.itemId) return;
    const { modelId, unitOfMeasurementId, costCenterId } = selectedItem;
    setValue('categoryId', modelId || '');
    setValue('costCenterId', costCenterId || '');
    setValue('unitOfMeasurementId', unitOfMeasurementId || '');
  }, [selectedItem]);

  const handleDelete = async (props) => {
    await crud.remove(
      'localpurchaseorderitems',
      props?.localPurchaseOrderItemId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Material Requisition Item Deleted Successfully');
          fetchData();
          tableInstance?.setExpanded({});
        } else {
        }
      }
    );
  };

  const handleEdit = (row) => {
    reset();

    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    }

    setEditingRowId(row?.localPurchaseOrderItemId);

    if (row) {
      Object.entries({ ...row }).forEach(([key, value]) => {
        setValue(key, value);
      });
    } else {
      toast.error('Row is undefined or null');
    }

    setEditingRowData({ ...row });
  };

  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const handleCancel = (row) => {
    setCreateNewOpen(false);
    if (row?.original?.localPurchaseOrderItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
    setEditingRowIndex(null);
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'],
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues());

  const handleBlur = () => {
    const currentValues = getValues();
    const value = getValues('salesOrderId');

    if (!value) {
      firstColumnInputRef.current.focus();
      return;
    }

    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      setHandleBlurLoading(true);
      apiCallAsync(currentValues);
      previousValues.current = currentValues;
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);

    const convertedValues = {
      localPurchaseOrderId: editData?.localPurchaseOrderId,
      localPurchaseOrderItems: [
        {
          ...values,
          materialRequisitionItemId: values?.materialRequisitionItemId || null,
          requestForQuoteItemId: values?.requestForQuoteItemId || null,
        },
      ],
    };

    const updatedCombinedData = {
      ...values,
      materialRequisitionItemId: values?.materialRequisitionItemId || null,
      requestForQuoteItemId: values?.requestForQuoteItemId || null,
      categoryId: Number(values?.categoryId),
      quantity: Number(values?.quantity) || 0,
      costCenterId: Number(values?.costCenterId),
      localPurchaseOrderId: values?.localPurchaseOrderId,
      status: 1,
    };

    if (editingRowId === null) {
      await crud.create(
        'localpurchaseorderitems',
        convertedValues,
        (err, res) => {
          if (res?.status === 201) {
            setLoader(false);
            toast.success('Material Requisition Item Added Successfully');
            fetchData();
            setEditingRowIndex(null);
            setCreateNewOpen(true);
            setHandleBlurLoading(false);
          } else {
          }
        }
      );
    } else {
      await crud.update(
        'localpurchaseorderitems',
        editingRowId,
        updatedCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            fetchData();
            toast.success('Material Requisition Item Updated Successfully');
            setCreateNewOpen(true);
            setEditingRowIndex(null);
            setHandleBlurLoading(false);
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (!isLoading && createNewOpen) {
      const button = document.getElementById('new-buttons');
      if (button) {
        button.click();
      }
    }
  }, [isLoading, createNewOpen]);

  const handleAddNewRow = (table) => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setSelectedItem(null);

    let expandedRows = table.getState().expanded;
    let expandedRowKey = Object.keys(expandedRows)[0];

    if (expandedRowKey) {
      setRowState({
        newRowId: newId,
        isCreatingRow: true,
      });

      const getAllRows = table.getRowModel().rows;
      const groupedRowModel = table.getGroupedRowModel();
      const groupedRowModelRows = groupedRowModel.rows;
      const currentGroupedRow = groupedRowModelRows.filter((row) => {
        return row.id === expandedRowKey;
      });

      const currentCategoryLastRow = currentGroupedRow[0]?.leafRows?.pop();
      const currentRowGroupLastIndex =
        currentGroupedRow[0]?.leafRows?.length + 1;
      const currentCategoryLastRowCatId =
        currentCategoryLastRow?.original?.categoryId;
      const CurrentCategortLastRowCatName =
        currentCategoryLastRow?.original?.categoryName;

      setEditingRowIndex(currentRowGroupLastIndex);

      setNewRowCreated(false);

      const newRow = {
        localPurchaseOrderItemId: newId,
        materialRequisitionItemId: null,
        requestForQuoteItemId: null,
        localPurchaseOrderId: editData?.localPurchaseOrderId,
        salesOrderId: '',
        itemId: '',
        costCenterId: '',
        quantity: '',
        unitOfMeasurementId: '',
        categoryId: currentCategoryLastRowCatId,
        categoryName: CurrentCategortLastRowCatName,
        remarks: '',
        isEditable: true,
        isNew: true,
      };

      const lastCategoryIndex = getAllRows.findLastIndex(
        (row) => row.original?.categoryId === expandedRowKey
      );

      setRows((prevRows) => {
        if (lastCategoryIndex !== -1) {
          return [
            ...prevRows.slice(0, lastCategoryIndex + 1),
            newRow,
            ...prevRows.slice(lastCategoryIndex + 1),
          ];
        }
        return [...prevRows, newRow];
      });
    } else {
      // new record update

      const newEmptyRow = {
        localPurchaseOrderItemId: newId,
        materialRequisitionItemId: null,
        requestForQuoteItemId: null,
        localPurchaseOrderId: editData?.localPurchaseOrderId,
        salesOrderId: '',
        itemId: '',
        costCenterId: '',
        quantity: '',
        unitOfMeasurementId: '',
        categoryId: 1,
        categoryName: 'Create New',
        remarks: '',
        isEditable: true,
        isNew: true,
      };

      setRowState({
        newRowId: newId,
        isCreatingRow: true,
      });

      setRows((prevRows) => [...prevRows, newEmptyRow]);

      setTimeout(() => {
        setEditingRowIndex(rows?.length + 1);
        const updatedFirstGroup = table.getGroupedRowModel().rows[0]?.id;
        if (updatedFirstGroup) {
          table.setExpanded((prev) => ({
            [updatedFirstGroup]: true,
          }));
        }
      }, 100);

      return;
    }

    reset();
  };

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setCreateNewOpen(false);
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );

    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        localPurchaseOrderItemId: newId,
        materialRequisitionItemId: null,
        requestForQuoteItemId: null,
        salesOrderId: '',
        localPurchaseOrderId: editData?.localPurchaseOrderId,
        itemId: '',
        costCenterId: '',
        quantity: '',
        unitOfMeasurementId: '',
        categoryId: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const maxSuppliers = 3;

  const supplierColumns = Array.from({ length: maxSuppliers }, (_, index) => ({
    accessorKey: `supplierDetails_${index + 1}`,
    header: `Supplier Details ${index + 1}`,
    enableEditing: false,
    size: 220,
    enableGrouping: false,
    Cell: ({ row }) => {
      const supplier = row?.original?.supplierDetails?.[index];
      return supplier?.supplierName || '-';
    },
  }));

  const columns = [
    {
      accessorKey: 'categoryId',
      header: 'Category',
      enableEditing: false,
      enableGrouping: true,
      size: 150,
      muiTableBodyCellProps: {
        className: 'font-bold',
        sx: {
          color: 'primary.main',
        },
      },
      Cell: ({ row }) => {
        return <>{row?.original?.categoryName || ''} </>;
      },
    },
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order',
      enableEditing: true,
      enableGrouping: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.localPurchaseOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.localPurchaseOrderItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            inputRef={firstColumnInputRef}
            apiUrl='salesorders'
            valueField='salesOrderId'
            labelField={['salesOrderCode', 'salesOrderName']}
            showField={['salesOrderCode', 'salesOrderName']}
            label={'Sales Order'}
            defaultValue={{
              salesOrderId: editingRowData?.salesOrderId || '',
              salesOrderName: editingRowData?.salesOrderName || '',
            }}
          />
        ) : (
          <TableCode
            title={row?.original?.salesOrderName}
            desc={row?.original?.salesOrderCode}
          />
        );
      },
    },

    {
      accessorKey: 'itemId',
      header: 'Item',
      enableEditing: true,
      enableGrouping: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.localPurchaseOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.localPurchaseOrderItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='itemId'
            control={control}
            apiUrl='item'
            valueField='itemId'
            headerField={['Item Name', 'Category']}
            labelField={['itemName', 'modelName']}
            showField={['itemName', 'modelName']}
            label='Item Name'
            defaultValue={{
              itemId: editingRowData?.itemId || '',
              itemName: editingRowData?.itemName || '',
            }}
            onChangeProp={(ev) => {
              setSelectedItem(ev);
            }}
          />
        ) : (
          row?.original?.itemName || '-'
        );
      },
    },

    {
      accessorKey: 'costCenterId',
      header: 'Cost Center',
      enableEditing: true,
      enableGrouping: false,
      size: 120,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.localPurchaseOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.localPurchaseOrderItemId || isNewRow;
        return isEditing ? (
          <>
            <ViewField
              label='Cost Center'
              title={
                selectedItem?.costCenterName ||
                editingRowData?.costCenterName ||
                ''
              }
            />
          </>
        ) : (
          row?.original?.costCenterName || '-'
        );
      },
    },

    {
      accessorKey: 'quantity',
      header: 'Required Quantity',
      size: 100,
      enableEditing: true,
      enableGrouping: false,
      Cell: ({ row, column }) => {
        const isNewRow =
          row?.original?.localPurchaseOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.localPurchaseOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='quantity'
            label='Quantity'
            control={control}
            onChange={scrollToColumn(column.index)}
          />
        ) : (
          row?.original?.quantity
        );
      },
    },

    {
      accessorKey: 'onHandQuantity',
      header: 'On Hand Quantity',
      enableEditing: false,
      enableGrouping: false,
      size: 100,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.localPurchaseOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.localPurchaseOrderItemId || isNewRow;
        return isEditing ? <Box></Box> : row?.original?.onHandQuantity || '-';
      },
    },

    {
      accessorKey: 'unitOfMeasurementId',
      header: 'UOM',
      enableEditing: true,
      enableGrouping: false,
      size: 100,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.localPurchaseOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.localPurchaseOrderItemId || isNewRow;
        return isEditing ? (
          <ViewField
            label='UOM'
            title={
              selectedItem?.unitOfMeasurementName ||
              editingRowData?.unitOfMeasurementName ||
              ''
            }
          />
        ) : (
          row?.original?.unitOfMeasurementName || '-'
        );
      },
    },

    ...supplierColumns,

    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      enableGrouping: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.localPurchaseOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.localPurchaseOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            multiline={true}
            rows={1}
            maxRows={1}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.remarks
        );
      },
    },

    {
      header: 'Action',
      enableColumnPinning: false,
      enableEditing: false,
      enableSorting: false,
      enableGrouping: false,
      size: 100,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.localPurchaseOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.localPurchaseOrderItemId || isNewRow;
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        // new

        setTableInstance(table);

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                {handleBlurLoading && (
                  <Box className='flex gap-2 items-center'>
                    <Icon icon='eos-icons:loading' width='20' height='20' />{' '}
                    Loading....
                  </Box>
                )}
                {!handleBlurLoading && (
                  <Button
                    className='px-1 py-1'
                    onClick={() => handleCancel(row)}
                    variant='contained'
                    color='error'
                  >
                    Close
                  </Button>
                )}

                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button onFocus={() => handleCancelAndAddNewRow()}></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  // useeffect when expand default
  useEffect(() => {
    if (!isLoading && rows.length > 0 && tableInstance) {
      const table = tableInstance;

      setTimeout(() => {
        const expandedRows = table.getState()?.expanded || {};

        if (Object.keys(expandedRows).length === 0) {
          const firstGroup = table.getGroupedRowModel().rows[0];
          if (firstGroup) {
            const groupId = firstGroup.id;
            if (groupId) {
              table.setExpanded(() => ({
                [groupId]: true,
              }));
            }
          }
        }
      }, 500);
      // end
    }
  }, [isLoading, tableInstance]);

  return (
    <>
      <GlassCard className='h-[450px]'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          onCreateRow={handleAddNewRow}
          isCreatingRow={editingRowIndex && true}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          setTableInstance={setTableInstance}
          extraInitialState={{
            grouping: ['categoryId'],
            expanded:
              rows.length > 0
                ? { [`categoryId:${rows[0]?.categoryId}`]: true }
                : {},
          }}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          searchKeyword={searchKeyword}
          enableGrouping={true}
          fetchData={fetchData}
          enableExpandAll={false}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          setTableInstance={setTableInstance}
          tableRef={tableRef}
        />
      </GlassCard>
    </>
  );
};

export default PurchaseOrderViewGroupItems;
