import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import ViewIncidentLayout from './ViewIncidentLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Box, Button, IconButton } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Icon } from '@iconify/react';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';

const imageHandler = function () {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const range = this.quill.getSelection();
      this.quill.insertEmbed(range.index, 'image', reader.result);
    };
    reader.readAsDataURL(file);
  };
};

const ViewIncident = () => {
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState(null);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      incidentReportId: 0,
      createIncidentReportRemarks: [{ remarks: '' }],
    },
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'createIncidentReportRemarks',
  });

  useEffect(() => {
    getIncidentReports();
  }, []);

  const getIncidentReports = async () => {
    setLoading(true);
    if (id) {
      await crud.getSingle('incidentreports', id, (err, res) => {
        if (res?.status === 200) {
          setViewData(res?.data);
          replace(res?.data?.incidentReportRemarks);
          setLoading(false);
        } else {
          setViewData(null);
          setLoading(false);
        }
      });
    } else {
      return;
    }
  };

  const onSubmit = async (data) => {
    const combinedData = {
      incidentReportId: Number(id),
      createIncidentReportRemarks: data?.createIncidentReportRemarks?.map(
        (item) => ({ remarks: item?.remarks })
      ),
    };

    const combinedDataupdate = {
      incidentReportId: Number(id),
      updateIncidentReportRemarks: data?.createIncidentReportRemarks?.map(
        (item) => ({
          incidentReportRemarkId: item?.incidentReportRemarkId,
          remarks: item?.remarks,
        })
      ),
    };

    if (viewData?.incidentReportRemarks?.length > 0) {
      await crud.update(
        'incidentreportremark',
        '',
        combinedDataupdate,
        (err, res) => {
          if (err) {
            toast.error('Something went wrong:', err);
            return;
          }
          if (res?.status === 200) {
            toast.success('Incident Report Created successfully');
            getIncidentReports();
          } else {
          }
        }
      );
    } else {
      await crud.create('incidentreportremark', combinedData, (err, res) => {
        if (err) {
          toast.error('Something went wrong:', err);
          return;
        }
        if (res?.status === 201) {
          toast.success('Incident Report Created successfully');
          reset();
          getIncidentReports();
        } else {
        }
      });
    }
  };

  const quillModules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ align: [] }],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  return (
    <ViewIncidentLayout title='View Incident Report'>
      {loading ? (
        <CommonLoader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields?.map((field, index) => (
            <GlassCard className='p-3 mb-3 relative' key={field.id}>
              <IconButton
                variant='contained'
                onClick={() => remove(index)}
                disabled={fields.length === 1}
                color='error'
                className='absolute right-3 top-3'
              >
                <Icon icon='solar:close-circle-broken' />
              </IconButton>
              <Controller
                name={`createIncidentReportRemarks.${index}.remarks`}
                control={control}
                render={({ field }) => (
                  <ReactQuill
                    theme='snow'
                    value={field.value}
                    onChange={field.onChange}
                    modules={quillModules}
                  />
                )}
              />
            </GlassCard>
          ))}

          <Box className='flex gap-4 items-center justify-center'>
            <Button
              variant='outlined'
              className='font-bold min-w-32'
              color='success'
              onClick={() => append({ remarks: '' })}
            >
              Add New Editor
            </Button>
            <Button
              variant='contained'
              type='submit'
              className='font-bold min-w-32'
            >
              {viewData?.incidentReportRemarks?.length > 0
                ? 'Update'
                : 'Submit'}
            </Button>
          </Box>
        </form>
      )}
    </ViewIncidentLayout>
  );
};

export default ViewIncident;
