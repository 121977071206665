/* eslint-disable */
import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid, alpha, CircularProgress, Button } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Icon } from '@iconify/react';
import SortableTree from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import {
  DragAndDropBox,
  DragCards,
} from '../../../components/_form/DragAndDropBox.jsx';
import Crud_Service from '../../../apis/CrudService';
import { useSelector } from 'react-redux';
import { setLoading } from '../../../store/loaderSlice.ts';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TinyMCEEditor from '../../TextEditor.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import AddDocuments from './AddDocuments.jsx';
import SortableTreeBoxStyle from '../../../components/page/SortableTreeBoxStyle.tsx';

const ProposalView = (props) => {
  const { type } = props;
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<any>([]);
  const isLoading = useSelector((state) => state.loader.isLoading);
  const crud = new Crud_Service();
  const dispatch = useDispatch();
  const [tenders, setTenders] = useState<any>([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [createSubDivision, setCreateSubDivision] = useState(false);
  const [doc, setDoc] = useState({});
  const [docTypes, setDocTypes] = useState([]);
  const [selectedTender, setSelectedTender] = useState(null);
  const [createDivision, setCreateDivision] = useState(false);
  const { handleSubmit, control } = useForm();
  const [selectedDocumentIds, setSelectedDocumentIds] = useState(new Set());
  const [localLoader, setLocalLoader] = useState(true);
  const [pageSize] = useState(100);
  const [pageNumber] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [openEditor, setOpenEditor] = useState(0);

  const handleAddTask = (doc) => {
    setSelectedDocumentTypes((prev) => {
      const updatedDocuments = [
        ...prev.documents,
        { ...doc, children: [], isDocument: false },
      ];
      return { ...prev, documents: updatedDocuments };
    });
  };

  useEffect(() => {
    fetchEnquiry();
    fetchTenderProposal();
  }, []);

  useEffect(() => {
    type === 'edit' &&
      selectedDocumentTypes?.documents?.length > 0 &&
      fetchData();
  }, [selectedDocumentTypes]);

  const toggleLoading = (loading) => {
    dispatch(setLoading(loading));
  };
  const handleDocSubmit = async () => {
    const data = {
      tenderProposalJson: {
        documents: selectedDocumentTypes.documents,
      },
      status: 2,
    };
    await crud.update(`tenderproposals/${id}`, '', data, (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      if (res.data) {
        toast.success('Proposal updated successfully');
        navigate(-1);
      }
    });
  };

  const fetchTenderProposal = async () => {
    await crud.getAll(`tenderproposals/${id}`, '', (err, res) => {
      if (err) {
        toast.error('Error fetching Proposal:', err);
      }
      if (res?.status === 200) {
        setSelectedDocumentTypes(JSON.parse(res?.data?.tenderProposalJson));
        toggleLoading(false);
      }
    });
  };
  const fetchEnquiry = async () => {
    await crud.getAll(`tenders/${id}`, '', (err, res) => {
      if (err) {
        toast.error('Error fetching Tenders:', err);
      }
      if (res?.status === 200) {
        setTenders(res.data);
        toggleLoading(false);
      }
    });
  };
  const handleCheckboxChange = (event, documentId, type) => {
    if (type === 1) {
      setOpenEditor(documentId);
      setCreateDivision(false);
    } else {
      setSelectedDocumentIds((prevIds) => {
        const newIds = new Set(prevIds);
        if (event.target.checked) {
          newIds.add(documentId);
        } else {
          newIds.delete(documentId);
        }
        return newIds;
      });
    }
  };
  const handleAddDocumentToType = () => {
    const updatedTree = addSelectedDocumentsToTree(
      Array.from(selectedDocumentIds),
      doc.documentTypeId,
      selectedDocumentTypes
    );
    setSelectedDocumentTypes(updatedTree);
    setCreateSubDivision(false);
    setSelectedDocumentIds(new Set());
  };
  const addSelectedDocumentsToTree = (selectedDocumentIds, nodeId, tree) => {
    const selectedDocs = documents.filter((doc) =>
      selectedDocumentIds.includes(doc.documentId)
    );
    const newTree = {
      ...tree,
      documents: addDocumentsToTree(selectedDocs, nodeId, tree.documents),
    };
    return newTree;
  };

  const addDocumentsToTree = (documents, nodeId, tree) => {
    return tree.map((node) => {
      if (node.documentTypeId === nodeId && !node.isDocument) {
        return {
          ...node,
          children: [
            ...node.children,
            ...documents.map((document) => ({
              documentTypeId: document.documentTypeId,
              documentTypeCode: document.documentTypeCode,
              documentTypeName: document.documentTypeName,
              documentTypeCategoryId: document.documentTypeCategoryId,
              documentName: document.documentName,
              documentTypeCategoryName: document.documentTypeCategoryName,
              tagId: document.tagId,
              status: document.status,
              statusName: document.statusName,
              createdDate: document.createdDate,
              updatedDate: document.updatedDate,
              isDocument: true,
              children: [],
            })),
          ],
        };
      }
      if (node.children) {
        return {
          ...node,
          children: addDocumentsToTree(documents, nodeId, node.children),
        };
      }
      return node;
    });
  };

  const handleDocumentTypeChange = (id) => {
    setLocalLoader(true);
    fetchDocuments(id);
  };
  const [documents, setDocuments] = useState([]);
  const createDocumentType = async (values) => {
    const data = {
      documentTypeName: values.documentTypeName,
      documentTypeCategoryId,
      tagId: 2,
    };
    await crud.create('documenttypes', data, (err, res) => {
      if (err) {
        toast.error('Error creating document type', err);
      }
      if (res?.status === 201) {
        fetchData();
        setCreateDivision(false);
      }
    });
  };
  const fetchDocuments = async (id) => {
    await crud.getAll(
      'documents',
      { pageSize, pageNumber, documentTypeId: id },
      (err, res) => {
        if (err) {
          toast.error('Error getting document', err);
          return;
        }
        if (res?.status === 200) {
          const data = res.data.data.map((row, index) => ({
            ...row,
            id: index + 1,
            selected: false,
          }));
          setDocuments(data);
          setLocalLoader(false);
        }
      }
    );
  };
  const [documentTypeId] = useState(2);
  const documentTypeCategoryId = 4;

  const handleTreeChange = (newTreeData) => {
    setSelectedDocumentTypes((prev) => {
      return { ...prev, documents: newTreeData };
    });
  };

  const handleDeleteTask = (doc) => {
    setSelectedDocumentTypes((prev) => {
      const filteredDocuments = prev.documents.filter(
        (task) => task.documentTypeId !== doc.documentTypeId
      );
      return { ...prev, documents: filteredDocuments };
    });
  };

  const fetchData = async () => {
    await crud.getAll(
      'documenttypes',
      { pageSize, pageNumber, documentTypeId, documentTypeCategoryId },
      (err, res) => {
        if (err) {
          toast.error('Error fetching data', err);
          return;
        }
        if (res?.status === 200) {
          const filteredData = res.data.data.filter(
            (item) =>
              !selectedDocumentTypes.documents?.some(
                (selected) => selected.documentTypeId === item.documentTypeId
              )
          );
          setDocTypes(filteredData);
          toggleLoading(false);
        }
      }
    );
  };

  return (
    <DashboardLayout title='Proposal'>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box className='inline-block w-full'>
          {tenders !== null && (
            <GlassCard className='flex flex-row items-center p-2 px-5 mb-2 justify-between'>
              <Box className='flex items-center'>
                <Box className='text-4xl me-5'>
                  <Icon icon='emojione-v1:memo' />
                </Box>
                <Box>
                  <Box>Enquiry Id</Box>
                  <Box className='font-semibold'>{tenders?.tenderId}</Box>
                </Box>
              </Box>
              <Box>
                <Box>Proposal Id</Box>
                <Box className='font-semibold'>
                  {tenders?.tenderProjectTypeId}
                </Box>
              </Box>
              <Box>
                <Box>Client Name</Box>
                <Box className='font-semibold'>{tenders?.clientName}</Box>
              </Box>
              <Box>
                <Box>Tender Name</Box>
                <Box className='font-semibold'>{tenders?.tenderName}</Box>
              </Box>
              <Box>
                <Box>Status</Box>
                <Box className='font-semibold text-green-600'>
                  {tenders?.statusName}
                </Box>
              </Box>
            </GlassCard>
          )}
          {type === 'edit' && (
            <Box className='font-bold text-lg my-4'>
              Facility Management Division
            </Box>
          )}
          <Box>
            <Grid container spacing={3}>
              {type === 'edit' && (
                <Grid item md={4}>
                  <Box
                    sx={{
                      border: '1px solid',
                      borderColor: 'border.main',
                      borderRadius: '10px',
                      bgcolor: 'background.white',
                    }}
                  >
                    <Box className='p-2 flex gap-2 items-center'>
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />

                      <CreateButton
                        className='font-semibold py-1 px-4'
                        size='small'
                        name='New'
                        onClick={() => setCreateDivision(!createDivision)}
                      />
                    </Box>
                    <Box
                      className='p-3'
                      sx={{ height: '540px', overflow: 'auto' }}
                    >
                      <Box className='flex flex-col gap-3'>
                        {docTypes?.map((item) => (
                          <Box key={item.documentTypeId}>
                            <DragAndDropBox
                              name={item.documentTypeName}
                              id={item.documentTypeId}
                              icon={<Icon icon='majesticons:plus-line' />}
                              onClick={() => handleAddTask(item)}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid item md={type === 'edit' ? 8 : 12}>
                <SortableTreeBoxStyle readonly editmode={type === 'edit'}>
                  <SortableTree
                    treeData={selectedDocumentTypes.documents}
                    onChange={handleTreeChange}
                    generateNodeProps={({ node }) => ({
                      title: (
                        <>
                          <DragCards
                            name={
                              node.isDocument
                                ? node.documentName
                                : node.documentTypeName
                            }
                            type={node.isDocument ? 'document' : 'file'}
                            id={node.documentTypeId}
                            icon={<Icon icon='majesticons:plus-line' />}
                            onAdd={() => {
                              handleDocumentTypeChange(node.documentTypeId);
                              setCreateSubDivision(true);
                              setDoc(node);
                            }}
                            onDelete={() => handleDeleteTask(node)}
                            childData={node.children}
                            edit={type === 'edit'}
                          />
                        </>
                      ),
                    })}
                  />
                </SortableTreeBoxStyle>
              </Grid>
            </Grid>
          </Box>
          {type === 'edit' && (
            <ActionButtons
              errorFields={!selectedTender && !type}
              onSubmit={handleDocSubmit}
              cancelText='Cancel'
              onCancel={() => navigate(-1)}
            >
              <Button variant='outlined'>Cancel</Button>
              <Button variant='contained'>Submit</Button>
            </ActionButtons>
          )}
        </Box>
      )}
      <AddDocuments
        doc={doc}
        createSubDivision={createSubDivision}
        setCreateSubDivision={setCreateSubDivision}
        localLoader={localLoader}
        documents={documents}
        selectedDocumentIds={selectedDocumentIds}
        handleCheckboxChange={handleCheckboxChange}
        handleAddDocumentToType={handleAddDocumentToType}
        createDivision={createDivision}
        setCreateDivision={setCreateDivision}
        handleSubmit={handleSubmit}
        createDocumentType={createDocumentType}
        control={control}
      />

      {openEditor !== 0 && <TinyMCEEditor documentId={openEditor} />}
    </DashboardLayout>
  );
};

export default ProposalView;
