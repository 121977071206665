import axios from 'axios';
import { store } from '../store/store.ts';

const BASE_URL = process.env.REACT_APP_BASE;

const getToken = () => {
  const state = store.getState();
  return state.auth.token;
};

export const userRequest = axios.create({
  baseURL: BASE_URL,
});

userRequest.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const getRequest = axios.create({
  baseURL: BASE_URL,
});
