import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { ViewField } from '../../../components/_form/FormElements';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';

const TenderCard = (props) => {
  const { estimationAMC } = props;

  return (
    <GlassCard className='p-4 mb-5'>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Stack spacing={0.5}>
            <Box>
              <span className='bg-blue-200 text-blue-700 inline-block px-2 rounded-md text-center'>
                Tender No : {estimationAMC?.tenderNumber}
              </span>
            </Box>
            <h3 className='font-bold text-xl'>{estimationAMC?.tenderName}</h3>
            <p>
              {
                <FmViewDateField
                  title='Enquiry Date'
                  value={estimationAMC?.dateOfEnquiry}
                />
              }
              <br /> Client : {estimationAMC?.clientName}
            </p>
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack spacing={1}>
            <ViewField
              label='Building Name'
              title={estimationAMC?.buildingName}
            />
            <FmViewDateField
              title='Tender date'
              value={estimationAMC?.dateOfEnquiry}
            />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack spacing={1}>
            <ViewField
              label='Tender Bond/Bidbond Required'
              title={estimationAMC?.bidBond === true ? 'Yes' : 'No'}
            />
            <ViewField
              label='Fixed Tender Bond/Bidbond Value'
              title={estimationAMC?.bidBondValue}
            />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack spacing={1}>
            <ViewField
              label='Site Visit Done By'
              title={estimationAMC?.sitVisitDoneByName}
            />
            <ViewField
              label='Prepared By'
              title={estimationAMC?.preparedByName}
            />
          </Stack>
        </Grid>
      </Grid>
    </GlassCard>
  );
};

export default TenderCard;
