import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import mammoth from 'mammoth';
import { saveAs } from 'file-saver';
import Crud_Service from '../apis/CrudService';
import { toast } from 'react-toastify';
import DashboardLayout from '../components/DashboardLayout.tsx';
import GlassCard from '../components/small/GlassCard.tsx';
import './Styles.css';
import { CircularProgress } from '@mui/material';
import htmlDocx from 'html-docx-js/dist/html-docx';

const TinyMCEEditor = (props) => {
  const [editorContent, setEditorContent] = useState('');
  const crud = new Crud_Service();
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    document.title = 'Text Editor';
    setLoader(true);
    if (props.documentId) {
      crud.getSingle('documents', props.documentId, async (err, res) => {
        if (res?.status === 200) {
          const file = res?.data;
          if (file) {
            crud.getSingle(`files/download`, file.fileId, async (err, res) => {
              try {
                const arrayBuffer = await file.arrayBuffer();
                const result = await mammoth.convertToHtml({ arrayBuffer });
                setEditorContent(result.value); // Set HTML content
                setLoader(false);
              } catch (error) {
                toast.error('Error converting file:', error);
              }
            });
          }
        } else {
        }
      });
    }
  }, []);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setEditorContent(result.value); // Set HTML content
      } catch (error) {
        toast.error('Error converting file:', error);
      }
    }
  };

  const handleSendToAPI = async () => {
    const content = editorContent;
    const converted = htmlDocx.asBlob(content);
    const fileFormData = new FormData();
    fileFormData.append('file', converted);
    fileFormData.append('fileTypeId', 2);
    await crud.create('/files/upload', fileFormData, (err, res) => {
      if (res?.status === 200) {
        setEditorContent('');
        toast.success('File uploaded successfully');
      } else {
      }
    });
  };

  const handleSave = () => {
    const content = editorContent;
    const converted = htmlDocx.asBlob(content);
    saveAs(converted, 'edited-document.docx');
  };

  return (
    <DashboardLayout title='Document Editor'>
      {loader ? (
        <CircularProgress />
      ) : (
        <GlassCard>
          <div className='editor-container'>
            <input type='file' accept='.docx' onChange={handleFileChange} />
            <Editor
              apiKey='ph3anzfeyxabk6w4uh57p9ieqf8z2tcb9b8z1biju1d6u5ba' // Replace with your TinyMCE API key
              value={editorContent}
              init={{
                height: 500,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap preview anchor textcolor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
              onEditorChange={(newValue) => setEditorContent(newValue)}
            />
            <div className='editor-buttons'>
              <button className='editor-button' onClick={handleSendToAPI}>
                Send to API
              </button>
              <button className='editor-button' onClick={handleSave}>
                Save as .docx
              </button>
            </div>
          </div>
        </GlassCard>
      )}
    </DashboardLayout>
  );
};

export default TinyMCEEditor;
