import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Icon } from '@iconify/react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import Nav from '../../../utils/Nav.tsx';
import AddUniforms from './AddUniforms.tsx';
import UniformDataTable from './uniformDataTable.tsx';

const Uniforms = () => {
  const [viewDetails, setViewdetails] = useState(false);
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`uniforms`);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const crud = new Crud_Service();

  //fetch All Apis
  const { data: uniformcategories } = useCommonFetchApi('uniformcategories');
  const { data: uniformtypes } = useCommonFetchApi('uniformtypes');

  useEffect(() => {
    document.title = `Uniforms`;
  }, []);

  const handleUpdateUniforms = async (values) => {
    await crud.update('uniforms', editingRowId, values, (err, res) => {
      if (res?.status === 200) {
        toast.success('uniform and Accessories updated successfully');
        fetchData();
        setEditingRowId(null);
        setEditingRowData(null);
      } else {
      }
    });
  };

  const excludeKeys = [
    'uniformCategoryId',
    'projectId',
    'uniformId',
    'uniformTypeId',
    'uniformSizeId',
    'createdDate',
    'updatedDate',
    'uniformId',
    'status',
  ];

  return (
    <>
      <DashboardLayout
        title='Uniforms'
        hasSubmenu
        menu={Nav[10].child[4].children}
        parentMenu={Nav[10].child[4].name}
      >
        <AddUniforms
          uniformcategories={uniformcategories}
          uniformtypes={uniformtypes}
          fetchData={fetchData}
        />
        <GlassCard>
          <UniformDataTable
            rows={rows}
            editingRowId={editingRowId}
            isLoading={isLoading}
            editingRowData={editingRowData}
            handleUpdateUniforms={handleUpdateUniforms}
            uniformtypes={uniformtypes}
            uniformcategories={uniformcategories}
            setViewdetails={setViewdetails}
            setEditingRowId={setEditingRowId}
            setEditingRowData={setEditingRowData}
            crud={crud}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            tableRecordCounts={tableRecordCounts}
            setPagination={setPagination}
            setGlobalFilter={setGlobalFilter}
            setColumnFilters={setColumnFilters}
            setSorting={setSorting}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </GlassCard>
      </DashboardLayout>

      {/* view */}

      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && viewDetails?.uniformId && (
          <CommonView
            url='uniforms'
            id={viewDetails?.uniformId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>
      {/* view */}
    </>
  );
};

export default Uniforms;
