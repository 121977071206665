import React, { useState } from 'react';
import GlassCard from '../../../../../../components/small/GlassCard.tsx';
import Crud_Service from '../../../../../../apis/CrudService.jsx';
import useTableLogic from '../../../../../../components/helpers/MRTUseTableLogic.tsx';
import AddInjuries from './AddInjuries.tsx';
import useCommonFetchApi from '../../../../../../components/helpers/useCommonFetchApi.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import InjuriesDataTable from './InjuriesDataTable.tsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

const InjuriesTableList = () => {
  const crud = new Crud_Service();
  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('injuriesdetails');
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleEdit = (row) => {
    setEditingRowId(row?.injuriesDetailId);
    setEditingRowData({ ...row });
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'injuriesdetails',
      props?.injuriesDetailId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Injuries Deleted Successfully!');
          fetchData();
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const handleUpdateInjuries = async (values) => {
    const combinedData = {
      ...values,
      dateOfSafetyInduction: dayjs(values?.dateOfSafetyInduction).format(
        'YYYY-MM-DD'
      ),
      incidentReportId: 4,
      updateInjuriesDetailImages: editingRowData?.injuriesDetailImages?.map(
        (item) => {
          return {
            injuriesDetailImageId: item?.injuriesDetailImageId,
            fileId: item?.fileId,
            status: 2,
          };
        }
      ),
    };
    await crud.update(
      'injuriesdetails',
      editingRowId,
      combinedData,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Injuries updated successfully');
          fetchData();
          setEditingRowId(null);
          setEditingRowData(null);
        } else {
          return;
        }
      }
    );
  };

  return (
    <>
      <h3 className='mb-3 text-sm font-bold'>Injuries</h3>

      <GlassCard className='my-4'>
        <AddInjuries fetchData={fetchData} />

        <InjuriesDataTable
          rows={rows}
          editingRowId={editingRowId}
          isLoading={isLoading}
          editingRowData={editingRowData}
          handleUpdateInjuries={handleUpdateInjuries}
          ActionData={ActionData}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          pagination={pagination}
          sorting={sorting}
          setPagination={setPagination}
          setSorting={setSorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          crud={crud}
          tableRecordCounts={tableRecordCounts}
        />
      </GlassCard>
    </>
  );
};

export default InjuriesTableList;
