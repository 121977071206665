import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './i18n.js';
import { Provider } from 'react-redux';
import PageLoader from './components/page/PageLoader.jsx';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from './ThemeContext.jsx';
import 'react-toastify/dist/ReactToastify.css';
import { store, persistor } from './store/store.ts'; // Adjust the import path
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={<PageLoader />} persistor={persistor}>
      <ThemeProvider>
        <React.Suspense fallback={<PageLoader />}>
          <App />
          <ToastContainer
            limit={3}
            autoClose={5000}
            closeOnClick
            theme='colored'
          />
        </React.Suspense>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
