import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import { StatusBadge } from '../../../components/_form/FormElements';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import DataTable from '../../../components/DataTable.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import PageHeader from '../../../components/PageHeader';
import { CreateButton } from '../../../components/small/Buttons';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import { Nav } from '../../../utils';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import CommonView from '../../../components/helpers/CommonView.jsx';

const UnitOfMeasurement = (props) => {
  const { type } = props;
  const [openCreate, setOpenCreate] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewDetails, setViewdetails] = useState(false);
  const [loading1, setLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();

  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('unitofmeasurements');

  const crud = new Crud_Service();
  const { handleSubmit, setValue, control, reset } = useForm();
  const { t } = useTranslation();

  //fetch Apis
  const { data: baseunits } = useCommonFetchApi('baseunits');

  const handleSubmitUnitOfMeasurement = async (values) => {
    startCreateLoading();
    await crud.create('unitofmeasurements', values, (err, res) => {
      if (res?.status === 201) {
        stopCreateLoading();
        setOpenCreate(false);
        toast.success('Unit of Measurements created successfully');
        fetchData();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };

  const handleCreateClose = async () => {
    reset();
    setOpenCreate(false);
  };

  const handleViewClick = async (props) => {
    setViewdetails(props);
  };

  const handleEditOpen = async (props) => {
    setOpenEdit(true);
    setLoader(true);
    await crud.getSingle(
      'unitofmeasurements',
      props?.unitOfMeasurementId,
      (err, res) => {
        if (res?.status === 200) {
          Object.entries({
            ...res?.data,
          }).forEach(([key, value]) => {
            setValue(key, value);
          });
          setSelectedRow(res?.data);
          setLoader(false);
        } else {
          setLoader(true);
        }
      }
    );
  };

  const handleEditUnitOfMearuement = async (values) => {
    startEditLoading();
    await crud.update(
      'unitofmeasurements',
      selectedRow?.unitOfMeasurementId,
      values,
      (err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          setOpenEdit(false);
          toast.success('Unit of Measurements updated successfully');
          fetchData();
        } else {
          stopEditLoading();
          setOpenEdit(true);
        }
      }
    );
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'unitofmeasurements',
      props?.unitOfMeasurementId,
      (err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Unit of Measurements deleted successfully');
        } else {
        }
      }
    );
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditOpen(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
          />
        );
      },
    },
    {
      field: 'unitOfMeasurementCode',
      headerName: 'Code',
      flex: 1,
    },
    {
      field: 'unitOfMeasurementName',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'baseUnitName',
      headerName: 'Base Unit',
      flex: 1,
    },
    {
      field: 'conversionFactor',
      headerName: 'Conversion Factor',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params?.value === 2 ? 'Active' : 'Inactive'}
            type={params?.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const excludeKeys = ['unitOfMeasurementId', 'baseUnitId', 'status'];

  return (
    <DashboardLayout
      title='Unit of Measurements'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={Nav[6].child[5].children}
      parentMenu={Nav[6].child[5].name}
    >
      {
        <GlassCard className='h-full-css'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={isLoading}
              getRowClassName={(params) =>
                `${params.row.status === 0 && 'bg-red-50'}`
              }
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) =>
                handlePaginationModelChange(model)
              }
              onSortModelChange={(model) => handleSortModelChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tableRecordCounts?.total}
              slots={{
                toolbar: () => (
                  <Box
                    className='p-2 w-full flex justify-between items-center'
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'border.main',
                    }}
                  >
                    <Box sx={{ maxWidth: '250px' }}>
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />
                    </Box>
                    <CreateButton name='New' onClick={handleCreateClickOpen} />
                  </Box>
                ),
              }}
            />
          </Box>
        </GlassCard>
      }

      {/* create  */}
      <MuiDialogOne
        title='Create'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitUnitOfMeasurement)}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FmTextField
                name='unitOfMeasurementName'
                control={control}
                label='Unit of Measurement Name'
              />
            </Grid>
            <Grid item md={12}>
              <FmAutoComplete
                name='baseUnitId'
                control={control}
                label='Base Unit'
                options={baseunits}
                displayField='baseUnitName'
                optionFields={['baseUnitName']}
                valueKey='baseUnitId'
              />
            </Grid>
            <Grid item md={12}>
              <FmTextField
                name='conversionFactor'
                control={control}
                label='Conversion Factor'
              />
            </Grid>

            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitUnitOfMeasurement)}
                onCancel={handleCreateClose}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && viewDetails?.unitOfMeasurementId && (
          <CommonView
            url='unitofmeasurements'
            id={viewDetails?.unitOfMeasurementId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>

      {/*EDIT*/}
      <MuiDialogOne
        title='Edit'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {loading1 ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditUnitOfMearuement)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='unitOfMeasurementName'
                  control={control}
                  label='Unit of Measurement Name'
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='baseUnitId'
                  control={control}
                  label='Base Unit'
                  options={baseunits}
                  displayField='baseUnitName'
                  optionFields={['baseUnitName']}
                  valueKey='baseUnitId'
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='conversionFactor'
                  control={control}
                  label='Conversion Factor'
                />
              </Grid>

              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditUnitOfMearuement)}
                  onCancel={() => setOpenEdit(false)}
                  onReset={reset}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default UnitOfMeasurement;
