import React, { useState } from 'react';
import GlassCard from '../../../../../../components/small/GlassCard.tsx';
import { Box, Grid } from '@mui/material';
import useTableLogic from '../../../../../../components/helpers/MRTUseTableLogic.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import Crud_Service from '../../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import MuiDialogOne from '../../../../../../components/_mui/MuiDialogOne.jsx';
import CommonView from '../../../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../../../components/helpers/DynamicViewFields.jsx';
import FmTextField from '../../../../../../components/_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';
import FmAutoComplete from '../../../../../../components/_mui/FmAutoComplete.tsx';
import { YesNoOptions } from '../../../../../../utils/CommonVariables.tsx';
import ActionButtons from '../../../../../../components/_form/ActionButtons.tsx';
import TypeOfAccidentDataTable from './TypeOfAccidentDataTable.tsx';

const MdItemValue = 4;

const TypeAccidentList = () => {
  const [viewDetails, setViewdetails] = useState(false);
  const {
    rows,
    rowCount,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('accidentstepstaken');
  const crud = new Crud_Service();
  const { handleSubmit, control, reset } = useForm();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);

  const handleDelete = async (props) => {
    await crud.remove(
      'accidentstepstaken',
      props?.accidentStepsTakenId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('Type of Accident deleted successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleEdit = async (row) => {
    setEditingRowId(row?.accidentStepsTakenId);
    setEditingRowData({ ...row });
  };

  const handleViewClick = async (props) => {
    setViewdetails(props);
  };

  const handleCancel = (row) => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const handleSubmitTypeOfIncident = async (values) => {
    const combinedData = {
      ...values,
      //TODO: In Future We Do Dynamic
      accidentReportId: 3,
      clientInformedStatusId: Number(values?.clientInformedStatusId),
    };

    await crud.create('accidentstepstaken', combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Type of Accident created successfully');
        fetchData();
        reset();
      } else {
      }
    });
  };

  const excludeKeys = [
    'accidentReportId',
    'accidentStepsTakenId',
    'clientInformedStatusId',
    'createdBy',
    'updatedBy',
    'createdDate',
    'updatedDate',
    'status',
  ];

  const handleupdateTypeOfAccident = async (values) => {
    const combinedData = {
      ...values,
      //TODO: In Future We Do Dynamic
      accidentReportId: 3,
      clientInformedStatusId: Number(values?.clientInformedStatusId),
      status: 2,
    };

    await crud.update(
      'accidentstepstaken',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Type of Accident updated successfully');
          fetchData();
          setEditingRowData(null);
          setEditingRowId(null);
        } else {
        }
      }
    );
  };

  return (
    <>
      <h3 className='mb-3 text-sm font-bold'>Type of Accident</h3>

      <GlassCard className='my-4'>
        <Box className='p-3'>
          <form>
            <Grid container spacing={2}>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='typeOfAccident'
                  label='Type of Accident'
                  control={control}
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='accidentStepsTakenDetail'
                  label='Accident Steps Taken'
                  control={control}
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmAutoComplete
                  name='clientInformedStatusId'
                  label='Client Informed Status'
                  control={control}
                  options={YesNoOptions}
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>
            </Grid>

            <ActionButtons
              onSubmit={handleSubmit(handleSubmitTypeOfIncident)}
              onReset={reset}
            />
          </form>
        </Box>

        <TypeOfAccidentDataTable
          rows={rows}
          setRows={setRows}
          tableRecordCounts={tableRecordCounts}
          editingRowId={editingRowId}
          editingRowData={editingRowData}
          ActionData={ActionData}
          handleupdateTypeOfAccident={handleupdateTypeOfAccident}
          handleCancel={handleCancel}
          pagination={pagination}
          sorting={sorting}
          setPagination={setPagination}
          setSorting={setSorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          crud={crud}
        />
      </GlassCard>

      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && viewDetails?.accidentStepsTakenId && (
          <CommonView
            url='accidentstepstaken'
            id={viewDetails?.accidentStepsTakenId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>
    </>
  );
};

export default TypeAccidentList;
