import React, { useEffect, useState } from 'react';
import BackToButton from './../../../components/small/BackToButton.tsx';
import { Box, Grid, Stack, Button, Tabs, Tab } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { StatusBadge } from '../../../components/_form/FormElements';
import { ViewField } from '../../../components/_form/FormElements';
import TableAvatar from '../../../components/_form/TableAvatar';
import { Icon } from '@iconify/react';
import JobDetails from './View/JobDetails.tsx';
import TicketsDocumentUpload from './View/TicketsDocumentUpload.tsx';
import TicketsDetailsHistory from './View/TicketsDetailsHistory.tsx';
import Lottie from 'react-lottie';
import * as animationData from '../../../assets/lottie/flag.json';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';

const ViewSourceArray = [
  {
    name: 'Job Detail',
  },
  {
    name: 'Documents Upload',
  },
  {
    name: 'History',
  },
];

const ViewDetailTicket = (props) => {
  const { editingRowId, setEditingRowId } = props;
  const { id } = useParams();
  const navigator = useNavigate();
  const [tabValue, setTabValues] = useState(0);
  const [viewData, setViewData] = useState([]);
  const [openUpload, setOpenUpload] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    searchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic(`tickets/${id}/attachments`);

  const crud = new Crud_Service();
  const {
    reset,
    getValues,
  } = useForm();
  const handleTabChange = (event, newValue) => {
    setTabValues(newValue);
  };
  const viewDataFetch = async () => {
    crud.getSingle('tickets', id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
      }
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadLoading(true);
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 64);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
        }
      });
    }
  };
  const handleSubmitAttachment = async () => {
    startCreateLoading();
    const combinedData = {
      ticketId: id,
      attachmentId: uploadedfileData?.fileId,
    }
    await crud.create(
      `ticketattachments`,
      combinedData,
      (err, res) => {
        if (res?.status === 201) {
          toast.success('Attachment Uploaded Successfully');
          stopCreateLoading();
          setOpenUpload(false);
          fetchData();
          setUploadedfileData(null);
          setOpenUpload(false);
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);
  return (
    <DashboardLayout>
      <>
        <BackToButton
          title='Back to Tickets'
          className='px-3'
          onClick={() => navigator('/cafm/tickets')}
        />

        <GlassCard className='my-4 overflow-hidden'>
          <Box className='p-6'>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <Box className='flex gap-3 items-center mb-3 text-sm'>
                  <Box>{viewData?.ticketCode || 'N/A'}</Box>|<Box>Assets : {viewData?.assetName || 'N/A'}</Box>
                </Box>

                <h2 className='text-xl font-bold mb-1 flex items-center gap-4'>
                  <span className='text-red-500 text-2xl pointer-events-none'>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                      }}
                      height={'40px'}
                      width={'40px'}
                    />
                  </span>
                  <span>{viewData?.description || 'N/A'}</span>
                </h2>
                <p className='mb-3 opacity-75'>
                  {viewData?.comment || 'N/A'}
                </p>

                <Button
                  variant='contained'
                  startIcon={<Icon icon='ic:twotone-attachment' />}
                  onClick={() => {
                    setOpenUpload(true);
                    setUploadedfileData(null);
                  }}>
                  Attachment
                </Button>
              </Grid>
              <MuiDialogOne
                open={openUpload}
                onClose={() => setOpenUpload(false)}
                title='Attachment'
                description='Upload your Attachment'
              >
                <FmFileInput
                  name='file'
                  documentName={uploadedfileData?.originalFileName}
                  onChange={handleFileUpload}
                  loading={uploadLoading}
                />
                <ActionButtons
                  onSubmit={handleSubmitAttachment}
                  onCancel={() => setOpenUpload(false)}
                  submitLoading={createLoading}
                  cancelLoading={false}
                  disabled={uploadLoading || !uploadedfileData}
                />
              </MuiDialogOne>
              <Grid item md={4}>
                <Stack spacing={2}>
                  <ViewField
                    label='Request Name'
                    title={<TableAvatar name={viewData?.requesterName || 'N/A'} />}
                  />
                  <ViewField
                    label='Technician'
                    title={<TableAvatar name={viewData?.technicianName || 'N/A'} />}
                  />
                </Stack>
              </Grid>

              <Grid item md={2}>
                <Stack spacing={2}>
                  <FmViewDateField
                    title='Create Date'
                    value={viewData?.createdDate || 'N/A'}
                  />
                  <FmViewDateField
                    title='Resolve Date'
                    value={viewData?.resolveDate || 'N/A'}
                  />
                  <Box>
                    <StatusBadge title={viewData?.ticketStatusName} type='green' />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ bgcolor: 'background.light', px: 1 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{
                '& .MuiButtonBase-root': {
                  fontWeight: 700,
                  justifyContent: 'center',
                },
              }}
            >
              {ViewSourceArray.map((item, i) => {
                return <Tab value={i} label={item.name} />;
              })}
            </Tabs>
          </Box>
        </GlassCard>

        {tabValue === 0 && <JobDetails
          viewData={viewData}
          setViewData={setViewData}
          editingRowId={editingRowId}
          setEditingRowId={setEditingRowId}
          fetchData={fetchData}
          crud={crud} />}
        {tabValue === 1 && <TicketsDocumentUpload
          uploadedfileData={uploadedfileData}
          rows={rows}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setSearchKeyword={setSearchKeyword}
          isLoading={isLoading}
          handlePaginationModelChange={handlePaginationModelChange}
          handleSortModelChange={handleSortModelChange}
          searchKeyword={searchKeyword}
          setUploadedfileData={setUploadedfileData}
          setOpenUpload={setOpenUpload}
          fetchData={fetchData}
          tableRecordCounts={tableRecordCounts}
          crud={crud}

        />}
        {tabValue === 2 && <TicketsDetailsHistory />}
      </>
    </DashboardLayout>
  );
};

export default ViewDetailTicket;
