import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid, TextField, Button, MenuItem } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../components/_form';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import CommonLoader from '../../../components/page/CommonLoader';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const EditTenderEnquiry = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { register, setValue, handleSubmit, control,reset} = useForm();
  // const [errors, setErrors] = useState({});
  const [clients, setClients] = useState([]);
  const [tenderEnquiryTypes, setTenderEnquiryTypes] = useState([]);
  const [tenderProjectTypes, setTenderprojectTypes] = useState([]);
  const [tenderFormats, setTenderFormats] = useState([]);
  const [tenderStatuses, setTenderStatuses] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getTenders();
    getClients();
    getTenderEnquiryTypes();
    getTenderProjectTypes();
    getTenderFormats();
    getTenderStatuses();
  }, []);

  const getTenders = async () => {
    setLoading(true);
    await crud.getSingle('tenders', id, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
      } else {
      }
    });
  };

  //get All Clients
  const getClients = async () => {
    await crud.getAll('clients', '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setClients(res?.data?.data);
      }
    });
  };

  //get All TenderEnquiry Types
  const getTenderEnquiryTypes = async () => {
    await crud.getAll('tenderenquirytypes', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Tender Enquiry Types:', err);
        return;
      }
      if (res?.status === 200) {
        setTenderEnquiryTypes(res?.data?.data);
      }
    });
  };

  //get All TenderProject Types
  const getTenderProjectTypes = async () => {
    await crud.getAll('tenderprojecttypes', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Tender project Types:', err);
        return;
      }
      if (res?.status === 200) {
        setTenderprojectTypes(res?.data?.data);
      }
    });
  };
  //get All TenderFormaatTypes
  const getTenderFormats = async () => {
    await crud.getAll('tenderformats', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Tender project Types:', err);
        return;
      }
      if (res?.status === 200) {
        setTenderFormats(res?.data?.data);
      }
    });
  };
  //get All TenderFormaatTypes
  const getTenderStatuses = async () => {
    await crud.getAll('tenderstatuses', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Tender project Types:', err);
        return;
      }
      if (res?.status === 200) {
        setTenderStatuses(res?.data?.data);
      }
    });
  };

  const handleEditTenders = async (values) => {
    const CombinedData = {
      ...values,
      bidBondValue: Number(values?.bidBondValue),
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      lastDate: dayjs(values?.lastDate).format('YYYY-MM-DD'),
      tenderValue: Number(values?.tenderValue),
      status: Number(values?.status),
    };

    await crud.update('tenders', id, CombinedData, (err, res) => {
      if (res?.status === 200) {
        navigate(-1);
      } else {
      }
    });
  };

  return (
    <DashboardLayout title='Edit Tender Enquiry'>
      <Box className='mt-10 mx-auto' sx={{ maxWidth: '1200px' }}>
        <GlassCard className='p-5'>
          <h2 className='text-lg font-bold mb-5'>Edit Tender/Lead Enquiry</h2>
          {loading ? (
            <CommonLoader />
          ) : (
            <form onSubmit={handleSubmit(handleEditTenders)}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <TextField
                      {...register('tenderName')}
                      fullWidth
                      label='Tender Name'
                      variant='filled'
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='clientId'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='client'
                          fullWidth
                          select
                          variant='filled'
                        >
                          {clients?.map((item) => (
                            <MenuItem
                              key={item?.clientId}
                              value={item?.clientId}
                            >
                              {item?.clientName}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='tenderEnquiryTypeId'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Tender Enquiry Type'
                          fullWidth
                          select
                          variant='filled'
                        >
                          {tenderEnquiryTypes?.map((item) => (
                            <MenuItem
                              key={item?.tenderEnquiryTypeId}
                              value={item?.tenderEnquiryTypeId}
                            >
                              {item?.tenderEnquiryTypeName}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='tenderProjectTypeId'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Tender Project Type'
                          fullWidth
                          select
                          variant='filled'
                        >
                          {tenderProjectTypes?.map((item) => (
                            <MenuItem
                              key={item?.tenderProjectTypeId}
                              value={item?.tenderProjectTypeId}
                            >
                              {item?.tenderProjectTypeName}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='tenderFormatId'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Tender Formats'
                          fullWidth
                          select
                          variant='filled'
                        >
                          {tenderFormats?.map((item) => (
                            <MenuItem
                              key={item?.tenderFormatId}
                              value={item?.tenderFormatId}
                            >
                              {item?.tenderFormatName}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='autoRenewal'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Auto Renewal'
                          fullWidth
                          select
                          variant='filled'
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item md={3}>
                    <TextField
                      {...register('bidBondValue')}
                      fullWidth
                      label='Bid Bond Value'
                      variant='filled'
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='subContractRequired'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Sub Contract Required'
                          fullWidth
                          select
                          variant='filled'
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='startDate'
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label='Start Date'
                          value={value ? dayjs(value) : null}
                          format='DD/MM/YYYY'
                          onChange={(newValue) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth variant='filled' {...params} />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='lastDate'
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label='Last Date'
                          value={value ? dayjs(value) : null}
                          format='DD/MM/YYYY'
                          onChange={(newValue) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth variant='filled' {...params} />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      {...register('tenderValue')}
                      fullWidth
                      label='Tender Value'
                      variant='filled'
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='bidBond'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Bid Bond'
                          fullWidth
                          select
                          variant='filled'
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='expressOfInterest'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Express Of Interest'
                          fullWidth
                          select
                          variant='filled'
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='buyTenderDocument'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Buy Tender Document'
                          fullWidth
                          select
                          variant='filled'
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='tenderStatusId'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Tender Statuses'
                          fullWidth
                          select
                          variant='filled'
                        >
                          {tenderStatuses?.map((item) => (
                            <MenuItem
                              key={item?.tenderStatusId}
                              value={item?.tenderStatusId}
                            >
                              {item?.tenderStatusName}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='status'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Status'
                          fullWidth
                          select
                          variant='filled'
                        >
                          <MenuItem value={2}>Active</MenuItem>
                          <MenuItem value={1}>Inactive</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>

              <ActionButtons onReset={reset}>
                <Button variant='text' onClick={() => navigate(-1)}>
                  {t('cancel')}
                </Button>
                <Button variant='contained' type='submit'>
                  {t('Update')}
                </Button>
              </ActionButtons>
            </form>
          )}
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default EditTenderEnquiry;
