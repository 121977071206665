import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import CreateSalesQuotationDetails from './CreateSalesQuotationDetails.tsx';
import ViewCreateSalesQuotationList from './ViewCreateSalesQuotationList.tsx';
import ViewAddSalesQuotation from './ViewAddSalesQuotation.tsx';
import HorizantalStepsTwo from '../../../../../components/_form/HorizantalStepsTwo.tsx';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import CommonLoader from '../../../../../components/page/CommonLoader.jsx';
import AddSalesOrder from '../../CreateSalesOrder/AddSalesOrder.tsx';
import EditSalesOrder from './EditSalesOrder.tsx';
import AddCreateSalesQuotation from '../AddCreateSalesQuotation.tsx';
import EditSalesQuotation from './EditSalesQuotation.tsx';

const ViewSalesQuotation = (props) => {
  const { type } = props;
  const id = useParams().id;
  const [data, setData] = useState([]);
  const crud = new Crud_Service();
  const [loader, setLoader] = useState(true);
  const [getData, setGetData] = useState(false);

  useEffect(() => {
    document.title = `View | Sales ${
      type === 2 ? 'Order | Accounts Receivable' : ''
    }${type === 3 ? 'Return | Accounts Receivable' : ''} ${
      type === 'quotation' ? 'Quotation | Work Order | FM Division' : ''
    }${type === 'order' ? 'Order | Work Order | FM Division' : ''} `;
    fetchData();
  }, []);

  const fetchData = async () => {
    await crud.getSingle(
      `${type === 'quotation' ? 'salesquotations' : 'salesorders'}`,
      id,
      (_err, res) => {
        if (res?.status === 200) {
          setData(res?.data);
          setLoader(false);
        } else {
        }
      }
    );
  };

  const ProgressData = [
    {
      id: 1,
      name: 'Sales Quotation',
      activeState: 1,
    },
    {
      id: 2,
      name: 'Sales Approval',
      activeState: 0,
    },
  ];

  return (
    <DashboardLayout
      title={`View Sales ${type === 'quotation' ? 'Quotation' : 'Order'}`}
    > <EditSalesQuotation/>
      <Box className='my-8'>
        <HorizantalStepsTwo  data={ProgressData} api='salesquotations'/>
      </Box>
      <ViewCreateSalesQuotationList
        type={type}
        getData={getData}
        setGetData={setGetData}
      />
    </DashboardLayout>
  );
};

export default ViewSalesQuotation;
