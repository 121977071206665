import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { Box, Button } from '@mui/material';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../../components/table/MRTExpandTableStyle.tsx';
import { Icon } from '@iconify/react';
const SalesRetentionItemListTable = (props) => {
  const {
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { handleSubmit, control, reset, setValue } = useForm();
  const { startEditLoading, stopEditLoading } = useLoader();

  const crud = new Crud_Service();
  const handleEdit = (row) => {
    setEditingRowId(row?.original?.salesRetentionItemId);
    setEditingRowData({ ...row?.original });
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'salesretentionitems',
      props?.original?.salesRetentionItemId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Sales Retention Item deleted successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  const handleUpdateSalesRetentionItem = async (values) => {
    try {
      startEditLoading();
      await crud.update(
        'salesretentionitems',
        values?.salesRetentionItemId,
        values,
        (_err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success('Sales Retention Updated Successfully');
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
            toast.error('Failed to update the Block');
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating the Block');
    }
  };
  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
    },
  ];
  const columns = [
    {
      field: 'action',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,

      Cell: (params) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId === params?.row?.original?.salesRetentionItemId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateSalesRetentionItem)}>
                  Save
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params.row),
                }))}
                selectedRow={params.row}
                setSelectedRow={() => {}}
              />
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'companyId',
      header: 'Company Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            headerField={['Company Code', 'Company Name']}
            labelField={['companyCode', 'companyName']}
            showField={['companyName']}
            pageSize={20}
            label='Company Code'
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyName: editingRowData?.companyName,
            }}
          />
        ) : (
          salesItemDetails?.companyName || ''
        );
      },
    },
    {
      accessorKey: 'itemId',
      header: 'Item',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmSearchableSelect
            name='itemId'
            control={control}
            apiUrl='item'
            valueField='itemId'
            headerField={['Item Name']}
            labelField={['itemName']}
            showField={['itemName']}
            pageSize={20}
            label='Item Name'
            defaultValue={{
              itemId: editingRowData?.itemId,
              itemName: editingRowData?.itemName,
            }}
          />
        ) : (
          salesItemDetails?.itemName || ''
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmTextField
            name='description'
            control={control}
            label='Description'
          />
        ) : (
          salesItemDetails?.description || ''
        );
      },
    },
    {
      accessorKey: 'unitOfMeasurementId',
      header: 'Units',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmSearchableSelect
            name='unitOfMeasurementId'
            control={control}
            apiUrl='unitofmeasurements'
            valueField='unitOfMeasurementId'
            headerField={['Unit']}
            labelField={['unitOfMeasurementName']}
            showField={['unitOfMeasurementName']}
            pageSize={20}
            label='Units'
            defaultValue={{
              unitOfMeasurementId: editingRowData?.unitOfMeasurementId,
              unitOfMeasurementName: editingRowData?.unitOfMeasurementName,
            }}
          />
        ) : (
          salesItemDetails?.unitOfMeasurementName || ''
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmTextField name='quantity' control={control} label='Quantity' />
        ) : (
          salesItemDetails?.quantity || ''
        );
      },
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmTextField name='rate' control={control} label='Rate' />
        ) : (
          salesItemDetails?.rate || ''
        );
      },
    },
    {
      accessorKey: 'gross',
      header: 'Gross',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmTextField name='gross' control={control} label='Gross' />
        ) : (
          salesItemDetails?.gross || ''
        );
      },
    },
    {
      accessorKey: 'discount',
      header: 'Discount',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmTextField name='discount' control={control} label='Discount' />
        ) : (
          salesItemDetails?.discount || ''
        );
      },
    },
    {
      accessorKey: 'vat',
      header: 'VAT',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmTextField name='vat' control={control} label='Vat' />
        ) : (
          salesItemDetails?.vat || ''
        );
      },
    },
    {
      accessorKey: 'amountWithTax',
      header: 'Amount With Tax',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmTextField
            name='amountWithTax'
            control={control}
            label='Amount With Tax'
          />
        ) : (
          salesItemDetails?.amountWithTax || ''
        );
      },
    },

    {
      accessorKey: 'taxCodeId',
      header: 'Tax Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmSearchableSelect
            name='taxCodeId'
            control={control}
            apiUrl='taxcodes'
            valueField='taxCodeId'
            headerField={['Tax Code']}
            labelField={['taxCodeCode']}
            showField={['taxCodeCode']}
            pageSize={20}
            label='Tax Code'
            defaultValue={{
              taxCodeId: editingRowData?.taxId,
              taxCodeCode: editingRowData?.taxCodeName,
            }}
          />
        ) : (
          salesItemDetails?.taxCodeName || ''
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmTextField name='remarks' control={control} label='Remarks' />
        ) : (
          salesItemDetails?.remarks || ''
        );
      },
    },
  ];

  useEffect(() => {
    if (editingRowData) {
      Object.entries({
        ...editingRowData,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);
  return (
    <>
      <GlassCard className='my-4'>
        <MRTExpandTableStyle>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default SalesRetentionItemListTable;
