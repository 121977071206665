import React, { useEffect } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Nav } from '../../../utils/index.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box, Stack, Button, InputAdornment } from '@mui/material';
import DataTable from '../../../components/DataTable.tsx';
import dayjs from 'dayjs';
import {
  LeaveColors,
  LeaveText,
  FilledTextPercentage,
} from '../../../components/_form/FormElements.jsx';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import CheckboxSearchFilter from '../../../components/_form/CheckboxSearchFilter.jsx';
import { Icon } from '@iconify/react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import MuiPopoverCustom from '../../../components/_form/MuiPopoverCustom.jsx';
import { useState } from 'react';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../store/loaderSlice.ts';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import { toast } from 'react-toastify';

const IndicadreorsData = [
  {
    smalltext: 'P',
    text: 'presentDays',
  },
  {
    smalltext: 'PH',
    text: 'Public Holiday',
  },
  {
    smalltext: 'H',
    text: 'Holiday',
  },
  {
    smalltext: 'O',
    text: 'Overtime',
  },
  {
    smalltext: 'L',
    text: 'Leave',
  },
  {
    smalltext: 'FH',
    text: 'First Leave',
  },
  {
    smalltext: 'SH',
    text: 'Second Leave',
  },
  {
    smalltext: '',
    text: 'Not Available',
  },
];

const SelectFilter = [
  {
    name: 'Department',
    child: [
      {
        id: 16,
        value: 'Construction',
        checked: false,
      },
      {
        id: 17,
        value: 'IT',
        checked: false,
      },
      {
        id: 18,
        value: 'Finance',
        checked: false,
      },

      {
        id: 19,
        value: 'Human Resources',
        checked: false,
      },

      {
        id: 20,
        value: 'Marketing',
        checked: false,
      },
    ],
  },
];

const AttendanceSummary = () => {
  const [textShow, setTextShow] = useState(false);
  const crud = new Crud_Service();
  const [rowData, setRowData] = useState();
  const isLoading = useSelector((state) => state.loader.isLoading);
  const dispatch = useDispatch();
  const [openUpload, setOpenUpload] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const [tablerecordCounts, setTableRecordCounts] = useState(null);
  const [sortDirection, setSortDirection] = useState('');
  const toggleLoading = (loading) => {
    dispatch(setLoading(loading));
  };

  useEffect(() => {
    fetchData(selectedDate, searchKeyword);
  }, [pageNumber, pageSize, sortBy, sortDirection, searchKeyword]);

  const handlePagination = (model) => {
    setPageSize(model?.pageSize);
    setPageNumber(model?.page + 1);
  };
  const fetchData = async (date) => {
    toggleLoading(true);
    const api =
      searchKeyword === ''
        ? `employeeattendancetransactions/${date.format('MM/YYYY')}`
        : `employeeattendancetransactions/${date.format(
            'MM/YYYY'
          )}?searchKeyword=${searchKeyword}`;
    crud.getAll(
      api,
      {
        pageSize,
        pageNumber,
        sortBy,
        sortDirection,
        searchKeyword,
      },
      (err, res) => {
        if (err) {
          toast.error('Error fetching Employee Attendance:', err);
          toggleLoading(false);
          return;
        } else if (res?.status === 204) {
          toggleLoading(false);
          setRowData();
          return;
        }
        setTableRecordCounts(res?.data);
        setRowData(res.data.data);
        toggleLoading(false);
      }
    );
  };
  const handleTextShow = () => {
    setTextShow(!textShow);
  };
  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    } else {
      setSortBy('');
      setSortDirection('');
    }
  };
  const getDayName = (year, month, day) => {
    const date = new Date(year, month, day);
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];
    return days[date.getDay()];
  };

  const isWeekend = (year, month, day) => {
    const date = new Date(year, month, day);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0;
  };

  const headerTextWrapper = (month, day, date) => {
    return (
      <Box>
        <Box sx={{ fontWeight: 500 }}>
          {month} {day}
        </Box>
        <Box sx={{ fontSize: '12px', opacity: 0.7, lineHeight: 1 }}>{date}</Box>
      </Box>
    );
  };

  const isCurrentDate = (year, month, day) => {
    const today = new Date();
    return (
      today.getFullYear() === year &&
      today.getMonth() === month &&
      today.getDate() === day
    );
  };

  const handleClose = () => {
    setOpenUpload(false);
  };
  const generateDateColumns = (month, year) => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const daysInMonth = new Date(year, month, 0).getDate();
    const YearName = new Date(year, month, 0).getFullYear();
    const monthCurrent = new Date(year, month, 0).getMonth();
    const daysInMonthName = monthNames[new Date(year, month, 0).getMonth()];

    const array = Array.from({ length: daysInMonth }, (_, i) => ({
      field: `day_${i + 1}`,
      headerName: headerTextWrapper(
        `${daysInMonthName}`,
        `${i + 1}`,
        `${getDayName(YearName, monthCurrent, i + 1)} `
      ),
      headerClassName: `text-no-wrap p-0 ${
        isWeekend(YearName, monthCurrent, i + 1)
          ? 'right-border-add bg-yellow-100'
          : isCurrentDate(YearName, monthCurrent, i + 1)
          ? 'bg-teal-100'
          : 'bg-yellow-100 '
      } `,
      width: 90,
      editable: false,
      sortable: false,
      cellClassName: `${
        isWeekend(YearName, monthCurrent, i + 1)
          ? 'right-border-add bg-yellow-50 '
          : isCurrentDate(YearName, monthCurrent, i + 1)
          ? 'bg-teal-100'
          : 'bg-yellow-50'
      } `,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <>
            {!textShow ? (
              <LeaveColors text={params.value} tooltipOff={false} />
            ) : (
              <LeaveText text={params.value} tooltipOff={false} />
            )}
          </>
        );
      },
    }));
    return array;
  };

  const generateRows = (employees, daysInMonth) => {
    return employees.map((employee, index) => {
      const attendance = {};

      for (let i = 1; i <= daysInMonth; i++) {
        const dayData = employee.days.find(
          (day) => new Date(day.date).getDate() === i
        );
        attendance[`day_${i}`] = dayData ? dayData.attendanceCode : '';
      }

      return {
        id: index,
        ...attendance,
        ...employee,
      };
    });
  };

  const rows = generateRows(rowData || [], selectedDate.daysInMonth());

  const columns = [
    {
      field: 'employeeCode',
      headerName: 'Code',
      hideable: false,
      width: 80,
      sortable: false,
    },
    {
      field: 'employeeName',
      headerName: 'Name',
      hideable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <TableAvatar
            name={params.value}
            img={params.row.imageUrl}
            desc={params.row.mobile_no}
          />
        );
      },
    },
    {
      field: 'cadre',
      headerName: 'Cadre',
      sortable: false,
      width: 75,
    },
    // {
    //   field: "dept",
    //   headerName: "Dept",
    //   sortable: false,
    //   width: 80,
    // },
    {
      field: 'contractCode',
      headerName: 'Contract Code',
      sortable: false,
      width: 150,
    },
    // {
    //   field: "contractCode",
    //   sortable: false,
    //   headerName: "Cont / WO Code",
    //   width: 80,
    // },
    {
      field: 'buildingCode',
      sortable: false,
      headerName: 'Build Code',
      width: 120,
    },
    {
      field: 'presentDays',
      headerName: 'P',
      sortable: false,
      width: 60,
      headerClassName: 'bg-orange-100',
      cellClassName: 'bg-orange-50',
    },
    {
      field: 'absentDays',
      headerName: 'A',
      sortable: false,
      width: 60,
      headerClassName: 'bg-orange-100',
      cellClassName: 'bg-orange-50',
    },
    {
      field: 'medicalLeave',
      headerName: 'ML',
      width: 60,
      sortable: false,
      headerClassName: 'bg-orange-100',
      cellClassName: 'bg-orange-50',
    },
    {
      field: 'notMinutes',
      headerName: 'NOT',
      width: 120,
      sortable: false,
      hide: false,
      headerClassName: 'bg-purple-100',
      cellClassName: 'bg-purple-50',
      renderCell: (params) => {
        const valueInHours = params.value ? params.value / 60 : 0;
        return <div>{valueInHours.toFixed(2)}</div>;
      },
    },
    {
      field: 'wotMinutes',
      headerName: 'WOT',
      width: 120,
      sortable: false,
      hide: false,
      headerClassName: 'bg-purple-100',
      cellClassName: 'bg-purple-50',
      renderCell: (params) => {
        const valueInHours = params.value ? params.value / 60 : 0;
        return <div>{valueInHours.toFixed(2)}</div>;
      },
    },
    {
      field: 'photMinutes',
      headerName: 'PHOT',
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: 'bg-purple-100',
      cellClassName: 'bg-purple-50',
      renderCell: (params) => {
        const valueInHours = params.value ? params.value / 60 : 0;
        return <div>{valueInHours.toFixed(2)}</div>;
      },
    },
    ...generateDateColumns(
      selectedDate.format('MM'),
      selectedDate.format('YYYY')
    ),

    {
      field: 'totalDays',
      headerName: 'Total Days',
      width: 100,
      sortable: false,
      align: 'right',
    },
    {
      field: 'attendance',
      headerName: 'Attendance (%)',
      width: 130,
      sortable: false,
      renderCell: (params) => {
        return <FilledTextPercentage text={params.value} />;
      },
    },
  ];

  const handleUploadSheet = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 4);

      crud.create('files/upload', fileFormData, (_err, res) => {
        if (res?.status === 200) {
          crud.create(`files/process/${res.data?.fileId}`, '', (_err, res) => {
            if (res?.status === 200) {
              fetchData(dayjs());
              setOpenUpload(false);
            }
          });
        } else {
          setOpenUpload(false);
        }
      });
    }
  };

  const handleMonthChange = (event) => {
    fetchData(event);
    setSelectedDate(event);
  };
  return (
    <>
      <DashboardLayout
        title='Attendance Summary'
        hasSubmenu
        menu={Nav[0].child[1].children}
        parentMenu={Nav[0].child[1].name}
        parentMenuUrl={Nav[0].child[1].path}
        actionButtons={
          <>
            <Stack spacing={1} direction='row'>
              <MuiPopoverCustom
                data={IndicadreorsData}
                openState={textShow}
                handleTextShow={handleTextShow}
              />
              <Button
                variant='contained'
                color='primary'
                onClick={() => setOpenUpload(true)}
                startIcon={<Icon icon='lucide:sheet' />}
              >
                Upload Attendance Sheet
              </Button>
            </Stack>
          </>
        }
      >
        {/* Listing */}
        {
          <GlassCard>
            <Box
              className='box_height_1'
              sx={{
                '& .text-no-wrap': {},
                '& .text-no-wrap .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'normal',
                  lineHeight: '18px',
                  textAlign: 'center',
                  fontWeight: 300,
                },
                '& .MuiDataGrid-root  .MuiDataGrid-cell.right-border-add, & .MuiDataGrid-root .MuiDataGrid-columnHeader.right-border-add ':
                  {
                    borderRight: '2px dashed #d6c10c',
                  },
              }}
            >
              <DataTable
                rows={rows || []}
                columns={columns}
                initialState={{
                  pinnedColumns: { left: ['employeeName'] },
                }}
                loading={isLoading}
                sortingMode='server'
                paginationMode='server'
                onPaginationModelChange={(model) => handlePagination(model)}
                onSortModelChange={(model) => handleSortChange(model)}
                page={pageNumber - 1}
                pageSize={pageSize}
                rowCount={tablerecordCounts?.total}
                slots={{
                  toolbar: () => (
                    <Box
                      className='flex justify-between items-center p-2'
                      sx={{
                        bgcolor: 'background.white',
                        borderBottom: '1px solid',
                        borderColor: 'border.main',
                      }}
                    >
                      <Box className='flex-1'>
                        <Stack direction='row' spacing={2} alignItems='center'>
                          <Box sx={{ maxWidth: '250px' }}>
                            <TableSearch
                              placeholder='Search'
                              fullWidth
                              setSearchKeyword={setSearchKeyword}
                              searchValue={searchKeyword}
                            />
                          </Box>
                          <CheckboxSearchFilter data={SelectFilter} />
                        </Stack>
                      </Box>
                      <Stack direction='row' spacing={2} alignItems='center'>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                              views={['year', 'month']}
                              value={dayjs(selectedDate)}
                              onChange={(day) => handleMonthChange(day)}
                              slotProps={{
                                textField: {
                                  id: 'hello-mobile-datepicker',
                                  sx: {
                                    '& .MuiInputBase-root': {
                                      cursor: 'pointer',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      display: 'none',
                                    },
                                    '& .MuiInputAdornment-root': {
                                      margin: '0px',
                                    },
                                    '& .MuiInputAdornment-root svg': {
                                      fontSize: '20px',
                                    },
                                    '& .MuiInputBase-input': {
                                      padding: '8px!important',
                                      width: 'auto',
                                      margin: '0 5px',
                                      maxWidth: '130px',
                                      cursor: 'pointer',
                                    },
                                  },

                                  InputProps: {
                                    startAdornment: (
                                      <InputAdornment position='start'>
                                        {' '}
                                        <Icon icon='fontisto:date' />
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        {' '}
                                        <Icon icon='fe:drop-down' />
                                      </InputAdornment>
                                    ),
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>

                        <DataTableToolbarButtons />
                      </Stack>
                    </Box>
                  ),
                }}
              />
            </Box>
            <MuiDialogOne
              title='Upload Attendance Document'
              open={openUpload}
              onClose={handleClose}
            >
              <input
                type='file'
                onChange={handleUploadSheet}
                id='file'
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              />
            </MuiDialogOne>
          </GlassCard>
        }
      </DashboardLayout>
    </>
  );
};

export default AttendanceSummary;
