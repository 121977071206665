import React from 'react';
import {
  MaterialReactTable,
  MRT_RowSelectionState,
} from 'material-react-table';
import MRTExpandTableStyle from './table/MRTExpandTableStyle.tsx';
import { Box, Button } from '@mui/material';
import TableSearch from './_form/TableSearch.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';

interface FmMRTDataTableProps {
  rows: any[];
  columns: any[];
  isLoading?: boolean;
  rowCount?: number;
  columnFilters?: any;
  globalFilter?: any;
  editingRow?: any;
  handlePaginationModelChange?: any;
  handleSortModelChange?: any;
  tableRecordCounts?: number;
  pageSize?: number;
  pageIndex?: number;
  editingRowId?: string | null;
  setPagination?: any;
  pagination?: any;
  setSorting: any;
  sorting: any;
  disableNewButton?: boolean;
  setSearchKeyword?: (value: any) => void;
  searchKeyword?: string | number;
  onCreateRow?: () => void;
  isCreatingRow?: boolean;
  muiTableContainerProps?: any;
  columnPining?: any;
  fetchData?: () => void;
  setRowSelected?: (value: MRT_RowSelectionState) => void;
  enableRowSelection?: boolean;
  renderTopToolbarCustomActionsChildrens?: any;
  delectCheckBoxClicked?: () => void;
  rowSelection?: MRT_RowSelectionState;
  setRowSelection?: (value: MRT_RowSelectionState) => void;
  handleRowDoubleClick?: (row: any) => void;
  handleExport?: () => void;
  handleImport?: () => void;
}

const FmMRTDataTable: React.FC<FmMRTDataTableProps> = React.memo((props) => {
  const {
    columns,
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    setPagination,
    setSorting,
    editingRow,
    searchKeyword,
    setSearchKeyword,
    onCreateRow,
    isCreatingRow,
    disableNewButton = true,
    muiTableContainerProps,
    columnPining = { left: ['Actions'], right: ['Action'] },
    enableRowSelection = false,
    fetchData,
    setRowSelection,
    rowSelection = {},
    renderTopToolbarCustomActionsChildrens,
    delectCheckBoxClicked,
    handleRowDoubleClick,
    handleExport,
    handleImport,
  } = props;

  // useEffect(() => {
  //   if (setRowSelected) {
  //     setRowSelected(rowSelection);
  //   }
  // }, [rowSelection, setRowSelected]);

  return (
    <MRTExpandTableStyle>
      <MaterialReactTable
        columns={columns}
        data={rows}
        manualPagination
        manualSorting={true}
        rowCount={rowCount}
        editingRow={editingRow}
        enableStickyHeader={true}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          sorting,
          rowSelection,
        }}
        enableRowSelection={enableRowSelection}
        onRowSelectionChange={setRowSelection}
        enableColumnActions={false}
        enableKeyboardShortcut={true}
        isLoading={isLoading}
        enableFilters={false}
        enableDensityToggle={false}
        initialState={{
          columnPinning: columnPining,
        }}
        muiTableBodyRowProps={({ row }) => ({
          onDoubleClick: () => {
            handleRowDoubleClick && handleRowDoubleClick(row);
          },
        })}
        muiTableContainerProps={muiTableContainerProps}
        renderTopToolbarCustomActions={() => (
          <>
            <Box className='flex items-center justify-between gap-2 w-full'>
              {rowSelection && Object.keys(rowSelection).length > 0 && (
                <Box
                  sx={{
                    maxWidth: '40px',
                    fontSize: '25px',
                  }}
                >
                  <Icon
                    icon='gridicons:trash'
                    onClick={delectCheckBoxClicked}
                  ></Icon>
                </Box>
              )}
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '400px',
                }}
              >
                <TableSearch
                  placeholder='Search'
                  fullWidth
                  setSearchKeyword={setSearchKeyword}
                  searchValue={searchKeyword}
                />
              </Box>
              <Box className='flex items-center'>
                {renderTopToolbarCustomActionsChildrens}
                <Button
                  onClick={fetchData}
                  variant='outlined'
                  className='py-1 px-4'
                  startIcon={<Icon icon='ic:baseline-refresh' />}
                >
                  Refresh
                </Button>
                {onCreateRow && disableNewButton && (
                  <Button
                    onClick={onCreateRow}
                    variant='contained'
                    color='primary'
                    sx={{ ml: 1 }}
                    disabled={isCreatingRow}
                    className='py-1 px-4'
                    startIcon={<Icon icon='ic:baseline-add' />}
                  >
                    New
                  </Button>
                )}
                {handleExport && (
                  <Button
                    variant='text'
                    color='primary'
                    sx={{ fontSize: '30px' }}
                    onClick={handleExport}
                  >
                    <Icon icon='material-symbols:download' />{' '}
                  </Button>
                )}
                {handleImport && (
                  <Button
                    variant='text'
                    color='primary'
                    sx={{ fontSize: '30px' }}
                    onClick={handleImport}
                  >
                    <Icon icon='material-symbols:upload' />{' '}
                  </Button>
                )}
              </Box>
            </Box>
          </>
        )}
      />
    </MRTExpandTableStyle>
  );
});

export default FmMRTDataTable;
