import React from 'react';
import { Box } from '@mui/material';

const Containers = ({ width = 600, children }) => {
  return (
    <Box className='mx-auto w-full' sx={{ maxWidth: `${width}px` }}>
      {children}
    </Box>
  );
};

export const MasterDatatableHeight = ({ children }) => {
  return <Box className='h-full-css'>{children}</Box>;
};

export const DatatableHeightCommon = ({ children }) => {
  return <Box className='box_height_1'>{children}</Box>;
};

export default Containers;
