import React from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import DataTable from '../../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../../components/helpers/DataTableToolbarButtons.tsx';
import { Box, Grid } from '@mui/material';
import { StatusBadge } from '../../../../components/_form/FormElements';
import { OrderViewSidebarBoxes } from '../../../../components/boxes/Boxes';

const TotalLoop = [
  {
    name: 'Sub Total',
    price: 'AED 120.00',
  },
  {
    name: 'Tax',
    price: 'AED 5.00',
  },
  {
    name: 'Total',
    price: 'AED 125.00',
  },
];

const TotalBoxes = (props) => {
  const { supplierSingleData } = props;
  return (
    <Box className='h-full flex-col flex'>
      <GlassCard className='bg-teal-100 dark:bg-teal-900'>
        <p className='flex justify-between items-center my-3 px-3'>
          <span>Sub Total</span>{' '}
          <span className='font-bold '>AED {supplierSingleData?.subTotal}</span>
        </p>
        <p className='flex justify-between items-center my-3 px-3'>
          <span>Tax</span>{' '}
          <span className='font-bold '>AED {supplierSingleData?.tax}</span>
        </p>
        <p className='flex justify-between items-center my-3 px-3'>
          <span>Total</span>{' '}
          <span className='font-bold '>AED {supplierSingleData?.total}</span>
        </p>
      </GlassCard>
    </Box>
  );
};

const PreviewSuppliersItemList = (props) => {
  const { supplierData, item, supplierSingleData } = props;
  const columns = [
    {
      field: 'itemCode',
      headerName: 'Item Code',
      minWidth: 150,
      hideable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'itemName',
      headerName: 'Item',
      minWidth: 200,
      sortable: false,
      flex: 1,
      cellClassName: 'text-sky-600 font-semibold',
    },

    {
      field: 'quantity',
      headerName: 'Quantity',
      width: 100,
      sortable: false,
    },
    {
      field: 'unitOfMeasurementName',
      headerName: 'Unit',
      width: 100,
      sortable: false,
    },
    {
      field: 'taxCodeName',
      headerName: 'Tax',
      width: 100,
      sortable: false,
    },
    {
      field: 'unitPrice',
      headerName: 'Unit Price',
      width: 100,
      sortable: false,
    },

    {
      field: 'totalPrice',
      headerName: 'Total',
      width: 100,
      sortable: false,
    },
  ];

  return (
    <Box className='mt-5'>
      <OrderViewSidebarBoxes
        side={<TotalBoxes supplierSingleData={supplierSingleData} />}
      >
        <GlassCard>
          <Box
            sx={{
              height: 'calc(100vh - 200px)',
            }}
          >
            <DataTable
              rows={
                supplierSingleData?.lpoSupplierItems?.map((item, index) => ({
                  ...item,
                  id: item?.lpoSupplierItemId,
                })) || []
              }
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              slots={{
                toolbar: () => (
                  <Box
                    className='p-2 px-4 '
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'border.main',
                    }}
                  >
                    <Grid container spacing={3} alignItems='center'>
                      <Grid item md={6}>
                        <Box className='flex items-center gap-5'>
                          <h3 className='font-bold text-base'>{item.name}</h3>
                          <Box
                            className='text-green-600 font-semibold'
                            sx={{
                              bgcolor: 'background.white',
                              padding: '3px 10px',
                              boxShadow: 1,
                              borderRadius: '8px',
                            }}
                          >
                            {supplierData[0]?.localPurchaseOrderNumber}
                          </Box>

                          <StatusBadge title='Open' type='green' />
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <Box className='flex justify-end gap-3'>
                          <DataTableToolbarButtons />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ),
              }}
            />
          </Box>
        </GlassCard>
      </OrderViewSidebarBoxes>
    </Box>
  );
};

export default PreviewSuppliersItemList;
