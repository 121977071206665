import React, { useEffect } from 'react';
import { Button, Box } from '@mui/material';
import MuiDialogOne from './MuiDialogOne.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import SignatureCanvas from 'react-signature-canvas';

const MuiSignModal = ({ signatureImage, sigCanvas, showModal, setShowModal, handleClearSignature, handleSaveSignature }) => {
  const handleCloseModal = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (showModal && sigCanvas.current && signatureImage) {
      sigCanvas.current.fromDataURL(signatureImage);
    }
  }, [showModal, signatureImage]);

  return (
    <>
      <Button
        onClick={handleOpenModal}
        fullWidth
        sx={{
          border: '1px solid',
          borderColor: 'border.main',
        }}
        className='flex justify-center items-center gap-4'
      >
        <span className='text-2xl'>
          <Icon icon='ph:signature-light' />
        </span>
        <span>Signature</span>
      </Button>

      <MuiDialogOne
        open={showModal}
        onClose={handleCloseModal}
        title='Signature'
      >
        <Box
          sx={{
            border: '2px dashed',
            borderColor: 'primary.main',
            bgcolor: 'background.mainLight',
            borderRadius: '10px',
            minHeight: '200px',
          }}
        >
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{ width: 500, height: 200, className: 'signature-canvas' }}
          />
        </Box>

        <Box className='mt-5 flex justify-end gap-5'>
          <Button className='min-w-28' variant='outlined' onClick={handleClearSignature}>
            Clear
          </Button>
          <Button className='min-w-28' variant='contained' onClick={handleSaveSignature}>
            Save
          </Button>
        </Box>
      </MuiDialogOne>
    </>
  );
};

export default MuiSignModal;
