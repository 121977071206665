/* eslint-disable */
import { useState, useEffect } from 'react';
import Crud_Service from '../../apis/CrudService';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loaderSlice.ts';
import { toast } from 'react-toastify';
const useTableLogic = (endpoint, customIdKey?, CustomId?) => {
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [tableRecordCounts, setTableRecordCounts] = useState(0);
  const isLoading = useSelector((state) => state.loader.isLoading);
  const crud = new Crud_Service();
  const dispatch = useDispatch();

  const toggleLoading = (loading) => {
    dispatch(setLoading(loading));
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, pageSize, searchKeyword, sortBy, sortDirection]);

  const fetchData = async () => {
    toggleLoading(true);
    await crud.getAll(
      endpoint,
      {
        pageSize,
        pageNumber,
        searchKeyword,
        sortBy,
        sortDirection,
        [customIdKey]: customIdKey ? CustomId : '',
      },
      (err, res) => {
        if (err) {
          toast.error(`Error fetching data from ${endpoint}:`, err);
          return;
        }
        if (res?.status === 200) {
          setTableRecordCounts(res?.data);
          const transformedData = res?.data?.data?.map((row, index) => ({
            ...row,
            id: index + 1,
          }));
          toggleLoading(false);
          setRows(transformedData);
        } else if (res?.status === 204) {
          setTableRecordCounts(0);
          setRows([]);
          toggleLoading(false);
        } else {
          setRows([]);
          toggleLoading(false);
          //  toast.error(`Error fetching data from ${endpoint}:`, err);
        }
      },
    );
  };

  const handlePaginationModelChange = (model) => {
    setPageSize(model?.pageSize);
    setPageNumber(model?.page + 1);
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    } else {
      setSortBy('');
      setSortDirection('');
    }
  };

  return {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    searchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
    setRows,
    toggleLoading,
  };
};

export default useTableLogic;
