import React, { useRef, useState } from "react";
import GlassCard from "../../../components/small/GlassCard.tsx";
import { Grid } from "@mui/material";
import MuiSignModal from "../../../components/_mui/MuiSignModal.tsx";
import { Controller, useForm } from "react-hook-form";
import FmSearchableSelect from "../../../components/_mui/FmSearchableSelect.tsx";
import FmAutoComplete from "../../../components/_mui/FmAutoComplete.tsx";
import FmTextField from "../../../components/_mui/FmTextField.tsx";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ActionButtons from "../../../components/_form/ActionButtons.tsx";
import useLoader from "../../../components/helpers/UseLoader.tsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import FmTimePicker from "../../../components/_mui/FmTimePicker.tsx";
import { AddInspectionReportSchema } from "../../../utils/CommonvalidationSchemas.tsx";
import { yupResolver } from "@hookform/resolvers/yup";
import FmDatePicker from "../../../components/_mui/FmDatePicker.tsx";
import useCommonFetchApi from "../../../components/helpers/useCommonFetchApi.tsx";
import Crud_Service from "../../../apis/CrudService.jsx";
import DashboardLayout from "../../../components/DashboardLayout.tsx";

const GridSize = 2.4;

const AddInspectionReport = () => {
  const [clientId, setClientId] = useState(null);
  const crud = new Crud_Service();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(AddInspectionReportSchema),
    defaultValues: {
      companyId: null,
      clientId: null,
      projectId: null,
      contractId: null,
      locationId: null,
      attendeesId: null,
      conductedBy: null,
      preparedBy: null,
      buildingId: null,
      inspectorId: null,
      monthId: null,
      reportMangerId: null,
      verifiedBy: null,
      signatureImage: null,
      referenceNumber: "",
      occupancy: "",
      inspectedArea: "",
      remarks: "",
    },
  });

  const sigCanvas = useRef(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [projectId, setProjectId] = useState(null);
  const { data: buildings } = useCommonFetchApi("buildings");
  const { data: months } = useCommonFetchApi("months");
  const [signatureId, setSignatureId] = useState(null);
  const handleSignatureUpload = (fileId) => {
    setSignatureId(fileId);
  };
  const handleSubmitInspection = async (values) => {
    startCreateLoading();

    const Combineddata = {
      ...values,
      signImageId: signatureId,
      time: dayjs(values?.time).format("HH:mm:ss"),
      inspectionStartDate: dayjs(values?.inspectionStartDate).format(
        "YYYY-MM-DD"
      ),
      inspectionEndDate: dayjs(values?.inspectionEndDate).format("YYYY-MM-DD"),
    };

    await crud.create("inspectionreports", Combineddata, (err, res) => {
      if (res?.status === 201) {
        toast.success("Inspection Report Created Successfully");
        stopCreateLoading();
        reset();
      } else {
        stopCreateLoading();
        toast.error(err);
      }
    });
  };

  const handleSaveSignature = async () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const signatureImage = sigCanvas.current.toDataURL("image/png");

      await uploadSignature(signatureImage);
    }
    setShowModal(false);
  };

  const uploadSignature = async (base64Image) => {
    const blob = await fetch(base64Image).then((res) => res.blob());
    const fileFormData = new FormData();
    fileFormData.append("file", blob, "signature.png");
    fileFormData.append("fileTypeId", 40);

    await crud.create(`/files/upload`, fileFormData, (_err, res) => {
      if (res?.status === 200) {
        setSignatureId(res?.data?.fileId);
        setSignatureImage(null);
        setValue("signatureImage", res?.data?.fileId);
        trigger("signatureImage");
        toast.success("signature upload successful");
      } else {
        toast.error("something_Went_Wrong");
      }
    });
  };

  return (
    <DashboardLayout
      title='Add Inspection Report'
      hasSubmenu>
    <GlassCard className="p-3">
      <form onSubmit={handleSubmit(handleSubmitInspection)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name="companyId"
                control={control}
                headerField={["Code", "Name"]}
                apiUrl="companies"
                valueField="companyId"
                labelField={["companyCode", "companyName"]}
                showField={["companyCode", "companyName"]}
                pageSize={50}
                label="Companies"
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name="clientId"
                control={control}
                headerField={["Code", "Name"]}
                apiUrl="clients"
                valueField="clientId"
                labelField={["clientCode", "clientName"]}
                showField={["clientCode", "clientName"]}
                onChangeProp={(ev) => setClientId(ev ? ev?.clientId : "")}
                pageSize={50}
                label="Clients"
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name="projectId"
                control={control}
                headerField={["Code", "Name"]}
                apiUrl="projects"
                valueField="projectId"
                labelField={["projectCode", "projectName"]}
                showField={["projectCode", "projectName"]}
                pageSize={50}
                label="Projects"
                queryparam={"clientid"}
                onChangeProp={(ev) => setProjectId(ev ? ev?.clientId : "")}
                queryparamValue={clientId}
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name="contractId"
                control={control}
                headerField={["Code", "Name"]}
                apiUrl="contracts"
                valueField="contractId"
                labelField={["contractCode", "contractName"]}
                showField={["contractCode", "contractName"]}
                pageSize={50}
                queryparam={"projectid"}
                queryparamValue={projectId}
                label="Contracts"
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name="locationId"
                control={control}
                headerField={["Code", "Name"]}
                apiUrl="locations"
                valueField="locationId"
                labelField={["locationCode", "locationName"]}
                showField={["locationCode", "locationName"]}
                pageSize={50}
                label="Locations"
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name="attendeesId"
                control={control}
                headerField={["Code", "Name"]}
                apiUrl="loginusers"
                valueField="loginUserId"
                labelField={["emailId", "loginUserName"]}
                showField={["emailId", "loginUserName"]}
                pageSize={50}
                label="Attendees"
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name="conductedBy"
                control={control}
                headerField={["Code", "Name"]}
                apiUrl="loginusers"
                valueField="loginUserId"
                labelField={["emailId", "loginUserName"]}
                showField={["emailId", "loginUserName"]}
                pageSize={50}
                label="Conducted By"
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name="preparedBy"
                control={control}
                headerField={["Code", "Name"]}
                apiUrl="loginusers"
                valueField="loginUserId"
                labelField={["emailId", "loginUserName"]}
                showField={["emailId", "loginUserName"]}
                pageSize={50}
                label="Prepared By"
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmAutoComplete
                name="buildingId"
                control={control}
                options={buildings}
                label="Buildings"
                displayField="buildingName"
                optionFields={["buildingName"]}
                valueKey="buildingId"
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name="inspectorId"
                control={control}
                headerField={["Code", "Name"]}
                apiUrl="loginusers"
                valueField="loginUserId"
                labelField={["emailId", "loginUserName"]}
                showField={["emailId", "loginUserName"]}
                pageSize={50}
                label="Inspector"
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmAutoComplete
                name="monthId"
                control={control}
                options={months}
                label="Months"
                displayField="monthName"
                optionFields={["monthName"]}
                valueKey="monthId"
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name="reportMangerId"
                control={control}
                headerField={["Code", "Name"]}
                apiUrl="loginusers"
                valueField="loginUserId"
                labelField={["emailId", "loginUserName"]}
                showField={["emailId", "loginUserName"]}
                pageSize={50}
                label="report Manager"
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name="verifiedBy"
                control={control}
                headerField={["Code", "Name"]}
                apiUrl="loginusers"
                valueField="loginUserId"
                labelField={["emailId", "loginUserName"]}
                showField={["emailId", "loginUserName"]}
                pageSize={50}
                label="Verified By"
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                name="referenceNumber"
                control={control}
                label="Reference Number"
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name="occupancy"
                control={control}
                label="Occupancy"
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name="inspectedArea"
                control={control}
                label="Inspected Area"
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmDatePicker
                name="inspectionStartDate"
                label="Inspection Start Date"
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmDatePicker
                name="inspectionEndDate"
                label="Inspection End Date"
                control={control}
                defaultValue={dayjs().add(1, "day")}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTimePicker
                name="time"
                control={control}
                label="Time"
                defaultValue={dayjs()}
              />
            </Grid>

            <Grid item md={GridSize}>
              <Controller
                name="signatureImage"
                control={control}
                rules={{ required: "Signature is required" }}
                render={({ field }) => (
                  <MuiSignModal
                    onSignatureUpload={(image) => {
                      handleSignatureUpload(image);
                      field.onChange(image); // Update Controller value
                    }}
                    signatureImage={signatureImage}
                    sigCanvas={sigCanvas} // If sigCanvas is defined elsewhere
                    handleSaveSignature={handleSaveSignature}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    handleClearSignature={() => {
                      setSignatureImage(null);
                      field.onChange(null); // Clear the value in Controller
                    }}
                  />
                )}
              />
              {errors?.signatureImage && (
                <p style={{ color: "red" }}>
                  {errors?.signatureImage?.message}
                </p>
              )}
            </Grid>

            <Grid item md={GridSize * 2}>
              <FmTextField
                name="remarks"
                control={control}
                label="Remarks"
                multiline={true}
                rows={2}
                maxRows={3}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitInspection)}
            onReset={reset}
            submitLoading={createLoading}
          />
        </LocalizationProvider>
      </form>
    </GlassCard>
    </DashboardLayout>
  );
};

export default AddInspectionReport;
