import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useParams } from 'react-router-dom';
import Crud_Service from '../../apis/CrudService';
const crud = new Crud_Service();
const HorizantalStepsTwo = ({data,api}) => {
  const [stateData, setStateData] = useState({ orderStatusId: null });



  const updatedData = data.map((item) => ({
    ...item,
    activeState: 
      (item.id === stateData?.orderStatusId || item.id === stateData?.quotationStatusId) || (stateData?.orderStatusId === 1 || stateData?.quotationStatusId === 1) 
      ? 0 
      : 1,
  }));
const id=useParams().id

useEffect(() => {
    if (id) {
      (async () => {
        await crud.getSingle(api,id, (err, res) => {
          if (res?.status === 200) {
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setStateData(res?.data);
            });
          } else {
          }
        });
      })();
    }
  }, [id]);


  return (
    <Box className='flex justify-between'>
      {updatedData?.map((Item) => {
        return (
          <Box
            className='flex flex-col items-center text-center w-full relative'
            key={Item.id}
          >
            <Box
              className={`dividers absolute ${
                data?.length === Item.id && 'hidden'
              }`}
              sx={{
                borderTop: '3px dashed',
                borderColor:
                  Item.activeState === 1 ? 'primary.main' : 'border.main',
                left: '50%',
                width: '100%',
                top: '25px',
              }}
            ></Box>
            <Box
              sx={{
                width: '45px',
                height: '45px',
                bgcolor:
                  Item.activeState === 1
                    ? 'primary.main'
                    : 'background.mainLight',
                borderRadius: '50%',
                border: '1px solid',
                borderColor:
                  Item.activeState === 1 ? 'primary.main' : 'border.main',
              }}
              className='flex justify-center items-center relative'
            >
              {Item.activeState === 1 ? (
                <Box
                  className='flex justify-center items-center text-4xl'
                  sx={{ color: 'text.white' }}
                >
                  <Icon icon='line-md:check-all' />
                </Box>
              ) : (
                <Box className='font-bold'>{Item.id}</Box>
              )}
            </Box>
            <h3
              className={`font-semibold mt-3 ${
                Item.activeState === 0 && 'opacity-50'
              } `}
            >
              {Item.name}
            </h3>
          </Box>
        );
      })}
    </Box>
  );
};

export default HorizantalStepsTwo;
