import React, { useState, useRef } from 'react';
import DataTable from '../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box } from '@mui/material';
import { ToolbarBoxesFlex } from '../../../components/boxes/Boxes';
import { StatusBadge } from '../../../components/_form/FormElements';
import { Icon } from '@iconify/react';
import TableActionButtons from './../../../components/_form/TableActionButtons';
import MuiDialogOne from './../../../components/_mui/MuiDialogOne';
import GRNViewDetails from './GRNViewDetails.tsx';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import apiInstance from '../../../apis/ApiService.jsx';
import { toast } from 'react-toastify';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';

const GoodsReceivedNoteDatagrid = () => {
  const [viewData, setViewData] = useState(null);
  const [tableView, setTableView] = useState(false);
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
    setRows,
    searchKeyword,
  } = useTableLogic('goodsreceivednotes');

  //handle Download
  const handleDownloadFile = async (props) => {
    if (props?.fileId === null || undefined) {
      toast.info('File Not Found');
    } else {
      try {
        const imageUrl = await apiInstance.getFiles(
          `files/download/${props?.fileId}`
        );
        const link = document.createElement('a');
        link.href = imageUrl;
        link.setAttribute(
          'download',
          `${props?.fileName === null || undefined ? 'File' : props?.fileName}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        toast.error('Error fetching image:', err);
      }
    }
  };

  //View
  const handleViewClcik = async (props) => {
    setTableView(true);
    await crud.getSingle(
      'goodsreceivednotes',
      props?.goodsReceivedNoteId,
      (err, res) => {
        if (res?.status === 200) {
          setViewData(res?.data);
        } else {
        }
      }
    );
  };

  const handleEditClick = async (props) => {
    navigate(`edit/${props?.goodsReceivedNoteId}`);
  };

  const ActionData = [
    {
      name: 'Download',
      icon: <Icon icon='material-symbols:download' />,
      onClick: (props) => handleDownloadFile(props),
    },
    //TODO: for Future Use
    // {
    //   name: "View",
    //   icon: <Icon icon="solar:eye-outline" />,
    //   onClick: (props) => handleViewClcik(props),
    // },
    {
      name: 'Edit',
      icon: <Icon icon='ic:baseline-edit' />,
      onClick: (props) => handleEditClick(props),
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 120,
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params?.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={() => {}}
          />
        );
      },
    },
    {
      field: 'goodsReceivedNoteCode',
      headerName: 'Goods Received Note Code',
      minWidth: 200,
    },

    {
      field: 'materialRequisitionCode',
      headerName: 'MR Number',
      minWidth: 150,
      hideable: false,
      flex: 1,
      cellClassName: 'text-sky-600 font-semibold',
    },

    {
      field: 'localPurchaseOrderNumber',
      headerName: 'Purchase Order Number',
      minWidth: 150,
      hideable: false,
      flex: 1,
    },

    {
      field: 'deliveryChallanNumber',
      headerName: 'Delivery Challan Number',
      minWidth: 250,
      hideable: false,
      flex: 1,
    },
    {
      field: 'goodsReceivedNoteTypeName',
      headerName: 'GRN Type',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'deliveryDate',
      headerName: 'Expected Delivery Date',
      minWidth: 220,
      flex: 1,
      renderCell: (params) => FmViewDateField({ value: params?.value }),
    },
    {
      field: 'goodsReceivedNoteStatusName',
      headerName: 'GRN Status',
      minWidth: 200,
      flex: 1,
      renderCell: (param) => {
        return (
          <StatusBadge
            title={param.value}
            type={
              param.value === 'SHORTCLOSE'
                ? 'red'
                : param.value === 'ACCEPTED'
                ? 'green'
                : param.value === 'PENDING'
                ? 'orange'
                : param.value === 'REVIEW'
                ? 'blue'
                : param.value === 'VOIDED'
                ? 'cyan'
                : param.value === 'FULFILLED'
                ? 'purple'
                : 'black'
            }
          />
        );
      },
    },
  ];

  const SearchFilteredBoxes = () => {
    return (
      <ToolbarBoxesFlex>
        <Box className='w-full' sx={{ maxWidth: '280px' }}>
          <TableSearch
            placeholder='Search'
            setSearchKeyword={setSearchKeyword}
            searchValue={searchKeyword}
          />
        </Box>
        {/* <Stack}
        //TODO: for Future Use
        {/* <Stack
          direction='row'
          spacing={1}
          justifyContent='flex-end'
          alignItems='center'
        >
          {StatusWithValue?.map((item, i) => {
            return (
              <StatusBadgeWithClick
                key={i}
                title={`${item?.name} (${item?.count})`}
                type={i === sortActive ? 'active' : item?.type}
                onClick={() => setSortActive(i)}
              />
            );
          })}
        </Stack> */}

        <DataTableToolbarButtons />
      </ToolbarBoxesFlex>
    );
  };

  return (
    <>
      <GlassCard className='overflow-hidden w-full flex-1 min-h-0'>
        <Box className='h-full-css'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            // onRowDoubleClick={(e) => onRowDoubleClick(e)}
            getRowClassName={(params) =>
              `${
                params.row.workingStatus === 'InActive' &&
                'bg-red-500 bg-opacity-15'
              }`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box>
                  <SearchFilteredBoxes />
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* view */}
      <MuiDialogOne
        title={
          <Box className='flex items-center'>
            {' '}
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                bgcolor: 'primary.main',
                color: 'text.white',
                fontSize: '30px',
                marginRight: '15px',
              }}
              className='flex justify-center items-center'
            >
              <Icon icon='ph:note' />
            </Box>{' '}
            <Box>Goods Received Note</Box>
          </Box>
        }
        open={tableView}
        onClose={() => setTableView(!tableView)}
        width='1200px'
      >
        <GRNViewDetails viewData={viewData} />
      </MuiDialogOne>
    </>
  );
};

export default GoodsReceivedNoteDatagrid;
