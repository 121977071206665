import React, { useEffect } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../components/small/BackToButton.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import { Grid } from '@mui/material';
import DashboardLayout from './../../../../components/DashboardLayout.tsx';
import ViewVendorLPOList from './ViewVendorLPOList.tsx';

const ViewVendorLPOReceivable = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const trimmedPath = location.pathname.split('/').slice(0, -1).join('/');

  useEffect(() => {
    document.title = 'View | Vendor LPO Receivable | Financial';
  });

  return (
    <>
      <DashboardLayout title='View Vendor LPO Receivable'>
        {/* glasscard */}
        <GlassCard className='p-3 mb-4'>
          <BackToButton
            title='Back to List'
            onClick={() => navigation(trimmedPath)}
            className='font-bold mb-3 px-1'
          />

          <Grid container spacing={3}>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Reference #',
                    title: '12655845',
                  },
                  {
                    label: 'Date',
                    title: '13-08-2024',
                    date: true,
                  },
                ]}
              />
            </Grid>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Received By',
                    title: 'Received 1',
                  },
                  {
                    label: 'Department',
                    title: 'Department 1',
                  },
                ]}
              />
            </Grid>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Designation',
                    title: ' Designation 1',
                  },
                ]}
              />
            </Grid>
          </Grid>
        </GlassCard>
        {/* glasscard */}
        <ViewVendorLPOList />
      </DashboardLayout>
    </>
  );
};

export default ViewVendorLPOReceivable;
