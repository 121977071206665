import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import { NavLink } from "react-router-dom";
import { Nav } from "../../../utils";
import WidgetOne from "../../../components/widgets/WidgetOne";
import Crud_Service from "../../../apis/CrudService";
import { toast } from 'react-toastify';

const HRMSEmployeeDashboard = () => {
  const [counts, setCounts] = useState({});
  const crud = new Crud_Service();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    crud.getSingle("employees/employee_counts", "", (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      setCounts(res?.data);
      setLoader(false);
    });
  }, []);

  const apiKeyToNavName = {
    employeeDailyAttendances: "Attendance",
    employeeLeaveRequest: "Leave Request",
    employeeTransactions: "Training Transaction",
    employeeLeaveJoins: "Leave Join",
    employeePassportRequests: "Passport Request",
    employementCancellations: "Employee Exit / Cancellation",
    employeeCertifications: "Certificates",
    candidate: "Candidates",
    employees: "Employees",
    finalSettlements: "Final Settlements",
    employeeAttendanceCalculations: "Employee Attendance Calculations",
  };

  // Function to get count for a given widget name
  const getCountForNavItem = (name) => {
    const entry = Object.entries(apiKeyToNavName).find(
      ([, navName]) => navName.toLowerCase() === name.toLowerCase()
    );
    return entry && counts ? counts[entry[0]] : 0;
  };

  return (
    <>
      {loader ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3} className="mb-5">
          <Grid item md={12}>
            <Box className="flex gap-5 flex-wrap">
              {Nav[0]?.child[1]?.children?.map((item, i) => {
                return (
                  <Box
                    key={i}
                    sx={{ width: "191px" }}
                    component={NavLink}
                    to={item.path}
                  >
                    <WidgetOne
                      title={item.name}
                      icon={<Icon icon="material-symbols:event-note-outline" />}
                      path={item?.path}
                      count={getCountForNavItem(item?.name) || 0}
                      record="25.25%"
                    />
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default HRMSEmployeeDashboard;
