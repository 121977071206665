import React, { useEffect, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { StyledCard } from '../../../components/_form';
import { Grid } from '@mui/material';
import { ViewField } from '../../../components/_form/FormElements';
import Crud_Service from '../../../apis/CrudService';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { toast } from 'react-toastify';

const CampDetails = (props) => {
  const crud = new Crud_Service();
  const [item, setItem] = useState(null);
  const { id, i, value } = props;

  useEffect(() => {
    crud.getAll(`employees/${id}/camp-detail`, '', (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        setItem(res?.data);
      }
    });
  }, [id]);

  // The given structure for display
  const displayStructure = {
    groupName: 'Employee Camp Details',
    child: [
      { title: 'Camp Location', name: 'campLocationName' },
      { title: 'Out Side Living', name: 'outSideLivingValue' },
      { title: 'Room.No', name: 'roomNo' },
    ],
  };

  //Formatting Fields for display
  const formatFieldValue = (field, value) => {
    switch (field.name) {
      case 'empIdCardProvided':
        return value ? 'Yes' : 'No';
      default:
        return value || '';
    }
  };

  const renderFields = (data, structure) => {
    return structure.child.map((field, i) => {
      const value = data ? data[field.name] : '';
      return (
        <Grid item md={4} key={i}>
          {field.name === 'createdDate' ? (
            <FmViewDateField title={field.title} value={value} />
          ) : (
            <ViewField
              label={field.title}
              title={String(formatFieldValue(field, value))}
            />
          )}
        </Grid>
      );
    });
  };

  return (
    <GlassCard className={value === i ? 'w-full  p-5' : 'hidden'}>
      <StyledCard title={'Employee Camp Details'}>
        <Grid container spacing={1.5}>
          {renderFields(item, displayStructure)}
        </Grid>
      </StyledCard>
    </GlassCard>
  );
};

export default CampDetails;
