import React, { useEffect } from 'react';
import DashboardLayout from './../../../../components/DashboardLayout.tsx';
import AddSalesOrder from './AddSalesOrder.tsx';
import CreateSalesOrderList from './CreateSalesOrderList.tsx';
import Nav from './../../../../utils/Nav.tsx';
import { Box } from '@mui/material';
import { navigate } from 'react-big-calendar/lib/utils/constants.js';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';

const CreateSalesOrder = (props) => {
  const { type } = props;
  const [getData, setGetData] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title =
      type === 2
        ? 'Sales Order | Accounts Receivable'
        : type === 3
          ? 'Sales Return | Accounts Receivable'
          : 'Sales Order | Work Order | CAFM';
  });

  return (
    <DashboardLayout
      title='Sales Order'
      hasSubmenu={type !== 4}
      menu={
        type === 1
          ? Nav[12].child[1].children //fm division
          : Nav[7].child[3].children //financial
      }
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create Sales Order'
              onClick={() => navigate('create')}
            />
          </Box>
        </>
      }
    >
      <CreateSalesOrderList type={type} getData={getData} />
    </DashboardLayout>
  );
};

export default CreateSalesOrder;
