export const statusOptions = [
  { statusId: 2, statusName: 'Active' },
  { statusId: 1, statusName: 'Inactive' },
];

export const projectTypes = [
  {
    projectTypeId: 1,
    projectTypeName: 'MEP',
  },
  {
    projectTypeId: 2,
    projectTypeName: 'AMC',
  },
  {
    projectTypeId: 3,
    projectTypeName: 'Project Division',
  },
];

export const attachments = [
  { id: 1, file: null, fileName: '', fileId: null },
  { id: 2, file: null, fileName: '', fileId: null },
  { id: 3, file: null, fileName: '', fileId: null },
];

export const BooleanOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
export const GoodsReceivedNoteTypeStatus = [
  { value: 1, label: ' DELIVERED' },
  { value: 2, label: 'SHORTCLOSE' },
  { value: 3, label: 'DELIVERYTERMS' },
];

export const YesNoOptions = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
];

export const BooleanOptionsWithInt = [
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
];

export const paymentStatus = [
  {
    value: 1,
    statusName: 'Sended',
  },
  {
    value: 2,
    statusName: 'Pending',
  },
];
