import React, { useRef } from 'react';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const MuiDatePickerOne = ({
  name,
  TextRequired = false,
  TextFieldVariant = 'filled',
  value,
  onChange,
  readOnly = false,
  ...props
}) => {
  const anchorElRef = useRef(null);

  return (
    <Box ref={anchorElRef}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...props}
          value={value}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          readOnly={readOnly}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                name={name}
                fullWidth
                variant={TextFieldVariant}
                required={TextRequired}
              />
            ),
          }}
          slotProps={{
            popper: {
              anchorEl: () => anchorElRef.current,
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default MuiDatePickerOne;
