import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import GlassCard from "../../../../components/small/GlassCard.tsx";
import Crud_Service from "../../../../apis/CrudService.jsx";
import useCommonFetchApi from "../../../../components/helpers/useCommonFetchApi.tsx";
import { Controller, get, useForm } from "react-hook-form";
import FmAutoComplete from "../../../../components/_mui/FmAutoComplete.tsx";
import FmTextField from "../../../../components/_mui/FmTextField.tsx";
import MuiDatePickerOne from "../../../../components/_mui/MuiDatePickerOne.tsx";
import dayjs from "dayjs";
import ActionButtons from "../../../../components/_form/ActionButtons.tsx";
import useLoader from "../../../../components/helpers/UseLoader.tsx";
import { toast } from "react-toastify";
import CommonLoader from "../../../../components/page/CommonLoader.jsx";
import { statusOptions } from "../../../../utils/CommonVariables.tsx";
import FmSearchableSelect from "../../../../components/_mui/FmSearchableSelect.tsx";

const PreviewSupplierAddForm = (props) => {
  const { supplierSingleData, getSuppliers, loading } = props;
  const crud = new Crud_Service();
  const { handleSubmit, control, reset, setValue } = useForm();
  const { startCreateLoading, stopCreateLoading } = useLoader();

  useEffect(() => {
    if (supplierSingleData) {
      Object.entries(supplierSingleData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [supplierSingleData]);

  const handleSubmitSuppliers = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      issueDate: dayjs(values?.issueDate).format("YYYY-MM-DD"),
      deliveryDate: dayjs(values?.deliveryDate).format("YYYY-MM-DD"),
      requestedBillingDate: dayjs(values?.requestedBillingDate).format(
        "YYYY-MM-DD"
      ),
    };

    await crud.update(
      "lposuppliers",
      values?.lpoSupplierId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          stopCreateLoading();
          toast.success("Supplier Updated Successfully");
          getSuppliers();
        } else {
          stopCreateLoading();
          toast.error("Something went wrong");
        }
      }
    );
  };

  return (
    <GlassCard className="p-8">
      {loading ? (
        <CommonLoader />
      ) : (
        <form onSubmit={handleSubmit(handleSubmitSuppliers)}>
          <Grid spacing={2} container>
            <Grid item md={3}>
              <FmTextField
                name="officialSupplierName"
                control={control}
                label="Supplier"
                readOnly={true}
              />
            </Grid>
            <Grid item md={3}>
              <FmTextField
                name="classificationId"
                control={control}
                label="Classification"
                pattern="Number"
              />
            </Grid>
            <Grid item md={3}>
              <FmSearchableSelect
                name="deliveryLocationId"
                control={control}
                apiUrl="locations"
                valueField="locationId"
                headerField={["Code", "Name"]}
                labelField={["locationCode", "locationName"]}
                showField={["locationCode", "locationName"]}
                pageSize={20}
                label={"Delivery Locations"}
                defaultValue={{
                  deliveryLocationId: supplierSingleData?.deliveryLocationId,
                  locationName:
                    supplierSingleData?.deliveryLocationName,
                }}
              />
            </Grid>
            <Grid item md={3}>
              <FmSearchableSelect
                name="supplierPaymentTermId"
                control={control}
                apiUrl="supplierpaymentterms"
                valueField="supplierPaymentTermId"
                headerField={["Code", "Name"]}
                labelField={["supplierPaymentTermCode", "paymentTermName"]}
                showField={["supplierPaymentTermCode", "paymentTermName"]}
                pageSize={20}
                label={"supplier Payment Terms"}
                defaultValue={{
                  supplierPaymentTermId:
                    supplierSingleData?.supplierPaymentTermId,
                    paymentTermName:
                    supplierSingleData?.supplierPaymentTermName,
                }}
              />
            </Grid>
            <Grid item md={3}>
              <FmTextField name="tag" control={control} label="Tag" />
            </Grid>
            <Grid item md={3}>
              <FmTextField name="contact" control={control} label="Contact" />
            </Grid>
            <Grid item md={3}>
              <Controller
                name="issueDate"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <MuiDatePickerOne
                    {...field}
                    label="Issue Date"
                    format="DD/MM/YYYY"
                    value={field?.value ? dayjs(field?.value) : null}
                    onChange={field?.onChange}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
              <Controller
                name="deliveryDate"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <MuiDatePickerOne
                    {...field}
                    label="Delivery Date"
                    format="DD/MM/YYYY"
                    value={field?.value ? dayjs(field?.value) : null}
                    onChange={field?.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item md={3}>
              <FmTextField name="memo" control={control} label="Memo" />
            </Grid>
            <Grid item md={3}>
              <Controller
                name="requestedBillingDate"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <MuiDatePickerOne
                    {...field}
                    label="Requested Billing Date"
                    format="DD/MM/YYYY"
                    value={field?.value ? dayjs(field?.value) : null}
                    onChange={field?.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item md={3}>
              <FmAutoComplete
                name="status"
                control={control}
                options={statusOptions}
                label="Status"
                displayField="statusName"
                optionFields={["statusName"]}
                valueKey="statusId"
              />
            </Grid>
            <Grid item md={3}></Grid>

            <Grid item md={6}>
              <FmTextField
                name="remarks"
                control={control}
                label="Remarks"
                multiline
                rows={4}
                maxRows={4}
              />
            </Grid>
          </Grid>

          <ActionButtons
            onSubmit={handleSubmit(handleSubmitSuppliers)}
            onReset={reset}
            submitLoading={false}
            cancelLoading={false}
            submitText="Update"
          />
        </form>
      )}
    </GlassCard>
  );
};

export default PreviewSupplierAddForm;
