import React from 'react';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import { Grid } from '@mui/material';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { useForm } from 'react-hook-form';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import { ActionButtons } from '../../../../../components/_form';
import { useParams } from 'react-router-dom';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { SalesRetentionItemvalidationSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
const GridSize = 2.4;

const AddViewSalesRetention = (props) => {
  const { fetchData } = props;
  const { id } = useParams();
  const handleSubmitSalesRetention = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      salesRetentionId: id,
      taxId: values?.taxCodeId,
      // itemId: 1,
    };

    await crud.create('salesretentionitems', combinedData, (err, res) => {
      stopCreateLoading();
      if (err) {
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Sales Retention Item Created successfully');
        fetchData();
        reset();
      } else {
      }
    });
  };
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(SalesRetentionItemvalidationSchema),
  });
  const { startCreateLoading, createLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();
  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(handleSubmitSalesRetention)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='companyId'
              control={control}
              apiUrl='companies'
              valueField='companyId'
              headerField={['Company Code', 'Company Name']}
              labelField={['companyCode', 'companyName']}
              showField={['companyName']}
              pageSize={20}
              label='Company Code'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='itemId'
              control={control}
              apiUrl='item'
              valueField='itemId'
              headerField={['Item Code']}
              labelField={['itemCode']}
              showField={['itemCode']}
              pageSize={20}
              label='Items'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='description'
              control={control}
              label='Description'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='unitOfMeasurementId'
              control={control}
              apiUrl='unitOfMeasurements'
              valueField='unitOfMeasurementId'
              headerField={['Code', 'Name']}
              labelField={['unitOfMeasurementCode', 'unitOfMeasurementName']}
              showField={['unitOfMeasurementName']}
              pageSize={20}
              label={'Units'}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='quantity' control={control} label='Quantity' />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField name='rate' control={control} label='Rate' />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='gross' control={control} label='Gross' />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='discount' control={control} label='Discount' />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='discountPercent'
              control={control}
              label='Discount Percent'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='vat' label='VAT' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='amountWithTax'
              label='Amount With Tax'
              control={control}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='taxCodeId'
              control={control}
              apiUrl='taxcodes'
              valueField='taxCodeId'
              headerField={['Tax Code']}
              labelField={['taxCodeCode']}
              showField={['taxCodeCode']}
              pageSize={20}
              label='Tax Code'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField name='remarks' label='Remarks' control={control} />
          </Grid>
        </Grid>
        <ActionButtons
          onSubmit={handleSubmit(handleSubmitSalesRetention)}
          onReset={reset}
          submitLoading={createLoading}
        />
      </form>
    </GlassCard>
  );
};

export default AddViewSalesRetention;
