import React from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useLocation } from 'react-router-dom';
import BackToButton from '../../../../components/small/BackToButton.jsx';
import { Grid, Box } from '@mui/material';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';

const ViewIncidentReportDetails = (props) => {
  const { viewData, image } = props;
  const navigation = useNavigate();
  const location = useLocation();
  const trimmedPath = location.pathname.split('/').slice(0, -1).join('/');

 

  return (
    <GlassCard className='p-3 mb-4'>
      <BackToButton
        title='Back to List'
        onClick={() => navigation(trimmedPath)}
        className='font-bold mb-3 px-1'
      />

      <Grid container spacing={3}>
        <Grid item md={4}>
          <ViewTable
            rows={[
              {
                label: 'Company',
                title: viewData?.companyName,
              },
              {
                label: 'Client',
                title: viewData?.clientName,
              },
              {
                label: 'Division',
                title: viewData?.divisionName,
              },
              {
                label: 'Project',
                title: viewData?.projectName,
              },
              {
                label: 'Contract',
                title: viewData?.contractName,
              },
            ]}
          />
        </Grid>
        <Grid item md={4}>
          <ViewTable
            rows={[
              {
                label: 'Root Cause Analysis',
                title: viewData?.rootCauseAnalysis,
              },
              {
                label: 'Building',
                title: viewData?.buildingName,
              },
              {
                label: 'Location',
                title: viewData?.locationName,
              },
              {
                label: 'Prepared By',
                title: viewData?.preparedByName,
              },
              {
                label: 'Verified By',
                title: viewData?.verifiedByName,
              },
            ]}
          />
        </Grid>
        <Grid item md={4}>
          <ViewTable
            rows={[
              {
                label: 'Date of Incident',
                title: <FmViewDateField value={viewData?.incidentDate} />,
              },
              {
                label: 'Date of Work Resumption',
                title: (
                  <FmViewDateField value={viewData?.dateOfWorkResumption} />
                ),
              },
              {
                label: 'Sign',
                title: (
                  <Box
                    component='img'
                    src={image}
                    sx={{ width: '60px', height: 'auto' }}
                  />
                ),
              },
              {
                label: 'Remarks',
                title: viewData?.remarks,
              },
            ]}
          />
        </Grid>
      </Grid>
    </GlassCard>
  );
};

export default ViewIncidentReportDetails;
