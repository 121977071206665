// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editor-buttons {
  margin-top: 20px;
}

.editor-button {
  background-color: #4caf50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 10px 20px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Inline-block */
  font-size: 16px; /* Set font size */
  margin: 4px 2px; /* Some margin */
  transition-duration: 0.4s; /* 0.4 second transition effect */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 5px; /* Rounded corners */
}

.editor-button:hover {
  background-color: white; /* White background on hover */
  color: black; /* Black text on hover */
  border: 2px solid #4caf50; /* Green border on hover */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB,EAAE,qBAAqB;EAChD,YAAY,EAAE,mBAAmB;EACjC,YAAY,EAAE,eAAe;EAC7B,kBAAkB,EAAE,iBAAiB;EACrC,kBAAkB,EAAE,kBAAkB;EACtC,qBAAqB,EAAE,qBAAqB;EAC5C,qBAAqB,EAAE,iBAAiB;EACxC,eAAe,EAAE,kBAAkB;EACnC,eAAe,EAAE,gBAAgB;EACjC,yBAAyB,EAAE,iCAAiC;EAC5D,eAAe,EAAE,sBAAsB;EACvC,kBAAkB,EAAE,oBAAoB;AAC1C;;AAEA;EACE,uBAAuB,EAAE,8BAA8B;EACvD,YAAY,EAAE,wBAAwB;EACtC,yBAAyB,EAAE,0BAA0B;AACvD","sourcesContent":[".editor-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.editor-buttons {\n  margin-top: 20px;\n}\n\n.editor-button {\n  background-color: #4caf50; /* Green background */\n  border: none; /* Remove borders */\n  color: white; /* White text */\n  padding: 10px 20px; /* Some padding */\n  text-align: center; /* Centered text */\n  text-decoration: none; /* Remove underline */\n  display: inline-block; /* Inline-block */\n  font-size: 16px; /* Set font size */\n  margin: 4px 2px; /* Some margin */\n  transition-duration: 0.4s; /* 0.4 second transition effect */\n  cursor: pointer; /* Pointer/hand icon */\n  border-radius: 5px; /* Rounded corners */\n}\n\n.editor-button:hover {\n  background-color: white; /* White background on hover */\n  color: black; /* Black text on hover */\n  border: 2px solid #4caf50; /* Green border on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
