import React from 'react';
import { Box } from '@mui/material';

const StyledCard = (props) => {
  const { title, titleCenter, children, titleNone } = props;
  return (
    <Box>
      {titleNone ? null : (
        <Box
          className='text-sm p-0 mb-4'
          sx={{
            fontWeight: 700,
            textAlign: titleCenter ? 'center' : 'none',
          }}
        >
          {title}
        </Box>
      )}
      <Box sx={{ p: 0 }}>{children}</Box>
    </Box>
  );
};

export default StyledCard;
