import React, { useState } from 'react';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { Box, Button } from '@mui/material';
import Icons from '../../../../utils/Icon.jsx';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { SalesOrderBudgetvalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';

const ViewSalesOrderDistributionBudgetsTable = (props) => {
  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(SalesOrderBudgetvalidationSchema),
    mode: 'onChange',
  });
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const handleEdit = (row) => {
    setEditingRowId(row?.salesOrderDistributionBudgetId);
    setEditingRowData({ ...row });
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const {
    salesOrderDistributionId,
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;

  const handleDeleteItem = async (view) => {
    await crud.remove(
      'salesorderdistributionbudgets',
      view?.salesOrderDistributionBudgetId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Sales Order Distribution Budget Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleUpdateSalesOrderBudget = async (values) => {
    const combinedData = {
      ...values,
      salesOrderDistributionId:
        salesOrderDistributionId?.salesOrderDistributionId ||
        salesOrderDistributionId,
      salesOrderId: values?.salesOrderId,
      divisionId: values?.divisionId,
      status: 2,
    };
    await crud.update(
      'salesorderdistributionbudgets',
      editingRowId,
      combinedData,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Sales Order Distribution Budget updated successfully');
          fetchData();
          handleCancel();
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteItem(props),
      danger: true,
    },
  ];
  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.salesOrderDistributionBudgetId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateSalesOrderBudget)}>
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => {
                  action.onClick(row?.original);
                },
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId ===
          row?.original?.salesOrderDistributionBudgetId ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
            apiUrl='divisions'
            valueField='divisionId'
            headerField={['Code']}
            labelField={['divisionName']}
            showField={['divisionName']}
            pageSize={20}
            label='Division'
            required
          />
        ) : (
          row?.original?.divisionName
        );
      },
    },
    {
      accessorKey: 'totalContractValuePercentage',
      header: 'Total Contract Value (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId ===
          row?.original?.salesOrderDistributionBudgetId ? (
          <FmTextField
            name='totalContractValuePercentage'
            control={control}
            label='Total Contract Value (%)'
            defaultValue={row?.original?.totalContractValuePercentage}
            onChange={(ev) =>
              setValue(
                'totalContractValue',
                (parseInt(ev.target.value) / 100) *
                editingRowData.totalContractValue
              )
            }
          />
        ) : (
          row?.original?.totalContractValuePercentage
        );
      },
    },
    {
      accessorKey: 'totalContractValue',
      header: 'Total Contract Value',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId ===
          row?.original?.salesOrderDistributionBudgetId ? (
          <FmTextField
            name='totalContractValue'
            control={control}
            label='Total Contract Value'
            defaultValue={row?.original?.totalContractValue}
            readOnly
          />
        ) : (
          row?.original?.totalContractValue
        );
      },
    },
    {
      accessorKey: 'totalBudgetValuePercentage',
      header: 'Total Budget(%)',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId ===
          row?.original?.salesOrderDistributionBudgetId ? (
          <FmTextField
            name='totalBudgetValuePercentage'
            control={control}
            label='Total Budget Value (%)'
            defaultValue={row?.original?.totalBudgetValuePercentage}
            onChange={(ev) =>
              setValue(
                'totalBudgetValue',
                (parseInt(ev.target.value) / 100) *
                editingRowData.totalBudgetValue
              )
            }
          />
        ) : (
          row?.original?.totalBudgetValuePercentage
        );
      },
    },
    {
      accessorKey: 'totalBudgetValue',
      header: 'Total Budget',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId ===
          row?.original?.salesOrderDistributionBudgetId ? (
          <FmTextField
            name='totalBudgetValue'
            control={control}
            label='Total Budget Value'
            defaultValue={row?.original?.totalBudgetValue}
            readOnly
          />
        ) : (
          row?.original?.totalBudgetValue
        );
      },
    },
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order Name',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId ===
          row?.original?.salesOrderDistributionBudgetId ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders'
            valueField='salesOrderId'
            headerField={['Name']}
            defaultValue={{
              salesOrderId: row?.original?.salesOrderId,
              salesOrderName: row?.original?.salesOrderName,
            }}
            labelField={['salesOrderName']}
            showField={['salesOrderName']}
            pageSize={20}
            label='Sales Order Name'
            required
          />
        ) : (
          row?.original?.salesOrderCode
        );
      },
    },
  ];

  return (
    <>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </>
  );
};

export default ViewSalesOrderDistributionBudgetsTable;
