import React from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import BackToButton from '../../../components/small/BackToButton.jsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';

const ViewSuppliers = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'supplierId',
    'regionId',
    'countryId',
    'approvalStatus',
    'supplierTypeId',
    'updatedDate',
    'status',
  ];

  const fieldFormat = {
    agreement: (value) => (value ? 'Yes' : 'No'),
    debitORCredit: (value) => (value ? 'Yes' : 'No'),
  };

  return (
    <DashboardLayout
      title='View Suppliers'
      actionButtons={
        <BackToButton
          title='Back to List'
          className='p-0'
          onClick={() => navigate(-1)}
        />
      }
      titleReverse
    >
      <Box className='mx-auto' sx={{ maxWidth: '800px' }}>
        <GlassCard className='p-5'>
          <CommonView
            url='suppliers'
            id={id}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields
                data={data}
                fields={fields}
                fieldFormat={fieldFormat}
              />
            )}
          />
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default ViewSuppliers;
