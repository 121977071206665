import React from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { TextField, Button, Grid, MenuItem } from '@mui/material';

const GridSize = 2;

const AddVendorLPOReceivable = () => {
  return (
    <GlassCard className='p-3'>
      <Grid container spacing={2}>
        <Grid item md={GridSize}>
          <TextField label='Reference #' fullWidth variant='filled' />
        </Grid>
        <Grid item md={GridSize}>
          <TextField label='Date' fullWidth variant='filled' />
        </Grid>
        <Grid item md={GridSize}>
          <TextField label='Received By' fullWidth variant='filled' select>
            <MenuItem value={10}>Received By 1</MenuItem>
            <MenuItem value={20}>Received By 2</MenuItem>
            <MenuItem value={30}>Received By 3</MenuItem>
          </TextField>
        </Grid>

        <Grid item md={GridSize}>
          <TextField label='Department' fullWidth variant='filled' select>
            <MenuItem value={10}>Department 1</MenuItem>
            <MenuItem value={20}>Department 2</MenuItem>
            <MenuItem value={30}>Department 3</MenuItem>
          </TextField>
        </Grid>

        <Grid item md={GridSize}>
          <TextField label='Designation' fullWidth variant='filled' select>
            <MenuItem value={10}>Designation 1</MenuItem>
            <MenuItem value={20}>Designation 2</MenuItem>
            <MenuItem value={30}>Designation 3</MenuItem>
          </TextField>
        </Grid>

        <Grid item md={GridSize}>
          <Button variant='contained' fullWidth>
            Create
          </Button>
        </Grid>
      </Grid>
    </GlassCard>
  );
};

export default AddVendorLPOReceivable;
