import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Box, Grid } from '@mui/material';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import ViewFieldInline from '../../../../components/view/ViewFieldInline.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';

const GridSize = 2.4;
const ViewWorkOrder = () => {
  const crud = new Crud_Service();
  const id = useParams().id;
  const [workOrder, setWorkOrder] = useState({});
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    crud.getSingle('workorders', id, (_err, res) => {
      if (res) {
        setWorkOrder(res.data);
        setLoader(false);
      }
    });
  }, []);

  return (
    <DashboardLayout
      title='VIew Work Order'
      hasSubmenu
      menu={Nav[12].child[1].children}
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create Work Order'
              onClick={() =>
                navigate('/fm-division/work-order/work-order-list/create')
              }
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-3'>
        {loader ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <ViewFieldInline name='Name' value={workOrder?.workOrderName} />
            </Grid>
            <Grid item md={GridSize}>
              <ViewFieldInline name='Code' value={workOrder?.workOrderCode} />
            </Grid>

            <Grid item md={GridSize}>
              <ViewFieldInline
                name='Type'
                value={workOrder?.workOrderTypeName}
              />
            </Grid>
            <Grid item md={GridSize}>
              <ViewFieldInline name='Company' value={workOrder?.companyName} />
            </Grid>
            <Grid item md={GridSize}>
              <ViewFieldInline name='Client' value={workOrder?.clientName} />
            </Grid>
            <Grid item md={GridSize}>
              <ViewFieldInline name='Project' value={workOrder?.projectName} />
            </Grid>
            <Grid item md={GridSize}>
              <ViewFieldInline
                name='Contract'
                value={workOrder?.contractName}
              />
            </Grid>
            <Grid item md={GridSize}>
              <ViewFieldInline
                name='Previous Contract'
                value={workOrder?.previousContractName}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmViewDateField
                title='Start Date'
                value={workOrder?.startDate}
              />
            </Grid>
            <Grid item md={GridSize}>
              <ViewFieldInline
                name='Division'
                value={workOrder?.divisionName}
              />
            </Grid>

            <Grid item md={12}>
              <ActionButtons
                cancelLoading={false}
                cancelText='Go Back'
                submitText='Edit'
                onSubmit={() =>
                  navigate(`/fm-division/work-order/work-order-list/edit/${id}`)
                }
                onCancel={() =>
                  navigate('/fm-division/work-order/work-order-list')
                }
              />
            </Grid>
          </Grid>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewWorkOrder;
