import React from 'react';
import { Box, Button } from '@mui/material';
import { useHotkeys } from 'react-hotkeys-hook';

interface ActionButtonsImpl {
  onSubmit: () => void;
  onCancel?: () => void;
  onReset?: () => void;
  submitLoading?: boolean;
  cancelLoading?: boolean;
  submitText?: string | undefined;
  cancelText?: string | undefined;
  removeSpacing?: string | undefined;
  errorFields?: boolean;
  disabled?: boolean;
}

const ActionButtons = (props: ActionButtonsImpl) => {
  const {
    onSubmit,
    onCancel,
    onReset,
    submitLoading,
    cancelLoading,
    submitText = 'Submit',
    cancelText = 'Cancel',
    removeSpacing,
    errorFields,
    disabled = false,
  } = props;

  useHotkeys(
    'ctrl+s',
    (event) => {
      event.preventDefault();
      if (!submitLoading && !cancelLoading && !errorFields && !disabled) {
        onSubmit();
      }
    },
    [onSubmit, submitLoading, cancelLoading, errorFields, disabled]
  );

  return (
    <Box
      className={`flex flex-row gap-2 justify-between ${
        removeSpacing ? '' : 'mt-5'
      }`}
      sx={{
        '& .MuiButtonBase-root': {
          minWidth: '120px',
          minHeight: '40px',
          fontWeight: 700,
        },
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Box>
        {onReset && (
          <Button
            variant='outlined'
            onClick={() => {
              onReset && onReset();
            }}
            className='px-4 py-1'
          >
            Reset
          </Button>
        )}
      </Box>
      <Box className='flex items-center gap-2'>
        {onCancel && (
          <Button
            variant='outlined'
            onClick={onCancel}
            disabled={submitLoading || cancelLoading}
            className='px-4 py-1'
          >
            {cancelLoading ? 'Cancelling...' : cancelText}
          </Button>
        )}

        {onSubmit && (
          <Button
            variant='contained'
            onClick={onSubmit}
            disabled={submitLoading || cancelLoading || errorFields || disabled}
            className='px-4 py-1'
          >
            {submitLoading ? 'Submitting...' : submitText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ActionButtons;
