import React, { useEffect } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import WorkOrderList from './WorkOrderList.tsx';
import { Box } from '@mui/material';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';

const WorkOrder = () => {
  const [getData] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Work Order | Work Order | CAFM ';
  });

  return (
    <DashboardLayout
      title='Work Order'
      hasSubmenu
      menu={Nav[12].child[1].children}
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create Work Order'
              onClick={() => navigate('create')}
            />
          </Box>
        </>
      }
    >
      <WorkOrderList getData={getData} />
    </DashboardLayout>
  );
};

export default WorkOrder;
