import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import AddMaterialRequest from './Form/AddMaterialRequest.tsx';
import CartMaterialRequest from './Form/CartMaterialRequest.tsx';
import { Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import ProgressCircleStep from '../../../components/page/ProgressCircleStep.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { useTranslation } from 'react-i18next';
import Crud_Service from '../../../apis/CrudService.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { mRSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { useForm } from 'react-hook-form';

const CreateMaterialRequest = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const endpoint = id ? `materialrequisitions/${id}/status-info` : null;
  const { data } = useCommonFetchApi(endpoint);
  const crud = new Crud_Service();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [locationId, setLocationId] = useState();
  const [editedData, setEditedData] = useState({});
  const [regionId, setRegionId] = useState();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(mRSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    id && getEditMaterialRequest();
  }, [id]);

  const getEditMaterialRequest = async () => {
    setLoading(true);
    await crud.getSingle('materialrequisitions', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
          setEditedData(res?.data);
          key === 'storeLocationId' && setLocationId(value);
          key === 'regionId' && setRegionId(value);
        });
        setLoading(false);
      } else {
      }
    });
  };

  return (
    <DashboardLayout title={isEdit ? 'Update' : 'Create New'}>
      {id && <ProgressCircleStep apiresponse={data} />}
      <AddMaterialRequest
        control={control}
        handleSubmit={handleSubmit}
        setValue={setValue}
        editedData={editedData}
        loading={id ? loading : false}
        getValues={getValues}
        reset={reset}
        location={locationId}
        region={regionId}
      />
      <Divider className='my-10' />
      {!loading && (
        <CartMaterialRequest locationId={locationId} regionId={regionId} />
      )}
    </DashboardLayout>
  );
};

export default CreateMaterialRequest;
