import React, { useEffect, useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ActionButtons } from '../../../components/_form';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import apiInstance from '../../../apis/ApiService.jsx';
import dayjs from 'dayjs';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { projectTypes } from '../../../utils/CommonVariables.tsx';
import UploadSquareBoxes from '../../../components/_form/UploadSquareBoxes.tsx';
import Containers from '../../../components/helpers/Containers.jsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';

const CreateProjects = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    employees: [],
    tenders: [],
    locations: [],
    loginUsers: [],
    clients: [],
    divisions: [],
  });
  const [imageUrl, setImageUrl] = useState([]);
  const [components, setComponents] = useState([]);
  const [counter, setCounter] = useState(1);
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();
  const [imageLoading, setImageLoading] = useState(
    components.reduce((acc, component) => {
      acc[component.id] = false; // Initialize loading state for each component
      return acc;
    }, {})
  );
  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required('Project Name is required'),
    projectValue: Yup.number()
      .typeError('Project Value must be a number')
      .required('Project Value is required')
      .test(
        'is-decimal',
        'Project Value must have at most 2 decimal places',
        (value) => value && /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),

    projectDescription: Yup.string()
      .required('Project Description is required')
      .min(0, 'Project Description cannot be less than 0')
      .max(256, 'Project Descriptioncannot exceed 256'),
    locationId: Yup.string().required('Location is required'),
    tenderId: Yup.string().required('Tender is required'),
    incentive: Yup.number()
      .typeError('Project Value must be a number')
      .required('Project Value is required'),
    // projectInChargeId: Yup.string().required('Project In Charge is required'),
    // divisionId: Yup.string().required('Division is required'),
    projectTypeId: Yup.string().required('Project Type is required'),
  });

  const { handleSubmit, control, setValue, getValues, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    getEmployees();
    getLocations();
    getTenders();
    getLoginUsers();
    getClients();
    getDivisions();
  }, []);

  //get All Divisions
  const getDivisions = async () => {
    await crud.getAll('divisions', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, divisions: res?.data?.data }));
      }
    });
  };
  //get All Employees
  const getEmployees = async () => {
    try {
      const res = await apiInstance.getAllEmployee();
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, employees: res?.data?.data }));
      } else {
      }
    } catch (error) {
      toast.error('Error fetching employees:', error);
    }
  };

  //get All Locations
  const getLocations = async () => {
    await crud.getAll('locations', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, locations: res?.data?.data }));
      }
    });
  };

  //get All Tenders
  const getTenders = async () => {
    await crud.getAll('tenders', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, tenders: res?.data?.data }));
      }
    });
  };

  //get All loginUsers
  const getLoginUsers = async () => {
    await crud.getAll('loginusers', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, loginUsers: res?.data?.data }));
      }
    });
  };

  //get All Departments
  const getClients = async () => {
    await crud.getAll('clients', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, clients: res?.data?.data }));
      }
    });
  };

  //Create For Projects
  const handleSubmitProject = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      deliveryDate: dayjs(values?.deliveryDate).format('YYYY-MM-DD'),
      fileUploadIds: values.fileUploadIds || [],
    };
    await crud.create('projects', combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Projects Created successfully');
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  const handleAddComponent = () => {
    setComponents([...components, { id: counter }]);
    setCounter(counter + 1);
  };

  const handleRemoveComponent = (id) => {
    setComponents(components.filter((component) => component.id !== id));
    setImageUrl((prev) => {
      const updatedUrls = { ...prev };
      delete updatedUrls[id];
      return updatedUrls;
    });
  };

  const handleFileChange = (id, fileId) => {
    if (typeof fileId === 'number') {
      const currentFileIds = getValues('fileUploadIds') || [];
      const updatedFileIds = [...currentFileIds, fileId];
      setValue('fileUploadIds', updatedFileIds);
    }
  };
  const [selectedTender, setSelectedTender] = React.useState({});

  useEffect(() => {
    setValue('projectValue', selectedTender?.tenderValue);
    setValue('clientId', selectedTender?.clientId);
  }, [selectedTender]);
  return (
    <>
      <DashboardLayout title='Create New Project'>
        <Containers width={700}>
          <GlassCard className='p-4'>
            <form onSubmit={handleSubmit(handleSubmitProject)}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='tenderId'
                      control={control}
                      queryparam='tenderStatusId'
                      queryparamValue={4}
                      apiUrl='tenders'
                      valueField='tenderId'
                      headerField={['Tender Code', 'Tender Name']}
                      labelField={['tenderCode', 'tenderName']}
                      showField={['tenderName']}
                      pageSize={20}
                      onChangeProp={(e) => {
                        setSelectedTender(e);
                      }}
                      label='Tenders'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='projectName'
                      control={control}
                      label='Project Name'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmDatePicker
                      name='deliveryDate'
                      control={control}
                      label='Delivery Date'
                      required
                    />
                  </Grid>
                  <Grid item md={12}>
                    <FmTextField
                      name='projectDescription'
                      control={control}
                      label='Project description'
                      multiline
                      rows={4}
                      maxRows={4}
                      required
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='projectInChargeId'
                      control={control}
                      apiUrl='loginusers'
                      valueField='loginUserId'
                      headerField={['Name']}
                      labelField={['loginUserName']}
                      showField={['loginUserName']}
                      pageSize={20}
                      label='Project Incharge'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='locationId'
                      control={control}
                      apiUrl='locations'
                      label='Location'
                      valueField='locationId'
                      pageSize={1000}
                      labelField={['locationName']}
                      headerField={['locationName']}
                      showField={['locationName']}
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='clientId'
                      control={control}
                      apiUrl='clients'
                      valueField='clientId'
                      headerField={['Client Code', 'Client Name']}
                      labelField={['clientCode', 'clientName']}
                      showField={['clientName']}
                      pageSize={20}
                      defaultValue={{
                        clientName: selectedTender?.clientName,
                        clientId: selectedTender?.clientId,
                      }}
                      label='Client'

                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='divisionId'
                      control={control}
                      apiUrl='divisions'
                      valueField='divisionId'
                      headerField={['Division Name']}
                      labelField={['divisionName']}
                      showField={['divisionName']}
                      pageSize={20}
                      label='Division'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmAutoComplete
                      name='projectTypeId'
                      control={control}
                      options={projectTypes}
                      optionFields={['projectTypeName']}
                      displayField='projectTypeName'
                      valueKey='projectTypeId'
                      label='Project Type'
                      required
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmTextField
                      name='projectValue'
                      control={control}
                      label='Project Value'
                      defaultValue={selectedTender?.tenderValue}
                      pattern='Decimal'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='incentive'
                      control={control}
                      label='Project Incentive'
                      pattern='Number'
                      required
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Box>
                      <h3 className='mb-3 font-semibold'>
                        Additional Files Upload
                      </h3>
                      <Box className='flex gap-5 flex-wrap'>
                        <Button
                          onClick={handleAddComponent}
                          sx={{
                            minWidth: '100px',
                            height: '100px',
                            border: '1px solid',
                            borderColor: 'border.main',
                            flexDirection: 'column',
                            padding: 0,
                            bgcolor: 'background.white',
                          }}
                        >
                          <span className='text-teal-600 text-3xl mb-3'>
                            <Icon icon='simple-line-icons:plus' />
                          </span>
                          <span>Add Upload</span>
                        </Button>

                        {components.map((component) => (
                          <UploadSquareBoxes
                            key={component.id}
                            imageUrl={component.path}
                            setImageUrl={setImageUrl} // If you need this, otherwise omit
                            handleRemove={handleRemoveComponent}
                            setComponents={setComponents}
                            id={component.id}
                            label='Upload File'
                            setLoading={setImageLoading}
                            isLoading={imageLoading[component.id]}
                            onChange={(fileId) =>
                              handleFileChange(component.id, fileId)
                            }
                            fileTypeId={38}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <ActionButtons
                      onSubmit={handleSubmit(handleSubmitProject)}
                      onCancel={() => navigate(-1)}
                      onReset={reset}
                      submitLoading={createLoading}
                      cancelLoading={false}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </form>
          </GlassCard>
        </Containers>
      </DashboardLayout>
    </>
  );
};

export default CreateProjects;
