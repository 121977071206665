import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { PurchaseVoucherSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import { useNavigate } from 'react-router-dom';

const GridSize = 2.4;

const CreatePurchaseVoucher = (props) => {
  const { type } = props;
  const navigate = useNavigate();

  const crud = new Crud_Service();
  const [salesOrder, setSalesOrder] = React.useState();
  const { control, handleSubmit, reset, setValue, watch, getValues } = useForm({
    resolver: yupResolver(PurchaseVoucherSchema),
    mode: 'onChange',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset();
  }, [type]);
  const [fileId, setFileId] = useState(null);

  const submitPurchaseVoucher = async (values) => {
    const data = {
      ...values,
      purchaseVoucherDate: dayjs(values?.purchaseVoucherDate).format(
        'YYYY-MM-DD'
      ),
      paymentDueDate: dayjs(values?.paymentDueDate).format('YYYY-MM-DD'),
      invoiceDate: dayjs(values?.invoiceDate).format('YYYY-MM-DD'),
      purchaseVoucherTypeId: type,
      purchaseVoucherStatusId: 1,
      vendorId: values.supplierId,
      fileId: fileId,
    };
    await crud.create('purchasevouchers', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Purchase Voucher Created Successfully');
        reset();
        navigate(-1);
      } else {
      }
    });
  };

  const handleSalesOrderChange = (ev) => {
    setSalesOrder(ev);
    setValue('locationId', ev?.locationId ?? null);
    setValue('currencyId', ev?.currencyId ?? null);
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 33);
      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);

              setLoading(false);
            } else {
              reject(new Error('File upload failed'));
              setLoading(false);
            }
          });
        });

        setFileId(response?.data?.fileId);
        setValue(`documentName`, response?.data?.originalFileName);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <DashboardLayout
      title={`Create ${
        type === 1
          ? 'Purchase Voucher'
          : type === 2
          ? 'Purchase Return'
          : 'Direct Purchase Order'
      }`}
      hasSubmenu
      menu={Nav[7].child[2].children}
      parentMenu={Nav[7].child[2].name}
    >
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(submitPurchaseVoucher)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmTextField
                name='purchaseVoucherName'
                label='Name'
                control={control}
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                name='purchaseVoucherCode'
                label='Code'
                control={control}
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmDatePicker
                name='purchaseVoucherDate'
                label='Voucher Date'
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='salesOrderId'
                control={control}
                apiUrl='salesorders'
                valueField='salesOrderId'
                labelField={['salesOrderName']}
                showField={['salesOrderName']}
                onChangeProp={(ev) => handleSalesOrderChange(ev)}
                pageSize={20}
                label='Sales Order'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='supplierId'
                control={control}
                apiUrl='suppliers'
                valueField='supplierId'
                labelField={['supplierCode', 'officialSupplierName']}
                showField={['supplierCode', 'officialSupplierName']}
                pageSize={20}
                required
                label={'Vendor'}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='currencyId'
                label='Currency'
                apiUrl={'currencies'}
                valueField='currencyId'
                required
                labelField={['currencyName', 'currencyCode']}
                showField={['currencyName', 'currencyCode']}
                control={control}
                defaultValue={{
                  currencyId: salesOrder?.currencyId,
                  currencyName: salesOrder?.currencyName,
                }}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='locationId'
                label='Location'
                required
                apiUrl={'locations'}
                valueField='locationId'
                labelField={['locationName', 'locationCode']}
                showField={['locationName', 'locationCode']}
                control={control}
                defaultValue={{
                  locationId: salesOrder?.locationId,
                  locationName: salesOrder?.locationName,
                }}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='supplyLocationId'
                label='Supply Location'
                apiUrl='locations'
                valueField='locationId'
                required
                labelField={['locationName', 'locationCode']}
                showField={['locationName', 'locationCode']}
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='companyId'
                label='Company'
                apiUrl={'companies'}
                valueField='companyId'
                labelField={['companyName', 'companyCode']}
                showField={['companyName', 'companyCode']}
                control={control}
                defaultValue={{
                  companyId: salesOrder?.companyId,
                  companyName: salesOrder?.companyName,
                }}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='inCharge'
                control={control}
                apiUrl='loginusers'
                valueField='loginUserId'
                labelField={['loginUserName']}
                showField={['loginUserName']}
                pageSize={20}
                label='In Charge'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='requestorId'
                control={control}
                apiUrl='loginusers'
                valueField='loginUserId'
                labelField={['loginUserName']}
                showField={['loginUserName']}
                pageSize={20}
                label='Requestor'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='paymentTermId'
                control={control}
                apiUrl='paymentterms'
                valueField='paymentTermId'
                labelField={['paymentTermName']}
                showField={['paymentTermName']}
                pageSize={20}
                label='Payment Term'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='paymentTypeId'
                control={control}
                apiUrl='paymenttypes'
                valueField='paymentTypeId'
                labelField={['paymentTypeName']}
                showField={['paymentTypeName']}
                pageSize={20}
                label='Payment Type'
                defaultValue={{
                  paymentTypeId: salesOrder?.paymentTypeId,
                  paymentTypeName: salesOrder?.paymentTypeName,
                }}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='divisionId'
                control={control}
                apiUrl='divisions'
                valueField='divisionId'
                labelField={['divisionName']}
                showField={['divisionName']}
                pageSize={20}
                label='Division'
                defaultValue={{
                  divisionId: salesOrder?.divisionId,
                  divisionName: salesOrder?.divisionName,
                }}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='approvedBy'
                control={control}
                apiUrl='loginusers'
                valueField='loginUserId'
                labelField={['loginUserName']}
                showField={['loginUserName']}
                pageSize={20}
                label='Approved By'
                defaultValue={{
                  approvedBy: salesOrder?.approvedBy,
                  loginUserName: salesOrder?.loginUserName,
                }}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmDatePicker
                name='paymentDueDate'
                label='Payment Due Date'
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmDatePicker
                name='invoiceDate'
                label='Supplier Invoice Date'
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='invoiceNo'
                label='Invoice No'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                name='billStatus'
                label='Bill Status'
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='exchangeRate'
                label='Exchange Rate'
                control={control}
                type='Number'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='remarks'
                multiline
                rows={2}
                label='Remarks'
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmFileInput
                name={`fileId`}
                documentName={getValues(`documentName`)}
                onChange={(e) => handleFileUpload(e)}
                loading={loading}
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(submitPurchaseVoucher)}
                onReset={reset}
                submitLoading={false}
                cancelLoading={false}
                onCancel={() => navigate(-1)}
                cancelText='Go Back'
                submitText='Create'
              />
            </Grid>
          </Grid>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default CreatePurchaseVoucher;
