import React from 'react';
import { GridOverlay } from '@mui/x-data-grid';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Button } from '@mui/material';

const DataTableNoRow = () => {
  return (
    <GridOverlay>
      <Box className='text-center py-10'>
        <Box className='text-6xl flex justify-center'>
          <Icon icon='material-symbols-light:table-view' />
        </Box>
        <Box className='font-semibold text-base'>No Results.</Box>
        <Box className='opacity-65'>
          Try asking the community. <br /> They might be able to you help find
          what you're looking for.
        </Box>
      </Box>
    </GridOverlay>
  );
};

export default DataTableNoRow;
