import React, { useState, useEffect } from 'react';
import DataTable from '../../../components/DataTable.tsx';
import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableDropDown from '../../../components/helpers/TableDropDown.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import {
  StatusBadge,
  TextColor,
} from '../../../components/_form/FormElements.jsx';
import { useTranslation } from 'react-i18next';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import DataTableTopBar from '../../../components/datagrid/DataTableTopBar.tsx';

const Suppliers = () => {
  const navigate = useNavigate();
  const [assetView] = useState(false);
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('suppliers');

  const crud = new Crud_Service();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `Presales | Suppliers`;
  }, []);

  // View Details
  const handleViewClick = async (props) => {
    navigate(`view/${props?.supplierId}`);
  };

  //Navigate Edit Page
  const handleEditClick = async (props) => {
    navigate(`edit/${props?.supplierId}`);
  };
  const handleAdddContacts = async (props) => {
    navigate(`add-contacts/${props?.supplierId}`, {
      state: { supplierData: props },
    });
  };
  const handleAddDocuments = async (props) => {
    navigate(`add-documents/${props?.supplierId}`, {
      state: { supplierData: props },
    });
  };

  const handledeleteClick = async (props) => {
    await crud.remove(`suppliers`, props?.supplierId, (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      if (res?.status === 204) {
        toast.success('Suppliers Deleted Successfully');
        fetchData();
      }
    });
  };

  const ActionData = [
    {
      name: 'Add Contacts',
      icon: <Icon icon='pixelarticons:contact-plus' />,
      onClick: (props) => handleAdddContacts(props),
    },
    {
      name: 'Add Documents',
      icon: <Icon icon='fluent:document-add-48-regular' />,
      onClick: (props) => handleAddDocuments(props),
    },
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-outline' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: t('edit'),
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handledeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 70,
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableDropDown
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={() => { }}
          />
        );
      },
    },
    {
      field: 'supplierCode',
      headerName: 'Supplier Code',
      width: 150,
      hideable: false,
    },
    {
      field: 'officialSupplierName',
      headerName: 'Supplier Name',
      width: 220,
      hideable: false,
    },
    {
      field: 'supplierTypeName',
      headerName: 'Supplier Type Name',
      width: 200,
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 200,
    },
    {
      field: 'headOfficeAddress',
      headerName: 'Head Office Address',
      width: 200,
    },
    {
      field: 'regionName',
      headerName: 'Region Name',
      width: 200,
    },
    {
      field: 'countryName',
      headerName: 'Country Name',
      minWidth: 220,
      cellClassName: 'font-semibold',
    },
    {
      field: 'taxNumber',
      headerName: 'Tax Number',
      width: 150,
    },
    {
      field: 'taxPercentage',
      headerName: 'Tax Percentage',
      width: 150,
    },
    {
      field: 'tradeLicenseNumber',
      headerName: 'Trade License Number',
      width: 200,
    },
    {
      field: 'agreement',
      headerName: 'Agreement',
      width: 150,
      renderCell: (params) => {
        return (
          <TextColor
            title={params.value === true ? 'Yes' : 'No'}
            color={params.value === true ? 'green' : 'red'}
          />
        );
      },
    },
    {
      field: 'debitORCredit',
      headerName: 'Debit OR Credit',
      width: 150,
      renderCell: (params) => {
        return (
          <TextColor
            title={params.value === true ? 'Yes' : 'No'}
            color={params.value === true ? 'green' : 'red'}
          />
        );
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params?.value === 2 ? 'Active' : 'Inactive'}
            type={params?.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <DashboardLayout
      title='Suppliers'
      actionButtons={
        <CreateButton name='New' onClick={() => navigate('create')} />
      }
      removeSpace
    >
      {!assetView && (
        <GlassCard className='overflow-hidden h-full-css border-0 rounded-none'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={isLoading}
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) =>
                handlePaginationModelChange(model)
              }
              onSortModelChange={(model) => handleSortModelChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tableRecordCounts?.total}
              slots={{
                toolbar: () => (
                  <DataTableTopBar
                    setSearchKeyword={setSearchKeyword}
                    searchValue={searchKeyword}
                  />
                ),
              }}
            />
          </Box>
        </GlassCard>
      )}
    </DashboardLayout>
  );
};

export default Suppliers;
