import React from 'react'
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';

const CheckOutListTable = ({
    rows,
    columns,
    editingRowId,
    searchKeyword,
    setSearchKeyword,
    isLoading,
    pagination,
    sorting,
    tableRecordCounts,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
}) => {
    return (
        <>
            <FmMRTDataTable
                columns={columns}
                enableRowSelection={false}
                rows={rows}
                rowCount={tableRecordCounts}
                editingRow={editingRowId}
                pagination={pagination}
                sorting={sorting}
                columnFilters={columnFilters}
                globalFilter={globalFilter}
                setPagination={setPagination}
                setSorting={setSorting}
                setColumnFilters={setColumnFilters}
                setGlobalFilter={setGlobalFilter}
                isLoading={isLoading}
                setSearchKeyword={setSearchKeyword}
                searchKeyword={searchKeyword}
            />
        </>
    )
}

export default CheckOutListTable
