import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import MuiDialogOne from './../_mui/MuiDialogOne.jsx';
import { useDropzone } from 'react-dropzone';
import GlassCard from '../small/GlassCard.tsx';
import Crud_Service from '../../apis/CrudService.jsx';

const UploadModalBox = ({
  files,
  setFiles,
  removeBorder,
  onSubmitFiles,
  onCancel,
  label = 'Upload Images',
}) => {
  const [view, setView] = useState(false);
  // const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const crud = new Crud_Service();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*,application/pdf',
    multiple: true,
    onDrop: (acceptedFiles) => {
      setLoading(true);
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      // Call upload API for each file
      Promise.all(newFiles.map(uploadFile))
        .then((uploadedFiles) => {
          const validFiles = uploadedFiles.filter(
            (file) => file && file.fileId
          );
          setFiles((prevFiles) => [...prevFiles, ...validFiles]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    },
  });

  const uploadFile = async (file) => {
    // Replace with actual API call
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileTypeId', 50);

    return await crud.create('/files/upload', formData, (err, res) => {
      if (res?.status === 200) {
        setLoading(false);
        const newFile = {
          fileName: res?.data?.originalFileName,
          fileId: res?.data?.fileId,
        };
        setFiles((prevFiles) => [...prevFiles, newFile]);
      } else {
        setLoading(false);
        return null;
      }
    });
  };

  const removeFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleCloseModal = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setView(false);
    }
  };

  const handleSubmit = () => {
    const fileIds = files
      ?.filter((file) => file?.fileId)
      .map((file) => file.fileId);
    onSubmitFiles(fileIds);
    setView(false);
  };
  const handleCancel = () => {
    setFiles([]);
    if (onCancel) {
      onCancel();
    }
    setView(false);
  };

  return (
    <>
      <Button
        className='flex items-center gap-3 justify-center py-2 font-semibold'
        onClick={() => setView(!view)}
        fullWidth
        sx={{
          border: !removeBorder && '1px solid',
          borderColor: !removeBorder && 'border.main',
        }}
      >
        {files?.length > 0 ? (
          <>
            <span className='text-lg'>
              <Icon icon='oi:circle-check' />
            </span>
            <span>{files?.length} Files Uploaded</span>
          </>
        ) : (
          <>
            {' '}
            <span className='text-lg'>
              <Icon icon='ph:upload-bold' />
            </span>
            <span style={{ lineHeight: 1 }}>{label}</span>
          </>
        )}
      </Button>

      {/* modal */}
      <MuiDialogOne
        title='Upload Images'
        open={view}
        onClose={handleCloseModal}
        width='480px'
      >
        <Box
          {...getRootProps()}
          className='w-full p-4 rounded-sm text-center'
          sx={{
            border: '2px dashed',
            borderColor: 'primary.main',
            bgcolor: isDragActive ? 'background.light' : 'background.mainLight',
            cursor: 'pointer',
          }}
        >
          <input {...getInputProps()} disabled={loading} />
          <Box
            className='text-5xl flex justify-center mb-4'
            sx={{ color: 'primary.main' }}
          >
            <Icon icon='ph:upload' />
          </Box>

          <Box className='text-base font-semibold mb-2'>
            {isDragActive
              ? 'Drop files here...'
              : 'Drag & drop files here, or click to select files'}
          </Box>

          <Button
            variant='outlined'
            color='primary'
            className='font-semibold'
            onClick={() => {}}
          >
            Upload Files
          </Button>
        </Box>

        {files?.length > 0 && (
          <Box mt={2}>
            {files?.map((file, index) => (
              <GlassCard
                key={index}
                className='flex justify-between items-center p-2 mb-3'
              >
                <Box className='flex gap-3 items-center'>
                  <Box className='text-lg'>
                    <Icon icon='pepicons-pencil:file' />
                  </Box>
                  <h3 className='font-bold'>{file?.fileName}</h3>
                </Box>
              </GlassCard>
            ))}

            <Box className='flex justify-end gap-3 mt-5'>
              <Button
                variant='outlined'
                className='min-w-28'
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                className='min-w-28 font-bold'
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        )}
      </MuiDialogOne>

      {/* modal */}
    </>
  );
};

export default UploadModalBox;
