import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/DashboardLayout.tsx";
import AddInspectionReport from "./AddInspectionReport.tsx";
import ListInspectionReport from "./ListInspectionReport.tsx";
import useCommonFetchApi from "../../../components/helpers/useCommonFetchApi.tsx";
import Crud_Service from "../../../apis/CrudService.jsx";
import useTableLogic from "../../../components/helpers/MRTUseTableLogic.tsx";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { CreateButton } from "../../../components/small/Buttons.jsx";
import { Box } from "@mui/material";

const InspectionReport = () => {
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic("inspectionreports");
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const crud = new Crud_Service();
  //fetch All Apis
  const { data: buildings } = useCommonFetchApi("buildings");
  const { data: months } = useCommonFetchApi("months");

  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Inspection Report | CAFM`;
  }, []);

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleUpdateInspectionReport = async (values) => {
    const combinedData = {
      ...values,
      time: values?.time,
      inspectionStartDate: dayjs(values?.inspectionStartDate).format(
        "YYYY-MM-DD"
      ),
      inspectionEndDate: dayjs(values?.inspectionEndDate).format("YYYY-MM-DD"),
      status: 2,
    };

    await crud.update(
      "inspectionreports",
      editingRowId,
      combinedData,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success("Inspection Report updated successfully");
          fetchData();
          handleCancel();
        } else {
        }
      }
    );
  };

  return (
    <DashboardLayout
      title="Inspection Report"
      actionButtons={
        <>
          <Box className="flex flex-row items-center whitespace-nowrap gap-3">
            <CreateButton
              name="Create Check Out Form"
              onClick={() => navigate("create")}
            />
          </Box>
        </>
      }
    >
      <ListInspectionReport
        rows={rows}
        tableRecordCounts={tableRecordCounts}
        editingRowId={editingRowId}
        editingRowData={editingRowData}
        setEditingRowData={setEditingRowData}
        setEditingRowId={setEditingRowId}
        fetchData={fetchData}
        handleUpdateInspectionReport={handleUpdateInspectionReport}
        handleCancel={handleCancel}
        pagination={pagination}
        sorting={sorting}
        setPagination={setPagination}
        setSorting={setSorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        buildings={buildings}
        months={months}
        crud={crud}
      />
    </DashboardLayout>
  );
};

export default InspectionReport;
