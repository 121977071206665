import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import Nav from '../../../utils/Nav.tsx';
import AddOwnVehicles from './AddOwnVehicles.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ListOwnVehicles from './ListOwnVehicles.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';

const OwnVehicles = () => {
  const [, setViewdetails] = useState(false);
  const crud = new Crud_Service();

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('ownvehicles');
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();

  useEffect(() => {
    document.title = `Own Vehicles | Admin`;
  }, []);

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleEdit = (row) => {
    setEditingRowId(row?.accommodationId);
    setEditingRowData({ ...row });
  };

  const handleViewClick = async (props) => {
    setViewdetails(props);
  };

  const handleDelete = async (props) => {
    await crud.remove('accommodations', props?.accommodationId, (_err, res) => {
      if (res?.status === 204) {
        toast.success('Accommodation deleted successfully');
        fetchData();
      } else {
      }
    });
  };

  const handleUpdateAccommodation = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(values?.endDate).format('YYYY-MM-DD'),
      status: 2,
      updateAccommodationImages: editingRowData?.accommodationImages?.map(
        (item) => {
          return {
            accommodationImageId: item?.accommodationImageId,
            fileId: item?.fileId,
            status: item?.status,
          };
        }
      ),
    };

    await crud.update(
      'accommodations',
      editingRowId,
      combinedData,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Accommodation updated successfully');
          fetchData();
          stopEditLoading();
          setEditingRowId(null);
          setEditingRowData(null);
        } else {
          stopEditLoading();
        }
      }
    );
  };
  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  return (
    <DashboardLayout
      title='Vehicles'
      hasSubmenu
      menu={Nav[10].child[7].children}
    >
      <AddOwnVehicles />
      <GlassCard>
        <ListOwnVehicles
          rows={rows}
          editingRowId={editingRowId}
          isLoading={isLoading}
          editingRowData={editingRowData}
          handleUpdateAccommodation={handleUpdateAccommodation}
          handleCancel={handleCancel}
          ActionData={ActionData}
          editLoading={editLoading}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          tableRecordCounts={tableRecordCounts}
          setPagination={setPagination}
          setGlobalFilter={setGlobalFilter}
          setColumnFilters={setColumnFilters}
          setSorting={setSorting}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default OwnVehicles;
