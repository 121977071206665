import React from 'react'
import DashboardLayout from '../../../../../components/DashboardLayout.tsx'
import SalesRetentionItemListTable from './SalesRetentionItemListTable.tsx'
import AddViewSalesRetention from './AddViewSalesRetention.tsx'
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx'
const SalesRetentionItem = (props) => {
    const { salesRententionId } = props;
    const {
        rows,
        rowCount,
        isLoading,
        pagination,
        sorting,
        columnFilters,
        globalFilter,
        tableRecordCounts,
        setPagination,
        setSorting,
        setColumnFilters,
        setGlobalFilter,
        fetchData,
        setSearchKeyword,
        searchKeyword,
    } = useTableLogic('salesretentionitems');


    return (
        <DashboardLayout title='Sales Retention Item'>
            <AddViewSalesRetention fetchData={fetchData} salesRententionId={salesRententionId} />
            <SalesRetentionItemListTable
                rows={rows}
                tableRecordCounts={tableRecordCounts}
                pagination={pagination}
                sorting={sorting}
                fetchData={fetchData}
                setPagination={setPagination}
                setSorting={setSorting}
                columnFilters={columnFilters}
                globalFilter={globalFilter}
                setColumnFilters={setColumnFilters}
                setGlobalFilter={setGlobalFilter}
                isLoading={isLoading}
                setSearchKeyword={setSearchKeyword}
                searchKeyword={searchKeyword} />
        </DashboardLayout>
    )
}

export default SalesRetentionItem
