import React, { useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import CreatePurchaseForms from './Form/CreatePurchaseForms.tsx';
import CreatePurchaseItem from './Form/CreatePurchaseItem.tsx';
import ProgressCircleStep from '../../../components/page/ProgressCircleStep.tsx';
import { useParams } from 'react-router-dom';

const CreatePurchaseOrderView = () => {
  const [localPurchaseOrderId, setLocalPurchaseOrderId] = useState(0);
  const [materialRequest, setMaterialRequest] = useState({});
  const [editData, setEditData] = useState(null);
  const { id } = useParams();

  return (
    <DashboardLayout title={id?'Edit Purchase Order':'Create Purchase Order'}>
      <ProgressCircleStep numElements={5} />
      <CreatePurchaseForms
        setLocalPurchaseOrderId={setLocalPurchaseOrderId}
        setMaterialRequest={setMaterialRequest}
        setEditData={setEditData}
      />
      {/* {localPurchaseOrderId > 0 || editData?.localPurchaseOrderItems && (
        <CreatePurchaseItem
          localPurchaseOrderId={localPurchaseOrderId}
          materialRequest={materialRequest}
          editData={editData}
        />
      )} */}
      {(localPurchaseOrderId > 0 ||
        editData?.localPurchaseOrderItems?.length > 0) && (
        <CreatePurchaseItem
          localPurchaseOrderId={localPurchaseOrderId}
          materialRequest={materialRequest}
          editData={editData}
        />
      )}
    </DashboardLayout>
  );
};

export default CreatePurchaseOrderView;
