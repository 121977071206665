import React from 'react';
import TableSearch from '../_form/TableSearch.jsx';
import { Box } from '@mui/material';

const DataTableTopBar = ({ setSearchKeyword, searchKeyword, children }) => {
  return (
    <Box
      className='p-2 w-full flex justify-between items-center'
      sx={{
        borderBottom: '1px solid',
        borderColor: 'border.main',
      }}
    >
      <Box sx={{ maxWidth: '350px', width: '100%' }}>
        <TableSearch
          placeholder='Search'
          fullWidth
          setSearchKeyword={setSearchKeyword}
          searchValue={searchKeyword}
        />
      </Box>
      {children}
    </Box>
  );
};

export default DataTableTopBar;
