import React, { useEffect, useState } from 'react';
import ViewIncidentLayout from './ViewIncidentLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Box, Button } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Controller, useForm } from 'react-hook-form';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';

const imageHandler = function () {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const range = this.quill.getSelection();
      this.quill.insertEmbed(range.index, 'image', reader.result);
    };
    reader.readAsDataURL(file);
  };
};

const ViewRemarks = () => {
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      remarks: '',
    },
  });
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    getIncidentReports();
  }, []);

  const quillModules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ align: [] }],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  const getIncidentReports = async () => {
    setLoading(true);
    await crud.getSingle('incidentreports', id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
        setValue('remarks', res?.data?.remarks || '');
        setLoading(false);
      } else {
        setViewData(null);
        setLoading(false);
      }
    });
  };

  const onSubmit = async (values) => {
    const combinedData = {
      ...viewData,
      remarks: values?.remarks,
    };

    await crud.update('incidentreports', id, combinedData, (err, res) => {
      if (res?.status === 200) {
        toast.success('Incident Report Remarks Updated successfully');
        getIncidentReports();
      } else {
      }
    });
  };

  return (
    <ViewIncidentLayout title='View Incident Report'>
      {loading ? (
        <CommonLoader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <GlassCard className='p-3 mb-3 relative'>
            <Controller
              name='remarks'
              control={control}
              render={({ field }) => (
                <ReactQuill
                  theme='snow'
                  value={field?.value}
                  onChange={field.onChange}
                  modules={quillModules}
                />
              )}
            />
          </GlassCard>

          <Box className='flex gap-4 items-center justify-center'>
            <Button
              variant='contained'
              type='submit'
              className='font-bold min-w-32'
            >
              Update
            </Button>
          </Box>
        </form>
      )}
    </ViewIncidentLayout>
  );
};

export default ViewRemarks;
