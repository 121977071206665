import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../apis/CrudService';
import { Button, Grid, IconButton } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../../components/_form';
import { toast } from 'react-toastify';
import apiInstance from '../../../../apis/ApiService.jsx';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { employeeEducationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const EmployeeEducation = (props) => {
  const { setEnabledTabs, setValues, value, createdEmployee } = props;

  const [qualification, setQualification] = useState([]);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(employeeEducationSchema),
    defaultValues: {
      qualifications: [
        {
          employeeId: '',
          qualificationTypeId: '',
          completedYear: '',
          attestationStatus: '',

          equivalencyCertificate: '',
          fileId: '',
          documentName: '',
        },
      ],
    },
  });

  const [files, setFiles] = useState([]);
  const attestationStatus = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];
  const [uploadLoading, setUploadLoading] = useState([]);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'qualifications',
  });

  useEffect(() => {
    if (value === 1) {
      getQualificationTypes();
      getEmployee();
    }
  }, [value]);

  const getQualificationTypes = async () => {
    await crud.getAll('qualifications', {}, (_err, res) => {
      if (res?.status === 200) {
        setQualification(res?.data?.data);
      } else {
      }
    });
  };

  const getEmployee = async () => {
    await apiInstance.getAllEmployee().then((res) => {
      if (res?.status === 200) {
      } else {
      }
    });
  };

  // File upload handler
  const handleFileUpload = async (index, event) => {
    const newLoadingState = [...uploadLoading];
    newLoadingState[index] = true;
    setUploadLoading(newLoadingState);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 10);

      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);
              newLoadingState[index] = false;
              setUploadLoading(newLoadingState);
            } else {
              newLoadingState[index] = false;
              setUploadLoading(newLoadingState);
              reject(new Error('File upload failed'));
            }
          });
        });

        const fileId = response?.data?.fileId;
        const fileName = response?.data?.originalFileName;

        // Update the file info in state
        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          newFiles[index] = { fileId, fileName };
          return newFiles;
        });

        // Update the form values with fileId and fileName
        setValue(`qualifications[${index}].fileId`, fileId);
        setValue(`qualifications[${index}].documentName`, fileName);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handleSubmitEducation = (values) => {
    startCreateLoading();

    const combinedData = values?.qualifications?.map((item, index) => ({
      ...item,
      employeeId: createdEmployee?.employeeId,
      fileId: files[index]?.fileId,
    }));
    crud.create(
      `employeeeducations/bulk-employee-education`,
      combinedData,
      (err, res) => {
        if (err) {
          stopCreateLoading();
          toast.error('Error creating employee:', err);
          return;
        }
        if (res?.status === 201) {
          toast.success('Employee Education created successfully');
          stopCreateLoading();
          setEnabledTabs(1);
          setEnabledTabs(2);
          setEnabledTabs(3);
          setEnabledTabs(4);
          setEnabledTabs(5);
          setEnabledTabs(6);
          setValues(2);
        } else {
          toast.error('Something went wrong when Create Employee Education');
          stopCreateLoading();
        }
      }
    );
  };

  return (
    <GlassCard className='p-4'>
      <form onSubmit={handleSubmit(handleSubmitEducation)}>
        {fields?.map((field, index) => (
          <>
            <Grid container spacing={3} key={field?.id}>
              <Grid item md={4}>
                <FmTextField
                  name={`qualifications.${index}.qualificationName`}
                  control={control}
                  options={qualification}
                  label='Qualification'
                />
              </Grid>
              <Grid item md={2}>
                <FmTextField
                  name={`qualifications.${index}.completedYear`}
                  label='Passed Out'
                  control={control}
                  pattern='Number'
                />
              </Grid>
              <Grid item md={3}>
                <FmAutoComplete
                  name={`qualifications.${index}.attestationStatus`}
                  control={control}
                  options={attestationStatus}
                  label='Attestation Status'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>

              <Grid item md={4}>
                <FmTextField
                  name={`qualifications.${index}.equivalencyCertificate`}
                  label='Equivalence Certificate'
                  control={control}
                />
              </Grid>
              <Grid item md={6}>
                <FmFileInput
                  name={`qualifications.${index}.fileId`}
                  documentName={getValues(
                    `qualifications[${index}].documentName`
                  )}
                  onChange={(e) => handleFileUpload(index, e)}
                  loading={uploadLoading[index]}
                />
              </Grid>
              <Grid item md={2} container alignItems='center'>
                <IconButton
                  color='error'
                  onClick={() => remove(index)}
                  disabled={fields.length === 1}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
            <br />
          </>
        ))}

        <Button
          startIcon={<Icon icon='ion:add' />}
          className='p-0 text-primary font-semibold'
          onClick={() => append({})}
        >
          Add
        </Button>

        <ActionButtons
          onSubmit={handleSubmit(handleSubmitEducation)}
          onCancel={() => setValues(0)}
          onReset={reset}
          submitLoading={createLoading}
          cancelLoading={false}
          errorFields={!isValid}
        />
      </form>
    </GlassCard>
  );
};

export default EmployeeEducation;
