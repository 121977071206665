import React from 'react';
import { Box, alpha } from '@mui/material';
import { FontSize } from '../../theme/FontSize.jsx';

const MRTExpandTableStyle = ({
  enableFullHeight = false,
  children,
  footerWidthAdjust = false,
  ...props
}) => {
  return (
    <Box
      sx={{
        height: enableFullHeight && '100%',
        '& > .MuiBox-root': {
          height: enableFullHeight && '100%',
          '& > .MuiPaper-root': {
            height: enableFullHeight && '100%',
            display: enableFullHeight && 'flex',
            flexDirection: enableFullHeight && 'column',
            height: enableFullHeight && '100%',
          },
        },
        '& .MuiTableCell-root, & .MuiFormLabel-root, & .MuiInputBase-root, & .MuiTypography-root':
          {
            fontSize: FontSize.bodyMinusOne,
            color: 'inherit',
          },

        '& .MuiTableRow-head': {
          boxShadow: 'inherit',
        },

        '& .MuiTablePagination-root': {
          padding: '0px',
        },
        '& .MuiTableCell-root': {
          padding: '2px 8px',
          borderLeft: '1px solid',
          borderColor: 'border.table',
          '&[data-pinned="true"]:before': {
            bgcolor: 'background.table',
            boxShadow: 'none',
          },
          '&:first-of-type': {
            borderLeft: 'none',
          },
          '&:first-of-type[data-pinned="true"]::before': {
            borderRight: '1px solid',
            borderColor: 'border.main',
          },
        },

        '& .MuiTableCell-head': {
          bgcolor: 'background.table',
          padding: '5px 8px',
          color: 'text.main',
          verticalAlign: 'middle',
          '&[data-pinned="true"]:before': {
            bgcolor: 'background.table',
            boxShadow: 'none',
          },
        },

        '& .MuiCollapse-horizontal': {
          '& .MuiInputBase-root': {
            padding: '0px 10px',
            '& .MuiOutlinedInput-input': {
              padding: '8px 4px',
              minWidth: '200px',
            },
          },
          '& .MuiSvgIcon-root': {
            fontSize: '20px',
          },
        },
        '& .MuiTableCell-root.MuiTableCell-head': {
          fontWeight: 600,
          lineHeight: 1.5,
        },

        '& .MuiButtonBase-root.MuiCheckbox-root': {
          width: '28px',
          height: '28px',
          margin: 0,
        },

        '& .MuiTableCell-root .MuiIconButton-root': {
          padding: '2px',
          fontSize: '1.15rem',
          width: '25px',
          height: '25px',
        },
        '& .MuiTableContainer-root + .MuiBox-root': {
          minHeight: 36,
        },
        '& .MuiPaper-elevation': {
          boxShadow: 'inherit',
          border: 'none',
          borderRadius: '0px',
          color: 'inherit',
          fontSize: 'inherit',
        },
        '& .MuiPaper-root>.MuiBox-root:first-of-type': {
          minHeight: 49,
          borderBottom: '1px solid',
          borderColor: 'border.table',
          background: 'none',
          '& .MuiIconButton-root': {
            padding: '4px',
          },
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            //borderColor: 'border.main',
          },
        },
        '& .MuiTableCell-alignLeft .MuiBox-root': {
          gap: 0,
        },

        '& .MuiTableBody-root .MuiTableRow-root': {
          //  background: 'inherit !important',
        },

        '& .MuiPaper-root.MuiAlert-root': {
          height: 'auto',
          zIndex: 12,
          minHeight: 40,
          bgcolor: 'background.mainLightBase',
          borderBottom: '1px solid',
          borderColor: 'border.table',
        },

        '& .MuiAlert-message': {
          padding: '0px 5px',
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100% !important',
          '& .MuiStack-root': {
            padding: 0,
          },
        },

        '& .MuiTableBody-root .MuiTableRow-root:hover td:after': {
          bgcolor: 'background.tableHover',
        },

        '& .MuiPaper-root>.MuiBox-root:last-child': {
          '& > .MuiBox-root > .MuiBox-root': {
            width: !footerWidthAdjust ? '100%' : 'auto',
            justifyContent: 'center',
          },
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default MRTExpandTableStyle;
