import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

const PageSubmenu = (props) => {
  const { menu } = props;

  return (
    <>
      <Box>
        <Box component='ul' className='flex flex-wrap gap-2 p-2 pb-0'>
          {menu &&
            menu?.map((value, i) => {
              return (
                <Box component='li' key={i} className='inline-block'>
                  <Box
                    component={NavLink}
                    to={value?.path}
                    sx={{
                      padding: '5px 15px',
                      display: 'inline-block',
                      fontWeight: 400,
                      position: 'relative',
                      border: '1px solid',
                      borderRadius: '5px',
                      borderColor: 'border.main',
                      // '&:after': {
                      //   content: "''",
                      //   position: 'absolute',
                      //   left: 0,
                      //   bottom: 0,
                      //   bgcolor: 'primary.main',
                      //   height: '2px',
                      //   width: '0%',
                      //   transition: '0.5s all',
                      // },
                      '&:hover': {
                        bgcolor: 'background.overlayTwo',
                        // '&:after': {
                        //   width: '100%',
                        // },
                      },
                      '&.active': {
                        fontWeight: 700,
                        bgcolor: 'background.white',
                        color: 'primary.main',
                        borderColor: 'primary.main',
                        '&:after': {
                          width: '100%',
                        },
                      },
                    }}
                  >
                    {value?.name}{' '}
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    </>
  );
};

export default PageSubmenu;
