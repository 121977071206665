import React, { useEffect, useState } from 'react';
import DashboardLayout from './../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import DataTable from './../../../components/DataTable.tsx';
import GlassCard from './../../../components/small/GlassCard.tsx';
import { Icon } from '@iconify/react';
import { Box, Tab, Tabs, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TableDropDown from '../../../components/helpers/TableDropDown.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import { StatusBadge } from '../../../components/_form/FormElements.jsx';
import Requisitions from '../../../components/modules/Requisitions.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import apiInstance from '../../../apis/ApiService.jsx';
import { toast } from 'react-toastify';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';

const MaterialRequestList = () => {
  const [statusId, setStatusId] = useState(null);
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic(
    'materialrequisitions',
    'materialRequisitionStatusId',
    statusId
  );
  const [sideRow, setSideRow] = useState([]);
  const [value, setValue] = React.useState(0);
  const [tabList, setTabList] = useState([]);

  const crud = new Crud_Service();

  const tableRows = rows?.map((row) => ({
    id: row?.materialRequisitionId,
    ...row,
  }));

  useEffect(() => {
    fetchData();
  }, [statusId]);

  const navigate = useNavigate();
  const fetchLists = async () => {
    await crud.getAll('materialrequisitionstatuses', null, (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setTabList(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    document.title = `Material Requisition Orders  | Procurement`;
    fetchLists();
  }, []);
  const downloadLeaveJoins = async () => {
    const downloadUrl = await apiInstance.getFiles(
      'materialrequisitions/exportexcel'
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Material Requisition.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleEditClick = (props) => {
    navigate(`edit/${props?.materialRequisitionId}`);
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'materialrequisitions',
      props?.materialRequisitionId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
        } else {
        }
      }
    );
  };

  const handleViewClick = async (props) => {
    navigate(`view/${props?.materialRequisitionId}`);
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-outline' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDelete(props),
      danger: true,
      disabled: sideRow ? true : false,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 80,
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableDropDown
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={() => {}}
          />
        );
      },
    },

    {
      field: 'materialRequisitionCode',
      headerName: 'MR Code',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'manualMRCode',
      headerName: 'Manual MR Code',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'projectName',
      headerName: 'Project Name',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'departmentName',
      headerName: 'Department Name',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'requestDate',
      headerName: 'Requested Date',
      minWidth: 120,
      flex: 1,
      renderCell: (params) => FmViewDateField({ value: params.value }),
    },
    {
      field: 'requestedDeliveryDate',
      headerName: 'Delivery Date',
      minWidth: 120,
      flex: 1,
      renderCell: (params) => FmViewDateField({ value: params.value }),
    },
    {
      field: 'contractName',
      headerName: 'Contract Name',
      minWidth: 120,
      flex: 1,
    },
    //TODO: For Future Use
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <StatusBadge
    //         title={params.value === 2 ? 'Active' : 'Inactive'}
    //         type={params.value === 2 ? 'green' : 'red'}
    //       />
    //     );
    //   },
    // },
    {
      field: 'materialRequisitionStatusName',
      headerName: 'MR Status',
      minWidth: 150,
      flex: 1,
      renderCell: (param) => {
        return (
          <StatusBadge
            title={param?.value}
            type={
              param?.value === 'Rejected'
                ? 'red'
                : param?.value === 'Approved'
                ? 'green'
                : param?.value === 'Submitted'
                ? 'orange'
                : param?.value === 'Draft'
                ? 'blue'
                : param?.value === 'Fulfilled'
                ? 'purple'
                : param?.value === 'Canceled'
                ? 'teal'
                : 'black'
            }
          />
        );
      },
    },
  ];
  const [disableBox, setDisableBox] = useState({}); // Store disabled states based on inCharge

  const handleSelectionModelChange = (newSelection) => {
    // Find the rows in tableRows that match the selected IDs
    const selectedRowsData = newSelection.map((id) =>
      tableRows.find((row) => row.id === id)
    );

    // Get the projectIds of the selected rows
    const selectedProjectIds = new Set(
      selectedRowsData.map((row) => row.inCharge)
    );

    // Create a map to determine if each row's checkbox should be disabled
    const disableMap = tableRows.reduce((map, row) => {
      map[row.id] = !selectedProjectIds.has(row.inCharge);
      return map;
    }, {});

    setDisableBox(disableMap);

    // Set the selected rows data to state
    setSideRow(selectedRowsData);
  };

  useEffect(() => {
    // Check if all values in disableBox are true
    const allDisabled = Object.values(disableBox).every(
      (value) => value === true
    );

    // Update state only if all checkboxes are disabled
    if (allDisabled) {
      // Use a function form of setState to avoid dependency issues
      setDisableBox((prevState) => {
        // Only update the state if there are changes
        const shouldUpdate = Object.values(prevState).some(
          (value) => value !== false
        );

        if (shouldUpdate) {
          return Object.keys(prevState).reduce((acc, key) => {
            acc[key] = false;
            return acc;
          }, {});
        }

        // Return previous state if no update is needed
        return prevState;
      });
    }
  }, [disableBox]);

  const handleClearAll = () => {
    setSideRow([]);
    setDisableBox((prevState) =>
      Object.keys(prevState).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    );
  };

  const handleCreateLPO = async () => {
    // Extract materialRequisitionIds from sideRow
    const materialRequisitionIds = sideRow.map(
      (item) => item.materialRequisitionId
    );

    // Create the request payload
    const payload = { materialRequisitionIds };

    try {
      await crud.create(
        'localpurchaseorderrequisitions',
        payload,
        (_err, res) => {
          if (res?.status === 201) {
            // Refresh the data after successful creation
            handleClearAll();
            toast.success('LPO Created successfully');
            navigate('/procurement/purchase-order-view');
            fetchData();
          } else {
          }
        }
      );
    } catch (error) {
      toast.error('Error creating LPO:', error);
    }
  };

  const handleCreateRFO = async () => {
    // Extract materialRequisitionIds from sideRow
    const materialRequisitionIds = sideRow.map(
      (item) => item.materialRequisitionId
    );

    // Create the request payload
    const payload = { materialRequisitionIds };

    try {
      await crud.create('requestforquoterequisitions', payload, (_err, res) => {
        if (res?.status === 201) {
          // Refresh the data after successful creation
          handleClearAll();
          toast.success('RFO Created successfully');
          navigate('/procurement/request-for-quote-management');
        } else {
        }
      });
    } catch (error) {
      toast.error('Error creating LPO:', error);
    }
  };
  //TODO
  // const SearchFilteredBoxes = () => {
  //   return (
  //     <ToolbarBoxesFlex>
  //       <Box className='w-full' sx={{ maxWidth: '280px' }}>
  //         <TableSearch
  //           placeholder='Search'
  //           fullWidth
  //           setSearchKeyword={setSearchKeyword}
  //           searchValue={searchKeyword}
  //         />
  //       </Box>

  //       <Stack
  //         direction='row'
  //         spacing={1}
  //         justifyContent='flex-end'
  //         alignItems='center'
  //       >
  //         {StatusWithValue.map((item, i) => {
  //           return (
  //             <StatusBadgeWithClick
  //               key={i}
  //               title={`${item.name} (${item.count})`}
  //               type={i === sortActive ? 'active' : item.type}
  //               onClick={() => setSortActive(i)}
  //             />
  //           );
  //         })}
  //       </Stack>

  //       <DataTableToolbarButtons handleExcelExport={downloadLeaveJoins}/>
  //     </ToolbarBoxesFlex>
  //   );
  // };ß
  return (
    <DashboardLayout
      title={'Material Requisition Orders'}
      actionButtons={
        <CreateButton
          name='New'
          onClick={() => navigate('/procurement/material-request/create')}
        />
      }
    >
      <Box className='h-full-css'>
        <Grid container spacing={3} className='h-full-css'>
          <Grid item md={9} className='h-full-css'>
            <Box className='flex flex-col h-full-css'>
              <GlassCard className='mb-2'>
                <Tabs
                  value={value}
                  onChange={(_event, newValue) => {
                    if (newValue === 0) {
                      setStatusId(null);
                      setSearchKeyword('');
                    } else {
                      setStatusId(
                        tabList[newValue - 1]?.materialRequisitionStatusId
                      );
                    }
                    setValue(newValue);
                    setSearchKeyword('');
                  }}
                  textColor='secondary'
                  indicatorColor='secondary'
                >
                  <Tab label='All' />
                  {tabList?.map((item) => {
                    return (
                      <Tab
                        label={item?.materialRequisitionStatusName}
                        key={item?.materialRequisitionStatusId}
                      />
                    );
                  })}
                </Tabs>
              </GlassCard>

              <GlassCard className='overflow-hidden h-full-css flex-1 min-h-0'>
                <Box className='h-full-css'>
                  <DataTable
                    rows={tableRows}
                    columns={columns}
                    loading={isLoading}
                    checkboxSelection={true}
                    onRowSelectionModelChange={(newSelection) =>
                      handleSelectionModelChange(newSelection)
                    }
                    isRowSelectable={(params) => !disableBox[params?.row?.id]}
                    getRowClassName={(params) =>
                      `${
                        params.row.workingStatus === 'InActive' &&
                        'bg-red-500 bg-opacity-15'
                      }`
                    }
                    sortingMode='server'
                    paginationMode='server'
                    onPaginationModelChange={(model) =>
                      handlePaginationModelChange(model)
                    }
                    onSortModelChange={(model) => handleSortModelChange(model)}
                    selectionModel={setSideRow}
                    page={pageNumber - 1}
                    pageSize={pageSize}
                    rowCount={
                      // sideRow?.length !== 0 ? 50 : tableRecordCounts?.total
                      tableRecordCounts?.total
                    }
                    slots={{
                      toolbar: () => (
                        <Box
                          className='p-2 w-full flex justify-between items-center'
                          sx={{
                            borderBottom: '1px solid',
                            borderColor: 'border.main',
                          }}
                        >
                          <Box sx={{ maxWidth: '250px' }}>
                            <TableSearch
                              placeholder='Search'
                              fullWidth
                              setSearchKeyword={setSearchKeyword}
                              searchValue={searchKeyword}
                            />
                          </Box>
                          <DataTableToolbarButtons
                            handleExcelExport={downloadLeaveJoins}
                          />
                        </Box>
                      ),
                    }}
                  />
                </Box>
              </GlassCard>
            </Box>
          </Grid>
          <Grid item md={3} className='h-full-css'>
            <Requisitions
              matReqData={sideRow}
              handleClearAll={handleClearAll}
              handleCreateLPO={handleCreateLPO}
              handleCreateRFO={handleCreateRFO}
              createRFO={true}
            />
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default MaterialRequestList;
