import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';

const ViewTick = ({ title, value }) => {
  return (
    <Box
      sx={{
        borderRadius: '5px',
        width: '160px',
        border: '1px solid',
        borderColor: 'border.main',
      }}
      className='flex items-center gap-1 p-1 px-2 border-dashed'
    >
      {value === 'Yes' ? (
        <Box className='text-green-600 text-2xl'>
          <Icon icon='ic:baseline-check-circle' />
        </Box>
      ) : value === 'No' ? (
        <Box className='text-red-600 text-2xl'>
          <Icon icon='material-symbols:close' />
        </Box>
      ) : (
        <Tooltip title={value} arrow>
          <Box className='text-blue-600 text-2xl'>
            <Icon icon='ic:baseline-info' />
          </Box>
        </Tooltip>
      )}
      <Box
        sx={{
          lineHeight: 1.1,
          opacity: 0.8,
          minHeight: '30px',
        }}
        className='flex items-center ms-2'
      >
        {title}
      </Box>
    </Box>
  );
};

export default ViewTick;
