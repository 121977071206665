import React, { useEffect } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { set, useForm } from 'react-hook-form';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import { addCheckInvalidationSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
const GridSize = 2.4;

const AddCheckInForm = (props) => {
  const { fetchData } = props;
  const id=useParams()?.id
  const crud = new Crud_Service();
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const [editData, setEditData] = React.useState(null);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addCheckInvalidationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (id) {
      (async () => {
        await crud.getSingle('checkinforms', id, (err, res) => {
          if (res?.status === 200) {
            setEditData(res?.data)
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
          } else {
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);

  const handleSubmitProject = async (values) => {
    startCreateLoading();

    await crud.create('checkinforms', values, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Check In Created successfully');

        fetchData();
        stopCreateLoading();
        reset();
      } else {
        stopCreateLoading();  
      }
    });
  };

  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(handleSubmitProject)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='clientId'
              control={control}
              apiUrl='clients'
              valueField='clientId'
              headerField={['Client Name']}
              labelField={['clientName']}
              showField={['clientName']}
              pageSize={20}
              label='Client'
              defaultValue={{
                clientId: editData?.clientId ||null,
                clientName: editData?.clientName ||''
              }}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='companyId'
              control={control}
              apiUrl='companies'
              valueField='companyId'
              headerField={['Company Name']}
              labelField={['companyName']}
              showField={['companyName']}
              pageSize={20}
              label='Company'
              defaultValue={{
                companyId:editData?.clientId||null,
                companyName:editData?.companyName||''
              }}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='projectId'
              control={control}
              apiUrl='projects'
              valueField='projectId'
              headerField={['Project Code']}
              labelField={['projectCode']}
              showField={['projectCode']}
              pageSize={20}
              label='Project Code'
              defaultValue={{
                projectId:editData?.projectId||null,
                projectCode:editData?.projectCode||''
              }}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='employeeId'
              control={control}
              apiUrl='employees'
              valueField='employeeId'
              headerField={['Employee Name']}
              labelField={['firstName']}
              showField={['firstName']}
              pageSize={20}
              label='Employee'
              defaultValue={{
                employeeId:editData?.employeeId||null,
                firstName:editData?.employeeName||''
              }}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='workOrderId'
              control={control}
              apiUrl='workOrders'
              valueField='workOrderId'
              headerField={['Work Order Code']}
              labelField={['workOrderCode']}
              showField={['workOrderCode']}
              pageSize={20}
              label='Work Order'
              defaultValue={{
                workOrderId:editData?.workOrderId||null,
                workOrderCode:editData?.workOrderCode||''
              }}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              valueField='contractId'
              headerField={['Contract Code']}
              labelField={['contractCode']}
              showField={['contractCode']}
              pageSize={20}
              label='Contract'
              defaultValue={{
                contractId:editData?.contractId||null,
                contractCode:editData?.contractCode||''
              }}
            />
          </Grid>
        </Grid>
      </form>
      <ActionButtons
        onSubmit={handleSubmit(handleSubmitProject)}
        onReset={reset}
        submitLoading={createLoading}
        cancelLoading={false}
      />
    </GlassCard>
  );
};

export default AddCheckInForm;
