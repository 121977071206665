import { Box, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Icons from '../../../utils/Icon.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import GlassCard from './../../../components/small/GlassCard.tsx';
import {
  ProjectName,
  ContractName,
} from '../../../components/table/MRTCommonColumns.tsx';
import StatusNewBadge from '../../../components/_form/StatusNewBadge.tsx';
import DashboardLayout from './../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';

import FilterDropdownStatus from './../../../components/table/FilterDropdownStatus.tsx';

const StoreLocations = () => {
  const [statusId, setStatusId] = useState(0);

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    `localpurchaseorders?localPurchaseOrderStatusId=${statusId}`
  );

  const { data: localpurchaseorderstatuses } = useCommonFetchApi(
    'localpurchaseorderstatuses'
  );

  const navigate = useNavigate();
  const location = useLocation();

  const handleEditPurchaseOrdre = async (props) => {
    navigate(`edit/${props?.localPurchaseOrderId}`);
  };

  const handleLpoPreview = (props) => {
    navigate(
      `/procurement/purchase-order-view/preview/${props?.localPurchaseOrderId}`
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => handleEditPurchaseOrdre(props),
    },
  ];

  useEffect(() => {
    fetchData();
  }, [statusId]);

  const columns = [
    {
      header: 'Actions',
      size: 150,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(row?.original),
            }))}
            selectedRow={row?.original}
            setSelectedRow={() => {}}
          />
          <Button
            onClick={() => handleLpoPreview(row?.original)}
            variant='contained'
            className='px-2 py-0.5'
          >
            LPO Preview
          </Button>
        </Box>
      ),
    },
    {
      accessorKey: 'localPurchaseOrderStatusName',
      header: 'Status',
      size: 120,
      Cell: ({ cell }) => {
        return (
          <StatusNewBadge title={cell.getValue()} type={cell.getValue()} />
        );
      },
    },
    {
      accessorKey: 'localPurchaseOrderNumber',
      header: 'PO Number',
      size: 150,
    },
    {
      accessorKey: 'lpoDate',
      header: 'PO Date',
      size: 150,
    },

    {
      accessorKey: 'vendorAccount',
      header: 'Vendor Account',
      size: 150,
    },

    {
      accessorKey: 'exchangeRate',
      header: 'Exchange Rate',
      size: 160,
    },

    {
      accessorKey: 'companyName',
      header: 'Company Name',
      size: 200,
    },

    ProjectName,
    ContractName,

    {
      accessorKey: 'requestorName',
      header: 'Requestor Name',
      size: 160,
    },

    {
      accessorKey: 'locationName',
      header: 'Location Name',
      size: 160,
    },

    {
      accessorKey: 'paymentTermName',
      header: 'Payment Name',
      size: 250,
    },
  ];

  useEffect(() => {
    document.title = `Purchase Order  | Procurement`;
  }, []);
  return (
    <DashboardLayout
      title='Purchase Order'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() => navigate(`${location.pathname}/create`)}
        />
      }
    >
      <GlassCard className='h-full-css'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          pagination={pagination}
          sorting={sorting}
          fetchData={fetchData}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          renderTopToolbarLeftChildrens={
            <FilterDropdownStatus
              data={localpurchaseorderstatuses}
              status={statusId}
              setStatus={setStatusId}
            />
          }
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          showSerialNumber={true}
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default StoreLocations;
