import React from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { useLocation, useParams } from 'react-router-dom';
import SnagReportViewDetails from './SnagReportViewDetails.tsx';

const ViewSnagReportLayout = ({ children, title }) => {
  const location = useLocation();
  const params = useParams();
  const trimmedPath = location.pathname.split('/').slice(0, -1).join('/');
  const NavLinkLocal = [
    { name: 'Snag Tab',
       path: `/elv/snag/snag-report/snag-tab/${params?.id}`,
       },
    {
      name: 'Functional Test Comments Tab',
      path: `/elv/snag/snag-report/functional-test-comments-tab/${params?.id}`,
    },
  ];
  
  return (
    <DashboardLayout title={title} hasSubmenu menu={NavLinkLocal}>
      <SnagReportViewDetails />
      {children}
    </DashboardLayout>
  );
};

export default ViewSnagReportLayout;
