import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import BackToButton from '../../../../components/small/BackToButton.jsx';
import { Grid } from '@mui/material';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';

const SnagReportViewDetails = () => {
  const navigation = useNavigate();
  const [viewData,setViewData] = useState([]);
  const location = useLocation();
  const trimmedPath = location.pathname.split('/').slice(0, -1).join('/');
  const { id } = useParams();
  const crud = new Crud_Service();

  const viewDataFetch = async () => {
    crud.getSingle('snagreports',id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
      } else {
        setViewData([]);
      }
    });
  };

  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);


  return (
    <GlassCard className='p-3 mb-4'>
      <BackToButton
        title='Back to List'
        onClick={() => navigation(trimmedPath)}
        className='font-bold mb-3 px-1'
      />

      <Grid container spacing={3}>
        <Grid item md={4}>
          <ViewTable
            rows={[
              {
                label: 'Company code',
                title:  viewData?.companyName,
              },
              {
                label: 'Client',
                title: viewData?.clientName,
              },
              {
                label: 'Division',
                title: viewData?.divisionName,
              },
              {
                label: 'Project Name',
                title: viewData?.projectName,
              },
              {
                label: 'Contract Code',
                title: viewData?.contractName,
              },
            ]}
          />
        </Grid>
        <Grid item md={4}>
          <ViewTable
            rows={[
              {
                label: 'Building',
                title: viewData?.buildingName,
              },
              {
                label: 'Area',
                title: viewData?.areaName,
              },
              {
                label: 'Propery Manager',
                title: viewData?.propertyManager,
              },
              {
                label: 'Inspected By',
                title: viewData?.inspectedBy,
              },
            ]}
          />
        </Grid>
        <Grid item md={4}>
          <ViewTable
            rows={[
              {
                label: 'Created Date',
                title: viewData?.createdDate,
              },
              {
                label: 'Notes',
                title: viewData?.notes,
              },
            ]}
          />
        </Grid>
      </Grid>
    </GlassCard>
  );
};

export default SnagReportViewDetails;
