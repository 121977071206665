import React from 'react';
import { Box, Tooltip } from '@mui/material';

const DataGridHeaderTooltip = ({ name, title }) => {
  return (
    <Tooltip title={title} arrow>
      <Box>{name}</Box>
    </Tooltip>
  );
};

export default DataGridHeaderTooltip;
