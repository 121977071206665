import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import ViewIncidentReportDetails from './ViewIncidentReportDetails.tsx';
import { useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import apiInstance from '../../../../apis/ApiService.jsx';
import { toast } from 'react-toastify';

const ViewIncidentLayout = ({ children, title }) => {
  const { id } = useParams();
  const crud = new Crud_Service();
  const [viewData, setViewData] = useState(null);
  const [image, setImage] = useState(null);

  const NavLinkLocal = [
    { name: 'Incident', path: `/elv/incident-report/incident/${id}` },
    { name: 'Injuries', path: `/elv/incident-report/injuries/${id}` },
    { name: 'Remarks', path: `/elv/incident-report/remarks/${id}` },
  ];

  useEffect(() => {
    getIncidentReports();
  }, []);

  const getIncidentReports = async () => {
    await crud.getSingle('incidentreports', id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
        getSignImage(res?.data);
      } else {
        setViewData(null);
      }
    });
  };

  const getSignImage = async (data) => {
    if (data?.sign) {
      try {
        const imageUrl = await apiInstance.getFiles(
          `files/download/${data?.sign}`
        );

        setImage(imageUrl);
      } catch (err) {
        toast.error('Error fetching image:', err);
      }
    } else {
      toast.error('No image found');
    }
  };

  return (
    <DashboardLayout title={title} hasSubmenu menu={NavLinkLocal}>
      <ViewIncidentReportDetails viewData={viewData} image={image} />
      {children}
    </DashboardLayout>
  );
};

export default ViewIncidentLayout;
