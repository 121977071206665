import React, { useEffect } from 'react';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import Nav from '../../../../../utils/Nav.tsx';
import AddPendingBillsPayments from './AddPendingBillsPayments.tsx';
import ListPendingBillsPayments from './ListPendingBillsPayments.tsx';

const PaymentsPendingBills = () => {
  useEffect(() => {
    document.title = 'Pending Bills - Payments | Cash and Bank | Financial';
  }, []);

  return (
    <DashboardLayout
      title='Pending Bills - Payments'
      hasSubmenu
      menu={Nav[7].child[10].children}
    >
      <AddPendingBillsPayments />
      <ListPendingBillsPayments />
    </DashboardLayout>
  );
};

export default PaymentsPendingBills;
