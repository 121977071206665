import * as React from 'react';
import { Control, Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

interface BasicTimePickerProps {
  control: Control<any>;
  name: string;
  label?: string;
  defaultValue?: Dayjs | null;
  required?: boolean;
  width?: string;
}

const FmTimePicker: React.FC<BasicTimePickerProps> = ({
  control,
  name,
  label = 'Select Time',
  defaultValue,
  required = false,
  width = '100%',
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || dayjs()}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <TimePicker
            label={label}
            value={
              value
                ? dayjs(value, 'HH:mm:ss').isValid()
                  ? dayjs(value, 'HH:mm:ss')
                  : dayjs()
                : dayjs()
            }
            onChange={(newValue) => {
              if (newValue) {
                onChange(newValue.format('HH:mm:ss'));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={ref}
                error={!!error}
                helperText={error ? error.message : null}
                required={required}
                sx={{ width }}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default FmTimePicker;
