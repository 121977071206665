import React, { useEffect } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import InspectionViewDetails from './InspectionViewDetails.tsx';
import BuildInspectionChecklist from './BuildInspectionChecklist.tsx';
import EditInspectionReport from './EditInspectionreport.tsx';

const ViewInspectionReport = () => {
  useEffect(() => {
    document.title = 'View | Inspection Report | CAFM';
  });
  return (
    <DashboardLayout title='View Details'>
      <EditInspectionReport />
      {/* <InspectionViewDetails /> */}
      <BuildInspectionChecklist />
    </DashboardLayout>
  );
};
export default ViewInspectionReport;
