import React from 'react';
import { Box, IconButton } from '@mui/material';

const MRTIconButton = ({ title, icon, error, ...props }) => {
  return (
    <Box>
      <IconButton color={error && 'error'} {...props}>
        {icon}
      </IconButton>
    </Box>
  );
};

export default MRTIconButton;
