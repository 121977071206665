import React, { useEffect } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../components/_form';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import { set, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { contractSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';

const gridValue = 2.4;

const CreateContracts = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    resolver: yupResolver(contractSchema),
    mode: 'onChange',
  });
  const selectedProjectId = watch('projectId');

  useEffect(() => {
    const selectedProject = projects.find(
      (project) => project?.projectId === selectedProjectId
    );
    if (selectedProject) {
      const projectEndDate = new Date(selectedProject.deliveryDate);
      const today = new Date();
      if (projectEndDate < today) {
        setValue('contractEndDate', today);
      } else {
        setValue('contractEndDate', projectEndDate);
      }
    }
  }, [selectedProjectId, setValue]);
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  useTranslation();
  const { data: contractmodes } = useCommonFetchApi(
    'contracts/contract-modes',
    {
      sortDirection: 'desc',
      sortBy: 'contractModeName',
    }
  );
  const { data: projects } = useCommonFetchApi('projects', {
    sortDirection: 'asc',
    sortBy: 'projectName',
    tenderStatusId: 4,
  });
  const { data: clients } = useCommonFetchApi('clients', {
    sortDirection: 'asc',
    sortBy: 'clientName',
  });
  const { data: contracttypes } = useCommonFetchApi('contracttypes', {
    sortDirection: 'asc',
    sortBy: 'contractTypeName',
  });
  const { data: contractscopes } = useCommonFetchApi('contractscopes');
  const { data: billingfrequencies } = useCommonFetchApi('billingfrequencies');
  const { data: guarantees } = useCommonFetchApi('contracts/guarantees', {
    sortDirection: 'asc',
    sortBy: 'guaranteeName',
  });
  const { data: currencies } = useCommonFetchApi('currencies');

  const handleSubmitProject = async (values) => {
    startCreateLoading();

    const CombinedData = {
      ...values,
      contractStartDate: dayjs(values?.contractStartDate).format('YYYY-MM-DD'),
      contractEndDate: dayjs(values?.contractEndDate).format('YYYY-MM-DD'),
      guaranteeStartDate: dayjs(values?.guaranteeStartDate).format(
        'YYYY-MM-DD'
      ),
      guaranteeEndDate: dayjs(values?.guaranteeEndDate).format('YYYY-MM-DD'),
      expectedContractNumber: values?.expectedContractNumber,
      contractScopeIds: values?.contractScopeId,
    };

    await crud.create(`contracts`, CombinedData, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Contract Created Successfully');
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create New Contract'>
      <GlassCard className='p-4'>
        <form onSubmit={handleSubmit(handleSubmitProject)}>
          <Grid container spacing={2}>
            <Grid item md={gridValue}>
              <FmTextField
                name='contractName'
                control={control}
                required
                label='Contract Name'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='expectedContractNumber'
                control={control}
                label='Contract Code'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                name='projectId'
                control={control}
                label='Projects'
                required
                options={projects}
                optionFields={['projectName']}
                displayField='projectName'
                valueKey='projectId'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                name='clientId'
                control={control}
                label='Clients'
                required
                options={clients}
                optionFields={['clientName']}
                displayField='clientName'
                valueKey='clientId'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmDatePicker
                name='contractStartDate'
                control={control}
                label='Contract Start Date'
                minDate={dayjs(new Date())}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmDatePicker
                name='contractEndDate'
                control={control}
                label='Contract End Date'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='contactPerson'
                control={control}
                required
                label='Contact Person'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='contactNumber'
                control={control}
                required
                label='Contact Number'
                pattern='Number'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='contactEmailId'
                required
                control={control}
                label='Contact Email'
                type='email'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='contractCost'
                label='Contract Cost'
                control={control}
                required
                pattern='Number'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='budget'
                required
                label='Budget'
                control={control}
                pattern='Number'
              />
            </Grid>

            <Grid item md={gridValue}>
              <FmTextField
                name='contractTerms'
                label='Contract Terms'
                control={control}
                required
                multiline={true}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='perviousContractCode'
                control={control}
                label='Pervious Contract Code'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                name='contractModeId'
                control={control}
                required
                label='Contract Mode'
                options={contractmodes}
                optionFields={['contractModeName']}
                displayField='contractModeName'
                valueKey='contractModeId'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='referenceNumber'
                control={control}
                label='Reference Number'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                name='contractTypeId'
                control={control}
                required
                label='Contract Types'
                options={contracttypes}
                optionFields={['contractTypeName']}
                displayField='contractTypeName'
                valueKey='contractTypeId'
              />
            </Grid>
            <Grid item md={gridValue * 2}>
              <FmAutoComplete
                name='contractScopeIds'
                control={control}
                label='Contract Scopes'
                options={contractscopes}
                isMultiSelect
                optionFields={['contractScopeName']}
                displayField='contractScopeName'
                valueKey='contractScopeId'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                name='billingFrequencyId'
                control={control}
                label='Billing Frequency'
                options={billingfrequencies}
                optionFields={['billingFrequencyName']}
                displayField='billingFrequencyName'
                valueKey='billingFrequencyId'
                required
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='thresholdValue'
                control={control}
                label='Threshold Value'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                name='currencyId'
                control={control}
                label='Currrencies'
                options={currencies}
                optionFields={['currencyName']}
                displayField='currencyName'
                valueKey='currencyId'
              />
            </Grid>
            <Grid item md={gridValue * 2}>
              <FmAutoComplete
                name='guaranteeIds'
                control={control}
                label='Guarantees'
                options={guarantees}
                //  isMultiSelect
                optionFields={['guaranteeName']}
                displayField='guaranteeName'
                valueKey='guaranteeId'
                isMultiSelect
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmDatePicker
                name='guaranteeStartDate'
                control={control}
                onChangeProp={(e) =>
                  setValue('guaranteeEndDate', dayjs(e).add(1, 'day'))
                }
                label='Guarantee Start Date'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmDatePicker
                name='guaranteeEndDate'
                control={control}
                label='Guarantee End Date'
                defaultValue={
                  watch('guaranteeStartDate')
                    ? dayjs(watch('guaranteeStartDate')).add(1, 'day')
                    : dayjs(new Date()).add(1, 'day')
                }
                minDate={
                  watch('guaranteeStartDate')
                    ? dayjs(watch('guaranteeStartDate')).add(1, 'day')
                    : dayjs(new Date()).add(1, 'day')
                }
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='guaranteeAmount'
                control={control}
                label='Guarantee Amount'
              />
            </Grid>
            <Grid item md={gridValue * 3}>
              <FmTextField
                name='remarks'
                control={control}
                label='Remarks'
                multiline={true}
                rows={2}
                maxRows={3}
              />
            </Grid>
          </Grid>

          <ActionButtons
            onSubmit={handleSubmit(handleSubmitProject)}
            onCancel={() => navigate(-1)}
            onReset={reset}
            submitLoading={createLoading}
            cancelLoading={false}
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default CreateContracts;
