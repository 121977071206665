//TODO
// import React, { useEffect, useState } from "react";
// import { Box } from "@mui/material";
// import { Icon } from "@iconify/react/dist/iconify.js";

// const ProgressData = [
//   {
//     id: 1,
//     name: "Sales Quotation",
//     icon: <Icon icon="fluent:book-add-20-filled" />,
//     activeState: 0,
//   },
//   {
//     id: 2,
//     name: "Sales Order",
//     icon: <Icon icon="fluent:book-database-20-filled" />,
//     activeState: 0,
//   },
//   {
//     id: 3,
//     name: "MR Generated",
//     icon: <Icon icon="fluent:notebook-sync-20-filled" />,
//     activeState: 0,
//   },
//   {
//     id: 4,
//     name: "Waiting MR for Approval",
//     icon: <Icon icon="fluent:record-stop-16-filled" />,
//     activeState: 0,
//   },
//   {
//     id: 5,
//     name: "MR Approved",
//     icon: <Icon icon="fluent:document-table-checkmark-20-filled" />,
//     activeState: 0,
//   },
//   {
//     id: 6,
//     name: "LPO Preview Generated",
//     icon: <Icon icon="fluent:content-view-gallery-16-filled" />,
//     activeState: 0,
//   },
//   {
//     id: 7,
//     name: "RFQ",
//     icon: <Icon icon="fluent:notepad-sparkle-16-filled" />,
//     activeState: 0,
//   },
//   {
//     id: 8,
//     name: "RFQ Approval",
//     icon: <Icon icon="fluent:radar-checkmark-20-filled" />,
//     activeState: 0,
//   },
//   {
//     id: 9,
//     name: "LPO Generated",
//     icon: <Icon icon="fluent:arrow-trending-sparkle-20-filled" />,
//     activeState: 0,
//   },
//   {
//     id: 10,
//     name: "LPO Accepted by Vendor",
//     icon: <Icon icon="fluent:checkmark-starburst-16-filled" />,
//     activeState: 0,
//   },
//   {
//     id: 11,
//     name: "Practically/Fully Delivered",
//     icon: <Icon icon="fluent:flag-48-filled" />,
//     activeState: 0,
//   },
// ];

// const ProgressCircleStep = ({ numElements = 0,data }) => {
//   const [progressValue, setProgressValue] = useState([]);

//   const updatedProgressData = ProgressData.map((progressItem,ind) => {
//     // Find the matching item in `data` by comparing `statusName` and `name`
//     const matchedDataItem = data.find((dataItem) => dataItem.statusName === progressItem.name);

//     // If a match is found, update the `activeState` based on the `status`
//     if (matchedDataItem) {
//       return {
//         ...progressItem,
//         activeState: matchedDataItem.status ? 1 : 0,
//       };
//     }

//     // If no match is found, return the original `progressItem`
//     return progressItem;
//   });

//   const updateActiveState = (data, numElements) => {
//     return data.map((item, index) => {
//       if (index < numElements) {
//         return { ...item, activeState: 1 };
//       }
//       return item;
//     });
//   };

//   useEffect(() => {
//     document.title = `Create Purchase Order View | Procurement`;
//     const updatedProgressData = updateActiveState(ProgressData, numElements);
//     setProgressValue(updatedProgressData);
//   }, []);

//   return (
//     <Box className="mb-8 flex justify-between">
//       {progressValue.map((itm, i) => {
//         return (
//           <Box
//             className="flex flex-col items-center text-center w-full relative"
//             key={itm.id}
//           >
//             <Box
//               className={`dividers absolute ${
//                 progressValue.length === itm.id && "hidden"
//               }`}
//               sx={{
//                 borderTop: "3px dashed",
//                 borderColor:
//                   itm.activeState === 1 ? "primary.main" : "border.main",
//                 left: "50%",
//                 width: "100%",
//                 top: "35px",
//               }}
//             ></Box>
//             <Box
//               sx={{
//                 width: "65px",
//                 height: "65px",
//                 bgcolor:
//                   itm.activeState === 1 ? "primary.main" : "background.white",
//                 borderRadius: "50%",
//                 border: "1px solid",
//                 borderColor:
//                   itm.activeState === 1 ? "primary.main" : "border.main",
//               }}
//               className="flex justify-center items-center relative"
//             >
//               {itm.activeState === 1 ? (
//                 <Box
//                   className="flex justify-center items-center text-4xl"
//                   sx={{ color: "text.white" }}
//                 >
//                   {itm.icon}
//                 </Box>
//               ) : (
//                 <Box className="font-bold">{itm.id}</Box>
//               )}
//             </Box>
//             <h3
//               className={`font-semibold mt-3 ${
//                 itm.activeState === 0 && "opacity-50"
//               } `}
//             >
//               {itm.name}
//             </h3>
//           </Box>
//         );
//       })}
//     </Box>
//   );
// };

// export default ProgressCircleStep;
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react';

const ProgressData = [
  {
    id: 1,
    name: 'Sales Quotation',
    icon: <Icon icon='fluent:book-add-20-filled' />,
    activeState: 0,
  },
  {
    id: 2,
    name: 'Sales Order',
    icon: <Icon icon='fluent:book-database-20-filled' />,
    activeState: 0,
  },
  {
    id: 3,
    name: 'MR Generated',
    icon: <Icon icon='fluent:notebook-sync-20-filled' />,
    activeState: 0,
  },
  {
    id: 4,
    name: 'Waiting MR for Approval',
    icon: <Icon icon='fluent:record-stop-16-filled' />,
    activeState: 0,
  },
  {
    id: 5,
    name: 'MR Approved',
    icon: <Icon icon='fluent:document-table-checkmark-20-filled' />,
    activeState: 0,
  },
  {
    id: 6,
    name: 'LPO Preview Generated',
    icon: <Icon icon='fluent:content-view-gallery-16-filled' />,
    activeState: 0,
  },
  {
    id: 7,
    name: 'RFQ',
    icon: <Icon icon='fluent:notepad-sparkle-16-filled' />,
    activeState: 0,
  },
  {
    id: 8,
    name: 'RFQ Approval',
    icon: <Icon icon='fluent:radar-checkmark-20-filled' />,
    activeState: 0,
  },
  {
    id: 9,
    name: 'LPO Generated',
    icon: <Icon icon='fluent:arrow-trending-sparkle-20-filled' />,
    activeState: 0,
  },
  {
    id: 10,
    name: 'LPO Accepted by Vendor',
    icon: <Icon icon='fluent:checkmark-starburst-16-filled' />,
    activeState: 0,
  },
  {
    id: 11,
    name: 'Practically/Fully Delivered',
    icon: <Icon icon='fluent:flag-48-filled' />,
    activeState: 0,
  },
];

const ProgressCircleStep = ({ apiresponse }) => {
  const [progressValue, setProgressValue] = useState(ProgressData);

  // useEffect(() => {

  //   const updatedProgressData = ProgressData.map((progressItem) => {
  //     const matchedApiItem = apiresponse.find(
  //       (apiItem) => apiItem.id === progressItem.id
  //     );

  //     if (matchedApiItem) {
  //       return {
  //         ...progressItem,
  //         activeState: matchedApiItem.status ? 1 : 0,
  //       };
  //     }

  //     return progressItem;
  //   });

  //   setProgressValue(updatedProgressData);
  // }, [apiresponse]);

  return (
    <Box className='mb-8 flex justify-between'>
      {apiresponse?.map((itm, ind) => (
        <Box
          className='flex flex-col items-center text-center w-full relative'
          key={itm.id}
          sx={{
            '&:last-child': {
              '& .dividers': {
                display: 'none',
              },
            },
          }}
        >
          <Box
            className={`dividers absolute ${
              apiresponse.length === itm.id && 'hidden'
            }`}
            sx={{
              borderTop: '3px dashed',
              borderColor: 'border.main',
              left: '50%',
              width: '100%',
              top: '23px',
            }}
          ></Box>
          <Box
            sx={{
              width: '45px',
              height: '45px',
              bgcolor:
                itm.status === true ? 'secondary.main' : 'background.white',
              borderRadius: '50%',
              border: '1px solid',
              borderColor:
                itm.status === true ? 'secondary.main' : 'border.main',
            }}
            className='flex justify-center items-center relative'
          >
            <Box
              className={`flex justify-center items-center text-3xl ${
                itm.status === false && 'opacity-50'
              }`}
              sx={{ color: itm.status && 'text.white' }}
            >
              {itm.status ? (
                <Icon icon='mage:message-check-round-fill' />
              ) : (
                <Icon icon='material-symbols-light:timelapse-outline-rounded' />
              )}
            </Box>
          </Box>
          <h3
            className={`font-semibold mt-3 ${
              itm.status === false && 'opacity-50'
            }`}
          >
            {itm.statusName}
          </h3>
        </Box>
      ))}
    </Box>
  );
};

export default ProgressCircleStep;
