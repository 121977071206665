import React from 'react';
import { Box, IconButton, Tooltip, alpha } from '@mui/material';
import { Icon } from '@iconify/react';

const DragAndDropBox = ({ name, id, onClick, icon }) => {
  return (
    <Box
      className='flex w-full items-center'
      sx={{
        border: '1px solid',
        borderColor: 'border.main',
        borderRadius: '5px',
        overflow: 'hidden',
        cursor: 'pointer',
        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
        '&:hover': {
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.25),
        },
      }}
      onClick={onClick}
    >
      <Box className='font-semibold flex-1 px-3 py-1'>{name}</Box>
      <Box
        sx={{ width: '40px', height: '40px', fontSize: '20px' }}
        className='flex justify-center items-center'
      >
        <Icon icon='ic:twotone-plus' />
      </Box>
    </Box>
  );
};

const DragCards = ({
  name,
  onDelete,
  onAdd,
  childData,
  id,
  icon,
  edit,
  type,
}) => {
  return (
    <Box className='flex w-full items-center'>
      <Box className='font-semibold flex-1 me-2'>
        <Box sx={{ color: type === 'document' ? '#032cfc' : 'yellow' }}>
          {name}
        </Box>
        {childData?.length > 0 && (
          <Tooltip
            title={
              <Box sx={{ padding: '15px' }}>
                {childData.map((child) => (
                  <Box key={child.documentId || child.name}>
                    {child.documentName || child.name}
                  </Box>
                ))}
              </Box>
            }
            arrow
            slotProps={{
              tooltip: {
                sx: {
                  bgcolor: 'background.white',
                  color: 'text.main',
                  boxShadow: 10,
                },
              },
              arrow: {
                sx: { color: 'text.white' },
              },
            }}
          >
            <Box
              className='font-light overflow-hidden text-ellipsis whitespace-nowrap'
              sx={{ fontSize: '12px', width: '230px' }}
            >
              {childData
                .map((child) => child.documentName || child.name)
                .join(', ')}
            </Box>
          </Tooltip>
        )}
      </Box>
      {edit && (
        <Box className='flex items-center'>
          <IconButton onClick={onDelete}>
            <Icon icon='ic:baseline-delete-outline' />
          </IconButton>
          <IconButton onClick={() => onAdd(id)}>
            <Icon icon='ic:baseline-playlist-add' />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export { DragAndDropBox, DragCards };
