import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import { StatusBadge } from '../../../components/_form/FormElements';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import DataTable from '../../../components/DataTable.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import PageHeader from '../../../components/PageHeader';
import { CreateButton } from '../../../components/small/Buttons';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import { Nav } from '../../../utils';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';

const TaxCodeMasters = (props) => {
  const { type } = props;
  const [openCreate, setOpenCreate] = useState(false);
  const [loading1, setLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [viewDetails, setViewdetails] = useState(false);
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();

  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('taxcodes');

  const crud = new Crud_Service();
  const { register, handleSubmit, setValue, control, reset } = useForm();
  const { t } = useTranslation();

  const handleSubmitTaxCode = async (values) => {
    startCreateLoading();
    await crud.create('taxcodes', values, (err, res) => {
      if (res?.status === 201) {
        stopCreateLoading();
        setOpenCreate(false);
        toast.success('Tax Code created successfully');
        fetchData();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };

  const handleCreateClose = async () => {
    reset();
    setOpenCreate(false);
  };

  const handleViewClickOpen = async (props) => {
    setViewdetails(props);
  };

  const handleEditOpen = async (props) => {
    setOpenEdit(true);
    setLoader(true);
    await crud.getSingle('taxcodes', props?.taxCodeId, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setSelectedRow(res?.data);
        setLoader(false);
      } else {
        setLoader(true);
      }
    });
  };

  const handleEditTaxCode = async (values) => {
    startEditLoading();
    await crud.update(
      'taxcodes',
      selectedRow?.taxCodeId,
      values,
      (err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          setOpenEdit(false);
          toast.success('Tax Code updated successfully');
          fetchData();
        } else {
          stopEditLoading();
          setOpenEdit(true);
        }
      }
    );
  };

  const handleDelete = async (props) => {
    await crud.remove('taxcodes', props?.taxCodeId, (err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Tax Code deleted successfully');
      } else {
      }
    });
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props, ev) => handleViewClickOpen(props, ev),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditOpen(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
          />
        );
      },
    },
    {
      field: 'taxCodeCode',
      headerName: 'Code',
      flex: 1,
    },
    {
      field: 'taxCodeName',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'taxCodeValue',
      headerName: 'Tax Code Value',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params?.value === 2 ? 'Active' : 'Inactive'}
            type={params?.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const excludeKeys = ['taxCodeId', 'status'];

  return (
    <DashboardLayout
      title='Tax Code'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={Nav[6].child[5].children}
      parentMenu={Nav[6].child[5].name}
    >
      {
        <GlassCard className='h-full-css'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={isLoading}
              getRowClassName={(params) =>
                `${params.row.status === 0 && 'bg-red-50'}`
              }
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) =>
                handlePaginationModelChange(model)
              }
              onSortModelChange={(model) => handleSortModelChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tableRecordCounts?.total}
              slots={{
                toolbar: () => (
                  <Box
                    className='p-2 w-full flex justify-between items-center'
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'border.main',
                    }}
                  >
                    <Box sx={{ maxWidth: '250px' }}>
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />
                    </Box>
                    <CreateButton name='New' onClick={handleCreateClickOpen} />
                  </Box>
                ),
              }}
            />
          </Box>
        </GlassCard>
      }

      {/* create  */}
      <MuiDialogOne
        title='Create'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitTaxCode)}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FmTextField
                name='taxCodeName'
                control={control}
                label='Tax Code Name'
              />
            </Grid>
            <Grid item md={12}>
              <FmTextField
                name='taxCodeValue'
                control={control}
                label='Tax Code Value'
              />
            </Grid>

            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitTaxCode)}
                onCancel={handleCreateClose}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && viewDetails?.taxCodeId && (
          <CommonView
            url='taxcodes'
            id={viewDetails?.taxCodeId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>

      {/*EDIT*/}
      <MuiDialogOne
        title='Edit'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {loading1 ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditTaxCode)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='taxCodeName'
                  control={control}
                  label='Tax Code Name'
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='taxCodeValue'
                  control={control}
                  label='Tax Code Value'
                />
              </Grid>

              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditTaxCode)}
                  onCancel={() => setOpenEdit(false)}
                  onReset={reset}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default TaxCodeMasters;
