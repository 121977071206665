import React, { CSSProperties } from 'react';
import useDateFormatter from '../helpers/useDateFormatter';
import { ViewField } from '../_form/FormElements';

interface FmViewDateFieldProps {
  title?: string;
  value: string;
  valueColor?: string;

  style?: CSSProperties;

  color?: string;
}
const FmViewDateField = (props: FmViewDateFieldProps) => {
  const { title, value, style, color, valueColor } = props;

  const formateDate = useDateFormatter();

  const dateFieldStyles: CSSProperties = {
    color: color || '',
    ...style,
  };

  return (
    <span style={dateFieldStyles}>
      {
        <ViewField
          label={title}
          title={value && String(formateDate(value))}
          valueColor={valueColor}
        />
      }
    </span>
  );
};

export default FmViewDateField;
