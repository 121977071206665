import React, { useEffect } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import AddCashBankReceipt from './AddCashBankReceipt.tsx';
import ListCashBankReceipt from './ListCashBankReceipt.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';

const CashBankReceipts = (props) => {
  const { type } = props;
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`financialtransactions?typeId=${type}`);


  useEffect(() => {
    document.title = type === 1 ? 'Receipts | Cash and Bank | Financial' :
      type === 2 ? 'Payments | Cash and Bank' :
        'Petty Cash | Cash and Bank';
  });

  useEffect(() => {
    fetchData();
  }, [type]);

  return (
    <DashboardLayout

      title={`${type === 1 ? 'Receipt' : type === 2 ? 'Payments' : 'Petty Cash'}`}
      hasSubmenu
      menu={Nav[7].child[10].children}
    >
      <AddCashBankReceipt type={type} fetchData={fetchData} />
      <ListCashBankReceipt
        rows={rows}
        tableRecordCounts={tableRecordCounts}
        pagination={pagination}
        sorting={sorting}
        fetchData={fetchData}
        setPagination={setPagination}
        setSorting={setSorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </DashboardLayout>
  );
};

export default CashBankReceipts;
