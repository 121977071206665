import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box, CircularProgress, Grid } from '@mui/material';
import DataTable from '../../../components/DataTable.tsx';
import { Icon } from '@iconify/react';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import {
  StatusBadge,
  ViewField,
} from '../../../components/_form/FormElements.jsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { toast } from 'react-toastify';

const Projects = () => {
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showView, setShowView] = useState(false);
  const crud = new Crud_Service();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('projects');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = `HRMS-Manage Projects`;
  }, []);

  //setvalues to the Inputs
  const handleEditClick = (props) => {
    navigate(`edit/${props?.projectId}`);
  };

  //view the ShiftTiming
  const handleViewClick = async (view) => {
    navigate(`view/${view?.projectId}`);
  };

  //handle Delete
  const handleDelete = async (props) => {
    await crud.remove('projects', props?.projectId, (_err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Project Deleted successfully');
      } else {
        toast.error('Something went wrong');
      }
    });
  };

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 120,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'tenderName',
      headerName: 'Tender Name',
      minWidth: 150,
    },
    {
      field: 'projectCode',
      headerName: 'Project Code',
      minWidth: 150,
    },

    {
      field: 'projectName',
      headerName: 'Project Name',
      minWidth: 230,
    },
    {
      field: 'deliveryDate',
      headerName: 'Delivery Date',
      minWidth: 230,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'projectInChargeName',
      headerName: 'Project In Charge Name',
      minWidth: 220,
    },
    {
      field: 'projectLocation',
      headerName: 'Project Location',
      minWidth: 230,
    },
    {
      field: 'clientName',
      headerName: 'Client Name',
      minWidth: 200,
    },
    {
      field: 'divisionName',
      headerName: 'Division Name',
      minWidth: 150,
    },
    {
      field: 'projectTypeName',
      headerName: 'Project Type',
      minWidth: 150,
    },
    {
      field: 'projectValue',
      headerName: 'Project Value',
      minWidth: 150,
    },
    {
      field: 'incentive',
      headerName: 'Project Incentive',
      minWidth: 150,
    },

    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params?.value === 2 ? 'Active' : 'Inactive'}
            type={params?.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <>
      <DashboardLayout
        title='Manage Projects'
        removeSpace
        actionButtons={
          <CreateButton name='New' onClick={() => navigate('create')} />
        }
      >
        <>
          {/* Listing */}
          <GlassCard className='h-full-css border-0 rounded-none'>
            <Box className='h-full-css'>
              <DataTable
                rows={rows}
                columns={columns}
                loading={isLoading}
                onRowDoubleClick={(e) => onRowDoubleClick(e)}
                getRowClassName={(params) =>
                  `${
                    params.row.workingStatus === 'InActive' &&
                    'bg-red-500 bg-opacity-15'
                  }`
                }
                sortingMode='server'
                paginationMode='server'
                onPaginationModelChange={(model) =>
                  handlePaginationModelChange(model)
                }
                onSortModelChange={(model) => handleSortModelChange(model)}
                page={pageNumber - 1}
                pageSize={pageSize}
                rowCount={tableRecordCounts?.total}
                slots={{
                  toolbar: () => (
                    <Box
                      className='p-2 w-full flex justify-between items-center'
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: 'border.main',
                      }}
                    >
                      <Box sx={{ maxWidth: '250px', width: '100%' }}>
                        <TableSearch
                          placeholder='Search'
                          fullWidth
                          setSearchKeyword={setSearchKeyword}
                          searchValue={searchKeyword}
                        />
                      </Box>
                    </Box>
                  ),
                }}
              />
            </Box>
          </GlassCard>
          {/* Listing */}
        </>
      </DashboardLayout>
    </>
  );
};

export default Projects;
