import React, { useState, useEffect } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Icon } from '@iconify/react';
import UploadSquareBoxes from '../../../../components/_form/UploadSquareBoxes.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Controller, set } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../apis/CrudService';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import CommonLoader from '../../../../components/page/CommonLoader';
import { ActionButtons } from '../../../../components/_form';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';

const AddMaterialRequest = ({
  control,
  setValue,
  getValues,
  reset,
  handleSubmit,
  location,
  region,
  loading,
  editedData,
}) => {
  const [components, setComponents] = useState([]);
  const [counter, setCounter] = useState(1);
  const [imageUrl, setImageUrl] = useState([]);
  const [regionId, setRegionId] = useState(null);
  const { id } = useParams();
  const { createLoading, stopCreateLoading, startCreateLoading } = useLoader();
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [data, setData] = useState({
    locations: [],
    regions: [],
    companies: [],
    projects: [],
    mrType: [],
    departments: [],
    unitNo: [],
    contracts: [],
    requester: [],
    suppplierSPOC: [],
    suppliers: [],
    getMaterialStatus: [],
    storeLocation: [],
    mrStatus: [],
  });

  const [imageLoading, setImageLoading] = useState(
    components.reduce((acc, component) => {
      acc[component.id] = false; // Initialize loading state for each component
      return acc;
    }, {})
  );

  const handleSpocChange = (newValue) => {
    setValue('spocContactNo', newValue?.phoneNumber);
    setValue('spocEmailId', newValue?.email);
  };

  useEffect(() => {
    getLocations();
    getRegions();
    getCompanies();
    getProjects();
    getMrType();
    getDepartment();
    getContracts();
    getRequester();
    getSupplierSPOC();
    getSuppliers();
    getMaterialStatus();
    getMRStatus();
  }, []);

  useEffect(() => {
    location && region && getStoreLocation();
  }, [location, region]);

  const getStoreLocation = async () => {
    await crud.getAll(
      'storelocations',
      { regionId: region, locationId: location },
      (err, res) =>
        err
          ? toast.error(err)
          : setData((prevData) => ({
              ...prevData,
              storeLocation: res?.data?.data,
            }))
    );
  };
 

  const getSupplierSPOC = async () => {
    await crud.getAll('supplierspocs', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({
          ...prevData,
          suppplierSPOC: res?.data?.data,
        }));
      }
    });
  };

  const handlePrefferedSupplier = async (newValue) => {
    await crud.getAll('supplierspocs', newValue?.supplierId, (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({
          ...prevData,
          suppplierSPOC: res?.data?.data,
        }));
      }
    });
  };

  const getMaterialStatus = async () => {
    await crud.getAll('materialrequisitionstatuses', id, (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({
          ...prevData,
          getMaterialStatus: res?.data?.data,
        }));
      }
    });
  };

  const getSuppliers = async () => {
    await crud.getAll('suppliers', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, suppliers: res?.data?.data }));
      }
    });
  };

  const getRequester = async () => {
    await crud.getAll('loginusers', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, requester: res?.data?.data }));
      }
    });
  };
  const getContracts = async () => {
    await crud.getAll('contracts', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, contracts: res?.data?.data }));
      }
    });
  };
  const getDepartment = async () => {
    await crud.getAll('divisions', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, departments: res?.data?.data }));
      }
    });
  };
  const getMrType = async () => {
    await crud.getAll('materialrequisitiontypes', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({
          ...prevData,
          mrType: res?.data?.data,
          unitNo: Array.isArray(res?.data?.data?.materialRequisitionItems)
            ? res?.data?.data?.materialRequisitionItems
            : [],
        }));
      }
    });
  };
  const getLocations = async () => {
    await crud.getAll('locations', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, locations: res?.data?.data }));
      }
    });
  };
  const getRegions = async () => {
    await crud.getAll('regions', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, regions: res?.data?.data }));
      }
    });
  };

  const getCompanies = async () => {
    await crud.getAll('companies', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, companies: res?.data?.data }));
      }
    });
  };
  const getProjects = async () => {
    await crud.getAll('projects', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, projects: res?.data?.data }));
      }
    });
  };
  const getMRStatus = async () => {
    await crud.getAll('materialrequisitionstatuses', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setData((prevData) => ({ ...prevData, mrStatus: res?.data?.data }));
      }
    });
  };
  const handleAddComponent = () => {
    setComponents([...components, { id: counter }]);
    setCounter(counter + 1);
  };

  const handleRemoveComponent = (id) => {
    setComponents(components.filter((component) => component.id !== id));
    setImageUrl((prev) => {
      const updatedUrls = { ...prev };
      delete updatedUrls[id];
      return updatedUrls;
    });
  };

  const handleFileChange = (id, fileId) => {
    if (typeof fileId === 'number') {
      const currentFileIds = getValues('fileUploadIds') || [];
      const updatedFileIds = [...currentFileIds, fileId];
      setValue('fileUploadIds', updatedFileIds);
    }
  };

  const onSubmit = async (data) => {
    startCreateLoading();
    const combinedData = {
      ...data,
      requestDate: data?.requestDate
        ? dayjs(data?.requestDate).format('YYYY-MM-DD')
        : null,
      requestedDeliveryDate: data?.requestedDeliveryDate
        ? dayjs(data?.requestedDeliveryDate).format('YYYY-MM-DD')
        : null,
      mrTypeNo: data?.mrTypeNo ? data?.mrTypeNo : null,
    };

    if (id) {
      await crud.update(
        'materialrequisitions',
        id,
        combinedData,
        (err, res) => {
          if (err) {
            stopCreateLoading();
            toast.error('Something went wrong:', err);
            return;
          }
          if (res?.status === 200) {
            toast.success('Material Request Updated successfully');
            navigate(-1);
            stopCreateLoading();
          } else {
            stopCreateLoading();
            toast.error('Something Went Wrong TestingEdit!');
          }
        }
      );
    } else {
      await crud.create('materialrequisitions', combinedData, (err, res) => {
        if (res?.status === 201) {
          toast.success('Material Request Created successfully');
          navigate(-1);
          stopCreateLoading();
        } else {
          stopCreateLoading();
          toast.error('Something Went Wrong Testing Create!');
        }
      });
    }
  };
  const [materialReqType,setMaterialReq]=useState(null)
  const [labelType, setLabelType] = useState(null);
  const [valueType, setValueType] = useState(null);
  const [apiRequest,setApiRequest]=useState(null)
  const [disablematerialReqType,setDisablematerialReqType]=useState(false)
  //TODO this will be change after proper api request
  useEffect(() => {
    switch (materialReqType) {
      case 1:
        setDisablematerialReqType(false)
        setLabelType('salesOrderName');
        setValueType('salesOrderId');
        setApiRequest('salesorders')
        break;
      case 15:
      //TODO
      // case 'helpdesks':
        setDisablematerialReqType(false)
        setLabelType('workOrderName');
        setValueType('workOrderId');
        setApiRequest('workorders')
        break;
      default:
        setLabelType(null);
        setValueType(null);
        setDisablematerialReqType(true);
    }
  }, [materialReqType]); 


  return (
    <GlassCard className='p-8'>
      <Box className={'mb-4'}>
        <Grid spacing={2} container>
          {loading ? (
            <CommonLoader />
          ) : (
            <form onSubmit={onSubmit}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={3}>
                <Grid item md={3}>
                    <FmSearchableSelect
                    name='materialRequisitionTypeId'
                    control={control}
                    apiUrl='materialrequisitiontypes'
                    valueField='materialRequisitionTypeID'
                    headerField={['Code', 'Name']}
                    labelField={['materialRequisitionTypeName']}
                    showField={['materialRequisitionTypeName']}
                    pageSize={20}
                    label={'MR Type'}
                    onChangeProp={(value) => {
                      //TODO this will be change after proper api request
                      setMaterialReq(value?.materialRequisitionTypeID);
                    }}
                    onChange={true}
                    defaultValue={{
                      materialRequisitionTypeId:editedData?.materialRequisitionTypeId||'',
                      materialRequisitionTypeName:editedData?.materialRequisitionTypeName||'',}}
                  />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                    name='mrTypeNo'
                    control={control}
                    apiUrl={`${apiRequest}`}
                    valueField={valueType}
                    headerField={['Code', 'Name']}
                    labelField={[labelType]}
                    showField={[labelType]}
                    pageSize={20}
                    label={'Service/Jobs'}
                    disabled={disablematerialReqType}
                    defaultValue={{
                      mrTypeNo:editedData?.mrTypeNo||'',
                      [labelType]:editedData?.[labelType]||'',
                    }}
                  />
                  </Grid>
                  <Grid item md={3}>
                    <FmTextField
                      name='manualMRCode'
                      control={control}
                      label='Manual MR Code'
                      rules={{ required: 'Code is required' }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='requestDate'
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label='Request Date'
                          value={value ? dayjs(value) : null}
                          format='DD/MM/YYYY'
                          onChange={(newValue) => {
                            onChange(newValue);
                          }}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                      name='companyId'
                      control={control}
                      apiUrl='companies'
                      valueField='companyId'
                      headerField={['Code', 'Name']}
                      labelField={['companyName']}
                      showField={['companyName']}
                      pageSize={20}
                      label={'Companies'}
                      defaultValue={{
                        companyId:editedData?.companyId||'',
                        companyName:editedData?.companyName||'',
                      }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                      name='projectId'
                      control={control}
                      apiUrl='projects'
                      valueField='projectId'
                      headerField={['Code', 'Name']}
                      labelField={['projectName']}
                      showField={['projectName']}
                      pageSize={20}
                      label={'Projects'} 
                      defaultValue={{ projectId:editedData?.projectId||'', projectName:editedData?.projectName||'' }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmAutoComplete
                      name='contractId'
                      control={control}
                      options={data?.contracts}
                      label='Contract'
                      displayField='contractName'
                      optionFields={['contractName']}
                      valueKey='contractId'
                      rules={{ required: 'Client is required' }}
                      defaultValue={{
                        contractId:editedData?.contractId||'',
                        contractName:editedData?.contractName||'',}}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                      name='divisionId'
                      control={control}
                      apiUrl='divisions'
                      valueField='divisionId'
                      headerField={['Code', 'Name']}
                      labelField={['divisionName']}
                      showField={['divisionName']}
                      pageSize={20}
                      label={'Divisions'} 
                      defaultValue={{ divisionId:editedData?.divisionId||'', divisionName:editedData?.divisionName||'' }}          
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                      name='inCharge'
                      control={control}
                      apiUrl='loginusers'
                      valueField='loginUserId'
                      headerField={['Code', 'Name']}
                      labelField={['loginUserName']}
                      showField={['loginUserName']}
                      pageSize={20}
                      label={'In Charge'} 
                      defaultValue={{ inCharge:editedData?.inChargeId||'', loginUserName:editedData?.inChargeName||'' }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                      name='requesterId'
                      control={control}
                      apiUrl='loginusers'
                      valueField='loginUserId'
                      headerField={['Code', 'Name']}
                      labelField={['loginUserName']}
                      showField={['loginUserName']}
                      pageSize={20}
                      label={'Requester'}
                      defaultValue={{ requesterId:editedData?.requesterId||'', loginUserName:editedData?.requesterName }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                    name='locationId'
                    control={control}
                    apiUrl='locations'
                    valueField='locationId'
                    headerField={['Code', 'Name']}
                    labelField={['locationName']}
                    showField={['locationName']}
                    pageSize={20}
                    label={'Locations'}
                    defaultValue={{ locationId:editedData?.locationId||'', locationName:editedData?.locationName||'' }}
                  />
                  </Grid>
                  <Grid item md={3}>
                    <FmAutoComplete
                      name='regionId'
                      control={control}
                      options={data?.regions}
                      label='Region'
                      displayField='regionName'
                      optionFields={['regionName']}
                      onChange={(ev) =>setRegionId(ev?ev?.regionId:'')}
                      onChangeValue={true}
                      valueKey='regionId'
                      rules={{ required: 'Client is required' }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                    name='storeLocationId'
                    control={control}
                    apiUrl='storeLocations'
                    queryparamValue={regionId}
                    queryparam={'regionId'}
                    valueField='storeLocationId'
                    headerField={['Code', 'Name']}
                    labelField={['storeLocationName']}
                    showField={['storeLocationName']}
                    pageSize={50}
                    label='Store Location'
                    defaultValue={{ storeLocationId:editedData?.storeLocationId||'', storeLocationName:editedData?.storeLocationName||'' }}
                  />
                  </Grid>
                  <Grid item md={3}>
                    <FmTextField
                      name='requestorEmailId'
                      label='Requestor Email Id'
                      control={control}
                      rules={{ required: 'Requestor Email Id is required' }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmTextField
                      name='unitId'
                      label='Unit NO'
                      control={control}
                      rules={{ required: 'Unit NO Id is required' }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Controller
                      name='requestedDeliveryDate'
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label='Request Delivery Date'
                          value={value ? dayjs(value) : null}
                          format='DD/MM/YYYY'
                          onChange={(newValue) => {
                            onChange(newValue);
                          }}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmAutoComplete
                      name='supplierId'
                      control={control}
                      options={data?.suppliers}
                      label='Preffered Supplier'
                      disabled={true}
                      displayField='officialSupplierName'
                      optionFields={['officialSupplierName']}
                      valueKey='supplierId'
                      onChange={handlePrefferedSupplier}
                      onChangeValue
                    />
                  </Grid>
                      <Grid item md={3}>
                        <FmAutoComplete
                          name='supplierSPOCId'
                          control={control}
                          options={data?.suppplierSPOC}
                          label='Supplier Contact Person'
                          displayField='supplierSPOCName'
                          optionFields={['supplierSPOCName']}
                          valueKey='supplierSPOCId'
                          rules={{ required: 'Client is required' }}
                          onChange={handleSpocChange}
                          onChangeValue
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FmTextField
                          name='spocContactNo'
                          label='Site Contact No'
                          control={control}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FmTextField
                          name='spocEmailId'
                          label='Site Contact Email Id'
                          control={control}
                        />
                      </Grid>
                  

                  <Grid item md={3}>
                    <FmAutoComplete
                      name='materialRequisitionStatusId'
                      control={control}
                      options={data?.mrStatus}
                      label='Material requisition Status'
                      displayField='materialRequisitionStatusName'
                      optionFields={['materialRequisitionStatusName']}
                      valueKey='materialRequisitionStatusId'
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmTextField
                      name='remarks'
                      control={control}
                      multiline={true}
                      rows={2}
                      maxRows={3}
                      label='Remarks'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Box>
                      <h3 className='mb-3 font-semibold'>
                        Additional files Upload
                      </h3>
                      <Box className='flex gap-5 flex-wrap'>
                        <Button
                          onClick={handleAddComponent}
                          sx={{
                            minWidth: '100px',
                            height: '100px',
                            border: '1px solid',
                            borderColor: 'border.main',
                            flexDirection: 'column',
                            padding: 0,
                            bgcolor: 'background.white',
                          }}
                        >
                          <span className='text-teal-600 text-3xl mb-3'>
                            <Icon icon='simple-line-icons:plus' />
                          </span>
                          <span>Add Upload</span>
                        </Button>

                        {components.map((component) => (
                          <UploadSquareBoxes
                            key={component?.id}
                            imageUrl={component?.path}
                            setImageUrl={setImageUrl} // If you need this, otherwise omit
                            handleRemove={handleRemoveComponent}
                            setComponents={setComponents}
                            id={component?.id}
                            setLoading={setImageLoading}
                            isLoading={imageLoading[component?.id]}
                            onChange={(fileId) =>
                              handleFileChange(component?.id, fileId)
                            }
                            fileTypeId={38}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item md={12}>
                    <ActionButtons
                      onSubmit={handleSubmit(onSubmit)}
                      onCancel={() => navigate(-1)}
                      onReset={reset}
                      submitLoading={createLoading}
                      cancelLoading={false}
                      submitText={id?'Update':'Submit'}
                      // errorFields={!isValid}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </form>
          )}
        </Grid>
      </Box>
    </GlassCard>
  );
};

export default AddMaterialRequest;
