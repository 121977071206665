import React from 'react';
import { Icon } from '@iconify/react';
import { Box } from '@mui/material';

const CommonLoader = (props) => {
  const { color } = props;
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 111,
        justifyContent: 'center',
      }}
    >
      <Box sx={{ fontSize: '40px', marginBottom: '30px' }}>
        <Icon icon='svg-spinners:90-ring-with-bg' style={{ color }} />
      </Box>
    </Box>
  );
};

export default CommonLoader;
