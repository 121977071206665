import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { Box, CircularProgress } from '@mui/material';
import { Icon } from '@iconify/react';
import EmployeeProfile from './EmployeeProfile';
import AccountDetails from './AccountDetails';
import Allowance from './Allowance';
import MuiTabs from '../../../../components/_mui/MuiTabs';
import Crud_Service from '../../../../apis/CrudService';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../store/loaderSlice.ts';
import ProjectDetails from './ProjectDetails.jsx';
import EmployeeEducation from './EmployeeEducation.jsx';
import EmployeeConfigration from './EmployeeConfigration.jsx';
import CampDetails from './CampDetails.jsx';
import { toast } from 'react-toastify';

const EmployeeCreate = () => {
  const [value, setValues] = useState(0);
  const [enabledTabs, setEnabledTabs] = useState(0);
  const [totalSalary, setTotalSalary] = useState(0);
  const [wpsCategories, setWpsCategories] = useState([]);
  const [selectedWps, setSelectedWps] = useState(null);
  const crud = new Crud_Service();
  const [employees, setEmployees] = useState([]);
  const [createdEmployee, setCreatedEmployee] = useState({});
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.loader.isLoading);
  const dispatch = useDispatch();

  const toggleLoading = (loading) => {
    dispatch(setLoading(loading));
  };

  useEffect(() => {
    const fetchData = async () => {
      document.title = 'HRMS-Employee-Create New Employee';
      toggleLoading(false);

      try {
        await crud.getAll('wpscategories', '', (err, res) => {
          if (res?.status === 200) {
            const transformedData = res?.data?.data.map((row) => ({
              ...row,
              id: row?.wpsCategoryId,
            }));
            setWpsCategories(transformedData);
          }
        });
      } catch (err) {
        toast.error('Error fetching wpscategories:', err);
      }
    };
    fetchData();
  }, []);

  return isLoading ? (
    <CircularProgress />
  ) : (
    <DashboardLayout title={'Create New Employee'}>
      <Box className='w-full mx-auto' sx={{ maxWidth: 1200 }}>
        <MuiTabs
          value={value}
          setValues={setValues}
          enabledTabs={enabledTabs}
          tabs={[
            {
              label: 'Employee Profile',
              content: (
                <EmployeeProfile
                  setEnabledTabs={setEnabledTabs}
                  setCreatedEmployee={setCreatedEmployee}
                  employees={employees}
                  setEmployees={setEmployees}
                  setValues={setValues}
                  enabledTabs={enabledTabs}
                  value={value}
                />
              ),
              icon: <Icon icon='solar:shield-user-broken' />,
            },
            {
              label: 'Employee Education',
              content: (
                <EmployeeEducation
                  setEnabledTabs={setEnabledTabs}
                  setValues={setValues}
                  createdEmployee={createdEmployee}
                  enabledTabs={enabledTabs}
                  value={value}
                />
              ),
              icon: <Icon icon='fluent-mdl2:education' />,
            },
            {
              label: 'Employment Configration',
              content: (
                <EmployeeConfigration
                  setEnabledTabs={setEnabledTabs}
                  setValues={setValues}
                  createdEmployee={createdEmployee}
                  enabledTabs={enabledTabs}
                  value={value}
                />
              ),
              icon: <Icon icon='material-symbols-light:conditions' />,
            },
            {
              label: 'Account Details',
              content: (
                <AccountDetails
                  setEnabledTabs={setEnabledTabs}
                  employees={employees}
                  setValues={setValues}
                  wpsCategories={wpsCategories}
                  createdEmployee={createdEmployee}
                  setTotalSalary={setTotalSalary}
                  setSelectedWps={setSelectedWps}
                  enabledTabs={enabledTabs}
                  value={value}
                />
              ),
              icon: <Icon icon='fluent:money-hand-24-regular' />,
            },
            {
              label: 'Salary Split-Up',
              content: (
                <Allowance
                  setEnabledTabs={setEnabledTabs}
                  setValues={setValues}
                  totalSalary={totalSalary}
                  createdEmployee={createdEmployee}
                  wpsDetails={wpsCategories.find(
                    (category) => category.wpsCategoryId === selectedWps,
                  )}
                  enabledTabs={enabledTabs}
                  value={value}
                />
              ),
              icon: <Icon icon='ep:money' />,
            },
            // {
            //   label: 'Employee Identity',
            //   content: (
            //     <EmployeeIdentity
            //       setIdentityData={setIdentityData}
            //       setEnabledTabs={setEnabledTabs}
            //       createdEmployee={createdEmployee}
            //       setValues={setValues}
            //       enabledTabs={enabledTabs}
            //     />
            //   ),
            //   icon: <Icon icon='ant-design:idcard-outlined' />,
            // },
            {
              label: 'Project Details',
              content: (
                <ProjectDetails
                  setEnabledTabs={setEnabledTabs}
                  setValues={setValues}
                  createdEmployee={createdEmployee}
                  enabledTabs={enabledTabs}
                  value={value}
                />
              ),
              icon: <Icon icon='lucide:building' />,
            },
            {
              label: 'Camp Details',
              content: (
                <CampDetails
                  createdEmployee={createdEmployee}
                  setEnabledTabs={setEnabledTabs}
                  setValues={setValues}
                  enabledTabs={enabledTabs}
                  value={value}
                />
              ),
              icon: <Icon icon='lucide:home' />,
            },
          ]}
        />
      </Box>
    </DashboardLayout>
  );
};

export default EmployeeCreate;
