import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Tab,
  Divider,
  Button,
  TextField,
  Tabs,
} from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { ViewField } from '../../../components/_form/FormElements';
import DataTable from '../../../components/DataTable.tsx';
import { Icon } from '@iconify/react';
import { StatusBadge } from '../../../components/_form/FormElements';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import Crud_Service from '../../../apis/CrudService.jsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import DocumentUpload from './DocumentUpload.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import HorizantalSteps from '../../../components/_form/HorizantalSteps.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { ViewEnquiryValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import ViewTick from '../../../components/view/ViewTick.tsx';
import ViewFieldInline from '../../../components/view/ViewFieldInline.tsx';
import BackToButton from '../../../components/small/BackToButton.jsx';
import ElementLoader from '../../../components/page/ElementLoader.tsx';
import DataTableNoRow from '../../../components/small/DataTableNoRow.tsx';
import ViewStatus from '../../../components/view/ViewStatus.tsx';

const ViewEnquiry = () => {
  const [value, setValues] = React.useState(0);
  const { id } = useParams();
  const [followView, setFollowView] = useState(false);
  const [followupsOpen, setFollowupsOpen] = useState(false);
  const [followupsEdit, setFollowupsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tenders, setTenders] = useState({});
  const [tenderFollowupsTypes, setTenderFollowupsTypes] = useState([]);
  const [contactModes, setContactModes] = useState([]);
  const [users, setUsers] = useState([]);
  const [followupsViewdata, setFollowupsViewData] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const defaultValues = {
    tenderId: tenders?.tenderId,
    tenderName: tenders?.tenderName,
  };
  const [loader, setLoader] = useState(false);
  const { control, handleSubmit, register, setValue, reset, watch } = useForm({
    resolver: yupResolver(ViewEnquiryValidationSchema),
    defaultValues,
    mode: 'all',
  });
  const rescheduleDate = watch('rescheduleDate');
  const remainderDate = watch('remainderDate');
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic(`tenderfollowups?tenderId=${id}`);

  const crud = new Crud_Service();
  const statusOptions = [
    { statusId: 2, statusName: 'Active' },
    { statusId: 1, statusName: 'Inactive' },
  ];
  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValues(newValue);
  };

  useEffect(() => {
    if (tenders) {
      reset({
        tenderId: tenders?.tenderId,
        tenderName: tenders?.tenderName,
      });
    }
  }, [tenders, reset]);

  useEffect(() => {
    getAllTenderFollowupsTypes();
    getContactModes();
    getLoginUsers();
    getTender();
  }, []);

  //get Single Tenders
  const getTender = async () => {
    setLoader(true);
    await crud.getSingle('tenders', id, (err, res) => {
      if (err) {
        toast.error('Error fetching Tenders:', err);
        return;
      }
      if (res?.status === 200) {
        setTenders(res?.data);
        setLoader(false);
        setValue('tenderId', res?.data?.tenderId);
        setValue('tenderName', res?.data?.tenderName);
      }
    });
  };

  //get All Tender FollowUps Types
  const getAllTenderFollowupsTypes = async () => {
    await crud.getAll('tenderfollowuptypes', '', (err, res) => {
      if (err) {
        return;
      }
      if (res?.status === 200) {
        setTenderFollowupsTypes(res?.data?.data);
      }
    });
  };
  //get All Tender FollowUps Types
  const getContactModes = async () => {
    await crud.getAll('contactmodes', '', (err, res) => {
      if (err) {
        return;
      }
      if (res?.status === 200) {
        setContactModes(res?.data?.data);
      }
    });
  };

  //get All Login Users
  const getLoginUsers = async () => {
    await crud.getAll('loginusers', '', (err, res) => {
      if (err) {
        return;
      }
      if (res?.status === 200) {
        setUsers(res?.data?.data);
      }
    });
  };

  const ViewSourceArray = [
    {
      name: 'Memeber Follow Up',
    },
    {
      name: 'Documents Upload',
    },
  ];

  //View Modal tender Followps
  const handleViewClick = async (props) => {
    setFollowView(true);
    setLoading(true);
    await crud.getSingle(
      `tenderfollowups`,
      props?.tenderFollowupId,
      (_err, res) => {
        if (res?.status === 200) {
          setFollowupsViewData(res?.data);
          setLoading(false);
        } else {
        }
      }
    );
  };

  //setValues to The Forms
  const handleEditClick = async (props) => {
    setFollowupsEdit(true);
    setLoading(true);
    await crud.getSingle(
      `tenderfollowups`,
      props?.tenderFollowupId,
      (_err, res) => {
        if (res?.status === 200) {
          Object.entries({
            ...res?.data,
          }).forEach(([key, value]) => {
            setValue(key, value);
          });
          setLoading(false);
        } else {
        }
      }
    );
  };

  //Update Followups
  const handleEditTenderFollowups = async (values) => {
    const combinedData = {
      ...values,
      rescheduleDate: dayjs(values?.rescheduleDate).format('YYYY-MM-DD'),
      remainderDate: dayjs(values?.remainderDate).format('YYYY-MM-DD'),
    };

    await crud.update(
      `tenderfollowups`,
      values?.tenderFollowupId,
      combinedData,
      (err, res) => {
        if (err) {
          return;
        }
        if (res?.status === 200) {
          toast.success('Enquiry Followup Updated Successfully');
          setFollowupsEdit(false);
          fetchData();
        }
      }
    );
  };

  //Delete Followups
  const handleDelete = async (props) => {
    await crud.remove(
      `tenderfollowups`,
      props?.tenderFollowupId,
      (err, res) => {
        if (err) {
          return;
        }
        if (res?.status === 204) {
          fetchData();
          toast.success('Followps Deleted Successfully');
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: t('edit'),
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: t('delete'),
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 120,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={() => {}}
          />
        );
      },
    },
    {
      field: 'tenderName',
      headerName: 'Tender Name',
      minWidth: 250,
      cellClassName: 'font-semibold',
    },

    {
      field: 'contactPerson',
      headerName: 'Contact Person',
      minWidth: 200,
      cellClassName: 'font-semibold',
    },
    {
      field: 'contactMail',
      headerName: 'Contact Mail',
      minWidth: 250,
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      minWidth: 180,
    },
    {
      field: 'rescheduleDate',
      headerName: 'Schedule Date',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'tenderFollowupTypeName',
      headerName: 'Enquiry Followup Type',
      flex: 1,
      minWidth: 250,
      cellClassName: 'font-semibold',
    },
    {
      field: 'contactModeName',
      headerName: 'Contact Mode',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'comments',
      headerName: 'Comments',
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'remainderDate',
      headerName: 'Remainder Date',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'assignToName',
      headerName: 'Assign To',
      flex: 1,
      minWidth: 150,
    },
    // {
    //   field: 'remainderDate',
    //   headerName: 'Remainder Date',
    //   flex: 1,
    //   minWidth: 120,

    //   renderCell: (params) => {
    //     return FmViewDateField({ value: params?.value });
    //   },
    // },
    //TODO: For Future Use
    // {
    //   field: 'document',
    //   headerName: 'Attached Document',
    //   flex: 1,
    //   minWidth: 120,
    //   renderCell: (_params) => (
    //     <IconButton aria-label='download' onClick={() => {}}>
    //       <DownloadIcon />
    //     </IconButton>
    //   ),
    // },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  //Create Followups
  const handleSubmitTenderFollowups = async (values) => {
    const combinedData = {
      ...values,
      rescheduleDate: dayjs(values?.rescheduleDate).format('YYYY-MM-DD'),
      remainderDate: dayjs(values?.remainderDate).format('YYYY-MM-DD'),
    };

    await crud.create(`tenderfollowups`, combinedData, (err, res) => {
      if (err) {
        return;
      }
      if (res?.status === 201) {
        toast.success('Enquiry Followup Created Successfully');
        setFollowupsOpen(false);
        fetchData();
      } else {
        return;
      }
    });
  };

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <DashboardLayout
      title='View Enquiries'
      titleReverse
      actionButtons={
        <BackToButton
          onClick={() => navigate(-1)}
          className='p-1 text-teal-700 font-semibold'
          title='Back to List'
        />
      }
    >
      {/* DETAILS */}
      <Box className='w-full mb-2'>
        <HorizantalSteps data={tenders?.tenderStatusTrackings} />
      </Box>

      <GlassCard className='p-2 mb-2'>
        {loader ? (
          <Box className='my-5'>
            <ElementLoader />
          </Box>
        ) : (
          <>
            <Box className='flex justify-between gap-2'>
              <Box>
                <Box className='mb-2'>
                  <Box
                    className='text-base font-bold'
                    sx={{ color: 'text.dark' }}
                  >
                    {tenders?.tenderName}
                  </Box>
                  <Box>{tenders?.companyName}</Box>
                </Box>
                <Stack
                  direction={'row'}
                  spacing={2}
                  className='mt-1'
                  divider={<Divider orientation='vertical' flexItem />}
                >
                  <ViewFieldInline
                    name={'Enquiry Number'}
                    value={tenders?.tenderNumber}
                  />
                  <ViewFieldInline name={'Code'} value={tenders?.tenderCode} />

                  <ViewFieldInline
                    name={'Client'}
                    value={tenders?.clientName}
                  />
                </Stack>
              </Box>
              <Box>
                <Box className='flex gap-2'>
                  <ViewTick
                    title={'Request For Estimate'}
                    value={tenders?.requestForEstimate}
                  />
                  <ViewTick
                    title={'Is Subcontract Required'}
                    value={tenders?.subContractRequired === true ? 'Yes' : 'No'}
                  />

                  <ViewTick
                    title={'Express of Interest'}
                    value={tenders?.expressOfInterest === true ? 'Yes' : 'No'}
                  />
                </Box>
                <Box className='flex gap-2 mt-2'>
                  <ViewTick
                    title={'Buy Enquiry Document'}
                    value={tenders?.buyTenderDocument === true ? 'Yes' : 'No'}
                  />
                  <ViewTick
                    title={'Auto Renewal'}
                    value={tenders?.autoRenewal === true ? 'Yes' : 'No'}
                  />
                </Box>
              </Box>
              <Box>
                <Stack spacing={1}>
                  <FmViewDateField
                    title='Requested Date'
                    value={tenders?.startDate}
                  />
                  <FmViewDateField
                    title='Enquiry Closing Date'
                    value={tenders?.lastDate}
                    valueColor='text-red-600'
                  />
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                borderTop: '1px solid',
                borderColor: 'border.main',
                pt: 1,
                mt: 1,
              }}
              className='flex justify-between items-center'
            >
              <Stack
                direction={'row'}
                spacing={2}
                divider={<Divider orientation='vertical' flexItem />}
              >
                <ViewFieldInline
                  name={'Enquiry Type'}
                  value={tenders?.tenderEnquiryTypeName}
                />
                <ViewFieldInline
                  name={'Project Name'}
                  value={tenders?.tenderProjectName}
                />
                <ViewFieldInline
                  name={'Enquiry Format'}
                  value={tenders?.tenderFormatName}
                />
                <ViewFieldInline
                  name={'Enquiry Value'}
                  value={tenders?.tenderValue}
                />

                {tenders?.bidBond && (
                  <ViewFieldInline
                    name={'Bidbond Value (Fixed)'}
                    value={tenders?.bidBondValue}
                  />
                )}
              </Stack>

              <Stack direction={'row'} spacing={3}>
                <ViewFieldInline
                  name={'Tender Status'}
                  value={
                    <ViewStatus
                      title={tenders?.tenderStatusName}
                      type={
                        (tenders?.tenderStatusName === 'Open' && 'green') ||
                        (tenders?.tenderStatusName === 'Awarded' && 'purple') ||
                        (tenders?.tenderStatusName === 'Submitted' &&
                          'orange') ||
                        (tenders?.tenderStatusName === 'Under Follow-up' &&
                          'blue') ||
                        (tenders?.tenderStatusName === 'Not Awarded' &&
                          'indigo') ||
                        (tenders?.tenderStatusName === 'Cancelled' && 'red') ||
                        (tenders?.tenderStatusName === 'Closed' && 'teal')
                      }
                    />
                  }
                />
              </Stack>
            </Box>
          </>
        )}
      </GlassCard>

      <GlassCard className='mb-2'>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            '& .MuiButtonBase-root': {
              fontWeight: 700,
              justifyContent: 'center',
            },
          }}
          centered
        >
          {ViewSourceArray.map((item, i) => {
            return <Tab value={i} label={item.name} />;
          })}
        </Tabs>
      </GlassCard>

      {value === 0 && (
        <Box>
          <GlassCard className='overflow-hidden'>
            <Box sx={{ height: 'calc(100vh - 430px)' }}>
              <DataTable
                rows={rows}
                columns={columns}
                loading={isLoading}
                sortingMode='server'
                paginationMode='server'
                onPaginationModelChange={(model) =>
                  handlePaginationModelChange(model)
                }
                onSortModelChange={(model) => handleSortModelChange(model)}
                onRowDoubleClick={(e) => onRowDoubleClick(e)}
                page={pageNumber - 1}
                pageSize={pageSize}
                rowCount={tableRecordCounts?.total}
                slots={{
                  toolbar: () => (
                    <Box
                      className='p-2 w-full flex justify-between items-center'
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: 'border.main',
                      }}
                    >
                      <Box sx={{ maxWidth: '350px', width: '100%' }}>
                        <TableSearch
                          placeholder='Search'
                          fullWidth
                          setSearchKeyword={setSearchKeyword}
                          searchValue={searchKeyword}
                        />
                      </Box>
                      <Button
                        variant='contained'
                        color='primary'
                        className='py-1 px-3'
                        startIcon={<Icon icon='ic:round-plus' />}
                        onClick={() => {
                          setFollowupsOpen(true);
                          reset();
                        }}
                      >
                        New
                      </Button>
                    </Box>
                  ),
                  noRowsOverlay: DataTableNoRow,
                }}
              />
            </Box>
          </GlassCard>
        </Box>
      )}

      {value === 1 && <DocumentUpload />}

      {/*Create Followups */}
      <MuiDialogOne
        title='Create Followups'
        open={followupsOpen}
        onClose={() => setFollowupsOpen(false)}
      >
        <form onSubmit={handleSubmit(handleSubmitTenderFollowups)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FmTextField
                  name='tenderName'
                  control={control}
                  label='Tender'
                  defaultValue={defaultValues}
                  readOnly
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='contactPerson'
                  control={control}
                  label='Contact Person'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='contactMail'
                  control={control}
                  label='Contact Mail'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='contactNumber'
                  control={control}
                  label='Contact Number'
                />
              </Grid>
              <Grid item md={6}>
                <FmDatePicker
                  name='rescheduleDate'
                  label='schedule Date'
                  control={control}
                  required
                  minDate={remainderDate ? dayjs(remainderDate) : null}
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='tenderFollowupTypeId'
                  control={control}
                  options={tenderFollowupsTypes}
                  label='Enquiry Followup Type'
                  displayField='tenderFollowupTypeName'
                  optionFields={['tenderFollowupTypeName']}
                  valueKey='tenderFollowupTypeId'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='contactModeId'
                  control={control}
                  options={contactModes}
                  label='Contact Modes'
                  displayField='contactModeName'
                  optionFields={['contactModeName']}
                  valueKey='contactModeId'
                />
              </Grid>

              <Grid item md={12}>
                <FmTextField
                  name='comments'
                  control={control}
                  label='Comments'
                  multiline={true}
                  rows={2}
                  maxRows={3}
                />
              </Grid>
              <Grid item md={6}>
                <FmDatePicker
                  name='remainderDate'
                  label='Remainder Date'
                  control={control}
                  required
                  maxDate={rescheduleDate ? dayjs(rescheduleDate) : null}
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='assignTo'
                  control={control}
                  options={users}
                  label='Assign To'
                  displayField='loginUserName'
                  optionFields={['loginUserName']}
                  valueKey='loginUserId'
                />
              </Grid>
              <Grid item md={12}>
                <Box className='text-end mt-3'>
                  <Button
                    variant='contained'
                    sx={{ minWidth: '180px' }}
                    type='submit'
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </form>
      </MuiDialogOne>

      {/*Update Followups */}
      <MuiDialogOne
        title='Update Followups'
        open={followupsEdit}
        onClose={() => setFollowupsEdit(false)}
      >
        {loading ? (
          <ElementLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditTenderFollowups)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FmTextField
                    name='tenderName'
                    control={control}
                    label='Tender'
                    defaultValue={defaultValues}
                    readOnly
                  />
                </Grid>
                <TextField
                  {...register('tenderFollowupId')}
                  fullWidth
                  variant='filled'
                  className='hidden'
                />

                <Grid item md={6}>
                  <TextField
                    {...register('contactPerson')}
                    fullWidth
                    label='Contact Person'
                    variant='filled'
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register('contactMail')}
                    fullWidth
                    label='Contact Mail'
                    variant='filled'
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register('contactNumber')}
                    fullWidth
                    label='Contact Number'
                    variant='filled'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmDatePicker
                    name='rescheduleDate'
                    label='schedule Date'
                    control={control}
                    required
                    minDate={remainderDate ? dayjs(remainderDate) : null}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='tenderFollowupTypeId'
                    control={control}
                    options={tenderFollowupsTypes}
                    label='Enquiry Followup Type'
                    displayField='tenderFollowupTypeName'
                    optionFields={['tenderFollowupTypeName']}
                    valueKey='tenderFollowupTypeId'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='contactModeId'
                    control={control}
                    options={contactModes}
                    label='Contact Modes'
                    displayField='contactModeName'
                    optionFields={['contactModeName']}
                    valueKey='contactModeId'
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    {...register('comments')}
                    fullWidth
                    label='Comments'
                    variant='filled'
                    multiline
                    rows={3}
                    maxRows={4}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmDatePicker
                    name='remainderDate'
                    label='Remainder Date'
                    control={control}
                    required
                    maxDate={rescheduleDate ? dayjs(rescheduleDate) : null}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='assignTo'
                    control={control}
                    options={users}
                    label='Assign To'
                    displayField='loginUserName'
                    optionFields={['loginUserName']}
                    valueKey='loginUserId'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='status'
                    control={control}
                    options={statusOptions}
                    label='Status'
                    displayField='statusName'
                    optionFields={['statusName']}
                    valueKey='statusId'
                  />
                </Grid>
                <Grid item md={12}>
                  <Box className='text-end mt-3'>
                    <Button
                      variant='contained'
                      sx={{ minWidth: '180px' }}
                      type='submit'
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </form>
        )}
      </MuiDialogOne>

      <MuiDialogOne
        title='View Details'
        open={followView}
        onClose={() => setFollowView(!followView)}
      >
        <Grid container spacing={2}>
          <Grid item md={6}>
            <ViewField
              label='Enquiry Followup Type'
              title={followupsViewdata?.tenderFollowupTypeName}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Enquiry Name'
              title={followupsViewdata?.tenderName}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Contact Person'
              title={followupsViewdata?.contactPerson}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Contact Mode Name'
              title={followupsViewdata?.contactModeName}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Contact Mail'
              title={followupsViewdata?.contactMail}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Contact Number'
              title={followupsViewdata?.contactNumber}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Assigned To'
              title={followupsViewdata?.assignToName}
            />
          </Grid>
          <Grid item md={6}>
            <FmViewDateField
              title='Remainder date'
              value={followupsViewdata?.remainderDate}
            />
          </Grid>
          <Grid item md={6}>
            <FmViewDateField
              title='Reschedule Date'
              value={followupsViewdata?.rescheduleDate}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField label='Status' title={followupsViewdata?.statusName} />
          </Grid>
          <Grid item md={12}>
            <ViewField label='Comments' title={followupsViewdata?.comments} />
          </Grid>
        </Grid>
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default ViewEnquiry;
