import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import Crud_Service from '../../../apis/CrudService';
import { toast } from 'react-toastify';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import BackToButton from './../../../components/small/BackToButton';
import TenderCard from './TenderCard.jsx';
import TotalBox from './TotalBox.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import apiInstance from '../../../apis/ApiService.jsx';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { AMCTableValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHotkeys } from 'react-hotkeys-hook';
import GlassCard from '../../../components/small/GlassCard.tsx';

const ViewDetails = ({ handleBackButton, TableData }) => {
  const [total, setTotalAmount] = useState(0);
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic(
    `estimationamcsheetdetails/${TableData?.estimationAmcSheetId}/amc-details?sortDirection=asc`,
    '',
    ''
  );
  const [estimationAMC, setEstimationAMC] = useState({});

  const {
    reset,
    control,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(AMCTableValidationSchema),
    mode: 'onChange',
    defaultValues: {
      sspDetail: '',
      productCode: '',
      workManPowerName: '',
      description: '',
      lineItemName: '',
      unitOfMeasurementId: 0,
      quantity: 0,
      months: 0,
      unitSellingPrice: 0,
      subTotalAmount: 0,
      actualUnitPrice: 0,
      overHeadPercent: 0,
      overHeadUnitPrice: 0,
      contingencyPercent: 0,
      contingencyUnitPrice: 0,
      markUpPercent: 0,
      markupUnitPrice: 0,
      discountPercent: 0,
      discountUnitPrice: 0,
      negotiationPercent: 0,
      negotiationUnitPrice: 0,
      unitPrice: 0,
      totalAmcAmount: 0,
      currencyId: 0,
    },
  });
  const [amcSheetsPercent, setAmcSheetsPercent] = useState({
    note: '',
    overHeadOverAllPercent: 0,
    overHeadCost: 0,
    contingencyOverAllPercent: 0,
    contingencyCost: 0,
    otherOverAllPercent: 0,
    otherCost: 0,
    markUpOverAllPercent: 0,
    markUpCost: 0,
    discountOverAllPercent: 0,
    discountCost: 0,
    negotiationPercent: 0,
    negotiationCost: 0,
    totalEstimationCost: 0,
  });
  const [totalBoxLoader, setTotalBoxLoader] = useState(true);
  const [openUpload, setOpenUpload] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const [selectedRate, setSelectedRate] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [activeField, setActiveField] = useState(null);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [amcSubtotal, setAmcSubtotal] = useState(0);
  const [loader, setLoader] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [stateErrorMessage, setStateErrorMessage] = useState(
    'This field is required.'
  );
  useEffect(() => {
    setTotalAmount(rows[0]?.totalAmount);
    console.log(rows);
  }, [rows]);

  const getEstimationAMC = async () => {
    setTotalBoxLoader(true);
    await crud.getSingle(
      `estimationamcsheets`,
      TableData?.estimationAmcSheetId,
      (err, res) => {
        if (err) {
          toast.error(err);
        }
        if (res?.status === 200) {
          setEstimationAMC(res?.data);
          setAmcSheetsPercent((prev) => ({
            ...prev,
            overHeadOverAllPercent: res?.data?.overHeadOverAllPercent || 0,
            overHeadCost: res?.data?.overHeadCost || 0,
            contingencyOverAllPercent:
              res?.data?.contingencyOverAllPercent || 0,
            contingencyCost: res?.data?.contingencyCost || 0,
            otherOverAllPercent: res?.data?.otherOverAllPercent,
            otherCost: res?.data?.otherCost,
            markUpOverAllPercent: res?.data?.markUpOverAllPercent || 0,
            markUpCost: res?.data?.markUpCost || 0,
            discountOverAllPercent: res?.data?.discountOverAllPercent,
            discountCost: res?.data?.discountCost,
            negotiationPercent: res?.data?.negotiationPercent,
            negotiationCost: res?.data?.negotiationCost,
            totalEstimationCost: res?.data?.totalEstimationCost || 0,
            note: res?.data?.note || '',
          }));
          setTotalBoxLoader(false);
        }
      }
    );
  };
  const { data: estimationTypes } = useCommonFetchApi('estimationTypes') || [];
  const { data: manpowerrates } = useCommonFetchApi('manpowerrates');
  const { data: unitofmeasurements } = useCommonFetchApi('unitofmeasurements');
  const { data: currencies } = useCommonFetchApi('currencies');

  const handleDeleteSheet = async (props) => {
    await crud.remove(
      'estimationamcsheetdetails',
      props?.estimationAmcSheetDetailId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('AMC Sheet deleted Successfully');
          fetchData();
        } else {
          toast.error('Something Went Wrong!');
        }
      }
    );
  };
  const handleExportAMC = async () => {
    const downloadUrl = await apiInstance.getFiles(
      `estimationamcsheetdetails/${TableData.estimationAmcSheetId}/exportexcel`
    );
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'AMC Details.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleTotalSubmit = () => {
    const data = {
      ...amcSheetsPercent,
      contingencyCost: Number(amcSheetsPercent.contingencyCost),
      discountCost: Number(amcSheetsPercent.discountCost),
      markUpCost: Number(amcSheetsPercent.markUpCost),
      otherCost: Number(amcSheetsPercent.otherCost),
      overHeadCost: Number(amcSheetsPercent.overHeadCost),
      negotiationCost: Number(amcSheetsPercent.negotiationCost),
      totalEstimationCost: Number(amcSheetsPercent.totalEstimationCost),
    };
    crud.update(
      `estimationamcsheets/${TableData?.estimationAmcSheetId}/update-note`,
      '',
      data,
      (err, res) => {
        if (err) {
          toast.error(err);
          return;
        }
        if (res?.status === 200) {
          toast.success('Estimation AMC Sheet Detail Created Successfully');
          fetchData();
        } else {
          toast.error('Something Went Wrong');
        }
      }
    );
  };

  const handleFileUpload = async (e) => {
    setUploadLoading(true);
    const file = e.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 40);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
          toast.error(t('something_Went_Wrong'));
        }
      });
    }
  };

  const handleSubmitImport = async () => {
    startCreateLoading();
    await crud.create(
      `estimationamcsheetdetails/${TableData?.estimationAmcSheetId}/process/${uploadedfileData?.fileId}`,
      '',
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Estimation AMC Sheet Detail Created Successfully');
          stopCreateLoading();
          setOpenUpload(false);
          setUploadedfileData(null);
          fetchData();
        } else {
          stopCreateLoading();
          toast.error('Something Went Wrong');
        }
      }
    );
  };

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows[0]?.estimationAmcSheetDetails?.length
        ? editingRowIndex + 1
        : rows[0]?.estimationAmcSheetDetails?.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        estimationAmcSheetId: newId,
        estimationAmcSheetDetailId: '', // Ensure this is set as needed
        productCode: '',
        estimationTypeId: '',
        workManPowerName: '',
        unitSellingPrice: 0,
        description: '',
        months: 0,
        quantity: 0,
        unitOfMeasurementId: '',
        currencyId: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleBlur = () => {
    if (isValid) {
      apiCallAsync(getValues());
    }
  };

  // const previousValues = useRef(getValues()); // store previous values to compare with

  // const handleBlur = () => {
  //   const currentValues = getValues();

  //   // Compare the previous values with the current values
  //   const hasChanges = Object.keys(currentValues).some(
  //     (key) => currentValues[key] !== previousValues.current[key]
  //   );

  //   if (hasChanges && isValid) {
  //     apiCallAsync(currentValues); // Call the API with the new values
  //     previousValues.current = currentValues; // Update previous values
  //   }
  // };

  // Use effect

  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      sspDetail: 'Some SSP Detail', // Example static field
      description: values?.description, // Ensure the correct field is passed
      lineItemName: values?.lineItemName, // If this exists in your form
      unitSellingPrice:
        values?.unitSellingPrice || editingRowData?.unitSellingPrice, // Get latest or fallback to editingRowData
      status: 2, // Static status value
      discountUnitPrice: editingRowData?.discountUnitPrice,
      markupUnitPrice: editingRowData?.markupUnitPrice,
      negotiationUnitPrice: editingRowData?.negotiationUnitPrice,
      overHeadUnitPrice: editingRowData?.overHeadUnitPrice,
      subTotalAmount: editingRowData?.subTotalAmount,
      totalAmcAmount: editingRowData?.totalAmcAmount,
      actualUnitPrice: editingRowData?.actualUnitPrice,
      contingencyUnitPrice: editingRowData?.contingencyUnitPrice,
      unitOfMeasurementId: values?.unitOfMeasurementId || null,
      months: values?.months || 1,
      quantity: values?.quantity || 1,
      currencyId: values?.currencyId || null,
    };

    const combinedData = {
      estimationAmcSheetId: TableData?.estimationAmcSheetId,
      // estimationAmcDetails: [convertedValues], // Combine to match API format
      ...convertedValues,
    };

    const updateCombinedData = {
      ...values, // Same as above, combining updated values
      estimationAmcSheetDetailId: TableData?.estimationAmcSheetDetailId, // Ensure proper ID is passed
      unitSellingPrice:
        values?.unitSellingPrice || editingRowData?.unitSellingPrice,
      discountUnitPrice: editingRowData?.discountUnitPrice,
      markupUnitPrice: editingRowData?.markupUnitPrice,
      negotiationUnitPrice: editingRowData?.negotiationUnitPrice,
      overHeadUnitPrice: editingRowData?.overHeadUnitPrice,
      subTotalAmount: editingRowData?.subTotalAmount,
      totalAmcAmount: editingRowData?.totalAmcAmount,
      actualUnitPrice: editingRowData?.actualUnitPrice,
      contingencyUnitPrice: editingRowData?.contingencyUnitPrice, // Ensure this field is always set
      status: 2,
      unitOfMeasurementId: values?.unitOfMeasurementId || null,
      months: values?.months || 1,
      quantity: values?.quantity || 1,
      currencyId: values?.currencyId || null,
    };

    // Conditional API call based on whether this is a new row or an update
    if (editingRowId === null) {
      await crud.create(
        'estimationamcsheetdetails',
        combinedData,
        (err, res) => {
          if (res?.status === 201) {
            // fetchData()
            setEditingRowId(res?.data?.estimationAmcSheetDetailId);
            setValue(
              'estimationAmcSheetDetailId',
              res?.data?.estimationAmcSheetDetailId
            );
          } else {
          }
        }
      );
    } else {
      await crud.update(
        'estimationamcsheetdetails',
        editingRowId,
        updateCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows[0]?.estimationAmcSheetDetails?.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows[0]?.estimationAmcSheetDetails];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows[0]?.estimationAmcSheetDetails];

              updatedRows[editingRowIndex] = updatedRow;

              setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
            }
          } else {
            // Handle error
          }
        }
      );
    }
  };

  const handleCancel = (row) => {
    if (row?.original?.estimationAmcSheetId === rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.estimationAmcSheetId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      setEditingRowIndex(null);
    } else {
      setEditingRowId(null);
      setEditingRowIndex(null);
    }
    reset();
  };

  useEffect(() => {
    if (selectedRate) {
      const selectedManPowerRate = manpowerrates.find(
        (item) => item.manPowerRateId === selectedRate
      );

      if (selectedManPowerRate?.ctcPerMonth) {
        setValue('unitSellingPrice', selectedManPowerRate.ctcPerMonth);
        setEditingRowData((prev) => ({
          ...prev,
          unitSellingPrice: selectedManPowerRate.ctcPerMonth,
        }));
      }
    }
  }, [selectedRate, manpowerrates, setValue, setEditingRowData]);

  // Update actualUnitPrice when unitSellingPrice changes
  useEffect(() => {
    const { unitSellingPrice } = editingRowData || {};

    if (unitSellingPrice) {
      setEditingRowData((prev) => ({
        ...prev,
        actualUnitPrice: unitSellingPrice,
      }));
    }
  }, [editingRowData?.unitSellingPrice]);

  useEffect(() => {
    const { months, quantity, unitSellingPrice } = editingRowData || {};
    if (quantity && months && unitSellingPrice) {
      const calculatedSubTotal = quantity * months * unitSellingPrice;
      setEditingRowData((prev) => ({
        ...prev,
        subTotalAmount: calculatedSubTotal,
      }));
    }
  }, [
    editingRowData?.quantity,
    editingRowData?.months,
    editingRowData?.unitSellingPrice,
  ]);
  useEffect(() => {
    const { unitSellingPrice } = editingRowData || {};

    if (unitSellingPrice) {
      setEditingRowData((prev) => ({
        ...prev,
        actualUnitPrice: unitSellingPrice,
      }));
    }
  }, [editingRowData?.unitSellingPrice]);

  // First useEffect for overHeadUnitPrice
  useEffect(() => {
    const { overHeadPercent, actualUnitPrice } = editingRowData || {};

    const basePrice = actualUnitPrice || 0;
    const calculatedOverHeadUnitPrice =
      basePrice * (1 + (overHeadPercent || 0) / 100);

    setEditingRowData((prev) => ({
      ...prev,
      overHeadUnitPrice: calculatedOverHeadUnitPrice.toFixed(2),
    }));
  }, [editingRowData?.overHeadPercent, editingRowData?.actualUnitPrice]);

  // Second useEffect for contingencyUnitPrice
  useEffect(() => {
    const { contingencyPercent, overHeadUnitPrice } = editingRowData || {};

    const basePrice = overHeadUnitPrice || editingRowData?.actualUnitPrice || 0;
    const calculatedContingencyUnitPrice =
      basePrice * (1 + (contingencyPercent || 0) / 100);

    setEditingRowData((prev) => ({
      ...prev,
      contingencyUnitPrice: calculatedContingencyUnitPrice.toFixed(2),
    }));
  }, [
    editingRowData?.contingencyPercent,
    editingRowData?.overHeadUnitPrice,
    editingRowData?.actualUnitPrice,
  ]);

  // Third useEffect for markupUnitPrice
  useEffect(() => {
    const { markUpPercent, contingencyUnitPrice } = editingRowData || {};

    const basePrice =
      contingencyUnitPrice ||
      editingRowData?.overHeadUnitPrice ||
      editingRowData?.actualUnitPrice ||
      0;
    const calculatedMarkupPrice = basePrice * (1 + (markUpPercent || 0) / 100);

    setEditingRowData((prev) => ({
      ...prev,
      markupUnitPrice: calculatedMarkupPrice.toFixed(2),
    }));
  }, [
    editingRowData?.markUpPercent,
    editingRowData?.contingencyUnitPrice,
    editingRowData?.overHeadUnitPrice,
    editingRowData?.actualUnitPrice,
  ]);

  // Fourth useEffect for discountUnitPrice
  useEffect(() => {
    const { discountPercent, markupUnitPrice } = editingRowData || {};

    const basePrice =
      markupUnitPrice ||
      editingRowData?.contingencyUnitPrice ||
      editingRowData?.overHeadUnitPrice ||
      editingRowData?.actualUnitPrice ||
      0;
    const calculatedDiscountPrice =
      basePrice * (1 - (discountPercent || 0) / 100);

    setEditingRowData((prev) => ({
      ...prev,
      discountUnitPrice: calculatedDiscountPrice.toFixed(2),
    }));
  }, [
    editingRowData?.discountPercent,
    editingRowData?.markupUnitPrice,
    editingRowData?.contingencyUnitPrice,
    editingRowData?.overHeadUnitPrice,
    editingRowData?.actualUnitPrice,
  ]);

  // Fifth useEffect for negotiationUnitPrice
  useEffect(() => {
    const { negotiationPercent, discountUnitPrice } = editingRowData || {};

    const basePrice =
      discountUnitPrice ||
      editingRowData?.markupUnitPrice ||
      editingRowData?.contingencyUnitPrice ||
      editingRowData?.overHeadUnitPrice ||
      editingRowData?.actualUnitPrice ||
      0;
    const calculatedNegotiationPrice =
      basePrice * (1 - (negotiationPercent || 0) / 100);

    setEditingRowData((prev) => ({
      ...prev,
      negotiationUnitPrice: calculatedNegotiationPrice.toFixed(2),
    }));
  }, [
    editingRowData?.negotiationPercent,
    editingRowData?.discountUnitPrice,
    editingRowData?.markupUnitPrice,
    editingRowData?.contingencyUnitPrice,
    editingRowData?.overHeadUnitPrice,
    editingRowData?.actualUnitPrice,
  ]);

  useEffect(() => {
    const { months, quantity, negotiationUnitPrice } = editingRowData || {};
    if (quantity && months && negotiationUnitPrice) {
      const calculatedAMCAmount = quantity * months * negotiationUnitPrice;
      setEditingRowData((prev) => ({
        ...prev,
        totalAmcAmount: calculatedAMCAmount.toFixed(2),
      }));
    }
  }, [
    editingRowData?.quantity,
    editingRowData?.months,
    editingRowData?.negotiationUnitPrice,
  ]);
  useEffect(() => {
    getEstimationAMC();
  }, []);

  const handleEdit = (row) => {
    if (rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.estimationAmcSheetId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.estimationAmcSheetDetailId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };
  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDeleteSheet(props),
      danger: true,
    },
  ];

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows[0]?.estimationAmcSheetDetails, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows[0]?.estimationAmcSheetDetails?.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[0]?.estimationAmcSheetDetails[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [
      editingRowIndex,
      rows[0]?.estimationAmcSheetDetails,
      stateError,
      loader,
      isValid,
    ]
  );

  useEffect(() => {
    if (editingRowData) {
      const amcSubtotal = rows[0]?.estimationAmcSheetDetails?.reduce(
        (total, row, index) => {
          const amountToAdd =
            index === editingRowIndex
              ? Number(editingRowData?.totalAmcAmount) || 0
              : Number(row.totalAmcAmount) || 0;

          return total + amountToAdd;
        },
        0
      );
      setAmcSubtotal(amcSubtotal);
    }
  }, [editingRowData]);

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues(); // Get the latest values from the form
    const updatedRows = [...rows[0]?.estimationAmcSheetDetails]; // Clone the rows[0] to avoid direct mutation

    // Update the specific row in the cloned array
    updatedRows[editingRowIndex] = updatedRow;

    setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);

    const values = getValues();
    const updateCombinedData = {
      ...values, // Same as above, combining updated values
      estimationAmcSheetDetailId: TableData?.estimationAmcSheetDetailId, // Ensure proper ID is passed
      unitSellingPrice:
        values?.unitSellingPrice || editingRowData?.unitSellingPrice,
      discountUnitPrice: editingRowData?.discountUnitPrice,
      markupUnitPrice: editingRowData?.markupUnitPrice,
      negotiationUnitPrice: editingRowData?.negotiationUnitPrice,
      overHeadUnitPrice: editingRowData?.overHeadUnitPrice,
      subTotalAmount: editingRowData?.subTotalAmount,
      totalAmcAmount: editingRowData?.totalAmcAmount,
      actualUnitPrice: editingRowData?.actualUnitPrice,
      contingencyUnitPrice: editingRowData?.contingencyUnitPrice, // Ensure this field is always set
      unitOfMeasurementId: values?.unitOfMeasurementId || null,
      months: values?.months || 1,
      quantity: values?.quantity || 1,
      currencyId: values?.currencyId || null,
      status: 2, // Static status value
    };
    await crud.update(
      'estimationamcsheetdetails',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows[0]?.estimationAmcSheetDetails?.length;
          if (
            editingRowIndex <= totalRows &&
            rows[0].some((row) => row.isNew)
          ) {
            setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows[0]?.estimationAmcSheetDetails];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows[0]?.estimationAmcSheetDetails];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };
            setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows[0]?.estimationAmcSheetDetails?.length
        ? editingRowIndex + 1
        : rows[0]?.estimationAmcSheetDetails?.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        estimationAmcSheetId: newId,
        estimationAmcSheetDetailId: '', // Ensure this is set as needed
        productCode: '',
        estimationTypeId: '',
        workManPowerName: '',
        unitSellingPrice: 0,
        description: '',
        months: 0,
        quantity: 0,
        unitOfMeasurementId: '',
        currencyId: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  useEffect(() => {
    // Filter rows[0]?.estimationAmcSheetDetails? based on rowSelection keys
    if (rowSelection) {
      const selectedRowIds = rows[0]?.estimationAmcSheetDetails
        ?.filter((_, index) => rowSelection[index]) // Check if row index is selected in rowSelection
        .map((row) => row?.estimationAmcSheetDetailId); // Get the `estimationAmcSheetDetailId` of selected rows[0]?.estimationAmcSheetDetails?

      setSelectedRowIds(selectedRowIds);
    }
  }, [rowSelection]);

  const delectCheckBoxClicked = async () => {
    await crud.remove(
      'estimationamcsheetdetails',
      selectedRowIds,
      (err, res) => {
        if (res) {
          fetchData();
          setSelectedRowIds([]);
          setRowSelection({});
        }
      }
    );
  };

  const columns = [
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <>
            <FmTextField
              name='description'
              required
              control={control}
              label='Description'
              inputRef={firstColumnInputRef}
              onBlur={
                rowState.isCreatingRow && editingRowId === null
                  ? handleBlur
                  : undefined
              } // Trigger onBlur
            />
            {editingRowId !== null ||
              (isValid === false && (
                <h3 class='text-red-600'>Please Enter Description</h3>
              ))}
          </>
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.description}
          </div>
        );
      },
    },
    {
      accessorKey: 'productCode',
      header: 'Product Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const isNewRow =
          cell?.row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === cell?.row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return isEditing ? (
          <FmAutoComplete
            name='productCode'
            control={control}
            options={estimationTypes}
            label='Product Code'
            displayField='estimationTypeName'
            optionFields={['estimationTypeName']}
            valueKey='estimationTypeId'
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(cell?.row?.original);
              setEditingRowIndex(cell?.row?.index);
            }}
          >
            {estimationTypes.find(
              (type) =>
                type.estimationTypeId == cell?.row?.original?.productCode
            )?.estimationTypeName || ''}
          </div>
        );
      },
    },
    {
      accessorKey: 'workManPowerName',
      header: 'Work Man Power Name',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='workManPowerName'
            control={control}
            label='Work Man Power Name'
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.workManPowerName}
          </div>
        );
      },
    },
    {
      accessorKey: 'unitSellingPrice',
      header: 'Unit Selling Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name='unitSellingPrice'
            control={control}
            label='Unit Selling Price'
            onBlur={handleBlur}
            onChange={(e) => {
              const value = parseFloat(e.target.value) || 0;

              // Update the unitSellingPrice in editingRowData
              setEditingRowData((prev) => ({
                ...prev,
                unitSellingPrice: value,
              }));
            }}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.unitSellingPrice || 0}
          </div>
        );
      },
    },

    {
      accessorKey: 'unitOfMeasurementId',
      header: 'Unit Of Measurement',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name='unitOfMeasurementId'
            control={control}
            options={unitofmeasurements}
            label='UOM'
            displayField='unitOfMeasurementName'
            optionFields={['unitOfMeasurementName']}
            valueKey='unitOfMeasurementId'
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.unitOfMeasurementName || ''}
          </div>
        );
      },
    },
    {
      accessorKey: 'months',
      header: 'Months',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='months'
            control={control}
            label='Months'
            pattern='Number'
            defaultValue={editingRowData?.months || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                months: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.months || 0}
          </div>
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='quantity'
            control={control}
            label='Quantity'
            pattern='Number'
            defaultValue={editingRowData?.quantity || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                quantity: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.quantity || 0}
          </div>
        );
      },
    },
    {
      accessorKey: 'currencyId',
      header: 'Currency',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name='currencyId'
            control={control}
            options={currencies}
            label='currency'
            displayField='currencyName'
            optionFields={['currencyName']}
            valueKey='currencyId'
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.currencyName || ''}
          </div>
        );
      },
    },
    {
      accessorKey: 'subTotalAmount',
      header: 'Sub Total',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.subTotalAmount || ''
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.subTotalAmount || 0}
          </div>
        );
      },
    },
    {
      accessorKey: 'actualUnitPrice',
      header: 'Actual Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.unitSellingPrice || ''
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.actualUnitPrice || ''}
          </div>
        );
      },
    },
    {
      accessorKey: 'overHeadPercent',
      header: 'Over Head(%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='overHeadPercent'
            control={control}
            label='Over Head(%)'
            defaultValue={editingRowData?.overHeadPercent || 0}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                overHeadPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.overHeadPercent}
          </div>
        );
      },
    },
    {
      accessorKey: 'overHeadUnitPrice',
      header: 'Over Head With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.overHeadUnitPrice || ''
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.overHeadUnitPrice || 0}
          </div>
        );
      },
    },
    {
      accessorKey: 'contingencyPercent',
      header: 'Contingency (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='contingencyPercent'
            control={control}
            label='Contingency (%)'
            defaultValue={editingRowData?.contingencyPercent || 0}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                contingencyPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div onClick={() => handleEdit(row?.original)}>
            {row?.original?.contingencyPercent}
          </div>
        );
      },
    },
    {
      accessorKey: 'contingencyUnitPrice',
      header: 'Contingency With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.contingencyUnitPrice || ''
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.contingencyUnitPrice || 0}
          </div>
        );
      },
    },
    {
      accessorKey: 'markUpPercent',
      header: 'Mark Up (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='markUpPercent'
            control={control}
            label='Mark Up (%)'
            defaultValue={editingRowData?.markUpPercent || 0}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                markUpPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.markUpPercent}
          </div>
        );
      },
    },
    {
      accessorKey: 'markupUnitPrice',
      header: 'Mark Up With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.markupUnitPrice || ''
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.markupUnitPrice || 0}
          </div>
        );
      },
    },
    {
      accessorKey: 'discountPercent',
      header: 'Discount (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='discountPercent'
            control={control}
            label='Discount (%)'
            pattern='Percentage'
            defaultValue={editingRowData?.discountPercent || 0}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                discountPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.discountPercent}
          </div>
        );
      },
    },
    {
      accessorKey: 'discountUnitPrice',
      header: 'Discount With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {isEditing
              ? editingRowData?.discountUnitPrice || 0
              : row?.original?.discountUnitPrice || 0}
          </div>
        );
      },
    },

    {
      accessorKey: 'negotiationPercent',
      header: 'Negotiation (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name='negotiationPercent'
            control={control}
            label='Negotiation (%)'
            pattern='Percentage'
            defaultValue={row.original.negotiationPercent || 0}
            onChange={(e) =>
              setEditingRowData((prev) => ({
                ...prev,
                negotiationPercent: e.target.value,
              }))
            }
            onBlur={handleBlurAndFetch}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.negotiationPercent}
          </div>
        );
      },
    },
    {
      accessorKey: 'negotiationUnitPrice',
      header: 'Negotiation With Unit Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.negotiationUnitPrice || 0
        ) : (
          <div onClick={() => handleEdit(row?.original)}>
            {row?.original?.negotiationUnitPrice}
          </div>
        );
      },
    },

    {
      accessorKey: 'unitPrice',
      header: 'Unit Price',
      enableEditing: true,
      Cell: ({ row, cell }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return (
          <div
            onClick={() => {
              handleEdit(cell?.row?.original);
              setEditingRowIndex(cell?.row?.index);
            }}
          >
            {isEditing
              ? editingRowData?.unitSellingPrice
              : row?.original?.unitSellingPrice}
          </div>
        );
      },
    },
    {
      accessorKey: 'totalAmcAmount',
      header: 'Total Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return (
          <div onDoubleClick={() => handleEdit(row?.original)}>
            {isEditing
              ? editingRowData?.totalAmcAmount
              : row?.original?.totalAmcAmount}
          </div>
        );
      },
    },

    {
      header: 'Action',
      enableColumnPinning: false,
      // enableEditing: false,
      // enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        // Determine if the current row is the last row in the table
        const isLastRow =
          row.index ===
          table.getRowModel().rows[0]?.estimationAmcSheetDetails?.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                {/* <Button onClick={handleSubmit(handleUpdateAMCDetails)}>
                  Save
                </Button> */}
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => {
                      handleCancelAndAddNewRow(row);
                    }}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {/* Show Add button only for the last row */}
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <BackToButton
        title='Back to List'
        onClick={handleBackButton}
        className='px-2 mb-1'
      />
      <TenderCard estimationAMC={estimationAMC} />

      <GlassCard>
        <FmMRTDataTable
          muiTableContainerProps={{
            sx: {
              maxHeight: '350px',
            },
          }}
          columns={columns}
          enableRowSelection={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          delectCheckBoxClicked={delectCheckBoxClicked}
          rows={rows[0]?.estimationAmcSheetDetails || []}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          disableNewButton={rows[0]?.estimationAmcSheetDetails?.length === 0}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          columnPining={false}
          fetchData={fetchData}
          renderTopToolbarCustomActionsChildrens={
            <Box className='flex justify-end gap-2 me-2'>
              <Button
                variant='outlined'
                color='primary'
                className='px-3 py-1'
                onClick={() => {
                  setOpenUpload(true);
                  setUploadedfileData(null);
                }}
              >
                Import AMC Details
              </Button>
              <Button
                variant='outlined'
                color='primary'
                className='px-3 py-1'
                onClick={handleExportAMC}
              >
                Export AMC Details
              </Button>
            </Box>
          }
        />
      </GlassCard>

      {!totalBoxLoader && (
        <TotalBox
          amcSheetsPercent={amcSheetsPercent}
          total={amcSubtotal != 0 ? amcSubtotal : total}
          setAmcSheetsPercent={setAmcSheetsPercent}
          handleTotalSubmit={handleTotalSubmit}
        />
      )}
      <MuiDialogOne
        open={openUpload}
        onClose={() => setOpenUpload(false)}
        title='Import AMC Details'
        description='Upload your amc details Xlsx file'
      >
        <FmFileInput
          name='file'
          documentName={uploadedfileData?.originalFileName || ''}
          onChange={handleFileUpload}
          loading={uploadLoading}
        />
        <ActionButtons
          onSubmit={handleSubmitImport}
          onCancel={() => setOpenUpload(false)}
          submitLoading={createLoading}
          onReset={reset}
          cancelLoading={false}
        />
      </MuiDialogOne>
    </>
  );
};

export default ViewDetails;
