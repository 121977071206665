import React from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import { addCheckInvalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
const GridSize = 2.4;

const AddCheckInForm = (props) => {
  const { fetchData } = props;

  const crud = new Crud_Service();
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addCheckInvalidationSchema),
    mode: 'onChange',
  });
  const handleSubmitProject = async (values) => {
    startCreateLoading();

    await crud.create('checkinforms', values, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Check In Created successfully');

        fetchData();
        stopCreateLoading();
        reset();
      } else {
        stopCreateLoading();  
      }
    });
  };

  return (
    <DashboardLayout
      title='Add Check In Form'
      hasSubmenu
      >
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(handleSubmitProject)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='clientId'
              control={control}
              apiUrl='clients'
              valueField='clientId'
              headerField={['Client Name']}
              labelField={['clientName']}
              showField={['clientName']}
              pageSize={20}
              label='Client'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='companyId'
              control={control}
              apiUrl='companies'
              valueField='companyId'
              headerField={['Company Name']}
              labelField={['companyName']}
              showField={['companyName']}
              pageSize={20}
              label='Company'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='projectId'
              control={control}
              apiUrl='projects'
              valueField='projectId'
              headerField={['Project Code']}
              labelField={['projectCode']}
              showField={['projectCode']}
              pageSize={20}
              label='Project Code'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='employeeId'
              control={control}
              apiUrl='employees'
              valueField='employeeId'
              headerField={['Employee Name']}
              labelField={['firstName']}
              showField={['firstName']}
              pageSize={20}
              label='Employee'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='workOrderId'
              control={control}
              apiUrl='workOrders'
              valueField='workOrderId'
              headerField={['Work Order Code']}
              labelField={['workOrderCode']}
              showField={['workOrderCode']}
              pageSize={20}
              label='Work Order'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              valueField='contractId'
              headerField={['Contract Code']}
              labelField={['contractCode']}
              showField={['contractCode']}
              pageSize={20}
              label='Contract'
            />
          </Grid>
        </Grid>
      </form>
      <ActionButtons
        onSubmit={handleSubmit(handleSubmitProject)}
        onReset={reset}
        submitLoading={createLoading}
        cancelLoading={false}
      />
    </GlassCard>
    </DashboardLayout>
  );
};

export default AddCheckInForm;
