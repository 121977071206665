import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../../components/_form';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../../apis/CrudService';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';

const AccountDetails = (props) => {
  const { setEnabledTabs, setValues, createdEmployee, value } = props;
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const [paymentTypes, setpaymentTypes] = useState([]);
  const crud = new Crud_Service();

  const validationSchema = Yup.object().shape({
    paymentType: Yup.mixed().required('Payment Type is required'),
    bankId: Yup.string().required('Bank is required'),
    accountNo: Yup.string()
      .required('Account No is required')
      .test(
        'is-valid-iban',
        'Account No must be at most 21 digits after the prefix',
        function (value) {
          if (!value || !value.startsWith('AE')) {
            return this.createError({
              message: 'Account No must start with AE',
            });
          }
          const digitsPart = value.slice(2);
          return /^\d{1,21}$/.test(digitsPart);
        }
      ),
    wpsAgentId: Yup.string().required('WPS Agent is required'),
    wpsPersonal: Yup.string().required('WPS Personal is required'),
    leaveSalaryCostCenterId: Yup.mixed().required(
      'Leave Salary Cost Center is required'
    ),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (value === 3) {
      getPaymentTypes();
    }
  }, [value]);
  const { data: banks } = useCommonFetchApi('banks');

  //get  All Payment Types
  const getPaymentTypes = () => {
    crud.getAll('paymenttypes', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Companies:', err);
        return;
      }
      if (res?.status === 200) {
        setpaymentTypes(res?.data?.data);
      } else {
      }
    });
  };

  const handleSubmitnew = (values) => {
    startCreateLoading();

    const CombinedData = {
      ...values,
      paymentType: Number(values?.paymentType),
      accountNo: values?.accountNo,
      wpsAgentId: Number(values?.wpsAgentId),
      wpsPersonal: Number(values?.wpsPersonal),
      empIdCardProvided: Number(values.empIdCardProvided),
      leaveSalaryCostCenterId: Number(values?.leaveSalaryCostCenterId),
    };
    crud.create(
      `employees/${createdEmployee?.employeeId}/employee-details/${createdEmployee?.employeeDetailId}`,
      CombinedData,
      (err, res) => {
        if (err) {
          stopCreateLoading();
          toast.error('Error creating employee:', err);
          return;
        }
        if (res?.status === 201) {
          toast.success('Employee Account created successfully');
          setValues(4);
          setEnabledTabs(1);
          setEnabledTabs(2);
          setEnabledTabs(3);
          setEnabledTabs(4);
          setEnabledTabs(5);
          setEnabledTabs(6);
          stopCreateLoading();
        } else {
          stopCreateLoading();
        }
      }
    );

    stopCreateLoading();
  };
  return (
    <>
      <GlassCard className='p-4'>
        <form onSubmit={handleSubmit(handleSubmitnew)}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <FmAutoComplete
                name='paymentType'
                control={control}
                options={paymentTypes}
                label='Payment Type'
                displayField='paymentTypeName'
                optionFields={['paymentTypeName']}
                valueKey='paymentTypeId'
                rules={{
                  required: 'Payment Type is required',
                }}
              />
            </Grid>

            <Grid item md={6}>
              <FmAutoComplete
                name='bankId'
                control={control}
                options={banks}
                label='Bank Name'
                displayField='bankName'
                optionFields={['bankName']}
                valueKey='bankId'
                rules={{
                  required: 'Bank Name is required',
                }}
              />
            </Grid>

            <Grid item md={6}>
              <FmTextField
                name='accountNo'
                label='Account No./IBAN No.'
                control={control}
                prefix='AE'
              />
            </Grid>

            <Grid item md={6}>
              <FmSearchableSelect
                name='wpsAgentId'
                control={control}
                apiUrl='wpsagents'
                valueField='wpsAgentId'
                headerField={['Code', 'Name']}
                labelField={['wpsAgentCode', 'wpsAgentName']}
                showField={['wpsAgentName']}
                pageSize={20}
                label={'WPS Agent'}
              />
            </Grid>

            <Grid item md={6}>
              <FmTextField
                name='wpsPersonal'
                label='WPS Personal'
                control={control}
                type='text'
                rules={{
                  required: 'WPS Personal is required',
                }}
              />
            </Grid>

            <Grid item md={6}>
              <FmSearchableSelect
                name='leaveSalaryCostCenterId'
                control={control}
                apiUrl='costcenters'
                valueField='costCenterId'
                headerField={['Code', 'Name']}
                labelField={['costCenterCode', 'costCenterName']}
                showField={['costCenterName']}
                pageSize={20}
                label={'Leave Salary Cost Center'}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitnew)}
            onCancel={() => setValues(2)}
            onReset={reset}
            submitLoading={createLoading}
            cancelLoading={false}
          />
        </form>
      </GlassCard>
    </>
  );
};

export default AccountDetails;
