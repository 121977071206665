import React from 'react';
import { Box, Button } from '@mui/material';

interface MRTSaveAndCancelProps {
  onClickCancel?: any;
  onClickSave?: any;
}

const MRTSaveAndCancel: React.FC<MRTSaveAndCancelProps> = React.memo(
  (props) => {
    const { onClickCancel, onClickSave } = props;

    return (
      <Box className='flex items-center gap-1'>
        <Button
          className='px-3 py-1'
          variant='outlined'
          onClick={onClickCancel}
        >
          Cancel
        </Button>
        <Button variant='contained' className='px-3 py-1' onClick={onClickSave}>
          Save
        </Button>
      </Box>
    );
  }
);

export default MRTSaveAndCancel;
