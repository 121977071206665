import React, { useEffect, useState } from 'react';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import { useNavigate, useParams } from 'react-router-dom';

const GridSize = 2.4;
const schema = yup.object().shape({
  salesQuotationName: yup.string()
    .required('Sales Quotation Name is required'),
  workOrderId: yup.number()
    .integer('Work Order ID must be an integer')
    .positive('Work Order ID must be positive')
    .required('Work Order ID is required'),
  regionId: yup.number()
    .integer('Region ID must be an integer')
    .positive('Region ID must be positive')
    .required('Region ID is required'),
  locationId: yup.number()
    .integer('Location ID must be an integer')
    .positive('Location ID must be positive')
    .required('Location ID is required'),
  paymentTerms: yup.string()
    .required('Payment Terms are required'),
  narration: yup.string()
    .required('Narration is required'),
  deliveryTerms: yup.string()
    .required('Delivery Terms are required'),
  subject: yup.string()
    .required('Subject is required'),
  notes: yup.string()
    .required('Notes are required'),
  contactPersonId: yup.number()
    .integer('Contact Person ID must be an integer')
    .positive('Contact Person ID must be positive')
    .required('Contact Person ID is required'),
  validity: yup.string()
    .required('Validity is required'),
  remarks: yup.string()
    .required('Remarks are required'),
  attn: yup.string()
    .required('Attention is required'),
  quotationStatusId: yup.number()
    .integer('Quotation Status ID must be an integer')
    .positive('Quotation Status ID must be positive')
    .required('Quotation Status ID is required')
});
const AddCreateSalesQuotation = (props) => {
  const { type, setGetData } = props;
  const crud = new Crud_Service();
  const navigate=useNavigate()
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [editingRowData, setEditingRowData] = useState(null);
  const { control, handleSubmit, reset,setValue} = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const id=useParams()
  useEffect(() => {
    if (id?.id) {
      (async () => {
        await crud.getSingle('salesquotations',id.id, (err, res) => {
          if (res?.status === 200) {
            setEditingRowData(res.data);
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
          } else {
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);
  const handleEditItem = async (values) => {
    const data = {
      ...values,
      quotationDate: dayjs(values?.quotationDate).format('YYYY-MM-DD'),
      contactPersonId:
        values.contactPersonId,
      companyId: values?.companyId,
      locationId: values.locationId,
      regionId: values.regionId,
      workOrderId: values.workOrderId,
      quotationStatusId:
        values.quotationStatusId,
      status: 2,
      salesOrderTypeId: type,
    };
    await crud.update(
      'salesquotations',
       id?.id,
      data,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Updated Successfully');
          navigate(-1)
        } else {
        }
      }
    );
  };

  const submitSalesQuotation = async (values: any) => {
    startCreateLoading();
    const data = {
      ...values,
      quotationDate: dayjs(values?.quotationDate).format('YYYY-MM-DD'),
      salesOrderTypeId: type,
      quotationStatusId: 1,
    };
    await crud.create('salesquotations', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Added Successfully');
        navigate(-1)
        reset();
        setGetData(true);
      } else {
        stopCreateLoading();
      }
    });
  };
  const [, setSelectedClient] = React.useState(null);

  const handleSelectedClient = (e) => {
    setSelectedClient(e);
  };
  return (

    <DashboardLayout
    title='Create Salse Quotation'
    hasSubmenu
    menu={Nav[12].child[2].children}
  >
    <GlassCard className='p-3'>
      <Grid container spacing={2}>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='companyId'
            label='Company'
            apiUrl={'companies'}
            valueField='companyId'
            labelField={['companyName', 'companyCode']}
            showField={['companyName', 'companyCode']}
            control={control}
            defaultValue={{
              companyId:editingRowData?.companyId,
              companyName:editingRowData?.companyName
            }}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='clientId'
            label='Client'
            apiUrl='clients'
            valueField='clientId'
            onChangeProp={handleSelectedClient}
            labelField={['clientName', 'clientCode']}
            showField={['clientName', 'clientCode']}
            control={control}
            defaultValue={{
              clientId:editingRowData?.clientId,
              clientName:editingRowData?.clientName
            }}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='salesQuotationName'
            label='Sales Quotation Name'
            required
            control={control}
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmDatePicker
            name='quotationDate'
            control={control}
            label='Quotation Date'
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='workOrderId'
            label='Work Order'
            apiUrl={'workorders'}
            valueField='workOrderId'
            labelField={['workOrderName', 'autoWorkOrderCode']}
            required
            showField={['workOrderName', 'autoWorkOrderCode']}
            control={control}
            defaultValue={{
              workOrderId:editingRowData?.workOrderId,
              workOrderName:editingRowData?.workOrderName
            }}
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='regionId'
            label='Region'
            apiUrl={'regions'}
            valueField='regionId'
            labelField={['regionName', 'regionCode']}
            required
            showField={['regionName', 'regionCode']}
            control={control}
            defaultValue={{
              regionId:editingRowData?.regionId,
              regionName:editingRowData?.regionName
            }}
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='locationId'
            label='Supply Location'
            apiUrl={'locations'}
            required
            valueField='locationId'
            labelField={['locationName', 'locationCode']}
            showField={['locationName', 'locationCode']}
            control={control}
            defaultValue={{
              locationId:editingRowData?.locationId,
              locationName:editingRowData?.locationName
            }}
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmTextField
            name='paymentTerms'
            required
            label='Payment Terms'
            control={control}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField name='narration' label='Narration' control={control} />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='deliveryTerms'
            label='Delivery Terms'
            required
            control={control}
          />{' '}
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='subject'
            label='Subject'
            control={control}
            required
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='contactPersonId'
            label='Contact Person'
            apiUrl={'loginusers'}
            required
            valueField='loginUserId'
            labelField={['loginUserName']}
            showField={['loginUserName']}
            control={control}
            defaultValue={{
              contactPersonId:editingRowData?.contactPersonId,
              loginUserName:editingRowData?.loginUserName
            }}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField name='notes' label='Notes' required control={control} />{' '}
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='validity'
            label='Validity'
            required
            control={control}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='remarks'
            required
            label='Remarks'
            control={control}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField name='attn' label='Attn' required control={control} />
        </Grid>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='quotationStatusId'
            label='Quotation Status'
            apiUrl={'salesquotations/statuses'}
            required
            valueField='salesQuotationStatusId'
            labelField={['salesQuotationStatusName']}
            showField={['salesQuotationStatusName']}
            control={control}
            defaultValue={{
              quotationStatusId:editingRowData?.quotationStatusId,
              salesQuotationStatusName:editingRowData?.salesQuotationStatusName
            }}
          />{' '}
        </Grid>

        <Grid item md={12}>
          <ActionButtons
            onSubmit={handleSubmit(id?.id ? handleEditItem:submitSalesQuotation)}
            onReset={reset}
            onCancel={()=>navigate(-1)}
            submitLoading={createLoading}
            cancelLoading={false}
            submitText='Create'
          />
        </Grid>
      </Grid>
    </GlassCard>
    </DashboardLayout>
  );
};

export default AddCreateSalesQuotation;
