import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader';
import { CreateButton } from '../../../components/small/Buttons';
import GlassCard from '../../../components/small/GlassCard.tsx';
import DataTable from '../../../components/DataTable.tsx';
import Crud_Service from '../../../apis/CrudService';
import { StatusBadge } from '../../../components/_form/FormElements';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import { ToolbarBoxesFlex } from '../../../components/boxes/Boxes.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { toast } from 'react-toastify';
import EnquiryButtonShape from '../../../components/boxes/EnquiryButtonShape.tsx';
import DataGridHeaderTooltip from '../../../components/datagrid/DataGridHeaderTooltip.tsx';
import { TableAvatarStatus } from '../../../components/_form/TableAvatar.jsx';
import TableTick from '../../../components/datagrid/TableTick.tsx';

const StatusIcons = [
  {
    icon: <Icon icon='mdi:book-open-page-variant-outline' />,
  },
  {
    icon: <Icon icon='mdi:comment-text-outline' />,
  },
  {
    icon: <Icon icon='mdi:credit-card-contactless' />,
  },
  {
    icon: <Icon icon='mdi:award' />,
  },
  {
    icon: <Icon icon='mdi:block' />,
  },
  {
    icon: <Icon icon='mdi:cancel-octagon-outline' />,
  },
  {
    icon: <Icon icon='mdi:checkbox-marked-circle-auto-outline' />,
  },
];

const statusIconMap = {
  Open: StatusIcons[0].icon,
  Submitted: StatusIcons[1].icon,
  'Under Follow-up': StatusIcons[2].icon,
  Awarded: StatusIcons[3].icon,
  'Not Awarded': StatusIcons[4].icon,
  Cancelled: StatusIcons[5].icon,
  Closed: StatusIcons[6].icon,
};

const statusColorsMap = {
  Open: 'green',
  Submitted: 'blue',
  'Under Follow-up': 'indigo',
  Awarded: 'cyan',
  'Not Awarded': 'orange',
  Cancelled: 'red',
  Closed: 'lime',
};

const Enquiry = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [statusActive, setStatusActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enquiryCounts, setEnquiryCounts] = useState([]);
  const [tablerecordCounts, setTableRecordCounts] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const crud = new Crud_Service();

  useEffect(() => {
    getAllEnquiryCounts();
    document.title = `Presales | Enquiry`;
  }, []);

  useEffect(() => {
    if (enquiryCounts?.length > 0) {
      handleStatusClick(0, 0);
    }
    setStatusActive(null);
  }, [
    enquiryCounts,
    pageNumber,
    pageSize,
    searchKeyword,
    sortBy,
    sortDirection,
  ]);

  //get Tender Enquiry Dashboard Counts
  const getAllEnquiryCounts = async () => {
    await crud.getAll('tenders/counts', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Enquiry', err);
        return;
      }
      if (res?.status === 200) {
        setEnquiryCounts(res?.data?.data);
      } else {
        setEnquiryCounts([]);
      }
    });
  };

  //Load Grid Data Based on Selected Enquiries
  const handleStatusClick = async (index, tenderStatusId) => {
    setLoading(true);
    setStatusActive(index);
    await crud.getAll(
      `tenders`,
      {
        pageSize,
        pageNumber,
        tenderStatusId,
        searchKeyword,
        sortBy,
        sortDirection,
      },
      (err, res) => {
        if (err) {
          toast.error('Error fetching Tenders:', err);
          setLoading(false);
          return;
        }
        if (res?.status === 200) {
          setTableRecordCounts(res?.data);
          const data = res?.data?.data || [];
          const transformedData = data.map((row) => ({
            ...row,
            id: row?.tenderId,
          }));
          setRows(transformedData);
        } else {
          setRows([]);
        }
        setLoading(false);
      }
    );
  };

  //Pagination and Filter
  const handlePagination = (model) => {
    setPageSize(model?.pageSize);
    setPageNumber(model?.page + 1);
  };

  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    } else {
      setSortBy('');
      setSortDirection('');
    }
  };

  const handleViewClickOpen = async (props) => {
    navigate(`view/${props?.tenderId}`);
  };

  const handleEditClick = (props) => {
    navigate(`edit/${props?.tenderId}`);
  };

  const handleDelete = async (props) => {
    await crud.remove('tenders', props?.tenderId, (_err, res) => {
      if (res?.status === 204) {
        handleStatusClick(0, 0);
        getAllEnquiryCounts();
        toast.success('Enquiry Deleted Successfully');
      } else {
      }
    });
  };
  const Actions = [
    //TODO: for Future Use
    // {
    //   name: t('view external'),
    //   icon: <Icon icon='lucide:external-link' />,
    //   onClick: null,
    // },
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 115,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={Actions?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'tenderName',
      headerName: 'Enquiry Name',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <TableAvatarStatus
            name={params.value}
            desc={params.row.tenderNumber}
            icon={statusIconMap[params?.row?.tenderStatusName] || null}
          />
        );
      },
    },
    {
      field: 'companyName',
      headerName: 'Company',
      minWidth: 200,
    },
    {
      field: 'requestForEstimate',
      headerName: (
        <DataGridHeaderTooltip name='RFE' title='Request For Estimate' />
      ),
      width: 80,
    },
    {
      field: 'clientName',
      headerName: 'Client',
      minWidth: 180,
    },
    {
      field: 'tenderEnquiryTypeName',
      headerName: 'Mode of Enquiry',
      minWidth: 150,
    },
    {
      field: 'tenderProjectName',
      headerName: 'Enquiry Type',
      minWidth: 180,
    },

    {
      field: 'tenderFormatName',
      headerName: 'Enquiry Format',
      minWidth: 160,
    },
    {
      field: 'bidBond',
      headerName: 'BidBond',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <TableTick
            type={params.value}
            title={params.value === true ? 'Yes' : 'No'}
          />
        );
      },
    },
    {
      field: 'autoRenewal',
      headerName: 'Auto Renewal',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <TableTick
            type={params.value}
            title={params.value === true ? 'Yes' : 'No'}
          />
        );
      },
    },
    {
      field: 'subContractRequired',
      headerName: 'Sub contract requried',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <TableTick
            type={params.value}
            title={params.value === true ? 'Yes' : 'No'}
          />
        );
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },

    {
      field: 'lastDate',
      headerName: 'Last Date',
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'buyTenderDocument',
      headerName: (
        <DataGridHeaderTooltip name='BED' title='Buy Enquiry document' />
      ),
      minWidth: 60,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <TableTick
            type={params.value}
            title={params.value === true ? 'Yes' : 'No'}
          />
        );
      },
    },
    {
      field: 'expressOfInterest',
      headerName: 'Express of interest',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <TableTick
            type={params.value}
            title={params.value === true ? 'Yes' : 'No'}
          />
        );
      },
    },

    {
      field: 'tenderStatusName',
      headerName: 'Tender Status',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params?.value}
            type={
              (params?.value === 'Open' && 'green') ||
              (params?.value === 'Awarded' && 'purple') ||
              (params?.value === 'Submitted' && 'orange') ||
              (params?.value === 'Under Follow-up' && 'blue') ||
              (params?.value === 'Not Awarded' && 'indigo') ||
              (params?.value === 'Cancelled' && 'red') ||
              (params?.value === 'Closed' && 'teal')
            }
          />
        );
      },
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      minWidth: 200,
    },
  ];

  const counters = [];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <DashboardLayout
      title='Enquiries'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <PageHeader counters={counters} />
            <Box>
              <CreateButton name={'New'} onClick={() => navigate('create')} />
            </Box>
          </Box>
        </>
      }
    >
      <Box className='flex flex-col h-full gap-2'>
        <Box className='flex w-full overflow-hidden gap-2 flex-wrap'>
          <EnquiryButtonShape
            name='All'
            count={enquiryCounts.reduce((sum, item) => sum + item.count, 0)}
            onClick={() => handleStatusClick(null, 0)}
            active={statusActive === null}
            icon={<Icon icon='mdi:email-outline' />}
            color={statusActive === null ? 'active' : 'yellow'}
          />
          {enquiryCounts
            ?.sort((a, b) => a?.tenderStatusId - b?.tenderStatusId)
            .map((item, index) => (
              <EnquiryButtonShape
                key={item?.tenderStatusId}
                name={item?.tenderStatusName}
                count={item?.count}
                onClick={() => {
                  handleStatusClick(index, item?.tenderStatusId);
                }}
                active={statusActive === index}
                icon={StatusIcons[index].icon}
                color={
                  statusActive === index
                    ? 'active'
                    : statusColorsMap[item?.tenderStatusName]
                }
              />
            ))}
        </Box>

        <GlassCard className='h-full-css flex-1 min-h-0 rounded-none'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={loading}
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              getRowClassName={(params) =>
                `${
                  params.row.workingStatus === 'InActive' &&
                  'bg-red-500 bg-opacity-15'
                }`
              }
              pageSizeOptions={[2, 10, 20, 50, 100]}
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) => handlePagination(model)}
              onSortModelChange={(model) => handleSortChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tablerecordCounts?.total}
              slots={{
                toolbar: () => (
                  <ToolbarBoxesFlex>
                    <Box className='w-full' sx={{ maxWidth: '350px' }}>
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />
                    </Box>
                  </ToolbarBoxesFlex>
                ),
              }}
            />
          </Box>
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default Enquiry;
