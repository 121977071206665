import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const ElementLoader = () => {
  return (
    <Box className='flex justify-center'>
      <CircularProgress />
    </Box>
  );
};

export default ElementLoader;
