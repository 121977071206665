import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import useCommonFetchApi from '../helpers/useCommonFetchApi.tsx';

const HorizantalSteps = (props) => {
  const { data } = props;
  const [progressValue, setProgressValue] = useState([]);

  // Get All Tender Counts
  const { data: tenderCounts } = useCommonFetchApi('tenders/counts');

  const updateActiveState = (tenderCounts, data) => {
    if (!tenderCounts || tenderCounts.length === 0) {
      return [];
    }

    return tenderCounts
      ?.sort((a, b) => a?.tenderStatusId - b?.tenderStatusId)
      ?.map((item, index) => {
        const matchingData = data?.find(
          (d) => d.tenderStatusId === item.tenderStatusId
        );

        return {
          ...item,
          name: item?.tenderStatusName,
          activeState: matchingData ? 1 : 0,
        };
      });
  };

  useEffect(() => {
    if (tenderCounts && data) {
      const updatedTenderCounts = updateActiveState(tenderCounts, data);
      setProgressValue(updatedTenderCounts);
    }
  }, [tenderCounts, data]);

  return (
    <Box className='flex justify-between'>
      {progressValue?.map((item) => (
        <Box
          className='flex flex-col items-center text-center w-full relative'
          key={item?.tenderStatusId}
          sx={{
            '&:last-child': {
              '& .dividers': {
                display: 'none',
              },
            },
          }}
        >
          <Box
            className={`dividers absolute ${
              progressValue?.length === item?.tenderStatusId && 'hidden'
            }`}
            sx={{
              borderTop: '3px dashed',
              borderColor:
                item?.activeState === 1 ? 'primary.main' : 'border.main',
              left: '50%',
              width: '100%',
              top: '25px',
            }}
          ></Box>
          <Box
            sx={{
              width: '45px',
              height: '45px',
              bgcolor:
                item?.activeState === 1 ? 'primary.main' : 'background.white',
              borderRadius: '50%',
              border: '1px solid',
              borderColor:
                item?.activeState === 1 ? 'primary.main' : 'border.main',
            }}
            className='flex justify-center items-center relative'
          >
            {item?.activeState === 1 ? (
              <Box
                className='flex justify-center items-center text-4xl'
                sx={{ color: 'text.white' }}
              >
                <Icon icon='line-md:check-all' />
              </Box>
            ) : (
              <Box className='font-bold'>{item?.tenderStatusId}</Box>
            )}
          </Box>
          <h3
            className={`font-semibold mt-3 ${
              item?.activeState === 0 && 'opacity-50'
            } `}
          >
            {item?.tenderStatusName}
          </h3>
        </Box>
      ))}
    </Box>
  );
};

export default HorizantalSteps;
