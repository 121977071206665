import React, { useEffect } from 'react';
import { Box, Grid, Button, TextField } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';

const TotalBox = (props) => {
  const { amcSheetsPercent, setAmcSheetsPercent, total, handleTotalSubmit } =
    props;

  const calculateCost = (base, percentage) => {
    return percentage > 0 ? (base * percentage) / 100 : 0;
  };

  useEffect(() => {
    const overHeadCost =
      total + calculateCost(total, amcSheetsPercent?.overHeadOverAllPercent);

    const contingencyCost =
      overHeadCost +
      calculateCost(overHeadCost, amcSheetsPercent?.contingencyOverAllPercent);

    const otherCost =
      contingencyCost +
      calculateCost(contingencyCost, amcSheetsPercent?.otherOverAllPercent);

    const markUpCost =
      otherCost +
      calculateCost(otherCost, amcSheetsPercent?.markUpOverAllPercent);

    const discountCost =
      markUpCost -
      calculateCost(markUpCost, amcSheetsPercent?.discountOverAllPercent);

    const negotiationCost =
      discountCost -
      calculateCost(discountCost, amcSheetsPercent?.negotiationPercent);

    const totalEstimationCost = negotiationCost;

    setAmcSheetsPercent((prev) => ({
      ...prev,
      overHeadCost: overHeadCost.toFixed(2),
      contingencyCost: contingencyCost.toFixed(2),
      otherCost: otherCost.toFixed(2),
      markUpCost: markUpCost.toFixed(2),
      discountCost: discountCost.toFixed(2),
      negotiationCost: negotiationCost.toFixed(2),
      totalEstimationCost: totalEstimationCost.toFixed(2),
    }));
  }, [
    amcSheetsPercent?.overHeadOverAllPercent,
    amcSheetsPercent?.contingencyOverAllPercent,
    amcSheetsPercent?.otherOverAllPercent,
    amcSheetsPercent?.markUpOverAllPercent,
    amcSheetsPercent?.discountOverAllPercent,
    amcSheetsPercent?.negotiationPercent,
    total,
  ]);

  return (
    <Box className='mt-5'>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <GlassCard className='p-3'>
            <h3 className='font-semibold mb-2'>{`Total - ${total}`}</h3>
            <Box
              sx={{
                '& table': {
                  width: '100%',
                  '& td': {
                    padding: '3px 10px',
                    border: '1px solid',
                    borderColor: 'border.main',
                  },
                },
              }}
            >
              <table>
                <tbody>
                  <tr className='font-semibold'>
                    <td>No</td>
                    <td>Total</td>
                    <td align='center'>% Value</td>
                    <td align='center'>Cost in AED</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Over Head value in %</td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.overHeadOverAllPercent}
                        fullWidth
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value) || 0;

                          if (value < 0) value = 0;
                          setAmcSheetsPercent((prev) => ({
                            ...prev,
                            overHeadOverAllPercent: value,
                          }));
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.overHeadCost}
                        disabled
                        fullWidth
                        placeholder='Cost in AED'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Contingency value in %</td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.contingencyOverAllPercent}
                        fullWidth
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value) || 0;
                          if (value < 0) value = 0;
                          setAmcSheetsPercent((prev) => ({
                            ...prev,
                            contingencyOverAllPercent: value,
                          }));
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.contingencyCost}
                        disabled
                        fullWidth
                        placeholder='Cost in AED'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Other Taxes if any in %</td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.otherOverAllPercent}
                        fullWidth
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value) || 0;
                          if (value < 0) value = 0;
                          setAmcSheetsPercent((prev) => ({
                            ...prev,
                            otherOverAllPercent: value,
                          }));
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.otherCost}
                        disabled
                        fullWidth
                        placeholder='Cost in AED'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Mark up value in %</td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.markUpOverAllPercent}
                        fullWidth
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value) || 0;
                          if (value < 0) value = 0;
                          setAmcSheetsPercent((prev) => ({
                            ...prev,
                            markUpOverAllPercent: value,
                          }));
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.markUpCost}
                        disabled
                        fullWidth
                        placeholder='Cost in AED'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Discount Value in %</td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.discountOverAllPercent}
                        fullWidth
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value) || 0;
                          if (value < 0) value = 0;
                          setAmcSheetsPercent((prev) => ({
                            ...prev,
                            discountOverAllPercent: value,
                          }));
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.discountCost}
                        disabled
                        fullWidth
                        placeholder='Cost in AED'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Negotiation Value in %</td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.negotiationPercent}
                        fullWidth
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value) || 0;
                          if (value > 100) value = 100;
                          if (value < 0) value = 0;
                          setAmcSheetsPercent((prev) => ({
                            ...prev,
                            negotiationPercent: value,
                          }));
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.negotiationCost}
                        disabled
                        fullWidth
                        placeholder='Cost in AED'
                      />
                    </td>
                  </tr>
                  <tr className='font-bold bg-teal-50 text-teal-600'>
                    <td colSpan={3} align='right' className='py-4'>
                      Total Estimated Cost
                    </td>
                    <td align='center' className='py-4'>
                      {amcSheetsPercent?.totalEstimationCost
                        ? `${amcSheetsPercent?.totalEstimationCost} AED`
                        : '0 AED'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </GlassCard>
        </Grid>
        <Grid item md={4}>
          <GlassCard className='p-3'>
            <TextField
              fullWidth
              label='Note'
              multiline
              value={amcSheetsPercent?.note}
              onChange={(e) =>
                setAmcSheetsPercent((prev) => ({
                  ...prev,
                  note: e.target.value,
                }))
              }
            />
            <Box className='mt-5 flex justify-end'>
              <Button
                variant='contained'
                color='primary'
                onClick={() => handleTotalSubmit(amcSheetsPercent)}
              >
                Submit
              </Button>
            </Box>
          </GlassCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TotalBox;
