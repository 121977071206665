import React from 'react';
import { Grid } from '@mui/material';
import { ViewField } from '../_form/FormElements';
import useDateFormatter from './useDateFormatter';
import FmViewDateField from '../_mui/FmViewDateField.tsx';

const DynamicViewFields = ({ data, fields, fieldFormat = '' }) => {
  const formatDate = useDateFormatter();

  const cleanLabel = (label) => {
    if (label.endsWith('Id')) {
      return label.slice(0, -2);
    }
    return label;
  };

  const formatTitle = (key, title) => {
    if (
      key.toLowerCase().endsWith('date') ||
      key.toLowerCase().endsWith('day')
    ) {
      return formatDate(title);
    }
    if (fieldFormat[key]) {
      return fieldFormat[key](title);
    } else {
      return title;
    }
  };

  return (
    <Grid container spacing={3}>
      {fields?.map((field) => (
        <Grid item md={field?.md || 6} key={field?.key}>
          {field.key.toLowerCase().endsWith('date') ||
          field.key.toLowerCase().endsWith('day') ? (
            <FmViewDateField
              title={cleanLabel(field?.label)}
              value={data[field?.key]}
            />
          ) : (
            <ViewField
              label={cleanLabel(field?.label)}
              title={formatTitle(field?.key, data[field?.key])}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default DynamicViewFields;
