import React, { useEffect, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { StyledCard } from '../../../components/_form';
import { Box, Button } from '@mui/material';
import Crud_Service from '../../../apis/CrudService';
import { Icon } from '@iconify/react/dist/iconify.js';
import StaticTable from './../../../components/_form/StaticTable';
import { StatusBadge } from '../../../components/_form/FormElements';
import apiInstance from '../../../apis/ApiService';
import { toast } from 'react-toastify';

const EmployeeEducationDetails = (props) => {
  const crud = new Crud_Service();
  const [item, setItem] = useState(null);
  const { id, i, value } = props;

  useEffect(() => {
    crud.getSingle(`employees/${id}/educations`, '', (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        const qualifications = res?.data?.data?.map((item) => ({
          ...item,
          attestationStatus: (
            <StatusBadge
              title={item?.attestationStatus === 1 ? 'Yes' : 'No'}
              type='green'
            />
          ),
          attachment: item?.fileId ? (
            <Button
              startIcon={<Icon icon='basil:attach-outline' />}
              onClick={() => handleDownload(item?.fileId, item?.fileName)}
            >
              Download
            </Button>
          ) : (
            '-'
          ),
        }));
        setItem(qualifications);
      }
    });
  }, [id]);

  const handleDownload = async (params, fileName) => {
    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${params}`);
      const link = document.createElement('a');
      link.href = imageUrl;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.error('Error fetching image:', err);
    }
  };

  return (
    <GlassCard className={value === i ? 'w-full p-5' : 'hidden'}>
      <StyledCard title={'Employee Education Details'}></StyledCard>

      <Box className='mt-5'>
        <StaticTable
          columns={[
            { field: 'qualificationName', headerName: 'Qualification' },
            { field: 'attestationStatus', headerName: 'Attestation Status' },
            {
              field: 'equivalencyCertificate',
              headerName: 'Equivalency Certificate',
            },
            { field: 'completedYear', headerName: 'Passed Out Year' },
            { field: 'attachment', headerName: 'Attachment' },
          ]}
          data={item}
          // data={[
          //   {
          //     qualification: 'B.E',
          //     education: 'Bachelor Degree',
          //     attestation: <StatusBadge title='Yes' type='green' />,
          //     equivalency: 'equivalency',
          //     passedout: 'passedout',
          //     attachment: (
          //       <Button startIcon={<Icon icon='basil:attach-outline' />}>
          //         Download
          //       </Button>
          //     ),
          //   },
          // ]}
        />
      </Box>
    </GlassCard>
  );
};

export default EmployeeEducationDetails;
