import React, { useState, useEffect } from 'react';
import {
  Stack,
  Box,
  Button,
  Tooltip,
  IconButton,
  InputAdornment,
  TextField,
  Chip,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import FilterButtonSearch from '../components/FilterButtonSearch';

const PageFilter = ({
  children,
  SelectFilter,
  searchFilter,
  clearFilterCallback,
  searchValue,
  setSearchValue,
}) => {
  const [options, setOptions] = useState([]);
  const [updatedOptions, setUpdatedOptions] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { t } = useTranslation();
  const [value, setValue] = useState(searchValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchValue(value);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (value) {
      searchFilter(value);
    }
  }, [value, searchFilter]);

  const debouncedHandleFilter = (from, to) => {
    const handler = setTimeout(() => {}, 500);
    return () => {
      clearTimeout(handler);
    };
  };

  const handleCheckboxChange = (id, i) => {
    const updatedOptions = [...options];
    updatedOptions[i].child = options[i].child.map((option) =>
      option.id === id ? { ...option, checked: !option.checked } : option,
    );
    setOptions(updatedOptions);
    setUpdatedOptions(updatedOptions[i].child);
  };

  const handleDelete = () => {
    setOptions(SelectFilter);
    setUpdatedOptions([]);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    debouncedHandleFilter(date, toDate);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    debouncedHandleFilter(fromDate, date);
  };

  const clearFilter = () => {
    setFromDate(null);
    setToDate(null);
    clearFilterCallback();
  };

  const handleSearchChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <Box
        className='w-full p-3'
        sx={{
          borderBottom: '1px solid',
          borderColor: 'border.main',
        }}
      >
        <div className='flex flex-row items-center'>
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            justifyContent='space-between'
            sx={{ width: '100%' }}
          >
            <Stack direction='row' spacing={1} alignItems='center'>
              <TextField
                placeholder={`Search Name..`}
                id='outlined-size-small'
                size='small'
                fullWidth
                value={value}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='start'
                      sx={{ cursor: 'pointer' }}
                      onClick={() => searchFilter(searchValue)}
                    >
                      <Icon icon='material-symbols:search' />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  cursor: 'pointer',
                  width: 300,
                  '& .MuiInputBase-root': {
                    height: '45px',
                    borderRadius: '10px',
                    fontSize: 'inherit',
                    border: '1px solid',
                    borderColor: 'border.main',
                    transition: '0.5s all',
                  },
                  '& .Mui-focused': {},
                  '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none',
                  },
                }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '200px' }}>
                  <DatePicker
                    label='From Date'
                    value={fromDate}
                    onChange={handleFromDateChange}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </Box>
                <Box sx={{ width: '200px' }}>
                  <DatePicker
                    label='To Date'
                    fullWidth
                    value={toDate}
                    onChange={handleToDateChange}
                    renderInput={(props) => (
                      <TextField variant='standard' {...props} />
                    )}
                  />
                </Box>
                <Box className='flex items-center'>
                  <Button disabled={!fromDate || !toDate}>{t('apply')}</Button>
                  <Tooltip title='Clear Filter'>
                    <IconButton onClick={clearFilter}>
                      <Icon icon='fluent-mdl2:clear-filter' />
                    </IconButton>
                  </Tooltip>
                </Box>
              </LocalizationProvider>

              {options.map((select, i) => (
                <FilterButtonSearch
                  name={select.name}
                  data={select.child}
                  key={i}
                  column={2}
                  handleCheckboxChange={(id) => handleCheckboxChange(id, i)}
                />
              ))}
            </Stack>

            {children}
          </Stack>
        </div>
      </Box>

      {updatedOptions.length > 0 && (
        <Box
          className='p-2 w-full'
          sx={{ borderBottom: '1px solid', borderColor: 'border.main' }}
        >
          <Stack direction='row' spacing={1} flexWrap='wrap' useFlexGap>
            {updatedOptions
              .filter((updatedOption) => updatedOption.checked)
              .map((selectedOption) => (
                <Chip
                  key={selectedOption.id}
                  label={selectedOption.value}
                  onDelete={handleDelete}
                />
              ))}
            <Chip label='Clear All Filters' onDelete={handleDelete} />
          </Stack>
        </Box>
      )}
    </>
  );
};

export default PageFilter;
